<template>
    <div class="document-view">
        <div class="actions__container">
            <a v-if="document" :href="documentString" :download="document.documentName" class="download-document__btn"><k-button><i class="fa-solid fa-download"></i> Download</k-button></a> 
        </div>
        <div class="viewer__container">
            <iframe :src="documentPreviewLink" height="100%" width="100%" frameBorder="0" v-if="documentString"></iframe>
        </div>

        <div class="loader-container" v-if="isLoading">
            <k-loader :size="'large'" :type="'infinite-spinner'"></k-loader>
        </div>

        <transition name="dialogfade">
            <k-dialog v-if="showErrorDialog" :title="errorTitle" class="error__dialog" @close="toggleErrorDialog">
                <p class="error-dialog__msg">{{ errorMsg }}</p>
            </k-dialog>
        </transition>
    </div>
</template>

<script>
    import { Loader } from "@progress/kendo-vue-indicators";
    import { Dialog } from '@progress/kendo-vue-dialogs';
    import { Button } from "@progress/kendo-vue-buttons";

    import auth from '../authConfig';
    import apiService from '../api/api.service.js';

    export default {
        name: 'playerManagementView',
        data() {
            return {
                documentId: null,
                document: null,
                documentPreviewLink: null,
                documentString: null,
                errorMsg: '',
                errorTitle: '',
                isLoading: false,
                showErrorDialog: false,
            }
        }, 
        async mounted() {
            this.documentId = this.$route.query.id;

            await this.getHockeyDocument();
        },
        methods: {
            async getHockeyDocument() {
                let self = this;
                self.isLoading = true;

                let tokenResponse = await auth.acquireTokenResponse();

                return apiService.GetHockeyDocument(tokenResponse.accessToken, this.documentId)
                    .then((res) => {
                        self.document = res.data.data.document;
                        self.documentPreviewLink = res.data.data.previewLink;
                        self.documentString = `data:${self.document.documentType};base64,${res.data.data.base64}`;

                        self.isLoading = false;
                    }).catch(error => {
                        console.log(error);
                        this.onRequestError('An Error Occurred', error?.response?.data?.message);
                    });
            },
            onRequestError(errorTitle, errorMsg) {
                this.isLoading = false;

                this.errorTitle = errorTitle;

                if (errorMsg) {
                    this.errorMsg = errorMsg;
                } else {
                    this.errorMsg = 'Please try again';
                }

                this.showErrorDialog = true;
            },
            toggleErrorDialog() {
                this.showErrorDialog = !this.showErrorDialog;

                if(!this.showErrorDialog) {
                    this.errorTitle = '';
                    this.errorMsg = '';
                }
            },
        },
        components: {
            'k-loader': Loader,
            'k-dialog': Dialog,
            'k-button': Button
        }
    }
</script>

<style>
    @import '/src/assets/css/documentView.css';
</style>