/* eslint-disable */
import * as msal from '@azure/msal-browser';

let msalApp;

export default {

    async init() {
        
        // Enforce Singleton
        if (msalApp) return msalApp;

        const config = {
            auth: {
                clientId: '29288be5-5b10-4f4b-8c7a-15844b49dfdb',
                authority: 'https://login.microsoftonline.com/2b7cd331-5fc1-498e-837a-460368566497',
                redirectUri: '/'
            },
            cache: {
                cacheLocation: 'localStorage'
            },
            //system: {
            //    loggerOptions: {
            //        loggerCallback: (level, message, containsPii) => {
            //            if (containsPii) {
            //                return;
            //            }
            //            switch (level) {
            //                case msal.LogLevel.Error:
            //                    console.error(message);
            //                    return;
            //                case msal.LogLevel.Info:
            //                    console.info(message);
            //                    return;
            //                case msal.LogLevel.Verbose:
            //                    console.debug(message);
            //                    return;
            //                case msal.LogLevel.Warning:
            //                    console.warn(message);
            //                    return;
            //                default:
            //                    return;
            //            }
            //        },
            //        logLevel: msal.LogLevel.Verbose
            //    }
            //}
        }

        msalApp = new msal.PublicClientApplication(config);

        return msalApp;
    },

    clientId() {
        if (!msalApp) {
            return null
        }

        return msalApp.config.auth.clientId
    },

    //By default, MSAL.js adds OIDC scopes(openid, profile, email, offline_access) to any login request
    async login(scopes = []) {
        if (!msalApp) return;

        await msalApp.loginRedirect({
            scopes,
            prompt: 'select_account',
            domainHint: '2b7cd331-5fc1-498e-837a-460368566497'
        })
    },

    logout() {
        if (!msalApp) return;

        msalApp.logoutRedirect({
/*            postLogoutRedirectUri: 'https://cgs.goldstarfinancial.com/'*/
        });
    },

    user() {
        if (!msalApp) return null;
        
        const currentAccounts = msalApp.getAllAccounts()
        if (!currentAccounts || currentAccounts.length === 0) {
            // No user signed in
            return null;
        } else if (currentAccounts.length > 1) {
            return currentAccounts[0];
        } else {
            return currentAccounts[0];
        }
    },

    // By default, request token for itself
    async acquireTokenResponse(scopes = ['api://82ee22b7-ba97-4154-ba13-7d8d12faed3f/Files.Read']) {
        if (!msalApp) return null;

        const accessTokenRequest = {
            scopes,
            account: this.user()
        }

        let tokenRes;
        try {
            tokenRes = await msalApp.acquireTokenSilent(accessTokenRequest)
        } catch (err) {
            tokenRes = await msalApp.acquireTokenRedirect(accessTokenRequest)
        }

        if (!tokenRes.accessToken) {
            throw new Error("accessToken missing in response")
        }

        return tokenRes;
    },

    clearLocal() {
        if (msalApp) {
            for (let entry of Object.entries(localStorage)) {
                let key = entry[0]
                if (key.includes('login.windows')) {
                    localStorage.removeItem(key)
                }
            }
        }
    }
}