<template>
    <div class="calendar-card">

        <div v-if="!isLoadingCalendar" class="calendar">
            <div class="legend">
                <h2>Legend</h2>
                <div v-for="(event, index) in resources[0].data" :key="index" class="legend-item">
                    <div class="legend-text">{{ event.text }}</div>
                    <div class="legend-color" :style="{ backgroundColor: event.color }"></div>
                </div>
            </div>
            <k-scheduler class="kendo-calendar" :data-items="dataReturn" view="month" :resources="resources"
                :modelFields="modelFields" :views="views" :editable="editable">
            </k-scheduler>
        </div>
        <div class="recently-added-loader-container" v-if="isLoadingCalendar">
            <k-loader :size="'large'" :type="'infinite-spinner'"></k-loader>
        </div>
    </div>
    <transition name="dialogfade">
        <k-dialog v-if="showErrorDialog" :title="errorTitle" class="error__dialog" @close="toggleErrorDialog">
            <p class="error-dialog__msg">{{ errorMsg }}</p>
        </k-dialog>
    </transition>
</template>
  
<script>

import { Scheduler } from '@progress/kendo-vue-scheduler';
import auth from '../authConfig'
import apiService from '../api/api.service.js';
import { Dialog } from '@progress/kendo-vue-dialogs';
import { Loader } from "@progress/kendo-vue-indicators";

import cloneDeep from 'lodash.clonedeep';

export default {
    components: {
        'k-scheduler': Scheduler,
        'k-dialog': Dialog,
        'k-loader': Loader,
    },
    data() {
        return {
            showErrorDialog: false,
            isLoadingCalendar: false,
            upcomingEvents: [],
            // Allows you to choose what you can edit on the Scheduler
            editable: {
                add: false,
                remove: false,
                drag: false,
                resize: false,
                edit: false,
            },
            modelFields: {
                id: 'TaskID',
                title: 'Subject',
                start: 'Start',
                end: 'End',
                recurrenceRule: 'RecurrenceRule'
            },

            data: [],
            views: [
                { name: 'month', title: "Month" },
                { name: 'day', title: "Day" },
                { name: 'week', title: "Week" },
                { name: 'workweek', title: "Work week" },
                { name: 'agenda', title: "Agenda" },
            ],
            resources: [
                {
                    name: 'Rooms',
                    data: [
                        {
                            text: 'Birthday',
                            value: 1,
                            color: '#32936F',
                        },
                        {
                            text: 'Visa Expiring',
                            value: 2,
                            color: '#140152',

                        },
                        {
                            text: 'Green Card Expiring',
                            value: 3,
                            color: '#6A8D92',
                        },
                        {
                            text: 'Passport Expiring',
                            value: 4,
                            color: '#291528',
                        },
                        {
                            text: 'Agency Contract Expiring',
                            value: 5,
                            color: '#FF5A5F',
                        },
                        {
                            text: 'Playing Contract Expiring',
                            value: 6,
                            color: '#FFBF00',
                        },
                        {
                            text: 'Equipment Contract Expiring',
                            value: 7,
                            color: '#2274A5',
                        },
                        {
                            text: 'Endorsement Contract Expiring',
                            value: 8,
                            color: '#4C3B4D',
                        },
                        {
                            text: 'Invoices Due',
                            value: 9,
                            color: '#D81159',
                        },
                        {
                            text: 'No Trade Reporting',
                            value: 10,
                            color: '#DA70D6'
                        },
                        {
                            text: 'Return From Injury',
                            value: 11,
                            color: '#A36A00'
                        },
                        { 
                            text: 'Draft Eligible',
                            value: 12,
                            color: '#DC4D01'
                        },
                        {
                            text: 'Signing Bonus',
                            value: 13,
                            color: '#4b0082'
                        }
                    ],
                    field: 'eventId',
                    valueField: 'value',
                    textField: 'text',
                    colorField: 'color',
                },
            ],
        };
    },
    computed: {
        dataReturn() {
            return this.data
        },
    },
    methods: {
        parseAdjust(eventDate) {
            const date = new Date(eventDate);
            date.setFullYear(this.currentYear);
            return date;
        },
        toggleErrorDialog() {
            this.showErrorDialog = !this.showErrorDialog;

            if (!this.showErrorDialog) {
                this.errorTitle = '';
                this.errorMsg = '';
            }
        },
        onRequestError(errorTitle, errorMsg) {
            this.isLoadingCalendar = false,
                this.errorTitle = errorTitle;

            if (errorMsg) {
                this.errorMsg = errorMsg;
            } else {
                this.errorMsg = 'Please try again';
            }

            this.showErrorDialog = true;
        },
        async GetAllEventDates() {
            this.isLoadingCalendar = true;
            let self = this;

            await this.$store.dispatch('getNoTradeClauses', false);
            await this.$store.dispatch('getInjuries', false);
            await this.$store.dispatch('getCitizenshipDocuments', false);
            await this.$store.dispatch('getActiveSigningBonuses');

            let noTradeItems = cloneDeep(this.$store.state.noTradeClauses);
            let injuries = cloneDeep(this.$store.state.injuries);
            let citizenshipDocuments = cloneDeep(this.$store.state.citizenshipDocuments.filter(doc => !doc.familyRecord));
            let signingBonuses = cloneDeep(this.$store.state.signingBonuses);

            citizenshipDocuments.forEach(doc => {
                if (doc.type === 'Passport') {
                    doc.passportExpirationDate = doc.expiringDate;
                }

                if (doc.type === 'Green Card') {
                    doc.greencardExpiringDate = doc.expiringDate;
                }

                if (doc.type === 'Visa') {
                    doc.visaExpiringDate = doc.expiringDate;
                }
            });

            let tokenResponse = await auth.acquireTokenResponse();

            apiService.GetAllEventDates(tokenResponse.accessToken)
                .then(res => {
                    if (res.data.data) {

                        self.upcomingEvents = [...res.data.data, ...noTradeItems, ...injuries, ...citizenshipDocuments, ...signingBonuses];
                        this.isLoadingCalendar = false;
                        const tasks = [];

                        self.upcomingEvents.forEach((event) => {
                            const dates = [
                                { eventDate: event.dob, eventid: 1 },
                                { eventDate: event.visaExpiringDate, eventid: 2 },
                                { eventDate: event.greencardExpiringDate, eventid: 3 },
                                { eventDate: event.passportExpirationDate, eventid: 4 },
                                { eventDate: event.agencyContractExpirationDate, eventid: 5 },
                                { eventDate: event.playingContractExpirationDate, eventid: 6 },
                                { eventDate: event.equipmentContractExpirationDate, eventid: 7 },
                                { eventDate: event.endorsementContractExpirationDate, eventid: 8 },
                                { eventDate: event.paymentDue, eventid: 9 },
                                { eventDate: event.dueDate, eventid: 10 },
                                { eventDate: event.returnDate, eventid: 11 },
                                { eventDate: event.draftEligibleDate, eventid: 12},
                                { eventDate: event.bonusDate, eventid: 13}
                            ];

                            // Create a separate task for each date
                            dates.forEach((date, index) => {
                                if (date.eventDate) {
                                    var date1 = new Date(date.eventDate);
                                    var date2 = new Date(date.eventDate);
                                    date2.setDate(date2.getDate() + 1);

                                    const recurrenceRule = date.eventid === 1
                                        ? `FREQ=YEARLY;BYMONTH=${date1.getMonth() + 1};BYMONTHDAY=${date1.getDate()}`
                                        : null;

                                    let id = Math.random().toString(36).slice(-5);

                                    tasks.push({
                                        TaskID: `${id}_${index}`,
                                        Subject: `${event.firstName} ${event.lastName}`,
                                        Start: date1,
                                        End: date2,
                                        isAllDay: true,
                                        eventId: date.eventid,
                                        RecurrenceRule: recurrenceRule,
                                        RecurrenceID: `${id}_${index}`,
                                    });

                                }
                            });
                        });
                        self.data = tasks;
                    }
                }).catch(error => {
                    console.log(error);
                    self.onRequestError('Failed to Fetch Player Events', error?.response?.data?.message);
                });
        },
    },
    async mounted() {
        await this.GetAllEventDates();
    },
};
</script>

  
<style>
.calendar-card {
    height: calc(100% - 130px);
    padding: 20px;
    display: flex;
}

.calendar-card .calendar {
    display: flex;
    width: 100%;
}

.calendar-card .calendar .kendo-calendar {
    flex: 1;
    height: 100% !important;
}

.calendar-card .calendar .legend {
    max-width: 100px;
    background-color: #fff;
    border: 1px solid #ccc;

}

.calendar-card .calendar .legend h2 {
    font-size: 14px;
    padding: 16px;
    text-align: center;
    background-color: #1F1E1C;
    color: #D4B139;
}

.calendar-card .calendar .legend-item {
    padding-top: 5px;
    padding-left: 5px;
    padding-right: 5px;
    padding-bottom: 5px;
}

.calendar-card .calendar .legend-color {
    border-radius: 5px;
    height: 20px;
}

.calendar-card .calendar .legend-text {
    font-size: 10px;
    padding-bottom: 5px;
    margin-left: 5px;
}

.calendar-card .calendar .k-scheduler-body {
    height: 100%;
}

.calendar-card .recently-added-loader-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
}

.calendar-card .k-toolbar {
    background: #1F1E1C;
    color: #D4B139;
}

.calendar-card .k-toolbar button {
    background: #D4B139;
    color: #1F1E1C;
}

.calendar-card .k-toolbar .k-nav-current {
    color: #D4B139;
}

.calendar-card .calendar .kendo-calendar .k-scheduler-body .k-event,
.calendar-card .calendar .kendo-calendar .k-link {
    cursor: default !important;
}
</style>