<template>
    <div class="elite-prospects-search">
        <k-dropdown :data-items="filteredSearchResults"
                    class="search-dropdown"
                    v-model="selectedResult"
                    :filterable="true"
                    :text-field="'display'"
                    :data-item-key="'id'"
                    :filter="searchFilter"
                    :default-item="{ display: 'Player Search', id: 1 }"
                    @change="onSearchResultSelected"
                    @filterchange="onSearchFilterChange"></k-dropdown>

        <elite-prospects-search-dialog :show-dialog="showSearchDialog"
                                       :detail-data="selectedDetail"
                                       @dialogclosed="toggleSearchDialog"></elite-prospects-search-dialog>

        <div class="loader-container" v-if="isLoading">
            <k-loader :size="'large'" :type="'infinite-spinner'"></k-loader>
        </div>

        <transition name="dialogfade">
            <k-dialog v-if="showErrorDialog" :title="errorTitle" class="error__dialog" @close="toggleErrorDialog">
                <p class="error-dialog__msg">{{ errorMsg }}</p>
            </k-dialog>
        </transition>
    </div>
</template>

<script>
    import { DropDownList } from '@progress/kendo-vue-dropdowns';
    import { Loader } from "@progress/kendo-vue-indicators";
    import { Dialog } from '@progress/kendo-vue-dialogs';

    import eliteProspectsSearchDialog from './eliteProspectsSearchDialog.vue';

    import auth from '../authConfig';
    import apiService from '../api/api.service.js';

    export default {
        name: 'eliteProspectsSearchComponent',
        data() {
            return {
                errorMsg: '',
                errorTitle: '',
                filteredSearchResults: [],
                isLoading: false,
                searchFilter: null,
                selectedDetail: null,
                selectedResult: null,
                showErrorDialog: false,
                showSearchDialog: false
            }
        },
        methods: {
            async getPlayerDetail(playerId) {
                this.isLoading = true;

                let tokenResponse = await auth.acquireTokenResponse();

                return apiService.GetEPPlayerDetail(tokenResponse.accessToken, playerId)
                    .then(res => {
                        res.data.data?.gameLogs?.data.forEach(log => {
                            log.game.date = new Date(`${log.game.date}T00:00:00`);
                        });

                        res.data.data?.transfers?.data.forEach(transfer => {
                            transfer.date = new Date(`${transfer.date}T00:00:00`);
                        });
                        
                        this.selectedDetail = res.data.data;
                        console.log(this.selectedDetail)

                        this.isLoading = false;

                        this.toggleSearchDialog();
                    }).catch(error => {
                        console.log(error);
                        this.onRequestError('An Error Occurred', error?.response?.data?.message);
                    })
            },
            async getSearchResults(search) {
                let tokenResponse = await auth.acquireTokenResponse();

                return apiService.GetSearchResults(tokenResponse.accessToken, search)
                    .then(res => {
                        res.data.data.data.player.forEach(player => {
                            player.display = `${player.name} ${player.position ? '(' + player.position + ')' : ''} - ${player.age ?? 'N/A'} y/o - ${player.latestStats?.teamName}`;
                        });
                        this.filteredSearchResults = res.data.data.data.player;
                    }).catch(error => {
                        console.log(error)
                    })
            },
            onRequestError(errorTitle, errorMsg) {
                this.isLoading = false;
                this.isLoadingContracts = false;

                this.errorTitle = errorTitle;

                if (errorMsg) {
                    this.errorMsg = errorMsg;
                } else {
                    this.errorMsg = 'Please try again';
                }

                this.showErrorDialog = true;
            },
            onSearchFilterChange(evt) {
                this.searchFilter = evt.event.value;

                if (this.searchFilter?.length === 0) {
                    this.selectedResult = null;
                    return this.filteredSearchResults = [];
                }
                
                if (this.searchFilter?.length > 0 && this.searchFilter?.length % 4 === 0) {
                    this.getSearchResults(this.searchFilter);
                }
            },
            onSearchResultSelected(evt) {
                this.getPlayerDetail(this.selectedResult.id);
            },
            toggleErrorDialog() {
                this.showErrorDialog = !this.showErrorDialog;

                if(!this.showErrorDialog) {
                    this.errorTitle = '';
                    this.errorMsg = '';
                }
            },
            toggleSearchDialog() {
                this.showSearchDialog = !this.showSearchDialog;

                if (!this.showSearchDialog) {
                    this.selectedResult = null;
                    this.filteredSearchResults = [];
                    this.searchFilter = null;
                }
            }
        },
        components: {
            'k-dropdown': DropDownList,
            'k-loader': Loader,
            'k-dialog': Dialog,
            'elite-prospects-search-dialog': eliteProspectsSearchDialog
        }
    }
</script>

<style>
    @import '/src/assets/css/eliteProspectsSearchComponent.css';
</style>