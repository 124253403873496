<template>
    <div class="create-contract create-spac">
        <transition name="dialogfade">
            <k-dialog v-if="showDialog" class="default__dialog create-contract-dialog create-spac-dialog">
                <div class="icon__container">
                    <i class="fa-solid fa-file-signature"></i>
                </div>
                <div class="header__container">
                    <svg viewBox="0 0 300 35" x="0px" y="0px" xml:space="preserve" fill="none" xmlns="http://www.w3.org/2000/svg" style="enable-background:new 0 0 300 35;"><path fill-rule="evenodd" class="icon__top-bar" clip-rule="evenodd" d="M290,21.74H169.3a19.32,19.32,0,0,1-38.64,0H10c-5.52,0-10,2.53-10,5.66V50H300V27.4C300,24.27,295.52,21.74,290,21.74Z" transform="translate(0 -21.74)" fill="#D4B139"></path></svg>
                </div>
                <div class="create-contract__header">
                    <h1 class="dialog--title">Create SPAC</h1>
                </div>
                <div class="upsert-item__container create-contract">
                    <section class="upsert-item__section">
                        <div class="input__row pair contract-date__row">
                            <label class="input__label">
                                Contract Date
                                <k-datepicker v-model="spac.dateOfContract"></k-datepicker>
                            </label>
                        </div>
                        <h2 class="upsert-item__section--title">Player Information</h2>
                        <div class="input__row pair">
                            <label class="input__label">
                                Player Name
                                <k-input v-model="spac.playerName" />
                            </label>
                            <label class="input__label">
                                Player Date of Birth
                                <k-datepicker v-model="spac.playerDob"></k-datepicker>
                            </label>
                        </div>
                        <div class="input__row pair">
                            <label class="input__label">
                                Player Street Address
                                <k-input v-model="spac.playerStreetAddress" />
                            </label>
                            <label class="input__label">
                                Player City
                                <k-input v-model="spac.playerCity" />
                            </label>
                        </div>
                        <div class="input__row trio">
                            <label class="input__label">
                                Player State/Province
                                <k-input v-model="spac.playerState" />
                            </label>
                            <label class="input__label">
                                Player Zip/Postal Code
                                <k-input v-model="spac.playerZipCode" />
                            </label>
                           <label class="input__label">
                                Player Country
                                <k-input v-model="spac.playerCountry" />
                            </label>
                        </div>
                        <h2 class="upsert-item__section--title">Commission Information</h2>
                        <div class="input__row pair">
                            <label class="input__label">
                                % Paid to Agent (Compensation)
                                <k-numeric-input v-model="spac.agentManagementPercent" :format="'##.00 \\%'" :decimals="2" :min="0"></k-numeric-input>
                            </label>
                            <label class="input__label">
                                Compensation % As Text
                                <k-input v-model="spac.agentManagementTextPercentage" />
                            </label>
                        </div>
                        <div class="input__row pair">
                            <label class="input__label">
                                % Paid to Agent (Endorsements)
                                <k-numeric-input v-model="spac.agentEndorsementPercent" :format="'##.00 \\%'" :decimals="2" :min="0"></k-numeric-input>
                            </label>
                            <label class="input__label">
                                Endorsements % As Text
                                <k-input v-model="spac.agentEndorsementTextPercentage" />
                            </label>
                        </div>
                        <h2 class="upsert-item__section--title">Agent/Guarantor Information</h2>
                        <div class="input__row pair">
                            <label class="input__label">
                                Second Agent
                                <k-dropdown :data-items="contractAgentSelects.filter(agent => agent !== spac.thirdAgent)" 
                                    :default-item="'Select Agent'" 
                                    @change="defaultDropdownSelected($event, 'secondAgent', 'Select Agent')"></k-dropdown>
                            </label>
                            <label class="input__label">
                                Second Guarantor
                                <k-dropdown :data-items="contractAgentSelects.filter(agent => agent !== spac.thirdGuarantor && agent !== spac.fourthGuarantor && agent !== spac.fifthGuarantor)" 
                                    :default-item="'Select Guarantor'" 
                                    @change="defaultDropdownSelected($event, 'secondGuarantor', 'Select Guarantor')"></k-dropdown>
                            </label>
                        </div>
                        <div class="input__row pair">
                            <label class="input__label">
                                Third Agent
                                <k-dropdown :data-items="contractAgentSelects.filter(agent => agent !== spac.secondAgent)" 
                                    :default-item="'Select Agent'" 
                                    @change="defaultDropdownSelected($event, 'thirdAgent', 'Select Agent')"></k-dropdown>
                            </label>
                            <label class="input__label">
                                Third Guarantor
                                <k-dropdown :data-items="contractAgentSelects.filter(agent => agent !== spac.secondGuarantor && agent !== spac.fourthGuarantor && agent !== spac.fifthGuarantor)" 
                                    :default-item="'Select Guarantor'" 
                                    @change="defaultDropdownSelected($event, 'thirdGuarantor', 'Select Guarantor')"></k-dropdown>
                            </label>
                        </div>
                        <div class="input__row pair">
                            <label class="input__label"></label>
                            <label class="input__label">
                                Fourth Guarantor
                                <k-dropdown :data-items="contractAgentSelects.filter(agent => agent !== spac.firstGuarantor && agent !== spac.secondGuarantor && agent !== spac.fifthGuarantor)" 
                                    :default-item="'Select Guarantor'" 
                                    @change="defaultDropdownSelected($event, 'fourthGuarantor', 'Select Guarantor')"></k-dropdown>
                            </label>
                        </div>
                        <div class="input__row pair">
                            <label class="input__label"></label>
                            <label class="input__label">
                                Fifth Guarantor
                                <k-dropdown :data-items="contractAgentSelects.filter(agent => agent !== spac.secondGuarantor && agent !== spac.thirdGuarantor && agent !== spac.fourthGuarantor)" 
                                    :default-item="'Select Guarantor'" 
                                    @change="defaultDropdownSelected($event, 'fifthGuarantor', 'Select Guarantor')"></k-dropdown>
                            </label>
                        </div>
                    </section>
                    <k-action-bar>
                        <k-button class="item-save__btn" @click="onSaveClicked" title="Generate SPAC">Generate SPAC</k-button>
                        <k-button class="item-cancel__btn" @click="onCloseDialog" title="Close">Close</k-button>
                    </k-action-bar>
                </div>
                <div class="loader-container" v-if="isLoading">
                    <k-loader :size="'large'" :type="'infinite-spinner'"></k-loader>
                </div>
            </k-dialog>
        </transition>

        <transition name="dialogfade">
            <k-dialog v-if="showErrorDialog" :title="errorTitle" class="error__dialog" @close="toggleErrorDialog">
                <p class="error-dialog__msg">{{ errorMsg }}</p>
            </k-dialog>
        </transition>
    </div>
</template>

<script>
    import { Dialog, DialogActionsBar } from '@progress/kendo-vue-dialogs';
    import { Button } from "@progress/kendo-vue-buttons";
    import { Input, NumericTextBox } from "@progress/kendo-vue-inputs";
    import { DatePicker } from '@progress/kendo-vue-dateinputs';
    import { Loader } from "@progress/kendo-vue-indicators";
    import { DropDownList } from '@progress/kendo-vue-dropdowns';

    import cloneDeep from 'lodash.clonedeep';

    import auth from '../authConfig';
    import apiService from '../api/api.service.js';
    import contractHelpers from '../helpers/contractHelpers.js';

    export default {
        name: 'createSpacDialog',
        props: ['showDialog'],
        emits: ['spaccreated', 'dialogclosed'],
        data() {
            return {
                errorMsg: '',
                errorTitle: '',
                isLoading: false,
                playerDob: null,
                showErrorDialog: false,
                spac: {},
                spacModel: {
                    dateOfContract: new Date(),
                    dayOfMonth: null,
                    secondAgent: null,
                    secondGuarantor: null,
                    thirdAgent: null,
                    thirdGuarantor: null,
                    fourthGuarantor: null,
                    fifthGuarantor: null,
                    guarantorLine: null,
                    month: null,
                    year: null,
                    playerName: null,
                    playerDateOfBirth: null,
                    playerDob: null,
                    agentManagementPercentage: null,
                    agentManagementPercent: null,
                    agentEndorsementPercentage: null,
                    agentEndorsementPercent: null,
                    playerStreetAddress: null,
                    playerCity: null,
                    playerState: null,
                    playerZipCode: null,
                    playerCountry: null,
                    agentManagementTextPercentage: null,
                    agentEndorsementTextPercentage: null
                }
            }
        },
        computed: {
            contractAgentSelects() {
                return contractHelpers.contractAgentSelects.filter(agent => agent !== 'Daniel Milstein');
            }
        },
        watch: {
            showDialog(val) {
                if (val) {
                    this.resetDefaults();
                }
            }
        },
        methods: {
            defaultDropdownSelected(evt, field, defaultValue) {
                if (evt.target.value === defaultValue) {
                    return this.spac[field] = null;
                }

                this.spac[field] = evt.target.value;
            },
            generateGuarantorLine() {
                let allGuarantors = [this.spac.secondGuarantor, this.spac.thirdGuarantor, this.spac.fourthGuarantor, this.spac.fifthGuarantor].filter(g => g);
                if (allGuarantors.length > 0) {
                    if (allGuarantors.length === 1) {
                        return `Daniel Milstein and ${allGuarantors[0]}`;
                    }

                    let allGuarantorsLine = allGuarantors.reduce((prev, cur, index, array) => {
                        if (index === array.length - 1) {
                            return prev + `and ${cur}`;
                        }

                        return prev + `${cur}, `
                    }, 'Daniel Milstein, ');

                    return allGuarantorsLine;
                } else {
                    return 'Daniel Milstein';
                }

            },
            async generateSpac() {
                let self = this;
                self.isLoading = true;

                let tokenResponse = await auth.acquireTokenResponse();

                return apiService.CreateSPAC(tokenResponse.accessToken, this.spac)
                    .then(res => {
                        self.openPDF(res.data.data);
                        self.isLoading = false;
                    }).catch(error => {
                        console.log(error);
                        self.onRequestError('An Error Occurred', error?.response?.data?.message);
                    });
            },
            onCloseDialog() {
                this.$emit('dialogclosed');
            },
            onRequestError(errorTitle, errorMsg) {
                this.isLoading = false;

                this.errorTitle = errorTitle;

                if (errorMsg) {
                    this.errorMsg = errorMsg;
                } else {
                    this.errorMsg = 'Please try again';
                }

                this.showErrorDialog = true;
            },
            onSaveClicked() {
                this.spac.dayOfMonth = this.spac.dateOfContract.getDate().toString();
                this.spac.month = this.spac.dateOfContract.toLocaleString("default", { month: 'long'});
                this.spac.year = this.spac.dateOfContract.getFullYear().toString();
                this.spac.playerDateOfBirth = this.spac.playerDob?.toLocaleDateString();
                this.spac.agentEndorsementPercentage = this.spac.agentEndorsementPercent ? `(${this.spac.agentEndorsementPercent?.toString()} %)` : '';
                this.spac.agentManagementPercentage = this.spac.agentManagementPercent ? `(${this.spac.agentManagementPercent?.toString()} %)` : '';
                this.spac.guarantorLine = this.generateGuarantorLine();

                this.generateSpac();
            },
            openPDF(src) {
                if (!src) return;
                
                let pdfWindow = window.open("");

                setTimeout(() => {
                    if (pdfWindow.document) {
                        pdfWindow.document.write("<iframe width='100%' height='100%' src='data:application/pdf;base64, " + encodeURI(src) + "'></iframe>");
                    }
                }, 2000)
            },
            resetDefaults() {
                this.spac = cloneDeep(this.spacModel);
                this.isLoading = false;
            },
            toggleErrorDialog() {
                this.showErrorDialog = !this.showErrorDialog;

                if(!this.showErrorDialog) {
                    this.errorTitle = '';
                    this.errorMsg = '';
                }
            }
        }, 
        components: {
            'k-dialog': Dialog,
            'k-action-bar': DialogActionsBar,
            'k-button': Button,
            'k-input': Input,
            'k-datepicker': DatePicker,
            'k-numeric-input': NumericTextBox,
            'k-loader': Loader,
            'k-dropdown': DropDownList
        }
    }
</script>

<style>
    @import '/src/assets/css/defaultUpsertStyles.css';
    @import '/src/assets/css/createContractDialog.css';
    @import '/src/assets/css/createSpacDialog.css';
</style>