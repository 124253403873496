<template>
    <div class="pipeline-view__container no-trade-reporting-view">
        <view-header :title="'No-Trade Reporting'">
            <template v-slot:viewicon>
                <i class="fa-duotone fa-file-check"></i>
            </template>
        </view-header>
        <k-grid
            :data-items="filteredItems"
            :data-item-key="'clauseId'"
            :columns="columns"
            :sortable="sortable"
            :sort="sort"
            :filterable="true"
            :filter="filter"
            :group="group"
            :column-menu="true"
            :pageable="gridPageable"
            :take="take"
            :total="total"
            :skip="skip"
            @datastatechange="dataStateChange"
            @filterchange="onFilterChange"
            @sortchange="onSortChange">
            <template v-slot:pipelineActions="{ props }">
                <td class="pipeline-actions__container">
                    <k-button class="show-notes__btn" @click.stop="openNotesDialog(props.dataItem)" title="Open Notes"><i class="fa-solid fa-notes"></i></k-button>
                    <k-button class="open-doc__btn" :disabled="!props.dataItem.associatedDocumentId" @click.stop="onOpenDocument(props.dataItem)" title="View Associated Document"><i class="fa-duotone fa-folder-open"></i></k-button>

                    <k-button class="delete-item__btn" @click.stop="onDeleteItem(props.dataItem)" title="Delete Item" :disabled="props.dataItem.status !== 'Pending'"><i class="fa-duotone fa-trash"></i></k-button>
                </td>
            </template>
            <template v-slot:clauseStatus="{ props }">
                <td @click.stop="">
                    <k-dropdown class="status__dropdown"
                                v-model="props.dataItem.status"
                                :data-items="clauseStatusSelects"
                                @change="onClauseStatusUpdated($event, props.dataItem)"></k-dropdown>
                </td>
            </template>
            <template v-slot:numberTeams="{ props }">
                <td @click.stop="" v-if="props.dataItem.clauseId !== editedId || !this.showEditNumberTeams">
                    <div class="report-to__cell">
                        {{ props.dataItem.numberTeams }}
                        <k-button class="edit-team__btn" @click.stop="onEditNumberTeams(props.dataItem)" title="Edit"><i class="fa-solid fa-pencil"></i></k-button>
                    </div>
                </td>
                <td @click.stop="" v-if="props.dataItem.clauseId === editedId && this.showEditNumberTeams">
                    <div class="report-to__edit-cell">
                        <k-numeric-input :placeholder="'Enter amount'" v-model="props.dataItem.numberTeams" :min="0"></k-numeric-input>
                        <k-button class="save-team__btn" @click.stop="onSaveNumberTeams(props.dataItem)" title="Save"><i class="fa-sharp fa-floppy-disk"></i></k-button>
                        <k-button class="cancel-team__btn" @click.stop="onCancelEditNumberTeams(props.dataItem)" title="Cancel Edit"><i class="fa-sharp fa-solid fa-rotate-left"></i></k-button>
                    </div>
                </td>
            </template>
            <template v-slot:reportToTeam="{ props }">
                <td @click.stop="" v-if="props.dataItem.clauseId !== editedId || !this.showEditReportToTeam">
                    <div class="report-to__cell">
                        {{ props.dataItem.reportToTeam }}
                        <k-button class="edit-team__btn" @click.stop="onEditReportToTeam(props.dataItem)" title="Edit"><i class="fa-solid fa-pencil"></i></k-button>
                    </div>
                </td>
                <td @click.stop="" v-if="props.dataItem.clauseId === editedId && this.showEditReportToTeam">
                    <div class="report-to__edit-cell">
                        <k-input v-model="props.dataItem.reportToTeam" />
                        <k-button class="save-team__btn" @click.stop="onSaveReportToTeam(props.dataItem)" title="Save"><i class="fa-sharp fa-floppy-disk"></i></k-button>
                        <k-button class="cancel-team__btn" @click.stop="onCancelEditReportToTeam(props.dataItem)" title="Cancel Edit"><i class="fa-sharp fa-solid fa-rotate-left"></i></k-button>
                    </div>
                </td>
            </template>
            <k-grid-toolbar>
                    <span class="k-textbox k-grid-search k-display-flex">
                        <k-input :style="{ width: '300px' }"
                                  placeholder="Search in all columns..."
                                  :value="searchWord"
                                  :icon-name="'search'"
                                  @input="onSearchChange"></k-input>
                    </span>
                    <k-dropdown class="grid-filter__select" 
                                :data-items="filterSelects" 
                                v-model="selectedFilter"></k-dropdown>
                    <div class="grid-button__group">
                        <k-button title="Export to Excel"
                                    class="export-excel__btn"
                                    @click="exportExcel">
                            <i class="fa-solid fa-file-export"></i> Export to Excel
                        </k-button>
                    </div>
            </k-grid-toolbar>
        </k-grid>

        <confirm-dialog :show-dialog="showConfirmDialog"
                        :dialog-Msg="confirmMsg"
                        :icon-class="confirmIconClass"
                        @dialogclosed="toggleConfirmDialog"
                        @cancelclicked="toggleConfirmDialog"
                        @confirmclicked="deleteItem"></confirm-dialog>

        <no-trade-clause-notes-dialog :show-dialog="showNotesDialog"
                                    :clause="selectedClause"
                                    @dialogclosed="toggleNotesDialog"></no-trade-clause-notes-dialog>

        <div class="loader-container" v-if="isLoading">
            <k-loader :size="'large'" :type="'infinite-spinner'"></k-loader>
        </div>

        <transition name="dialogfade">
            <k-dialog v-if="showErrorDialog" :title="errorTitle" class="error__dialog" @close="toggleErrorDialog">
                <p class="error-dialog__msg">{{ errorMsg }}</p>
            </k-dialog>
        </transition>

        <k-notification-group class="default-notification-container">
                <k-fade :appear="successNotification">
                    <k-notification v-if="successNotification"
                                    :type="{ style: 'success', icon: true }"
                                    :closable="true"
                                    @close="onCloseNotification('successNotification')">
                        <span>{{ notificationMsg }}</span>
                    </k-notification>
                </k-fade>
        </k-notification-group>
    </div>
</template>

<script>
    import { Dialog } from '@progress/kendo-vue-dialogs';
    import { Grid, GridToolbar } from '@progress/kendo-vue-grid';
    import { Loader } from "@progress/kendo-vue-indicators";
    import { Input, NumericTextBox } from "@progress/kendo-vue-inputs";
    import { Button } from "@progress/kendo-vue-buttons";
    import { Notification, NotificationGroup } from "@progress/kendo-vue-notification";
    import { Fade } from "@progress/kendo-vue-animation";
    import { DropDownList } from '@progress/kendo-vue-dropdowns';

    import { saveExcel } from '@progress/kendo-vue-excel-export';
    import { orderBy, filterBy, process } from '@progress/kendo-data-query';

    import viewHeader from '../components/viewHeader.vue';
    import confirmDialog from '../components/confirmDialog.vue';
    import noTradeClauseNotesDialog from '../components/noTradeClauseNotesDialog.vue';

    import cloneDeep from 'lodash.clonedeep';

    import auth from '../authConfig';
    import apiService from '../api/api.service.js';
    import contractHelpers from '../helpers/contractHelpers.js';

    export default {
        name: 'noTradeReportingView',
        data() {
            return {
                allItems: [],
                columns: [
                    { field: 'dueDate', title: 'Due Date', filter: 'date', type: 'date', format: '{0:M/d/y}' },
                    { field: 'fullName', title: 'Player' },
                    { cell: 'numberTeams', title: 'Teams', filter: 'numeric', type: 'numeric', width: '200' },
                    { cell: 'reportToTeam', field: 'reportToTeam', title: 'Report To' },
                    { field: 'contractTitle', title: 'Contract' },
                    { cell: 'clauseStatus', field: 'status', title: 'Status' },
                    { cell: 'pipelineActions', filterable: false, columnMenu: false, width: '150' }
                ],
                confirmIconClass: '',
                confirmMsg: '',
                contractDocuments: [],
                deleteItemId: '',
                editedId: '',
                filter: null,
                filteredItems: [],
                filterSelects: ['All Records', 'Pending', 'Sent'],
                gridPageable: {
                    buttonCount: 5,
                    info: true,
                    type: "numeric",
                    pageSizes: [5, 10, 20, 50],
                    previousNext: true,
                },
                group: [],
                isLoading: false,
                notificationMsg: '',
                searchWord: '',
                selectedDocument: null,
                selectedFilter: 'Pending',
                selectedClause: null,
                showConfirmDialog: false,
                showEditNumberTeams: false,
                showEditReportToTeam: false,
                showErrorDialog: false,
                showNotesDialog: false,
                skip: 0,
                sort: [{ field: 'dueDate', dir: 'asc' }],
                sortable: { allowUnsort: false, mode: 'single' },
                successNotification: false,
                take: 50,
                total: 0,
                uneditedNumberTeams: null,
                uneditedReportToTeam: ''
            }
        },
        computed: {
            clauseStatusSelects() {
                return contractHelpers.noTradeClauseStatusSelects;
            }
        },
        watch: {
            selectedFilter(val) {
                this.getFilteredItems();
            }
        },
        mounted() {
            this.getItems();
        },
        methods: {
            createAppState(dataState) {
                this.group = dataState.group;
                this.take = dataState.take;
                this.skip = dataState.skip;
                this.sort = dataState.sort;
                this.filter = dataState.filter;

                this.getData();
            },
            dataStateChange(event) {
                this.createAppState(event.data);
            },
            async deleteItem() {
                if (this.editedId === this.deleteItemId) {
                    this.editedId = '';
                    this.uneditedReportToTeam = '';
                }

                let self = this;

                this.isLoading = true;
                this.toggleConfirmDialog();

                let tokenResponse = await auth.acquireTokenResponse();

                return apiService.DeleteNoTradeClause(tokenResponse.accessToken, this.deleteItemId) 
                    .then(() => {
                        self.getItems(true);
                        self.showNotification('Record deleted successfully');
                    }).catch(error => {
                        console.log(error);
                        self.onRequestError('An Error Occurred', error?.response?.data?.message);
                    });
            },
            exportExcel() {
                let data = this.getFilteredItems();

                saveExcel({
                    data: data,
                    fileName: "No-Trade Reporting",
                    columns: this.columns
                });
            },
            async getAssociatedContractDocument() {
                let self = this;

                let tokenResponse = await auth.acquireTokenResponse();

                return apiService.GetPlayerDocument(tokenResponse.accessToken, this.selectedDocument.associatedDocumentId)
                    .then((res) => {
                        self.contractDocuments = res.data.data;
                    }).catch(error => {
                        console.log(error);
                        self.onRequestError('An Error Occurred', error?.response?.data?.message);
                    });
            },
            getData() {
                let filteredData = this.getFilteredItems();
              
                this.filteredItems = process(filteredData, {
                    take: this.take,
                    skip: this.skip,
                    group: this.group,
                    sort: this.sort,
                    filter: this.filter,
                }).data;

                this.total = process(filteredData, {
                    filter: this.filter
                }).total;

                this.isLoading = false;
            },
            getFilteredItems() {
                if (this.searchWord) {
                    this.filteredItems = this.allItems.filter(item => {
                        return Object.values(item).some(val => val && val.toString().toLowerCase().includes(this.searchWord.toLowerCase()));
                    });
                } else {
                    this.filteredItems = cloneDeep(this.allItems);
                }

                if (this.sort.length > 0) {
                    this.filteredItems = orderBy(this.filteredItems, this.sort);
                }

                if (this.filter) {
                    this.filteredItems = filterBy(this.filteredItems, this.filter);
                }

                if (this.selectedFilter && this.selectedFilter !== 'All Records') {
                    this.filteredItems = this.filteredItems.filter(item => item.status === this.selectedFilter);
                } 

                return this.filteredItems;
            },
            async getItems(update = false) {     
                this.isLoading = true;
                await this.$store.dispatch('getNoTradeClauses', update);

                this.allItems = cloneDeep(this.$store.state.noTradeClauses);
                console.log(this.allItems)
        
                this.getData();
            },
            onCancelEditNumberTeams(item) {
                item.numberTeams = this.uneditedNumberTeams;
                this.editedId = '';
                this.showEditNumberTeams = false;
                this.uneditedNumberTeams = null;
            },
            onCancelEditReportToTeam(item) {
                item.reportToTeam = this.uneditedReportToTeam;
                this.editedId = '';
                this.showEditReportToTeam = false;
                this.uneditedReportToTeam = '';
            },
            onClauseStatusUpdated(evt, clause) {
                this.updateClause(clause);
            },
            onCloseNotification(flag) {
                this[flag] = false;
            },
            onDeleteItem(item) {
                this.confirmIconClass='fa-solid fa-trash-can';
                this.deleteItemId = item.clauseId;
                this.confirmMsg = `Are you sure you would like to delete this Record?`;
                this.toggleConfirmDialog();
            },
            onEditNumberTeams(item) {
                this.uneditedNumberTeams = item.numberTeams;
                this.editedId = item.clauseId;

                this.showEditNumberTeams = true;
                this.showEditReportToTeam = false;
            },
            onEditReportToTeam(item) {
                this.uneditedReportToTeam = item.reportToTeam;
                this.editedId = item.clauseId;

                this.showEditReportToTeam = true;
                this.showEditNumberTeams = false;
            },
            onFilterChange(evt) {
                this.filter = evt.filter;
                this.getData();
            },
            async onOpenDocument(contractDoc) {
                this.selectedDocument = contractDoc;

                await this.getAssociatedContractDocument();

                if (this.contractDocuments.length > 0) {
                    let selectedPlayerDocument = this.contractDocuments[0];
                    let routeData = this.$router.resolve({name: 'documentview', query: { id: selectedPlayerDocument.documentId }});
                    window.open(routeData.href, '_blank');
                }
            },
            onRequestError(errorTitle, errorMsg) {
                this.isLoading = false;

                this.errorTitle = errorTitle;

                if (errorMsg) {
                    this.errorMsg = errorMsg;
                } else {
                    this.errorMsg = 'Please try again';
                }

                this.showErrorDialog = true;
            },
            onSaveNumberTeams(item) {
                this.editedId = '';
                this.uneditedNumberTeams = null;
                this.showEditNumberTeams = false;

                this.updateClause(item);
            },
            onSaveReportToTeam(item) {
                this.editedId = '';
                this.uneditedReportToTeam = '';
                this.showEditReportToTeam = false;

                this.updateClause(item);
            },
            onSearchChange(evt) {
                this.searchWord = evt.value;
                this.getData();
            },
            onSortChange(evt) {
                this.sort = evt.sort;
                this.getData();
            },
            openNotesDialog(item) {
                this.selectedClause = item;
                this.toggleNotesDialog();
            },
            showNotification(notificationMsg) {
                this.notificationMsg = notificationMsg;

                this.successNotification = true;

                setTimeout(() => {
                    this.successNotification = false;
                }, 4000);
            },
            toggleConfirmDialog() {
                this.showConfirmDialog = !this.showConfirmDialog;
            },
            toggleErrorDialog() {
                this.showErrorDialog = !this.showErrorDialog;

                if(!this.showErrorDialog) {
                    this.errorTitle = '';
                    this.errorMsg = '';
                }
            },
            toggleNotesDialog() {
                this.showNotesDialog = !this.showNotesDialog;

                if (!this.showNotesDialog) {
                    this.selectedClause = null;
                }
            },
            async updateClause(request) { 
                let self = this;
                this.isLoading = true;

                let tokenResponse = await auth.acquireTokenResponse();

                return apiService.UpdateNoTradeClause(tokenResponse.accessToken, request) 
                    .then(async () => {
                        await this.$store.dispatch('getNoTradeClauses', true);

                        self.isLoading = false;
                        self.showNotification('Record updated successfully');

                        self.getItems();
                    }).catch(error => {
                        console.log(error);
                        self.onRequestError('An Error Occurred', error?.response?.data?.message);
                    });
            }
        },
        components: {
            'k-dialog': Dialog,
            'k-loader': Loader,
            'view-header': viewHeader,
            'k-input': Input,
            'k-button': Button,
            'k-notification': Notification,
            'k-notification-group': NotificationGroup,
            'k-fade': Fade,
            'k-dropdown': DropDownList,
            'k-grid': Grid,
            'k-grid-toolbar': GridToolbar,
            'confirm-dialog': confirmDialog,
            'no-trade-clause-notes-dialog': noTradeClauseNotesDialog,
            'k-numeric-input': NumericTextBox
        }
    }
</script>

<style>
    @import '/src/assets/css/pipelineViewContainer.css';
    @import '/src/assets/css/noTradeReportingView.css';
</style>