<template>
    <div class="no-trade-clause-notes">
        <transition name="dialogfade">
            <k-dialog v-if="showDialog" class="default__dialog no-trade-clause-notes-dialog">
                <div class="icon__container">
                    <i class="fa-solid fa-notes"></i>
                </div>
                <div class="header__container">
                    <svg viewBox="0 0 300 50" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" class="icon__top-bar" clip-rule="evenodd" d="M10 0C4.47715 0 0 4.47715 0 10V50H300V10C300 4.47715 295.523 0 290 0H184.388C184.388 9.01736 180.732 17.6654 174.226 24.0416C167.72 30.4179 158.895 34 149.694 34C140.492 34 131.668 30.4179 125.161 24.0416C118.655 17.6654 115 9.01737 115 5.13386e-06L149.694 0H10Z" fill="#D4B139"></path></svg>
                </div>
                <div class="create-document__header">
                    <h1 class="dialog--title">Notes</h1>
                </div>

                <div class="upsert-item__container no-trade-clause-notes">
                    <div class="notes__container">
                        <div class="editor__container">
                            <k-editor :tools="tools"
                                    ref="noteseditor"
                                    :content-style="{ height: '150px' }"
                                    :resizable="true"
                                    v-on:focus="onEditorFocus"
                                    :default-content="defaultEditorContent"
                                    :defaultEditMode="'div'" />
                            <div class="editor__container--submit">
                                <k-button class="submit-note__btn" @click="addNote"><i class="fa-duotone fa-pen-line"></i> Submit </k-button>
                            </div>
                        </div>
                        <div v-if="notes.length === 0" class="note-detail__container--empty">
                            <h4>No notes available</h4>
                        </div>
                        <div v-if="notes.length > 0" class="note-detail__container">
                            <div v-for="(note, index) in notes" :key="index" class="note">
                                <div class="note-date">{{ note.userName }} - {{ note.createdOn.toLocaleDateString('en-US', { hour: 'numeric', minute: '2-digit'}).split(',').join('') }}</div>
                                <div class="note-body" v-html="note.note"></div>
                            </div>
                        </div>
                    </div>
                    <k-action-bar>
                        <k-button class="item-cancel__btn" @click="onCancelClicked">Close</k-button>
                    </k-action-bar>
                </div>

                <div class="loader-container" v-if="isLoading">
                    <k-loader :size="'large'" :type="'infinite-spinner'"></k-loader>
                </div>

                <transition name="dialogfade">
                    <k-dialog v-if="showErrorDialog" :title="errorTitle" class="error__dialog" @close="toggleErrorDialog">
                        <p class="error-dialog__msg">{{ errorMsg }}</p>
                    </k-dialog>
                </transition>

                <k-notification-group class="default-notification-container">
                        <k-fade :appear="successNotification">
                            <k-notification v-if="successNotification"
                                            :type="{ style: 'success', icon: true }"
                                            :closable="true"
                                            @close="onCloseNotification('successNotification')">
                                <span>{{ notificationMsg }}</span>
                            </k-notification>
                        </k-fade>
                </k-notification-group>
            </k-dialog>
        </transition>
    </div>
</template>

<script>
    import { Dialog, DialogActionsBar } from '@progress/kendo-vue-dialogs';
    import { Button } from "@progress/kendo-vue-buttons";
    import { Loader } from "@progress/kendo-vue-indicators";
    import { Notification, NotificationGroup } from "@progress/kendo-vue-notification";
    import { Fade } from "@progress/kendo-vue-animation";
    import { Editor } from '@progress/kendo-vue-editor';

    import auth from '../authConfig';
    import apiService from '../api/api.service.js';
    import formatHelpers from '@/helpers/formatHelpers';

    export default {
        name: 'noTradeClauseNotesDialog',
        props: ['clause', 'showDialog'],
        emits: ['dialogclosed'],
        data() {
            return {
                defaultEditorContent: 'Add your note here!',
                noteModel: {
                    noteId: null,
                    playerId: null,
                    note: null,
                    contractId: null,
                    createdOn: null,
                    userName: null,
                    clauseId: null
                },
                notes: [],
                errorMsg: '',
                errorTitle: '',
                isLoading: false,
                notificationMsg: '',
                showErrorDialog: false,
                successNotification: false,
                tools: [
                    ["Bold", "Italic", "Underline"],
                    ["Undo", "Redo"],
                    ["Link", "Unlink"],
                    ["AlignLeft", "AlignCenter", "AlignRight"],
                    ["OrderedList", "UnorderedList", "Indent", "Outdent"],
                ],
            }
        },
        watch: {
            showDialog(val) {
                if (val) {
                    this.getNotes();
                } else {
                    this.resetDefaults();
                }
            }
        },
        methods: {
            async addNote() {
                let noteHtml = this.$refs.noteseditor.getHTML();
                let noteText = noteHtml.replace(/<[^>]+>/g, '');

                if (noteText && noteText.trim() !== this.defaultEditorContent) {

                    let self = this;

                    this.isLoading = true;

                    let note = {
                        note: noteHtml,
                        playerId: this.clause.playerId,
                        noteId: null,
                        userName: auth.user()?.name,
                        contractId: this.clause.contractId,
                        clauseId: this.clause.clauseId       
                    };

                    this.$refs.noteseditor.setHTML(this.defaultEditorContent);

                    let tokenResponse = await auth.acquireTokenResponse();

                    return apiService.CreateNoTradeClauseNote(tokenResponse.accessToken, note)
                        .then((res) => {
                            self.notes = res.data.data ?? [];

                            self.notes.forEach(note => {
                                note.createdOn = formatHelpers.formatDateTime(note.createdOn);
                            });
                            self.notes.sort((a, b) => b.createdOn - a.createOn);

                            self.isLoading = false;
                            self.showNotification('Note added successfully');
                        }).catch(error => {
                            console.log(error);
                            self.onRequestError('An Error Occurred', error?.response?.data?.message);
                        });
                }
            },
            async getNotes() {
                let self = this;
                self.isLoading = true;

                let tokenResponse = await auth.acquireTokenResponse();

                return apiService.GetNoTradeClauseNotes(tokenResponse.accessToken, this.clause.clauseId)
                    .then(res => {
                        if (res.data.data) {
                            self.notes = res.data.data;

                            self.notes.forEach(note => {
                                note.createdOn = formatHelpers.formatDateTime(note.createdOn);
                            });
                            self.notes.sort((a, b) => b.createdOn - a.createOn);

                            self.isLoading = false;
                        } else {
                            self.onRequestError('An Error Occurred', 'Notes unavailable');
                        }
                    }).catch(error => {
                        console.log(error);
                        this.onRequestError('An Error Occurred', error?.response?.data?.message);
                    });
            },
            onCancelClicked() {
                this.$emit('dialogclosed');
            }, 
            onCloseNotification(flag) {
                this[flag] = false;
            },
            onEditorFocus() {
                let noteHtml = this.$refs.noteseditor.getHTML();
                let noteText = noteHtml.replace(/<[^>]+>/g, '');

                if (noteText && noteText.trim() === this.defaultEditorContent) {
                    this.$refs.noteseditor.setHTML('');
                }
            },
            onRequestError(errorTitle, errorMsg) {
                this.isLoading = false;

                this.errorTitle = errorTitle;

                if (errorMsg) {
                    this.errorMsg = errorMsg;
                } else {
                    this.errorMsg = 'Please try again';
                }

                this.showErrorDialog = true;
            },
            resetDefaults() {
                this.notes = [];
            },
            showNotification(notificationMsg) {
                this.notificationMsg = notificationMsg;

                this.successNotification = true;

                setTimeout(() => {
                    this.successNotification = false;
                }, 4000);
            },
            toggleErrorDialog() {
                this.showErrorDialog = !this.showErrorDialog;

                if(!this.showErrorDialog) {
                    this.errorTitle = '';
                    this.errorMsg = '';
                }
            }
        },
        components: {
            'k-dialog': Dialog,
            'k-action-bar': DialogActionsBar,
            'k-button': Button,
            'k-loader': Loader,
            'k-notification': Notification,
            'k-notification-group': NotificationGroup,
            'k-fade': Fade,
            'k-editor': Editor
        }
    }
</script>

<style>
    @import '/src/assets/css/defaultUpsertStyles.css';
    @import '/src/assets/css/noTradeClauseNotesDialog.css';
</style>