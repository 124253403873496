<template>
    <div class="upsert-draft-detail">
        <transition name="dialogfade">
            <k-dialog v-if="showDialog" class="default__dialog upsert-draft-detail-dialog upsert-rights-detail-dialog">
                <div class="icon__container">
                    <i class="fa-solid fa-hockey-mask"></i>
                </div>
                <div class="header__container">
                    <svg viewBox="0 0 300 50" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" class="icon__top-bar" clip-rule="evenodd" d="M10 0C4.47715 0 0 4.47715 0 10V50H300V10C300 4.47715 295.523 0 290 0H184.388C184.388 9.01736 180.732 17.6654 174.226 24.0416C167.72 30.4179 158.895 34 149.694 34C140.492 34 131.668 30.4179 125.161 24.0416C118.655 17.6654 115 9.01737 115 5.13386e-06L149.694 0H10Z" fill="#D4B139"></path></svg>
                </div>
                <div class="create-document__header">
                    <h1 class="dialog--title">{{ editMode ? 'Edit Record' : 'Create Record' }}</h1>
                </div>
                <div class="upsert-item__container">
                    <p class="field--required">*Required</p>
                    <section class="upsert-item__section">
                        <div class="input__row pair">
                            <label class="input__label">
                                <k-checkbox v-model="detail.currentRights"></k-checkbox>
                                Current Rights
                            </label>
                        </div>
                        <div class="input__row pair">
                            <label class="input__label">
                                *Team
                                <k-dropdown :data-items="nhlTeamSelects" 
                                            :default-item="detail.teamName ?? 'Select Team'" 
                                            @change="defaultDropdownSelected($event, 'teamName', 'Select Team')"></k-dropdown>
                                <span class="item-input--vuelidate-error" v-if="detail$.detail.teamName.$error"><i>Team name is required</i></span>
                            </label>
                            <label class="input__label">
                                Date
                                <k-datepicker v-model="detail.rightsDate"></k-datepicker>
                            </label>
                        </div>
                    </section>
                    <k-action-bar>
                        <k-button class="item-save__btn" @click="onSaveClicked" :disabled="saveChangesBtnDisabled">Save</k-button>
                        <k-button class="item-cancel__btn" @click="onCancelClicked">Cancel</k-button>
                    </k-action-bar>
                </div>
            </k-dialog>
        </transition>

        <div class="loader-container" v-if="isLoading">
            <k-loader :size="'large'" :type="'infinite-spinner'"></k-loader>
        </div>

        <transition name="dialogfade">
            <k-dialog v-if="showErrorDialog" :title="errorTitle" class="error__dialog" @close="toggleErrorDialog">
                <p class="error-dialog__msg">{{ errorMsg }}</p>
            </k-dialog>
        </transition>

        <k-notification-group class="default-notification-container">
                <k-fade :appear="successNotification">
                    <k-notification v-if="successNotification"
                                    :type="{ style: 'success', icon: true }"
                                    :closable="true"
                                    @close="onCloseNotification('successNotification')">
                        <span>{{ notificationMsg }}</span>
                    </k-notification>
                </k-fade>
        </k-notification-group>
    </div>
</template>

<script>
    import { Dialog, DialogActionsBar } from '@progress/kendo-vue-dialogs';
    import { Button } from "@progress/kendo-vue-buttons";
    import { Loader } from "@progress/kendo-vue-indicators";
    import { Notification, NotificationGroup } from "@progress/kendo-vue-notification";
    import { Fade } from "@progress/kendo-vue-animation";
    import { DatePicker } from '@progress/kendo-vue-dateinputs';
    import { DropDownList } from '@progress/kendo-vue-dropdowns';
    import { Checkbox } from "@progress/kendo-vue-inputs";

    import { useVuelidate } from '@vuelidate/core';
    import { required } from '@vuelidate/validators';

    import cloneDeep from 'lodash.clonedeep';

    import auth from '../authConfig';
    import apiService from '../api/api.service.js';
    import playerHelpers from '../helpers/playerHelpers.js';

    export default {
        setup() {
            return { detail$: useVuelidate() }
        },
        name: 'upsertPlayerInjuryDialog',
        props: ['addMode', 'editedDetail', 'editMode', 'playerDetail', 'playerId', 'showDialog' ],
        emits: ['dialogclosed', 'rightsdetailsupdated'],
        data () {
            return {
                detail: {},
                detailModel: {
                    playerId: null,
                    teamName: null,
                    rightsDate: null,
                    currentRights: true
                },
                errorMsg: '',
                errorTitle: '',
                isLoading: false,
                notificationMsg: '',
                showErrorDialog: false,
                signByOptionSelects: ['Date', 'Indefinite'],
                successNotification: false
            }
        },
        validations() {
            return {
                detail: {
                    teamName: { required }
                }
            }
        },
        computed: {
            nhlTeamSelects() {
                return playerHelpers.nhlTeamSelects;
            },
            saveChangesBtnDisabled() {
                return !this.detail.teamName;
            }
        },
        watch: {
            showDialog(val) {
                if (val) {
                    this.assignDetail();
                } else {
                    this.resetDefaults();
                }
            }
        },
        methods: {
            assignDetail() {
                if (this.editMode) {
                    return this.detail = cloneDeep(this.editedDetail);
                }

                this.detail = cloneDeep(this.detailModel);
                this.detail.playerId = this.playerId;
            },
            defaultDropdownSelected(evt, field, defaultValue) {
                if (evt.target.value === defaultValue) {
                    return this.detail[field] = null;
                }

                this.detail[field] = evt.target.value;
            },
            onCancelClicked() {
                this.$emit('dialogclosed');
            }, 
            onCloseNotification(flag) {
                this[flag] = false;
            },
            onRequestError(errorTitle, errorMsg) {
                this.isLoading = false;

                this.errorTitle = errorTitle;

                if (errorMsg) {
                    this.errorMsg = errorMsg;
                } else {
                    this.errorMsg = 'Please try again';
                }

                this.showErrorDialog = true;
            },
            async onSaveClicked() {
                await this.detail$.$validate();

                if (this.detail$.detail.$error) return;

                let request = {
                    rightsDetail: cloneDeep(this.detail),
                    playerDetail: {
                        playerId: this.playerId,
                        player: cloneDeep(this.playerDetail),
                        playerUpdated: true
                    }

                }

                this.upsertRightsDetail(request);
            },
            resetDefaults() {
                this.detail$.$reset();
                this.detail = {};
            },
            showNotification(notificationMsg) {
                this.notificationMsg = notificationMsg;

                this.successNotification = true;

                setTimeout(() => {
                    this.successNotification = false;
                }, 4000);
            },
            toggleErrorDialog() {
                this.showErrorDialog = !this.showErrorDialog;

                if(!this.showErrorDialog) {
                    this.errorTitle = '';
                    this.errorMsg = '';
                }
            },
            async upsertRightsDetail(request) {
                let self = this;

                this.isLoading = true;
                this.$emit('dialogclosed');

                let tokenResponse = await auth.acquireTokenResponse();

                return apiService.UpsertRightsDetail(tokenResponse.accessToken, request) 
                    .then((res) => {
                        self.isLoading = false;
                        self.showNotification('Record updated successfully');

                        self.$emit('rightsdetailsupdated', res.data.data);
                    }).catch(error => {
                        console.log(error);
                        self.onRequestError('An Error Occurred', error?.response?.data?.message);
                    });
            }
        },
        components: {
            'k-dialog': Dialog,
            'k-action-bar': DialogActionsBar,
            'k-button': Button,
            'k-loader': Loader,
            'k-notification': Notification,
            'k-notification-group': NotificationGroup,
            'k-fade': Fade,
            'k-datepicker': DatePicker,
            'k-dropdown': DropDownList,
            'k-checkbox': Checkbox
        }
    }
</script>

<style>
    @import '/src/assets/css/defaultUpsertStyles.css';
    @import '/src/assets/css/upsertDraftDetailDialog.css';
</style>