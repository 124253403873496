<template>
    <div class="pipeline-view__container detail-view-grid">
        <k-grid :class="[{ 'clickable': rowClickable }]"
            :data-items="filteredItems"
            :data-item-key="itemIdField"
            :columns="columns"
            :sortable="sortable"
            :sort="sort"
            :filterable="agentDashboard || eliteProspectsSearch ? false : true"
            :filter="filter"
            :column-menu="true"
            :pageable="gridPageable"
            :take="take"
            :total="total"
            :skip="skip"
            @datastatechange="dataStateChange"
            @rowclick="onRowClick"
            @filterchange="onFilterChange"
            @sortchange="onSortChange">
            <template v-slot:headerTemplate="{ props }"><span :title="props.field.replace(/([A-Z])/g, ' $1').replace(/^./, function(str){ return str.toUpperCase() })">{{ props.title }}</span></template>
            <template v-slot:amountYearOne="{ props }">
                <td v-if="props.dataItem.amountYearOne">{{ $options.filters.formatCurrency(props.dataItem.amountYearOne) }}</td>
                <td v-if="!props.dataItem.amountYearOne"><span :class="[{ 'expiration-block': props.dataItem.expirationStatus }]">{{ props.dataItem.expirationStatus }}</span></td>
            </template>
            <template v-slot:amountYearTwo="{ props }">
                <td v-if="props.dataItem.amountYearTwo">{{ $options.filters.formatCurrency(props.dataItem.amountYearTwo) }}</td>
                <td v-if="!props.dataItem.amountYearTwo"><span :class="[{ 'expiration-block': props.dataItem.expirationStatus }]">{{ props.dataItem.expirationStatus }}</span></td>
            </template>
            <template v-slot:amountYearThree="{ props }">
                <td v-if="props.dataItem.amountYearThree">{{ $options.filters.formatCurrency(props.dataItem.amountYearThree) }}</td>
                <td v-if="!props.dataItem.amountYearThree"><span :class="[{ 'expiration-block': props.dataItem.expirationStatus }]">{{ props.dataItem.expirationStatus }}</span></td>
            </template>
            <template v-slot:amountYearFour="{ props }">
                <td v-if="props.dataItem.amountYearFour">{{ $options.filters.formatCurrency(props.dataItem.amountYearFour) }}</td>
                <td v-if="!props.dataItem.amountYearFour"><span :class="[{ 'expiration-block': props.dataItem.expirationStatus }]">{{ props.dataItem.expirationStatus }}</span></td>
            </template>
            <template v-slot:projecteddraftround="{ props }">
                <td @click.stop="" v-if="props.dataItem.id !== editedPlayerId || !this.showEditProjRound">
                    <div class="data-cell">
                        {{ props.dataItem.projectedDraftRound }}
                        <k-button class="edit-round__btn" @click.stop="onEditProjRound(props.dataItem)" title="Edit"><i class="fa-solid fa-pencil"></i></k-button>
                    </div>
                </td>
                <td @click.stop="" v-if="props.dataItem.id === editedPlayerId && this.showEditProjRound">
                    <div class="data-cell">
                        <k-numeric-input :placeholder="'Enter round'" v-model="props.dataItem.projectedDraftRound" :format="'0'" :min="1" :max="7"></k-numeric-input>
                        <k-button class="save-round__btn" :disabled="uneditedProjRound === props.dataItem.projectedDraftRound" @click.stop="onSaveProjRound(props.dataItem)" title="Save"><i class="fa-sharp fa-floppy-disk"></i></k-button>
                        <k-button class="cancel-round__btn" @click.stop="onCancelEditProjRound(props.dataItem)" title="Cancel Edit"><i class="fa-sharp fa-solid fa-rotate-left"></i></k-button>
                    </div>
                </td>
            </template>
            <template v-slot:teamName="{ props }">
                <td>
                    <div class="logo-name-container">
                        <img class="team-logo" :src="props.dataItem.teamLogo" />
                        {{ props.dataItem.teamName }}
                    </div>
                </td>
            </template>
            <template v-slot:teamCapHit="{ props }">
                <td class="cap-info">
                    <div class="cap-info-container">
                        {{ $options.filters.formatCurrency(props.dataItem.currentRosterAnnualCapHit) }}
                        <i class="info-icon fa-regular fa-circle-info" @mouseover="toggleCapChart($event, props.dataItem)" @mouseleave="toggleCapChart"></i>    
                        <div v-bind:class="['cap-chart-tooltip', { 'tooltip--show': selectedCapChartTeam?.teamId === props.dataItem.teamId }]">
                            <bar-chart-component :chart-series="props.dataItem.chartSeries"></bar-chart-component>
                        </div>              
                    </div>
                </td>
            </template>
            <template v-slot:capHitForwards="{ props }">
                <td class="cap-breakdown">
                    {{ $options.filters.formatCurrency(props.dataItem.currentRosterAnnualCapHitForwards) }}
                    <span v-if="props.dataItem.currentRosterAnnualCapHitForwards"> | {{ ((props.dataItem.currentRosterAnnualCapHitForwards / props.dataItem.currentRosterAnnualCapHit) * 100).toFixed(2) }} %</span>
                </td>
            </template>
            <template v-slot:capHitDefense="{ props }">
                <td class="cap-breakdown">
                    {{ $options.filters.formatCurrency(props.dataItem.currentRosterAnnualCapHitDefense) }}
                    <span v-if="props.dataItem.currentRosterAnnualCapHitDefense"> | {{ ((props.dataItem.currentRosterAnnualCapHitDefense / props.dataItem.currentRosterAnnualCapHit) * 100).toFixed(2) }} %</span>
                </td>
            </template>
            <template v-slot:capHitLtir="{ props }">
                <td class="cap-breakdown">
                    {{ $options.filters.formatCurrency(props.dataItem.currentRosterLtir) }}
                    <span v-if="props.dataItem.currentRosterLtir"> | {{ ((props.dataItem.currentRosterLtir / props.dataItem.currentRosterAnnualCapHit) * 100).toFixed(2) }} %</span>
                </td>
            </template>
            <template v-slot:capHitGoalies="{ props }">
                <td class="cap-breakdown">
                    {{ $options.filters.formatCurrency(props.dataItem.currentRosterAnnualCapHitGoalies) }}
                    <span v-if="props.dataItem.currentRosterAnnualCapHitGoalies"> | {{ ((props.dataItem.currentRosterAnnualCapHitGoalies / props.dataItem.currentRosterAnnualCapHit) * 100).toFixed(2) }} %</span>
                </td>
            </template>
            <template v-slot:capHitDead="{ props }">
                <td class="cap-breakdown">
                    {{ $options.filters.formatCurrency(props.dataItem.currentRosterDeadCapHit) }}
                    <span v-if="props.dataItem.currentRosterDeadCapHit"> | {{ ((props.dataItem.currentRosterDeadCapHit / props.dataItem.currentRosterAnnualCapHit) * 100).toFixed(2) }} %</span>
                </td>
            </template>
            <template v-slot:pipelineActions="{ props }"> 
                <div class="pipeline-actions__container">
                    <k-button class="edit-item__btn" v-if="editAction" @click.stop="onEditItem(props.dataItem)" title="Edit"><i class="fa-solid fa-pen-to-square"></i></k-button>
                    <k-button class="delete-item__btn" v-if="deleteAction" @click.stop="onDeleteItem(props.dataItem)" title="Delete"><i class="fa-solid fa-trash"></i></k-button>
                </div>
            </template>
            <k-grid-toolbar v-if="!agentDashboard">
                <span class="k-textbox k-grid-search k-display-flex">
                    <k-input :style="{ width: '300px' }"
                                placeholder="Search in all columns..."
                                :value="searchWord"
                                :icon-name="'search'"
                                @input="onSearchChange"></k-input>
                </span>
                <div class="grid-button__group">
                    <k-button title="Export to Excel"
                            class="export-excel__btn" 
                            v-if="allowExport"
                            @click="exportExcel">
                            <i class="fa-solid fa-file-export"></i> Export to Excel
                    </k-button>
                </div>
            </k-grid-toolbar>
        </k-grid>
    </div>
</template>

<script>
    import { Grid, GridToolbar } from '@progress/kendo-vue-grid';
    import { Input, NumericTextBox } from "@progress/kendo-vue-inputs";
    import { Button } from "@progress/kendo-vue-buttons";

    import { saveExcel } from '@progress/kendo-vue-excel-export';
    import { orderBy, filterBy, process } from '@progress/kendo-data-query';

    import barChartComponent from './barChartComponent.vue';

    import cloneDeep from 'lodash.clonedeep';

    export default {
        name: 'detailViewGrid',
        props: ['agentDashboard', 'agentSearchWord', 'allowExport',  'columns', 'deleteAction', 'deleteFunction', 'editAction', 'editFunction', 'eliteProspectsSearch', 'exportTitle', 'initialSort', 'itemIdField', 'items', 'playerId', 'rowClickable', 'rowClickFunction'],
        emits: ['filtereditems', 'playerupdated'],
        data() {
            return {
                columnMenu: true,
                editedPlayerId: '',
                filter: null,
                filteredItems: [],
                searchWord: '',
                selectedCapChartTeam: null,
                showEditProjRound: false,
                skip: 0,
                sort: null,
                sortable: { allowUnsort: false, mode: 'single' },
                take: 50,
                total: 0,
                uneditedProjRound: null

            }
        },
        computed: {
            gridPageable() {
                if (this.agentDashboard || this.eliteProspectsSearch) return null;

                return {
                    buttonCount: 5,
                    info: true,
                    type: "numeric",
                    pageSizes: [5, 10, 20, 50],
                    previousNext: true,
                }
            }
        },
        watch: {
            agentSearchWord(val) {
                this.searchWord = val;
                this.getData();
            },
            items(val) {
                this.filteredItems = cloneDeep(this.items);
                this.getData();
            }
        },
        mounted() {
            if (this.initialSort) {
                this.sort = this.initialSort;
            }

            this.filteredItems = cloneDeep(this.items);
            this.getData();
        },
        methods: {
            createAppState(dataState) {
                this.take = dataState.take;
                this.skip = dataState.skip;
                this.sort = dataState.sort;
                this.filter = dataState.filter;

                this.getData();
            },
            dataStateChange(event) {
                this.createAppState(event.data);
            },
            exportExcel() {
                let data = this.getFilteredItems();

                saveExcel({
                    data: data,
                    fileName: this.exportTitle,
                    columns: this.columns
                });
            },
            getData() {
                let filteredData = this.getFilteredItems();
              
                this.filteredItems = process(filteredData, {
                    take: this.take,
                    skip: this.skip,
                    sort: this.sort,
                    filter: this.filter,
                }).data;

                this.total = process(filteredData, {
                    filter: this.filter
                }).total;

                if (this.agentDashboard) {
                    this.$emit('filtereditems', this.total);
                }

                this.isLoading = false;
            },
            getFilteredItems() {
                if (this.searchWord) {
                    this.filteredItems = this.items.filter(item => {
                        return this.searchObject(item, this.searchWord);
                    });
                } else {
                    this.filteredItems = cloneDeep(this.items);
                }

                if (this.sort?.length > 0) {
                    this.filteredItems = orderBy(this.filteredItems, this.sort);
                }

                if (this.filter) {
                    this.filteredItems = filterBy(this.filteredItems, this.filter);
                } 

                return this.filteredItems;
            },
            onCancelEditProjRound(item) {
                item.projectedDraftRound = this.uneditedProjRound;
                this.editedPlayerId = '';
                this.showEditProjRound = false;
                this.uneditedProjRound = null;
            },
            onDeleteItem(item) {
                this.deleteFunction(item);
            },
            onEditItem(item) {
                this.editFunction(item);
            },
            onEditProjRound(item) {
                this.uneditedProjRound = item.projectedDraftRound;
                this.editedPlayerId = item.id;

                this.showEditProjRound = true;
            },
            onFilterChange(evt) {
                this.filter = evt.filter;
                this.getData();
            },
            onRowClick(evt) {
                if (!this.rowClickable || !this.rowClickFunction) return;
                
                this.rowClickFunction(evt)
            },
            onSaveProjRound(item) {
                this.editedPlayerId = '';
                this.uneditedProjRound = null;
                this.showEditProjRound = false;

                let request = {
                    playerId: item.id,
                    playerUpdated: true,
                    player: item
                }

                this.$emit('playerupdated', request);
            },
            onSearchChange(evt) {
                this.searchWord = evt.value;
                this.getData();
            },
            onSortChange(evt) {
                this.sort = evt.sort;
                this.getData();
            },
            searchObject(obj, searchWord) {
                for (let key in obj) {
                    if (typeof obj[key] === 'object') {
                        let result = this.searchObject(obj[key], searchWord);

                        if (result) return result;
                    } else {
                        if (obj[key]?.toString().toLowerCase().includes(searchWord.toLowerCase())) {
                            return true;
                        }
                    }
                }

                return false;
            },
            toggleCapChart(evt, team = null) {
                if (team) {
                    this.selectedCapChartTeam = team;
                } else {
                    this.selectedCapChartTeam = null;
                }
            }
        },
        filters: {
            formatCurrency(val) {
                if (val === null || val === undefined || val === '') return '';

                if (val.toString().includes('$')) return val;

                if (isNaN(val)) {
                    val = 0;
                }

                let formatter = new Intl.NumberFormat('en-US', {
                    style: 'currency',
                    currency: 'USD',
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0
                });

                return formatter.format(val);
            },
            formatCurrencyDec(val) {
                if (val === null || val === undefined || val === '') return '';

                if (val.toString().includes('$')) return val;

                if (isNaN(val)) {
                    val = 0;
                }

                let formatter = new Intl.NumberFormat('en-US', {
                    style: 'currency',
                    currency: 'USD',
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                });

                return formatter.format(val);
            }
        },
        components: {
            'k-grid': Grid,
            'k-grid-toolbar': GridToolbar,
            'k-input': Input,
            'k-button': Button,
            'k-numeric-input': NumericTextBox,
            'bar-chart-component': barChartComponent
        }
    }
</script>

<style>
    @import '/src/assets/css/detailViewGrid.css';
</style>