<template>
    <div class="nhl-dashboard-component">
        <k-tabstrip class="team-data-tabstrip" :selected="selectedTeamDataTab" @select="onTeamDataTabSelected" :tabs="teamDataTabs">
            <template v-slot:capspace>
                <view-header :title="'Cap Space'">
                    <template v-slot:viewicon>
                        <i class="fa-duotone fa-solid fa-layer-group"></i>
                    </template>
                </view-header>
                <div class="grid-container cap-space">
                    <detail-view-grid :columns="capSpaceColumns"
                                    :initial-sort="[{ field: 'teamName', dir: 'asc' }]"
                                    :item-id-field="'teamId'"
                                    :items="teamData"
                                    :row-clickable="false"
                                    :delete-action="false"
                                    :allow-export="true"
                                    :elite-prospects-search="true"
                                    :export-title="'Team Cap Space'"></detail-view-grid>
                </div>
            </template>
            <template v-slot:positionalbreakdown>
                <view-header :title="'Positional Breakdown'">
                    <template v-slot:viewicon>
                        <i class="fa-duotone fa-solid fa-layer-group"></i>
                    </template>
                </view-header>
                <div class="grid-container positional-breakdown">
                    <detail-view-grid :columns="positionalBreakdownColumns"
                                    :initial-sort="[{ field: 'teamName', dir: 'asc' }]"
                                    :item-id-field="'teamId'"
                                    :items="teamData"
                                    :row-clickable="false"
                                    :delete-action="false"
                                    :allow-export="true"
                                    :elite-prospects-search="true"
                                    :export-title="'Team Cap Space Breakdown'"></detail-view-grid>
                </div>
            </template>
        </k-tabstrip>
        <div class="loader-container" v-if="isLoading">
            <k-loader :size="'large'" :type="'infinite-spinner'"></k-loader>
        </div>
    </div>
</template>

<script>
    import { Loader } from "@progress/kendo-vue-indicators";
    import { TabStrip } from '@progress/kendo-vue-layout';

    import detailViewGrid from '../components/detailViewGrid.vue';
    import viewHeader from '../components/viewHeader.vue';

    import auth from '../authConfig';
    import apiService from '../api/api.service.js';

    export default {
        name: 'nhlDashboardComponent',
        data() {
            return {
                capSpaceColumns: [
                    { cell: 'teamName', field: 'teamName', title: 'Team' },
                    { field: 'currentCapSpace', title: 'Cap Space', type: 'number', filter: 'numeric', format: '{0:c0}' },
                    { cell: 'teamCapHit', field: 'currentRosterAnnualCapHit', title: 'Cap Hit', type: 'number', filter: 'numeric', format: '{0:c0}' },
                    { field: 'rosterString', title: 'Active Roster' },
                    { field: 'contractsString', title: 'Contracts' },
                    { field: 'retainedSalaryString', title: 'Retained Slots' }
                ],
                isLoading: false,
                positionalBreakdownColumns: [
                    { cell: 'teamName', field: 'teamName', title: 'Team' },
                    { cell: 'capHitForwards', field: 'currentRosterAnnualCapHitForwards', title: 'Forwards', type: 'number', filter: 'numeric', format: '{0:c0}' },
                    { cell: 'capHitDefense', field: 'currentRosterAnnualCapHitDefense', title: 'Defense', type: 'number', filter: 'numeric', format: '{0:c0}' },
                    { cell: 'capHitGoalies', field: 'currentRosterAnnualCapHitGoalies', title: 'Goalies', type: 'number', filter: 'numeric', format: '{0:c0}' },
                    { cell: 'capHitLtir', field: 'currentRosterLtir', title: 'LTIR', type: 'number', filter: 'numeric', format: '{0:c0}' },
                    { cell: 'capHitDead', field: 'currentRosterDeadCapHit', title: 'Dead Cap', type: 'number', filter: 'numeric', format: '{0:c0}' }

                ],
                selectedTeamDataTab: 0,
                teamData: [],
                teamDataTabs: [
                    {
                        title: 'Cap Space',
                        content: 'capspace'
                    },
                    {
                        title: 'Positional Breakdown',
                        content: 'positionalbreakdown'
                    }
                ]
            }
        },
        mounted() {
            this.getNhlDashboardData();
        },
        methods: {
            async getNhlDashboardData() {
                this.isLoading = true;

                let request = {
                    nhl_team_id: null,
                    team_id: null
                }

                let tokenResponse = await auth.acquireTokenResponse();

                return apiService.GetPuckPediaTeams(tokenResponse.accessToken, request)
                    .then((res) => {
                        if (res?.data?.data) {
                            res.data.data.forEach(team => {
                                team.chartSeries = [
                                    {
                                        title: 'Forwards',
                                        dataItems: [team.currentRosterAnnualCapHitForwards]
                                    },
                                    {
                                        title: 'Defense',
                                        dataItems: [team.currentRosterAnnualCapHitDefense]
                                    },
                                    {
                                        title: 'Goalies',
                                        dataItems: [team.currentRosterAnnualCapHitGoalies]
                                    },
                                    {
                                        title: 'Dead Cap',
                                        dataItems: [team.currentRosterDeadCapHit]
                                    }
                                ]
                            });

                            this.teamData = res.data.data;

                            console.log(this.teamData)
                        }

                        this.isLoading = false;
                    }).catch(error => {
                        console.log(error)
                    })

            },
            onTeamDataTabSelected(evt) {
                this.selectedTeamDataTab = evt.selected;
            }
        },
        components: {
            'detail-view-grid': detailViewGrid,
            'view-header': viewHeader,
            'k-loader': Loader,
            'k-tabstrip': TabStrip
        }
    }
</script>

<style>
    @import '/src/assets/css/nhlDashboardComponent.css';
</style>