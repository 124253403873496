<template>
    <div class="create-invoice create-invoice-dialog invoice-detail-view">
        <view-header :title="player.firstName ? `${player.firstName} ${player.lastName}: ${uneditedInvoice.invoiceNo}` : ''">
            <template v-slot:viewicon>
                <i class="fa-duotone fa-file-invoice-dollar"></i>
            </template>
            <template v-slot:viewactions>
                <div class="action-btns">
                    <k-button class="save-changes__btn" :disabled="saveChangesBtnDisabled" @click="onUpdateInvoice"><i class="fa-sharp fa-solid fa-floppy-disk"></i> Save Changes</k-button>
                    <k-button class="discard-changes__btn" :disabled="saveChangesBtnDisabled" @click="onDiscardChanges"><i class="fa-sharp fa-solid fa-rotate-left"></i> Discard Changes</k-button>
                    <k-button class="discard-changes__btn" @click="onBackToInvoices"><i class="fa-solid fa-left"></i> Back to Invoices</k-button>
                </div>
            </template>
        </view-header>
        <div class="create-invoice__container invoice-detail__container" v-if="invoice">
            <div class="upsert-item__container invoice-info">
                <section class="upsert-item__section invoice-detail">
                    <div class="total-due__container">
                        <k-button @click="onGeneratePDF" class="gen-pdf__btn"><i class="fa-solid fa-file-pdf"></i> Generate PDF</k-button>
                        <p>Total Due: <span>{{ $options.filters.formatCurrencyDec(invoice.totalDue) }}</span></p>
                    </div>
                    <k-tabstrip :selected="selectedDetailTab" @select="onDetailTabSelected" :tabs="invoiceDetailTabs">
                        <template v-slot:invoiceinformation>
                            <div class="invoice-detail__tab-container">
                                <div class="detail-input">
                                    <div class="invoice-title__container">
                                        <i class="fa-duotone fa-memo-circle-info"></i>
                                        <h2>Invoice Information</h2>
                                        <p class="field--required">*Required</p>
                                    </div>
                                    <div class="input__row cinq">
                                        <label class="input__label">
                                            *Customer
                                            <k-input disabled v-model="player.fullName" />
                                        </label>
                                        <label class="input__label">
                                            *Invoice Number
                                            <k-input v-model="invoice.invoiceNo" @keydown="invoice$.invoice.invoiceNo.$reset()" :class="[{ 'error': invoice$.invoice.invoiceNo.$error }]"/>
                                            <span class="item-input--vuelidate-error" v-if="invoice$.invoice.invoiceNo.$error"><i>Invoice number is required</i></span>
                                        </label>
                                        <label class="input__label">
                                            *Invoice Date
                                            <k-datepicker v-model="invoice.invoiceDate" @focus="invoice$.invoice.invoiceDate.$reset()" :class="[{ 'error': invoice$.invoice.invoiceDate.$error }]"></k-datepicker>
                                            <span class="item-input--vuelidate-error" v-if="invoice$.invoice.invoiceDate.$error"><i>Invoice date is required</i></span>
                                        </label>
                                        <label class="input__label">
                                            *Payment Due Date
                                            <k-datepicker v-model="invoice.paymentDue" @focus="invoice$.invoice.paymentDue.$reset()" :class="[{ 'error': invoice$.invoice.paymentDue.$error }]"></k-datepicker>
                                            <span class="item-input--vuelidate-error" v-if="invoice$.invoice.paymentDue.$error"><i>Payment due date is required</i></span>
                                        </label>
                                        <label class="input__label">
                                            Date Paid
                                            <k-datepicker :disabled="uneditedInvoice.status !== 'Paid'" v-model="invoice.datePaid"></k-datepicker>
                                        </label>
                                    </div>

                                    <div class="input__row trio">
                                        <label class="input__label">
                                            Status
                                            <k-dropdown class="status__dropdown"
                                                        v-model="invoiceUpdatedStatus"
                                                        :data-items="invoiceStatusSelects"
                                                        @change="onInvoiceStatusUpdated($event)"></k-dropdown>
                                        </label>
                                        <label class="input__label">
                                            Total Due
                                            <k-numeric-input disabled v-model="invoice.totalDue" :format="'c2'"></k-numeric-input>
                                        </label>
                                        <label class="input__label label--balance-remaining">
                                            <span class="label-content">Balance Remaining <span v-if="invoice.status === 'Invoice Sent' || invoice.status === 'Paid in Part'" class="label-action" @click="onAddPayment"><i class="fa-solid fa-plus"></i> Add Payment</span></span>
                                            <k-numeric-input disabled v-model="invoice.balanceRemaining" :format="'c2'"></k-numeric-input>
                                        </label>
                                    </div>

                                    <div class="input__row">
                                        <label class="input__label">
                                            In Reference To
                                            <k-textarea v-model="invoice.inReferenceTo"></k-textarea>
                                        </label>
                                    </div>

                                    <h2 class="upsert-item__section--title">Invoice Contact</h2>
                                    <div class="input__row trio">
                                        <label class="input__label">
                                            First Name
                                            <k-input v-model="invoiceContact.firstName" />
                                        </label>
                                        <label class="input__label">
                                            Last Name
                                            <k-input v-model="invoiceContact.lastName" />
                                        </label>
                                        <label class="input__label">
                                            Title
                                            <k-input v-model="invoiceContact.title" />
                                        </label>
                                    </div>
                                    <div class="input__row trio">
                                        <label class="input__label">
                                            Phone
                                            <k-input v-model="invoiceCompany.phone" @keydown="formatContactPhoneNumberInput" @input="invoice$.invoiceCompany.phone.$reset()" :class="[{ 'error': invoice$.invoiceCompany.phone.$error }]" />
                                            <span class="item-input--vuelidate-error" v-if="invoice$.invoiceCompany.phone.$error"><i>Phone number is invalid</i></span>
                                        </label>
                                        <label class="input__label">
                                            Ext
                                            <k-input v-model="invoiceCompany.phoneExtension" />
                                        </label>
                                        <label class="input__label">
                                            Email
                                            <k-input v-model="invoiceCompany.email" />
                                        </label>
                                    </div>
                                    <div class="input__row pair">
                                        <label class="input__label">
                                            Address
                                            <k-input v-model="invoiceCompany.address" />
                                        </label>
                                        <label class="input__label">
                                            Additional (Suite, Unit, etc)
                                            <k-input v-model="invoiceCompany.addressAdditional" />
                                        </label>
                                    </div>
                                    <div class="input__row trio">
                                        <label class="input__label">
                                            City
                                            <k-input v-model="invoiceCompany.city" />
                                        </label>
                                        <label class="input__label">
                                            State
                                            <k-dropdown :data-items="stateSelectsAbbr"
                                                        v-model="invoiceCompany.state"
                                                        :default-item="'Select State'"
                                                        @change="defaultDropdownSelected($event, 'invoiceCompany.state', 'Select State')"></k-dropdown>
                                        </label>
                                        <label class="input__label">
                                            Zip
                                            <k-input v-model="invoiceCompany.zip" />
                                        </label>
                                    </div>
                                </div>
                                <k-tabstrip class="invoice-data__tabstrip" :selected="selectedDataTab" @select="onDataTabSelected" :tabs="invoiceDataTabs">
                                    <template v-slot:notes>
                                        <div class="invoice-notes__container">
                                            <div class="invoice-title__container">
                                                <i class="fa-duotone fa-notes"></i>
                                                <h2>Invoice Notes</h2>
                                            </div>
                                            <div class="editor__container">
                                                <k-editor :tools="tools"
                                                        ref="noteseditor"
                                                        :content-style="{ height: '150px' }"
                                                        :resizable="true"
                                                        v-on:focus="onEditorFocus"
                                                        :default-content="defaultEditorContent"
                                                        :defaultEditMode="'div'" />
                                                <div class="editor__container--submit">
                                                    <k-button class="submit-note__btn" @click="addInvoiceNote"><i class="fa-duotone fa-pen-line"></i> Submit </k-button>
                                                </div>
                                            </div>
                                            <div v-if="invoiceNotes.length === 0" class="note-detail__container--empty">
                                                <h4>No invoice notes available</h4>
                                            </div>
                                            <div v-if="invoiceNotes.length > 0" class="note-detail__container">
                                                <div v-for="(note, index) in invoiceNotes" :key="index" class="note">
                                                    <div class="note-date">{{ note.userName }} - {{ note.createdOn.toLocaleDateString('en-US', { hour: 'numeric', minute: '2-digit'}).split(',').join('') }}</div>
                                                    <div class="note-body" v-html="note.notes"></div>
                                                </div>
                                            </div>
                                        </div>
                                    </template>
                                    <template v-slot:payments>
                                        <div class="invoice-payments__container">
                                            <div class="invoice-title__container">
                                                <i class="fa-duotone fa-file-invoice-dollar"></i>
                                                <h2>Invoice Payments</h2>
                                            </div>
                                            <div v-if="invoicePayments.length === 0" class="note-detail__container--empty">
                                                <h4>No invoice payments available</h4>
                                            </div>
                                            <div v-if="invoicePayments.length > 0" class="invoice-payments">
                                                <div v-for="payment in invoicePayments" :key="payment.paymentId" class="payment">
                                                    <div class="payment-detail">
                                                        <div class="payment-detail__row trio">
                                                            <p class="detail-item">Date: <span>{{ payment.paymentDate ? new Date(payment.paymentDate).toLocaleDateString() : 'N/A' }}</span></p>
                                                            <p class="detail-item">Amount: <span>{{ $options.filters.formatCurrencyDec(payment.paymentAmount) }}</span></p>
                                                            <p class="detail-item">Invoice: <span>{{ payment.invoiceNo ?? 'N/A' }}</span></p>
                                                        </div>
                                                        <div class="payment-detail__row" v-if="payment.paymentNote">
                                                            <p class="detail-item">Note: <span>{{ payment.paymentNote }}</span></p>
                                                        </div>
                                                    </div>
                                                    <div v-if="payment.manuallyEntered" class="payment-delete" @click="onDeletePayment(payment)" title="Delete Payment"><i class="fa-solid fa-trash-can"></i></div>
                                                </div>
                                            </div>
                                        </div>
                                    </template>
                                    <template v-slot:history>
                                        <div class="invoice-history__container">
                                            <div class="invoice-title__container">
                                                <i class="fa-duotone fa-clock-rotate-left"></i>
                                                <h2>Invoice History</h2>
                                            </div>
                                            <div class="invoice-history-dropdown__container">
                                                <k-dropdown :data-items="filteredInvoiceHistoryFields"
                                                    class="invoice-history__dropdown"
                                                    :value="selectedHistoryField"
                                                    :filterable="true"
                                                    @filterchange="onInvoiceHistoryFilterChange"
                                                    @change="onInvoiceHistoryFieldSelected"></k-dropdown>
                                            </div>
                                            <div v-if="filteredInvoiceHistory.length === 0" class="note-detail__container--empty">
                                                <h4>No invoice history available</h4>
                                            </div>

                                            <div v-if="filteredInvoiceHistory.length > 0" class="note-detail__container">
                                                <div v-for="(note, index) in filteredInvoiceHistory" :key="index" class="note">
                                                    <div class="note-date">{{ note.updatedOn.toLocaleDateString('en-US', { hour: 'numeric', minute: '2-digit'}).split(',').join('') }} - {{ note.fieldName }}<span>{{ note.updatedBy }}</span></div>
                                                    <div class="note-body" v-if="note.newValue && !note.oldValue && note.fieldName !== 'Created'">Added: {{ formatHistoryLog(note.fieldName, note.newValue) }}</div>
                                                    <div class="note-body" v-if="!note.newValue && note.oldValue && note.fieldName !== 'Created'">Deleted: {{ formatHistoryLog(note.fieldName, note.oldValue) }}</div>
                                                    <div class="note-body" v-if="note.newValue && note.oldValue && note.fieldName !== 'Created'">Updated: {{ formatHistoryLog(note.fieldName, note.oldValue) }} to {{ formatHistoryLog(note.fieldName, note.newValue) }}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </template>
                                </k-tabstrip>
                            </div>
                        </template>
                        <template v-slot:invoiceitems>
                            <div class="invoice-items__tab-container">
                                <h2 class="upsert-item__section--title salary-details">Salary Details 
                                    <i class="fa-sharp fa-circle-info" @mouseover="toggleSalaryDetails" @mouseleave="toggleSalaryDetails"></i>
                                    <div v-bind:class="['salary__tooltip', { 'tooltip--show': showSalaryDetails }]">
                                        <div v-if="playingContract">
                                            <div class="contract-title__row">
                                                <i class="fa-duotone fa-file-signature"></i>
                                                <div class="contract-title-dates">
                                                    <h4>{{ playingContract.contractTitle }}</h4>
                                                    <p class="contract-date">Signed: <span>{{ new Date(playingContract.nhlContractStartYear).toLocaleDateString() }}</span> Expires: <span>{{ new Date(playingContract.nhlContractEndYear).toLocaleDateString() }}</span></p>
                                                </div>
                                            </div>

                                            <div v-for="detail in playingContractSalaryDetails" :key="detail.detailId" class="salary-detail__container">
                                                <div class="salary-detail__row">
                                                    <p class="detail-year">Yr <span>{{ detail.year }}</span></p>
                                                    <p v-if="showNhlSalaryDetails" class="detail-salary">NHL Base: <span>{{ $options.filters.formatCurrencyDec(detail[nhlContractAmountField]) }}</span></p>
                                                    <p v-if="showAhlSalaryDetails" class="detail-salary">AHL Base: <span>{{ $options.filters.formatCurrencyDec(detail[ahlContractAmountField]) }}</span></p>
                                                    <p v-if="showEchlSalaryDetails" class="detail-salary">ECHL: <span>{{ $options.filters.formatCurrencyDec(detail[echlContractAmountField]) }}</span></p>

                                                    <p v-if="showSalaryBufferColumn" class="detail-salary"></p>
                                                </div>
                                            </div>
                                        </div>
                                        <div v-if="!playingContract">
                                            <div class="contract-title__row">
                                                <i class="fa-duotone fa-folder-magnifying-glass"></i>
                                                <div class="contract-title-dates">
                                                    <h4>No active playing contract available</h4>
                                                    <p class="contract-date"></p>
                                                </div>                                                        
                                            </div>
                                        </div>
                                    </div>
                                </h2>
                                <div class="input__row trio" v-if="showNhlSalaryDetails">
                                    <label class="input__label">
                                        NHL Base Salary
                                        <k-numeric-input v-model="nhlSalaryDetails.baseSalary" :format="'c2'" :min="0"></k-numeric-input>
                                    </label>
                                    <label class="input__label">
                                        Total Payable Days
                                        <k-numeric-input v-model="nhlSalaryDetails.payableDays" :format="'n0'" :min="0"></k-numeric-input>
                                    </label>
                                    <label class="input__label">
                                        Daily Salary
                                        <k-numeric-input v-model="nhlSalaryDetails.dailySalary" :format="'c2'" :min="0"></k-numeric-input>
                                    </label>
                                </div>

                                <div class="input__row trio" v-if="showAhlSalaryDetails">
                                    <label class="input__label">
                                        AHL Base Salary
                                        <k-numeric-input v-model="ahlSalaryDetails.baseSalary" :format="'c2'" :min="0"></k-numeric-input>
                                    </label>
                                    <label class="input__label">
                                        Total Payable Days 
                                        <k-numeric-input v-model="ahlSalaryDetails.payableDays" :format="'n0'" :min="0"></k-numeric-input>
                                    </label>
                                    <label class="input__label">
                                        Daily Salary
                                        <k-numeric-input v-model="ahlSalaryDetails.dailySalary" :format="'c2'" :min="0"></k-numeric-input>
                                    </label>
                                </div>

                                <div class="input__row trio" v-if="showEchlSalaryDetails">
                                    <label class="input__label">
                                        ECHL Base Salary
                                        <k-numeric-input v-model="echlSalaryDetails.baseSalary" :format="'c2'" :min="0"></k-numeric-input>
                                    </label>
                                    <label class="input__label">
                                        Total Payable Days 
                                        <k-numeric-input v-model="echlSalaryDetails.payableDays" :format="'n0'" :min="0"></k-numeric-input>
                                    </label>
                                    <label class="input__label">
                                        Daily Salary
                                        <k-numeric-input v-model="echlSalaryDetails.dailySalary" :format="'c2'" :min="0"></k-numeric-input>
                                    </label>
                                </div>
                                <div class="invoice-title__container">
                                    <i class="fa-duotone fa-file-lines"></i>
                                    <h2>Invoice Items</h2>
                                </div>
                                <div class="input__row add-item">
                                    <label class="input__label add-item">
                                        <span @click="onAddInvoiceItem"><i class="fa-sharp fa-solid fa-plus"></i> Add Invoice Item</span>
                                    </label>
                                </div>
                                <div :class="['invoice-items__container', { 'active': invoiceItems.length > 0 }]">
                                    <div class="invoice-item" v-for="(item, index) in invoiceItems" :key="item.itemId">
                                        <k-slide :appear="invoiceItems[index] !== null">
                                            <div class="input__row invoice-item-row">
                                                <label class="input__label item-category">
                                                    Category
                                                    <k-dropdown :data-items="invoiceItemCategorySelects"
                                                                v-model="item.category"
                                                                :default-item="'Select Category'"
                                                                @change="invoiceItemDropdownSelected($event, 'category', 'Select Category', index)"></k-dropdown>
                                                </label>
                                                <label class="input__label item-quantity">
                                                    {{ calculateQuantityDisplay(item) }}
                                                    <k-numeric-input v-model="item.quantity" :format="'n0'" :min="0"></k-numeric-input>
                                                </label>
                                                <label class="input__label item-name">
                                                    Name
                                                    <k-dropdown v-if="item.category === invoiceItemCategoryManagementFee"
                                                                :data-items="invoiceItemManagementFeeNameSelects"
                                                                v-model="item.name"
                                                                :default-item="'Select Name'"
                                                                @change="invoiceItemDropdownSelected($event, 'name', 'Select Name', index)"></k-dropdown>
                                                    <k-dropdown v-if="item.category === invoiceItemCategoryBalanceRemaining"
                                                                :data-items="invoicesWithBalancesSelects"
                                                                v-model="item.name"
                                                                :default-item="'Select Name'"
                                                                @change="invoiceItemDropdownSelected($event, 'name', 'Select Name', index)"></k-dropdown>
                                                    <k-input v-if="item.category !== invoiceItemCategoryManagementFee && item.category !== invoiceItemCategoryBalanceRemaining" v-model="item.name" />
                                                </label>
                                                <label class="input__label item-description">
                                                    Description
                                                    <k-input v-model="item.description" />
                                                </label>
                                                <label class="input__label item-date">
                                                    Date Earned
                                                    <k-datepicker v-model="item.dateEarned"></k-datepicker>
                                                </label>
                                                <label class="input__label item-player-earnings">
                                                    {{ item.category === invoiceItemCategoryEndorsementFee || item.category === invoiceItemCategoryEscrow || item.category === invoiceItemCategoryEscrowBonus || item.category === invoiceItemCategoryManagementFee ? 'Player Earnings' : 'Amount' }}
                                                    <k-numeric-input :placeholder="'Enter amount'" v-model="item.playerEarnings" :format="'c2'"></k-numeric-input>
                                                </label>
                                                <label class="input__label item-rate">
                                                    Rate 
                                                    <k-numeric-input :placeholder="'Enter %'" v-model="item.rate" :format="'##.00 \\%'" :decimals="2" :min="0"></k-numeric-input>
                                                </label>
                                                <label class="input__label item-amount-due">
                                                    Amount Due
                                                    <k-numeric-input v-model="item.amountDue" :format="'c2'"></k-numeric-input>
                                                </label>
                                            </div>
                                        </k-slide>
                                        <div class="item-delete__container" @click="onDeleteInvoiceItem(item)" title="Delete Item"><i class="fa-solid fa-trash-can"></i></div>
                                    </div>
                                </div>
                            </div>
                        </template>
                        <template v-slot:contracts>
                            <div class="contract-tab__container">
                                <div class="invoice-title__container">
                                    <i class="fa-duotone fa-file-signature"></i>
                                    <h2>Active Contracts</h2>
                                    <k-dropdown :data-items="contractDropdownItems"
                                                class="active-contracts__dropdown"
                                                v-model="selectedContractItem"
                                                :text-field="'title'"
                                                :data-item-key="'id'"
                                                @change="onGetContractDetail"></k-dropdown>
                                    <i v-if="selectedContractItem.title !== 'Select a Contract' && selectedActiveContract" title="View Contract Detail" class="detail-link fa-solid fa-pen-to-square" @click="goToSelectedContract"></i>
                                </div>
                                <div class="selected-contract__container" v-if="selectedContractItem.title !== 'Select a Contract' && selectedActiveContract">
                                    <div :class="['selected-contract__detail-container', { 'agency-detail': selectedActiveContract.contract.contractType === agencyContractType }]">
                                        <div class="active-contract agency" v-if="selectedActiveContract.contract.contractType === agencyContractType">
                                            <h2 class="contract--title first">Contract Information</h2>
                                            <div class="active-contract__row pair">
                                                <p class="contract-row__item">Signing Date: 
                                                    <span>
                                                        {{ selectedActiveContract.contract.spacSigningDate ? new Date(selectedActiveContract.contract.spacSigningDate).toLocaleDateString() :  'N/A'}}
                                                    </span>
                                                </p>
                                                <p class="contract-row__item">Expiration Date: 
                                                    <span>
                                                        {{ selectedActiveContract.contract.expirationDate ? new Date(selectedActiveContract.contract.expirationDate).toLocaleDateString() :  'N/A'}}
                                                    </span>
                                                </p>
                                            </div>
                                            <div class="active-contract__row pair">
                                                <p class="contract-row__item">Primary Agent: <span>{{ selectedActiveContract.contract.primaryAgent ?? 'N/A' }}</span></p>
                                                <p class="contract-row__item">Secondary Agent: <span>{{ selectedActiveContract.contract.secondaryAgent ?? 'N/A' }}</span></p>
                                            </div>
                                            <div class="active-contract__row pair">
                                                <p class="contract-row__item">Third Agent: <span>{{ selectedActiveContract.contract.thirdAgent ?? 'N/A' }}</span></p>
                                            </div>
                                            <h2 class="contract--title">Contract Commission Information</h2>
                                            <div class="active-contract__row pair">
                                                <p class="contract-row__item">Playing Commission Percent: <span>{{ selectedActiveContract.contract.playingCommissionPercent ? `${selectedActiveContract.contract.playingCommissionPercent} %` : 'N/A' }}</span></p>
                                                <p class="contract-row__item">Endorsement Commission Percent: <span>{{ selectedActiveContract.contract.endorsementCommissionPercent ? `${selectedActiveContract.contract.endorsementCommissionPercent} %` : 'N/A' }}</span></p>
                                            </div>
                                        </div>
                                        <div class="active-contract endorsement" v-if="selectedActiveContract.contract.contractType === endorsementContractType">
                                            <h2 class="contract--title first">Contract Information</h2>
                                            <div class="active-contract__row pair">
                                                <p class="contract-row__item">Signing Date: 
                                                    <span>
                                                        {{ selectedActiveContract.contract.endorsementSigningDate ? new Date(selectedActiveContract.contract.endorsementSigningDate).toLocaleDateString() :  'N/A'}}
                                                    </span>
                                                </p>
                                                <p class="contract-row__item">Expiration Date: 
                                                    <span>
                                                        {{ selectedActiveContract.contract.endorsementExpirationDate ? new Date(selectedActiveContract.contract.endorsementExpirationDate).toLocaleDateString() :  'N/A'}}
                                                    </span>
                                                </p>      
                                            </div>
                                            <div class="active-contract__row">
                                                <p class="contract-row__item">Agency Contract: 
                                                    <span>
                                                        {{ selectedContractAssociatedContract ? selectedContractAssociatedContract.contractTitle : 'N/A' }}
                                                    </span>
                                                </p>      
                                            </div>
                                            <div class="active-contract__row pair">
                                                <p class="contract-row__item">Endorsement Type: <span>{{ selectedActiveContract.contract.endorsementContractType ?? 'N/A' }}</span></p>
                                                <p class="contract-row__item">Compnay Name: <span>{{ selectedActiveContract.contract.endorsementCompanyName ?? 'N/A' }}</span></p>
                                            </div>
                                            <div class="active-contract__row pair">
                                                <p class="contract-row__item">Contract Amount: <span>{{ selectedActiveContract.contract.endorsementAmount ? $options.filters.formatCurrencyDec(selectedActiveContract.contract.endorsementAmount) : 'N/A' }}</span></p>
                                                <p class="contract-row__item">Playing Commission Percent: <span>{{ selectedActiveContract.contract.endorsementCommissionPercent ? `${selectedActiveContract.contract.endorsementCommissionPercent} %` : 'N/A' }}</span></p>
                                            </div>
                                            <div class="active-contract__notes">
                                                <p class="contract-notes__label">Notes:</p>
                                                <div class="contract-notes__container">
                                                    <p>{{ selectedActiveContract.contract.endorsementNotes}}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="active-contract equipment" v-if="selectedActiveContract.contract.contractType === equipmentContractType">
                                            <h2 class="contract--title first">Contract Information</h2>
                                            <div class="active-contract__row pair">
                                                <p class="contract-row__item">Equipment Contract: <span>{{ selectedActiveContract.contract.equipmentContract ?? 'N/A' }}</span></p>
                                                <p class="contract-row__item">Agency Contract: 
                                                    <span>
                                                        {{ selectedContractAssociatedContract ? selectedContractAssociatedContract.contractTitle : 'N/A' }}
                                                    </span>
                                                </p> 
                                            </div>
                                            <div class="active-contract__row pair">
                                                <p class="contract-row__item">Signing Date: 
                                                    <span>
                                                        {{ selectedActiveContract.contract.equipmentSigningDate ? new Date(selectedActiveContract.contract.equipmentSigningDate).toLocaleDateString() :  'N/A'}}
                                                    </span>
                                                </p>
                                                <p class="contract-row__item">Expiration Date: 
                                                    <span>
                                                        {{ selectedActiveContract.contract.equpmentExpirationDate ? new Date(selectedActiveContract.contract.equipmentExpirationDate).toLocaleDateString() :  'N/A'}}
                                                    </span>
                                                </p>   
                                            </div>
                                            <div class="active-contract__row pair">
                                                <p class="contract-row__item">Contract Amount: <span>{{ selectedActiveContract.contract.equipmentAmount ? $options.filters.formatCurrencyDec(selectedActiveContract.contract.equipmentAmount) : 'N/A' }}</span></p>
                                                <p class="contract-row__item">Equipment Commission Percent: <span>{{ selectedActiveContract.contract.equipmentCommissionRate ? `${selectedActiveContract.contract.equipmentCommissionRate} %` : 'N/A' }}</span></p>
                                            </div>
                                            <div class="active-contract__notes">
                                                <p class="contract-notes__label">Notes:</p>
                                                <div class="contract-notes__container">
                                                    <p>{{ selectedActiveContract.contract.equipmentNotes }}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="active-contract playing" v-if="selectedActiveContract.contract.contractType === playingContractType">
                                            <h2 class="contract--title first">Contract Information</h2>
                                            <div class="active-contract__row pair">
                                                <p class="contract-row__item">Signing Date: 
                                                    <span>
                                                        {{ selectedActiveContract.contract.nhlContractStartYear ? new Date(selectedActiveContract.contract.nhlContractStartYear).toLocaleDateString() :  'N/A'}}
                                                    </span>
                                                </p>
                                                <p class="contract-row__item">Expiration Date: 
                                                    <span>
                                                        {{ selectedActiveContract.contract.nhlContractEndYear ? new Date(selectedActiveContract.contract.nhlContractEndYear).toLocaleDateString() :  'N/A'}}
                                                    </span>
                                                </p>   
                                            </div>
                                            <div class="active-contract__row pair">
                                                <p class="contract-row__item">NHL Signing Club: <span>{{ selectedActiveContract.contract.nhlSigningClub ?? 'N/A' }}</span></p>
                                                <p class="contract-row__item">Agency Contract: 
                                                    <span>
                                                        {{ selectedContractAssociatedContract ? selectedContractAssociatedContract.contractTitle : 'N/A' }}
                                                    </span>
                                                </p> 
                                            </div>
                                            <h2 class="contract--title">NHL Information</h2>
                                            <div class="active-contract__row pair">
                                                <p class="contract-row__item">NHL Contract Type: <span>{{ selectedActiveContract.contract.nhlContractType ?? 'N/A' }}</span></p>
                                                <p class="contract-row__item">Exp Free Agent Status: <span>{{ selectedActiveContract.contract.nhlContractExpirationStatus ?? 'N/A' }}</span></p>
                                            </div>
                                            <div v-if="selectedActiveContract.contract.nhlContractType === nhlOneWayContractType || selectedActiveContract.contract.nhlContractType === ahlNhlTwoWayContractType">
                                                <h2 class="contract--title">No-Trade Clause Information</h2>
                                                <div class="active-contract__row pair">
                                                    <p class="contract-row__item">Type: <span>{{ selectedActiveContract.contract.noTradeClauseType ?? 'N/A' }}</span></p>
                                                    <p v-if="selectedActiveContract.contract.limitedModifiedNoTradeClause" class="contract-row__item">Due: <span>{{ selectedActiveContract.contract.limitedModifiedOption ?? 'N/A' }}</span></p>
                                                </div>
                                            </div>
                                            <h2 class="contract--title">Salary Details</h2>
                                            <div v-if="selectedActiveContract.contract.nhlContractType === ahlNhlTwoWayContractType" class="detail-years__container">
                                                <div v-for="detail in selectedActiveContract.ahlNhlTwoWayContractDetails" :key="detail.detailId" class="detail-year__container">
                                                    <p class="salary-detail--year">Year {{ detail.year }}</p>
                                                    <div class="active-contract__row trio">
                                                        <p class="contract-row__item">AHL Amount: <span>{{ detail.ahlTwoWayAmount ? $options.filters.formatCurrencyDec(detail.ahlTwoWayAmount) : 'N/A' }}</span></p>
                                                        <p class="contract-row__item">AHL Payable Days: <span>{{ detail.ahlTotalPayableDays ?? 'N/A' }}</span></p>
                                                        <p class="contract-row__item">AHL Daily Salary: <span>{{ detail.ahlDailySalary ? $options.filters.formatCurrencyDec(detail.ahlDailySalary) : 'N/A' }}</span></p>
                                                    </div>
                                                    <div class="active-contract__row trio">
                                                        <p class="contract-row__item">NHL Amount: <span>{{ detail.nhlTwoWayAmount ? $options.filters.formatCurrencyDec(detail.nhlTwoWayAmount) : 'N/A' }}</span></p>
                                                        <p class="contract-row__item">NHL Payable Days: <span>{{ detail.nhlTotalPayableDays ?? 'N/A' }}</span></p>
                                                        <p class="contract-row__item">NHL Daily Salary: <span>{{ detail.nhlDailySalary ? $options.filters.formatCurrencyDec(detail.nhlDailySalary) : 'N/A' }}</span></p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div v-if="selectedActiveContract.contract.nhlContractType === ahlOneWayContractType" class="detail-years__container">
                                                <div v-for="detail in selectedActiveContract.ahlOneWayContractDetails" :key="detail.detailId" class="detail-year__container">
                                                    <p class="salary-detail--year">Year {{ detail.year }}</p>
                                                    <div class="active-contract__row trio">
                                                        <p class="contract-row__item">AHL Amount: <span>{{ detail.ahlOneWayAmount ? $options.filters.formatCurrencyDec(detail.ahlOneWayAmount) : 'N/A' }}</span></p>
                                                        <p class="contract-row__item">AHL Payable Days: <span>{{ detail.ahlTotalPayableDays ?? 'N/A' }}</span></p>
                                                        <p class="contract-row__item">AHL Daily Salary: <span>{{ detail.ahlOneWayDailySalary ? $options.filters.formatCurrencyDec(detail.ahlOneWayDailySalary) : 'N/A' }}</span></p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div v-if="selectedActiveContract.contract.nhlContractType === ahlTwoWayContractType" class="detail-years__container">
                                                <div v-for="detail in selectedActiveContract.ahlTwoWayContractDetails" :key="detail.detailId" class="detail-year__container">
                                                    <p class="salary-detail--year">Year {{ detail.year }}</p>
                                                    <div class="active-contract__row trio">
                                                        <p class="contract-row__item">AHL Amount: <span>{{ detail.ahlTwoWayAmount ? $options.filters.formatCurrencyDec(detail.ahlTwoWayAmount) : 'N/A' }}</span></p>
                                                        <p class="contract-row__item">AHL Payable Days: <span>{{ detail.ahlTotalPayableDays ?? 'N/A' }}</span></p>
                                                        <p class="contract-row__item">AHL Daily Salary: <span>{{ detail.ahlDailySalary ? $options.filters.formatCurrencyDec(detail.ahlDailySalary) : 'N/A' }}</span></p>
                                                    </div>
                                                    <div class="active-contract__row trio">
                                                        <p class="contract-row__item">ECHL Amount: <span>{{ detail.echlTwoWayAmount ? $options.filters.formatCurrencyDec(detail.echlTwoWayAmount) : 'N/A' }}</span></p>
                                                        <p class="contract-row__item">ECHL Payable Days: <span>{{ detail.echlTotalPayableDays ?? 'N/A' }}</span></p>
                                                        <p class="contract-row__item">ECHL Daily Salary: <span>{{ detail.echlDailySalary ? $options.filters.formatCurrencyDec(detail.echlDailySalary) : 'N/A' }}</span></p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div v-if="selectedActiveContract.contract.nhlContractType === nhlOneWayContractType" class="detail-years__container">
                                                <div v-for="detail in selectedActiveContract.nhlOneWayContractDetails" :key="detail.detailId" class="detail-year__container">
                                                    <p class="salary-detail--year">Year {{ detail.year }}</p>
                                                    <div class="active-contract__row trio">
                                                        <p class="contract-row__item">NHL Amount: <span>{{ detail.nhlOneWayAmount ? $options.filters.formatCurrencyDec(detail.nhlOneWayAmount) : 'N/A' }}</span></p>
                                                        <p class="contract-row__item">NHL Payable Days: <span>{{ detail.nhlTotalPayableDays ?? 'N/A' }}</span></p>
                                                        <p class="contract-row__item">NHL Daily Salary: <span>{{ detail.nhlOneWayDailySalary ? $options.filters.formatCurrencyDec(detail.nhlOneWayDailySalary) : 'N/A' }}</span></p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div v-if="selectedActiveContract.contract.nhlContractType === echlContractType" class="detail-years__container">
                                                <div v-for="detail in selectedActiveContract.echlContractDetails" :key="detail.detailId" class="detail-year__container">
                                                    <p class="salary-detail--year">Year {{ detail.year }}</p>
                                                    <div class="active-contract__row pair">
                                                        <p class="contract-row__item">ECHL Amount: <span>{{ detail.echlAmount ? $options.filters.formatCurrencyDec(detail.echlAmount) : 'N/A' }}</span></p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div v-if="selectedActiveContract.contract.nhlContractType === elcContractType" class="detail-years__container">
                                                <div v-for="detail in selectedActiveContract.elcContractDetails" :key="detail.detailId" class="detail-year__container">
                                                    <p class="salary-detail--year">Year {{ detail.year }}</p>
                                                    <div class="active-contract__row pair">
                                                        <p class="contract-row__item">AHL Amount: <span>{{ detail.ahlSalary ? $options.filters.formatCurrencyDec(detail.ahlSalary) : 'N/A' }}</span></p>
                                                        <p class="contract-row__item">NHL Amount: <span>{{ detail.nhlSalary ? $options.filters.formatCurrencyDec(detail.nhlSalary) : 'N/A' }}</span></p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div v-bind:class="['selected-contract__items-container', { 'playing-contract': selectedActiveContract.contract.contractType === playingContractType }]">
                                        <div class="selected-contract__items active-contract" v-if="selectedActiveContract.contract.contractType === endorsementContractType">
                                            <h2 class="contract--title first">Endorsement Payouts</h2>
                                            <div class="items__container payouts">
                                                <div class="active-contract__row trio" v-for="payout in selectedActiveContract.endorsementPayouts" :key="payout.payoutId">
                                                    <p class="contract-row__item">Payout Date: <span>{{ payout.payoutDate ? new Date(payout.payoutDate).toLocaleDateString() : 'N/A' }}</span></p>
                                                    <p class="contract-row__item">Payout Amount: <span>{{ payout.payoutAmount ? $options.filters.formatCurrencyDec(payout.payoutAmount) : 'N/A' }}</span></p>
                                                    <p class="contract-row__item"></p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="bonus-tabstrip__container active-contract" v-if="selectedActiveContract.contract.contractType === playingContractType">
                                            <k-tabstrip :selected="selectedContractBonusTab" @select="onContractBonusTabSelected" :tabs="contractBonusTabs">
                                                <template v-slot:performancebonus>
                                                    <div class="active-contract__row trio" v-for="bonus in selectedActiveContract.performanceBonuses" :key="bonus.bonusId">
                                                        <p class="contract-row__item">Title: <span>{{ bonus.bonusTitle ?? 'N/A' }}</span></p>
                                                        <p class="contract-row__item">Bonus Amount: <span>{{ bonus.bonusAmount ? $options.filters.formatCurrencyDec(bonus.bonusAmount) : 'N/A' }}</span></p>
                                                        <p class="contract-row__item">Notes: <span>{{ bonus.notes ?? 'N/A' }}</span></p>
                                                    </div>
                                                    <div class="contract--no-bonus" v-if="selectedActiveContract.performanceBonuses.length === 0">
                                                        <h4><i class="fa-duotone fa-money-check-dollar-pen"></i> No Bonuses Found</h4>
                                                    </div>
                                                </template>
                                                <template v-slot:nhlbonus>
                                                    <div class="active-contract__row trio" v-for="bonus in selectedActiveContract.nhlSigningBonuses" :key="bonus.bonusId">
                                                        <p class="contract-row__item">Bonus Date: <span>{{ bonus.bonusDate ? new Date(bonus.bonusDate).toLocaleDateString() : 'N/A' }}</span></p>
                                                        <p class="contract-row__item">Bonus Amount: <span>{{ bonus.bonusAmount ? $options.filters.formatCurrencyDec(bonus.bonusAmount) : 'N/A' }}</span></p>
                                                        <p class="contract-row__item"></p>
                                                    </div>
                                                    <div class="contract--no-bonus" v-if="selectedActiveContract.nhlSigningBonuses.length === 0">
                                                        <h4><i class="fa-duotone fa-money-check-dollar-pen"></i> No Bonuses Found</h4>
                                                    </div>
                                                </template>
                                                <template v-slot:elcbonus>
                                                    <div class="active-contract__row trio" v-for="bonus in selectedActiveContract.elcPerformanceBonuses" :key="bonus.bonusId">
                                                        <p class="contract-row__item">Bonus Amount: <span>{{ bonus.bonusAmount ? $options.filters.formatCurrencyDec(bonus.bonusAmount) : 'N/A' }}</span></p>
                                                        <p class="contract-row__item">Schedule Type: <span>{{ bonus.scheduleType ?? 'N/A' }}</span></p>
                                                        <p class="contract-row__item">Notes: <span>{{ bonus.notes ?? 'N/A' }}</span></p>
                                                    </div>
                                                    <div class="contract--no-bonus" v-if="selectedActiveContract.elcPerformanceBonuses.length === 0">
                                                        <h4><i class="fa-duotone fa-money-check-dollar-pen"></i> No Bonuses Found</h4>
                                                    </div>
                                                </template>
                                            </k-tabstrip>
                                        </div>
                                    </div>
                                </div>
                                <div class="selected-contract__container no-contract-selected" v-if="selectedContractItem.title === 'Select a Contract'">
                                    <i class="fa-duotone fa-file-signature"></i>
                                    <p>No Contract Selected</p>
                                </div>
                            </div>
                        </template>
                    </k-tabstrip>
                </section>
            </div>
        </div>

        <confirm-dialog :showDialog="showConfirmDialog"
                        :dialog-Msg="confirmMsg"
                        :icon-class="confirmIconClass"
                        @dialogclosed="toggleConfirmDialog"
                        @cancelclicked="toggleConfirmDialog"
                        @confirmclicked="deleteItem"></confirm-dialog>

        <invoice-payment-dialog :show-dialog="showInvoicePaymentDialog"
                                :selected-invoice="invoice"
                                @dialogclosed="onPaymentCanceled"
                                @paymentEntered="onPaymentEntered"></invoice-payment-dialog>

        <invoice-payment-date-dialog :show-dialog="showInvoicePaymentDateDialog"
                                     :selected-invoice="invoice"
                                     @dialogclosed="onPaymentDateCanceled"
                                     @dateentered="onPaymentDateEntered"></invoice-payment-date-dialog>

        <div class="loader-container" v-if="isLoading">
            <k-loader :size="'large'" :type="'infinite-spinner'"></k-loader>
        </div>

        <transition name="dialogfade">
            <k-dialog v-if="showErrorDialog" :title="errorTitle" class="error__dialog" @close="toggleErrorDialog">
                <p class="error-dialog__msg">{{ errorMsg }}</p>
            </k-dialog>
        </transition>

        <k-notification-group class="default-notification-container">
                <k-fade :appear="successNotification">
                    <k-notification v-if="successNotification"
                                    :type="{ style: 'success', icon: true }"
                                    :closable="true"
                                    @close="onCloseNotification('successNotification')">
                        <span>{{ notificationMsg }}</span>
                    </k-notification>
                </k-fade>
        </k-notification-group>
    </div>
</template>

<script>
    import { Button } from "@progress/kendo-vue-buttons";
    import { Loader } from "@progress/kendo-vue-indicators";
    import { Notification, NotificationGroup } from "@progress/kendo-vue-notification";
    import { Dialog } from '@progress/kendo-vue-dialogs';
    import { DropDownList } from '@progress/kendo-vue-dropdowns';
    import { DatePicker } from '@progress/kendo-vue-dateinputs';
    import { Input, NumericTextBox, TextArea } from "@progress/kendo-vue-inputs";
    import { TabStrip } from '@progress/kendo-vue-layout';
    import { Slide, Fade } from '@progress/kendo-vue-animation';
    import { Editor } from '@progress/kendo-vue-editor';

    import { filterBy } from '@progress/kendo-data-query';

    import viewHeader from '../components/viewHeader.vue';

    import { useVuelidate } from '@vuelidate/core';
    import { required } from '@vuelidate/validators';
    import confirmDialog from '../components/confirmDialog.vue';
    import invoicePaymentDialog from '../components/invoicePaymentDialog.vue';
    import invoicePaymentDateDialog from '../components/invoicePaymentDateDialog.vue';

    import cloneDeep from 'lodash.clonedeep';
    import isEqualWith from 'lodash.isequalwith';

    import auth from '../authConfig';
    import apiService from '../api/api.service.js';
    import invoiceHelpers from '../helpers/invoiceHelpers.js';
    import formatHelpers from '@/helpers/formatHelpers';
    import contractHelpers from '../helpers/contractHelpers.js';

    const phoneValidator = (val) => {
        if (!val) return true;

        const phoneRegEx = `^((\\(\\d{3}\\))|\\d{3})[- ]?\\d{3}[- ]?\\d{4}$`;
        return val.match(phoneRegEx);
    }

    export default {
        setup() {
            return { invoice$: useVuelidate() }
        },
        name: 'invoiceDetailView',
        data() {
            return {
                agencyContract: null,
                ahlContractAmountField: '',
                ahlSalaryDetails: null,
                confirmIconClass: '',
                confirmMsg: '',
                contractBonusTabs: [
                    {
                        title: 'Signing Bonus',
                        content: 'nhlbonus'
                    },
                    {
                        title: 'NHL Bonus',
                        content: 'performancebonus'
                    },
                    {
                        title: 'ELC Bonus',
                        content: 'elcbonus'
                    }
                ],
                contractDropdownItems: [],
                defaultEditorContent: 'Add your note here!',
                deletedItem: null,
                deletedItemType: '',
                echlContractAmountField: '',
                echlSalaryDetails: null,
                errorMsg: '',
                errorTitle: '',
                filteredInvoiceHistoryFields: [],
                historyLogCurrencyFields: ['Balance Remaining', 'Initial Deposit', 'Total Due'],
                historyLogDateFields: ['Payment Due', 'Invoice Date'],
                invoice: null,
                invoiceCompany: {},
                invoiceContact: null,
                invoiceDataTabs: [
                    {
                        title: 'Notes',
                        content: 'notes'
                    },
                    {
                        title: 'Payments',
                        content: 'payments'
                    },
                    {
                        title: 'History',
                        content: 'history'
                    }
                ],
                invoiceDetailTabs: [
                    {
                        title: 'Information',
                        content: 'invoiceinformation'
                    },
                    {
                        title: 'Items',
                        content: 'invoiceitems'
                    },
                    {
                        title: 'Active Contracts',
                        content: 'contracts'
                    }
                ],
                invoiceHistory: [],
                invoiceId: '',
                invoiceCurrencyFields: ['amountDue', 'dailySalary', 'expenses', 'playerEarnings', 'totalDue', 'totalDueThisInvoice', 'balanceRemaining'],
                invoiceHistoryFields: ['All', 'Balance Remaining', 'Created', 'Initial Deposit', 'In Reference To', 'Invoice Date', 'Invoice No', 'Payment Due', 'Status', 'Total Due'],
                invoiceItemModel: {
                    itemId: null,
                    invoiceId: null,
                    amountDue: null,
                    category: null,
                    comments: null,
                    description: null,
                    expenses: null,
                    playerEarnings: null,
                    name: null,
                    quantity: null,
                    rate: null,
                    status: 'included',
                    new: true,
                    dateEarned: null
                },
                invoiceItems: [],
                invoiceNotes: [],
                invoicePayments: [],
                invoicePdfItems: [],
                invoicePDFModel: {
                    BalanceRemaining: '$0.00',
                    DepositRemaining: '$0.00',
                    Company: {
                        Name: '',
                        Email: '',
                        Phone: '',
                        PhoneExtension: '',
                        Address: '',
                        AddressAdditional: '',
                        City: '',
                        State: '',
                        Zip: ''
                    },
                    CustomerId: '',
                    Id: '',
                    InitialDeposit: '',
                    InvoiceContact: {
                        FirstName: '',
                        LastName: '',
                        Title: '',
                        Email: '',
                        Phone: '',
                        PhoneExtension: '',
                        Address: '',
                        AddressAdditional: '',
                        City: '',
                        State: '',
                        Zip: ''
                    },
                    InvoiceDate: '',
                    InvoiceNo: '',
                    ItemDetails: [],
                    PaymentDue: '',
                    ServicesFees: [],
                    SubTotalServiceFees: '',
                    TotalDue: '',
                    TotalDueThisInvoice: ''
                },
                invoicesWithBalances: [],
                invoicesWithBalancesSelects: [],
                invoiceUpdatedStatus: '',
                linkedInvoices: [],
                nhlContractAmountField: '',
                nhlSalaryDetails: null,
                notificationMsg: '',
                isLoading: true,
                player: {},
                playerId: '',
                playerContracts: [],
                playingContract: null,
                playingContractSalaryDetails: [],
                playerEarningsTotal: null,
                selectedActiveContract: null,
                selectedContractAssociatedContract: null,
                selectedContractBonusTab: 0,
                selectedContractItem: { title: 'Select a Contract', id: 1 },
                selectedContractItemModel: { title: 'Select a Contract', id: 1 },
                selectedDataTab: 0,
                selectedDetailTab: 0,
                selectedHistoryField: 'All',
                showConfirmDialog: false,
                showErrorDialog: false,
                showInvoicePaymentDateDialog: false,
                showInvoicePaymentDialog: false,
                showSalaryDetails: false,
                successNotification: false,
                tools: [
                    ["Bold", "Italic", "Underline"],
                    ["Undo", "Redo"],
                    ["Link", "Unlink"],
                    ["AlignLeft", "AlignCenter", "AlignRight"],
                    ["OrderedList", "UnorderedList", "Indent", "Outdent"],
                ],
                uneditedAhlSalaryDetails: null,
                uneditedEchlSalaryDetails: null,
                uneditedInvoice: null,
                uneditedInvoiceCompany: null,
                uneditedInvoiceContact: null,
                uneditedInvoiceItems: [],
                uneditedNhlSalaryDetails: null
            }
        }, 
        computed: {
            agencyContractType() {
                return contractHelpers.agencyContractType;
            },
            ahlNhlTwoWayContractType() {
                return contractHelpers.ahlNhlTwoWayContractType;
            },
            ahlOneWayContractType() {
                return contractHelpers.ahlOneWayContractType;
            },
            ahlTwoWayContractType() {
                return contractHelpers.ahlTwoWayContractType;
            },
            echlContractType() {
                return contractHelpers.echlContractType;
            },
            elcContractType() {
                return contractHelpers.elcContractType;
            },
            endorsementContractType() {
                return contractHelpers.endorsementContractType;
            },
            equipmentContractType() {
                return contractHelpers.equipmentContractType;
            },
            filteredInvoiceHistory() {
                if (this.selectedHistoryField === 'All') {
                    return cloneDeep(this.invoiceHistory);
                } else {
                    return cloneDeep(this.invoiceHistory.filter(log => log.fieldName === this.selectedHistoryField));
                }
            },
            invoiceItemCategoryBalanceRemaining() {
                return invoiceHelpers.invoiceItemCategoryBalanceRemaining;
            },
            invoiceItemCategoryCredit() {
                return invoiceHelpers.invoiceItemCategoryCredit;
            },
            invoiceItemCategoryEndorsementFee() {
                return invoiceHelpers.invoiceItemCategoryEndorsementFee;
            },
            invoiceItemCategoryEscrow() {
                return invoiceHelpers.invoiceItemCategoryEscrow;
            },
            invoiceItemCategoryEscrowBonus() {
                return invoiceHelpers.invoiceItemCategoryEscrowBonus;
            },
            invoiceItemCategoryExpense() {
                return invoiceHelpers.invoiceItemCategoryExpense;
            },
            invoiceItemCategoryManagementFee() {
                return invoiceHelpers.invoiceItemCategoryManagementFee;
            },
            invoiceItemCategoryMiscellaneous() {
                return invoiceHelpers.invoiceItemCategoryMiscellaneous;
            },
            invoiceItemCategorySelects() {
                return invoiceHelpers.invoiceItemCategorySelects;
            },
            invoiceDateFields() {
                return invoiceHelpers.invoiceDateFields;
            },
            invoiceItemDateFields() {
                return invoiceHelpers.invoiceItemDateFields;
            },
            invoiceItemManagementFeeNameSelects() {
                return invoiceHelpers.invoiceItemManagementFeeNameSelects;
            },
            invoiceItemNameSigningBonus() {
                return invoiceHelpers.invoiceItemNameSigningBonus;
            },
            invoiceItemNameNHLSalaryDays() {
                return invoiceHelpers.invoiceItemNameNHLSalaryDays;
            },
            invoiceItemNameAHLSalaryDays() {
                return invoiceHelpers.invoiceItemNameAHLSalaryDays;
            },
            invoiceItemNameECHLSalaryDays() {
                return invoiceHelpers.invoiceItemNameECHLSalaryDays;
            },
            invoiceItemNamePerformanceBonus() {
                return invoiceHelpers.invoiceItemNamePerformanceBonus;
            },
            invoiceItemNameNHLAHLSalaryDays() {
                return invoiceHelpers.invoiceItemNameNHLAHLSalaryDays;
            },
            invoiceStatusSelects() {
                return invoiceHelpers.invoiceStatusSelects;
            },
            nhlOneWayContractType() {
                return contractHelpers.nhlOneWayContractType;
            },
            nhlTwoWayContractType() {
                return contractHelpers.nhlTwoWayContractType;
            },
            pdfInvoice() {
                return {
                    BalanceRemaining: '$0.00',
                    DepositRemaining: '$0.00',
                    company: {
                        Name: this.invoiceCompany?.name || '',
                        Email: this.invoiceCompany?.email || '',
                        Phone: this.invoiceCompany?.phone || '',
                        PhoneExtension: this.invoiceCompany?.phoneExtension || '',
                        Address: this.invoiceCompany?.address || '',
                        AddressAdditional: this.invoiceCompany?.addressAdditional || '',
                        City: this.invoiceCompany?.city || '',
                        State: this.invoiceCompany?.state || '',
                        Zip: this.invoiceCompany?.zip || ''
                    },
                    CustomerId: this.player?.id || '',
                    Id: this.invoice?.id || '',
                    InitialDeposit: this.invoice?.initialDeposit || '',
                    contact: {
                        FirstName: this.invoiceContact?.firstName || '',
                        LastName: this.invoiceContact?.lastName || '',
                        Title: this.invoiceContact?.title || '',
                        Email: this.invoiceContact?.email || '',
                        Phone: this.invoiceContact?.phone || '',
                        PhoneExtension: this.invoiceContact?.phoneExtension || '',
                        Address: this.invoiceContact?.address || '',
                        AddressAdditional: this.invoiceContact?.addressAdditional || '',
                        City: this.invoiceContact?.city || '',
                        State: this.invoiceContact?.state || '',
                        Zip: this.invoiceContact?.zip || ''
                    },
                    InvoiceDate: this.invoice?.invoiceDate?.toLocaleDateString() || '',
                    InvoiceNo: this.invoice?.invoiceNo || '',
                    items: this.invoicePdfItems,
                    PaymentDue: this.invoice?.paymentDue?.toLocaleDateString() || '',
                    ServicesFees: [],
                    SubTotalServiceFees: '',
                    TotalDue: this.invoice?.totalDue ? this.$options.filters.formatCurrencyDec(this.invoice.totalDue) : '$0.00',
                    TotalDueThisInvoice: this.invoice?.totalDue ? this.$options.filters.formatCurrencyDec(this.invoice.totalDue) : '$0.00',
                    InReferenceTo: this.invoice?.inReferenceTo || '',
                    PlayerEarningsTotal: this.playerEarningsTotal || '',
                    ShowAhlSalaryDetails: this.showAhlSalaryDetails ? true : false,
                    ShowNhlSalaryDetails: this.showNhlSalaryDetails ? true : false,
                    AHLSalary: this.showAhlSalaryDetails ? this.$options.filters.formatCurrencyDec(this.ahlSalaryDetails.baseSalary) : '',
                    AHLDailySalary: this.showAhlSalaryDetails ? this.$options.filters.formatCurrencyDec(this.ahlSalaryDetails.dailySalary) : '',
                    NHLSalary: this.showNhlSalaryDetails ? this.$options.filters.formatCurrencyDec(this.nhlSalaryDetails.baseSalary) : '',
                    NHLDailySalary: this.showNhlSalaryDetails ? this.$options.filters.formatCurrencyDec(this.nhlSalaryDetails.dailySalary) : ''
                }
            },
            playingContractType() {
                return contractHelpers.playingContractType;
            },
            salaryDetailDateFields() {
                return invoiceHelpers.salaryDetailDateFields;
            },
            saveChangesBtnDisabled() {
                let invoiceItems = cloneDeep(this.invoiceItems).filter(item => item.category).sort((a, b) => a.itemId.localeCompare(b.itemId));
                let uneditedInvoiceItems = cloneDeep(this.uneditedInvoiceItems).filter(item => item.category).sort((a, b) => a.itemId.localeCompare(b.itemId));
                
                let ahlSalaryDetailsEqual = this.isEqualWith(this.ahlSalaryDetails, this.uneditedAhlSalaryDetails);

                let invoiceEqual = this.isEqualWith(this.invoice, this.uneditedInvoice);

                let invoiceContactEqual = this.isEqualWith(this.invoiceContact, this.uneditedInvoiceContact);

                let invoiceCompanyEqual = this.isEqualWith(this.invoiceCompany, this.uneditedInvoiceCompany);

                let invoiceItemsEqual = this.isEqualWith(invoiceItems, uneditedInvoiceItems);

                let nhlSalaryDetailsEqual = this.isEqualWith(this.nhlSalaryDetails, this.uneditedNhlSalaryDetails);

                let echlSalaryDetailsEqual = this.isEqualWith(this.echlSalaryDetails, this.uneditedEchlSalaryDetails);

                if (ahlSalaryDetailsEqual && invoiceEqual && invoiceContactEqual && invoiceCompanyEqual && invoiceItemsEqual && nhlSalaryDetailsEqual && echlSalaryDetailsEqual) return true;

                return false;
            },
            showAhlSalaryDetails() {
                return this.playingContract && (this.playingContract.nhlContractType === this.ahlNhlTwoWayContractType || this.playingContract.nhlContractType === this.ahlOneWayContractType || this.playingContract.nhlContractType === this.elcContractType || this.playingContract.nhlContractType === this.ahlTwoWayContractType);
            },
            showEchlSalaryDetails() {
                return this.playingContract && (this.playingContract.nhlContractType === this.echlContractType || this.playingContract.nhlContractType === this.ahlTwoWayContractType);
            },
            showNhlSalaryDetails() {
                return this.playingContract && (this.playingContract.nhlContractType === this.ahlNhlTwoWayContractType || this.playingContract.nhlContractType === this.nhlOneWayContractType || this.playingContract.nhlContractType === this.elcContractType);
            },
            showSalaryBufferColumn() {
                return [this.showAhlSalaryDetails, this.showEchlSalaryDetails, this.showNhlSalaryDetails].filter(item => item).length === 1;
            },
            stateSelectsAbbr() {
                return invoiceHelpers.stateSelectsAbbr;
            }
        },
        validations() {
            return {
                invoice: {
                    invoiceDate: { required },
                    invoiceNo: { required },
                    paymentDue: { required },
                },
                invoiceCompany: {
                    phone: { phoneValidator }
                }
            }
        },
        watch: {
            invoiceItems: {
                handler(val) {
                    this.calculateItemsAmountDue();
                },
                deep: true
            },
            'invoice.totalDue'(val) {
                let totalPayments = this.invoicePayments.reduce((prev, cur) => {
                    return prev + cur.paymentAmount;
                }, 0);

                this.invoice.balanceRemaining = val - totalPayments;
            },
            nhlSalaryDetails: {
                handler(val) {
                    if (!val) return;

                    if (val.baseSalary && val.payableDays) {
                        this.nhlSalaryDetails.dailySalary = parseInt(val.baseSalary) / parseInt(val.payableDays);
                    } else {
                        this.nhlSalaryDetails.dailySalary = null;
                    }

                    this.calculateItemsAmountDue();
                },
                deep: true
            },
            ahlSalaryDetails: {
                handler(val) {
                    if (!val) return;

                    if (val.baseSalary && val.payableDays) {
                        this.ahlSalaryDetails.dailySalary = parseInt(val.baseSalary) / parseInt(val.payableDays);
                    } else {
                        this.ahlSalaryDetails.dailySalary = null;
                    }

                    this.calculateItemsAmountDue();
                },
                deep: true
            },
            echlSalaryDetails: {
                handler(val) {
                    if (!val) return;

                    if (val.baseSalary && val.payableDays) {
                        this.echlSalaryDetails.dailySalary = parseInt(val.baseSalary) / parseInt(val.payableDays);
                    } else {
                        this.echlSalaryDetails.dailySalary = null;
                    }

                    this.calculateItemsAmountDue();
                },
                deep: true
            }
        },
        async mounted() {
            this.invoiceId = this.$route.query.id;
            this.playerId = this.$route.query.playerId;

            await this.getSeasonalSettings();
            this.getInvoiceDetail();
            this.getInvoicesWithBalances();

            this.filteredInvoiceHistoryFields = cloneDeep(this.invoiceHistoryFields);
        },
        methods: {
            addDefaultAmountDue(itemIndex) {
                let selectedInvoice = this.invoicesWithBalances.find(invoice => invoice.label === this.invoiceItems[itemIndex].name);

                if (selectedInvoice) {
                    this.invoiceItems[itemIndex].playerEarnings = selectedInvoice.balanceRemaining;
                    this.invoiceItems[itemIndex].comments = selectedInvoice.id;
                } else {
                    this.invoiceItems[itemIndex].playerEarnings = null;
                    this.invoiceItems[itemIndex].comments = null;
                }
            },
            async addInvoiceNote() {
                let noteHtml = this.$refs.noteseditor.getHTML();
                let noteText = noteHtml.replace(/<[^>]+>/g, '');

                if (noteText && noteText.trim() !== this.defaultEditorContent) {
                    let self = this;

                    this.isLoading = true;

                    let note = {
                        notes: noteHtml,
                        invoiceId: this.invoiceId,
                        noteId: null,
                        userName: auth.user()?.name
                    };

                    this.$refs.noteseditor.setHTML(this.defaultEditorContent);

                    let tokenResponse = await auth.acquireTokenResponse();

                    return apiService.CreateInvoiceNote(tokenResponse.accessToken, note)
                        .then((res) => {
                            self.invoiceNotes = res.data.data;

                            self.invoiceNotes.forEach(note => {
                                note.createdOn = formatHelpers.formatDateTime(note.createdOn);
                            });
                            self.invoiceNotes.sort((a, b) => b.createdOn - a.createdOn);

                            self.isLoading = false;

                            self.showNotification('Note added successfully');
                        }).catch(error => {
                            console.log(error);
                            self.onRequestError('An Error Occurred', error?.response?.data?.message);
                        });
                }
            },
            assignAndFormatData(data) {
                this.player = data.player;
                this.company = data.company;
                this.player.fullName = `${this.player.firstName} ${this.player.lastName}`;
                this.playerContracts = data.allContracts;
                this.playingContract = data.contract;
                this.playingContractSalaryDetails = this.getPlayingContractSalaryDetails(data);
                this.playingContractSalaryDetails.sort((a, b) => a.year - b.year);

                this.playerContracts.forEach(contract => {
                    contract.signing_field = contractHelpers.getContractSigningField(contract);
                    contract.expiration_field = contractHelpers.getContractExpirationField(contract);
                });

                let filteredContracts = this.playerContracts
                    .filter(contract => (!contract.expiration_field || contract.expiration_field >= new Date()))
                    .sort((a, b) => b.expiration_field - a.expiration_field);

                if (filteredContracts.length > 0) {
                    let agencyContracts = filteredContracts.filter(contract => contract.contractType === this.agencyContractType);

                    if (agencyContracts.length > 0) {
                        if (this.playingContract?.associatedContractId) {
                            this.agencyContract = agencyContracts.find(contract => contract.contractId === this.playingContract.associatedContractId);
                        } else {
                            this.agencyContract = agencyContracts.sort((a, b) => b.spacSigningDate - a.spacSigningDate)[0];
                        }

                        filteredContracts = filteredContracts.filter(contract => contract.contractType !== this.agencyContractType || contract.contractId === this.agencyContract?.contractId);
                    }

                    this.contractDropdownItems = filteredContracts.map(contract => {
                        return {
                            title: contract.contractTitle,
                            id: contract.contractId
                        }
                    });
                }

                this.invoiceNotes = data.invoiceNotes;
                this.invoiceNotes.forEach(note => {
                    note.createdOn = formatHelpers.formatDateTime(note.createdOn);
                });
                this.invoiceNotes.sort((a, b) => b.createdOn - a.createdOn);

                this.invoiceHistory = data.invoiceHistoryLogs.filter(log => log.newValue || log.oldValue);
                this.invoiceHistory.forEach(log => {
                    log.updatedOn = formatHelpers.formatDateTime(log.updatedOn);
                });
                this.invoiceHistory.sort((a, b) => b.updatedOn - a.updatedOn);

                this.invoicePayments = data.invoicePayments;
                this.invoicePayments.forEach(payment => {
                    payment.paymentLogDate = formatHelpers.formatDate(payment.paymentLogDate);
                });
                this.invoicePayments.sort((a, b) => b.paymentLogDate - a.paymentLogDate);

                this.uneditedAhlSalaryDetails = data.ahlSalaryDetails;
                this.uneditedInvoice = data.invoice;
                this.uneditedInvoiceContact = data.invoiceContact;
                this.uneditedInvoiceCompany = data.invoiceCompany;
                this.uneditedInvoiceItems = data.invoiceItems;
                this.uneditedNhlSalaryDetails = data.nhlSalaryDetails;
                this.uneditedEchlSalaryDetails = data.echlSalaryDetails;

                this.invoiceDateFields.forEach(field => {
                    this.uneditedInvoice[field] = formatHelpers.formatDate(this.uneditedInvoice[field]);
                });

                this.uneditedInvoiceItems.forEach(item => {
                    this.invoiceItemDateFields.forEach(field => {
                        if (item[field]) {
                            item[field] = formatHelpers.formatDate(item[field]);
                        }
                    })
                });


                [this.uneditedAhlSalaryDetails, this.uneditedEchlSalaryDetails, this.uneditedNhlSalaryDetails].filter(detail => detail).forEach(detail => {
                    this.salaryDetailDateFields.forEach(field => {
                        if (detail[field]) {
                            detail[field] = formatHelpers.formatDate(detail[field]);
                        }
                    })
                });

                this.assignEditableInvoiceProps();
            },
            assignEditableInvoiceProps() {
                this.ahlSalaryDetails = cloneDeep(this.uneditedAhlSalaryDetails);
                this.invoice = cloneDeep(this.uneditedInvoice);
                this.invoiceUpdatedStatus = this.invoice.status;
                this.invoiceContact = cloneDeep(this.uneditedInvoiceContact);
                this.invoiceCompany = cloneDeep(this.uneditedInvoiceCompany);
                this.invoiceItems = cloneDeep(this.uneditedInvoiceItems);
                this.nhlSalaryDetails = cloneDeep(this.uneditedNhlSalaryDetails);
                this.echlSalaryDetails = cloneDeep(this.uneditedEchlSalaryDetails);
            },
            calculateInvoiceStatus(invoice) {
                let balanceRemaining = Math.round(invoice.balanceRemaining);
                let totalDue = Math.round(invoice.totalDue);

                if (balanceRemaining > 0 && balanceRemaining < totalDue) {
                    return 'Paid in Part';
                }

                if (balanceRemaining <= 0) {
                    return 'Paid';
                }

                if (balanceRemaining === totalDue) {
                    return 'Invoice Sent';
                }

                return '';
            },
            calculateItemQuantity(item) {
                if (!item.category) return item.quantity ? item.quantity : 0;

                if (item.category !== this.invoiceItemCategoryManagementFee) return item.quantity ? item.quantity : 1;

                if (item.name && (item.name === this.invoiceItemNamePerformanceBonus || item.name === this.invoiceItemNameSigningBonus)) return item.quantity ? item.quantity : 1;

                return item.quantity ? item.quantity : 0;
            },
            calculateItemsAmountDue() {
                let totalDue = 0;

                this.invoiceItems.forEach(item => {
                    let rate = item.rate ?? 100;
                    let qty = this.calculateItemQuantity(item);

                    let amountDue = 0;
                
                    if (item.category === this.invoiceItemCategoryEscrow) {
                        let escrowItem = this.invoiceItems.find(i => i.category === this.invoiceItemCategoryManagementFee && i.name === this.invoiceItemNameNHLSalaryDays);

                        if (escrowItem) {
                            item.rate = this.$store.state.seasonalSettings.escrowPercentage;
                            item.playerEarnings = -(escrowItem.playerEarnings * (this.$store.state.seasonalSettings.escrowPercentage / 100));
                            amountDue = (item.playerEarnings * (escrowItem.rate / 100));
                        } else {
                            item.playerEarnings = 0;
                        }
                    } else {
                        if (item.category === this.invoiceItemCategoryEscrowBonus) {
                            let bonusEscrowItem = this.invoiceItems.find(i => i.category === this.invoiceItemCategoryManagementFee && i.name === this.invoiceItemNameSigningBonus);

                            if (bonusEscrowItem) {
                                item.rate = this.$store.state.seasonalSettings.escrowPercentage;
                                item.playerEarnings = -(bonusEscrowItem.playerEarnings * (this.$store.state.seasonalSettings.escrowPercentage / 100));
                                amountDue = (item.playerEarnings * (bonusEscrowItem.rate / 100));
                            } else {
                                item.playerEarnings = 0;
                            }
                        } else {
                            if (item.category === this.invoiceItemCategoryEndorsementFee && this.agencyContract && this.agencyContract.endorsementCommissionPercent) {
                                item.rate = item.rate ?? this.agencyContract.endorsementCommissionPercent;
                            }

                            if (item.category === this.invoiceItemCategoryManagementFee && this.agencyContract && this.agencyContract.playingCommissionPercent) {
                                item.rate = item.rate ?? this.agencyContract.playingCommissionPercent;
                            }

                            if (item.category === this.invoiceItemCategoryManagementFee && (item.name === this.invoiceItemNameNHLSalaryDays || item.name === this.invoiceItemNameNHLAHLSalaryDays)) {
                                if (this.nhlSalaryDetails.dailySalary) {
                                    item.playerEarnings = this.nhlSalaryDetails.dailySalary * item.quantity;
                                }

                                amountDue = item.playerEarnings * ((item.rate || rate) / 100);
                                item.description = item.description ?? `${item.quantity} Days`;
                            }

                            if (item.category === this.invoiceItemCategoryManagementFee && item.name === this.invoiceItemNameAHLSalaryDays) {
                                if (this.ahlSalaryDetails.dailySalary) {
                                    item.playerEarnings = this.ahlSalaryDetails.dailySalary * item.quantity;
                                }

                                amountDue = item.playerEarnings * ((item.rate || rate) / 100);
                                item.description = item.description ?? `${item.quantity} Days`;
                            }

                            if (item.category === this.invoiceItemCategoryManagementFee && item.name === this.invoiceItemNameECHLSalaryDays) {
                                if (this.echlSalaryDetails.dailySalary) {
                                    item.playerEarnings = this.echlSalaryDetails.dailySalary * item.quantity;
                                }

                                amountDue = item.playerEarnings * ((item.rate || rate) / 100);
                                item.description = item.description ?? `${item.quantity} Days`;
                            }

                            if (item.playerEarnings && item.category !== this.invoiceItemCategoryEscrow && item.category !== this.invoiceItemCategoryEscrowBonus) {
                                if (item.category !== this.invoiceItemCategoryManagementFee || (item.category === this.invoiceItemCategoryManagementFee && (item.name !== this.invoiceItemNameNHLSalaryDays && item.name !== this.invoiceItemNameNHLAHLSalaryDays && item.name !== this.invoiceItemNameAHLSalaryDays && item.name !== this.invoiceItemNameECHLSalaryDays))) {
                                    amountDue = (item.playerEarnings * (rate / 100)) * qty;
                                } 
                            }
                        }
                    }
                    
                    item.amountDue = amountDue;
                    item.quantity = qty;

                    totalDue += item.amountDue;
                });

                    this.invoice.totalDue = totalDue;
                    this.invoice.totalDueThisInvoice = totalDue;
            },
            calculateQuantityDisplay(item) {
                if (item.category !== this.invoiceItemCategoryManagementFee) return 'Qty';

                if (item.name === this.invoiceItemNameNHLSalaryDays || item.name === this.invoiceItemNameAHLSalaryDays || item.name === this.invoiceItemNameNHLAHLSalaryDays || item.name === this.invoiceItemNameECHLSalaryDays) return 'Days';

                return 'Qty';
            },
            defaultDropdownSelected(evt, field, defaultValue) {
                let fields = field.split('.');

                if (fields.length === 2) {
                    if (evt.target.value === defaultValue) {
                        return this[fields[0]][fields[1]] = null;
                    }

                    this[fields[0]][fields[1]] = evt.target.value;
                } else {
                    if (evt.target.value === defaultValue) {
                        return this.invoice[field] = null;
                    }

                    this.invoice[field] = evt.target.value;
                }
            },
            async deleteInvoiceItem() {
                let self = this;
                let tokenResponse = await auth.acquireTokenResponse();

                return apiService.DeleteInvoiceItem(tokenResponse.accessToken, this.deletedItem.itemId, this.invoiceId)
                    .then((res) => {
                        self.uneditedInvoiceItems = res.data.data;
                        self.invoiceItems.splice(self.invoiceItems.findIndex(item => item.itemId === self.deletedItem.itemId), 1);

                        self.isLoading = false;
                        self.showNotification('Item deleted successfully');
                    }).catch(error => {
                        console.log(error);
                        self.onRequestError('An Error Occurred', error?.response?.data?.message);
                    });
            },
            async deleteInvoicePayment() {
                let request = {
                    paymentId: this.deletedItem.paymentId,
                    invoice: cloneDeep(this.invoice),
                    playerId: this.playerId
                }

                request.invoice.balanceRemaining += this.deletedItem.paymentAmount;

                request.invoice.status = this.calculateInvoiceStatus(request.invoice);

                let self = this;
                let tokenResponse = await auth.acquireTokenResponse();

                return apiService.DeleteInvoicePayment(tokenResponse.accessToken, request)
                    .then((res) => {
                        if (res.data.data) {
                            self.assignAndFormatData(res.data.data);
                            self.$store.dispatch('getInvoices', true);

                            self.isLoading = false;
                            self.showNotification('Payment deleted successfullly');
                        } else {
                            self.onRequestError('An Error Occurred', 'Failed to delete payment');
                        }
                    }).catch(error => {
                        console.log(error);
                        this.onRequestError('An Error Occurred', error?.response?.data?.message);
                    });
            },
            deleteItem() {
                this.toggleConfirmDialog();
                this.isLoading = true;

                if (this.deletedItemType === 'invoiceItem') {
                    this.deleteInvoiceItem();
                }

                if (this.deletedItemType === 'invoicePayment' ) {
                    this.deleteInvoicePayment();
                }
            },
            formatContactPhoneNumberInput() {
                if (!this.invoiceCompany.phone) return;

                const phoneNumber = this.invoiceCompany.phone.replace(/[^\d]/g, '');
                const phoneNumberLength = phoneNumber.length;

                if (phoneNumberLength < 4) return this.invoiceCompany.phone = phoneNumber;

                if (phoneNumberLength < 7) {
                    return this.invoiceCompany.phone = `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
                }

                this.invoiceCompany.phone = `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6, 10)}`;

            },
            formatHistoryLog(fieldName, value) {
                if (this.historyLogCurrencyFields.some(field => field === fieldName)) {
                    return this.$options.filters.formatCurrencyDec(value);
                }

                if (this.historyLogDateFields.some(field => field === fieldName)) {
                    return new Date(value).toLocaleDateString('en-US', {timeZone: 'UTC'});
                }

                return value;
            },
            async generatePDF() {
                this.isLoading = true;
                let self = this;

                let tokenResponse = await auth.acquireTokenResponse();

                return apiService.CreateInvoicePDF(tokenResponse.accessToken, this.pdfInvoice)
                    .then(res => {
                        self.isLoading = false;
                        self.openPDF(res.data.data);
                    }).catch(error => {
                        console.log(error);
                        self.onRequestError('An Error Occurred', error?.response?.data?.message);
                    });
            },
            async getContractDetail() {
                let self = this;
                self.isLoading = true;

                let tokenResponse = await auth.acquireTokenResponse();

                return apiService.GetContractDetail(tokenResponse.accessToken, this.selectedContractItem.id)
                    .then(res => {
                        if (res.data.data) {
                            self.selectedActiveContract = res.data.data;

                            self.selectedActiveContract.endorsementPayouts.sort((a, b) => new Date(b.payoutDate) - new Date(a.payoutDate));
                            self.selectedActiveContract.elcPerformanceBonuses.sort((a, b) => new Date(b.bonusDate) - new Date(a.bonusDate));
                            self.selectedActiveContract.nhlSigningBonuses.sort((a, b) => new Date(b.bonusDate) - new Date(a.bonusDate));
                            self.selectedActiveContract.performanceBonuses.sort((a, b) => new Date(b.bonusDate) - new Date(a.bonusDate));

                            self.selectedActiveContract.ahlNhlTwoWayContractDetails.sort((a, b) => a.year - b.year);
                            self.selectedActiveContract.ahlOneWayContractDetails.sort((a, b) => a.year - b.year);
                            self.selectedActiveContract.echlContractDetails.sort((a, b) => a.year - b.year);
                            self.selectedActiveContract.elcContractDetails.sort((a, b) => a.year - b.year);
                            self.selectedActiveContract.nhlOneWayContractDetails.sort((a, b) => a.year - b.year);
                            
                            if (self.selectedActiveContract.contract.contractType != self.agencyContractType) {
                                self.selectedContractAssociatedContract = self.playerContracts.find(contract => contract.contractId === self.selectedActiveContract.contract.associatedContractId);
                            }

                            self.isLoading = false;
                        } else {
                            self.onRequestError('An Error Occurred', 'Contract detail unavailable');
                        }
                    }).catch(error => {
                        console.log(error);
                        this.onRequestError('An Error Occurred', error?.response?.data?.message);
                    });
            },
            async getInvoiceDetail() {
                let self = this;

                let tokenResponse = await auth.acquireTokenResponse();

                return apiService.GetInvoiceDetail(tokenResponse.accessToken, this.invoiceId, this.playerId)
                    .then((res) => {
                        if (res.data.data) {
                            console.log(res.data.data);
                            self.assignAndFormatData(res.data.data);
                            self.isLoading = false;
                        } else {
                            self.onRequestError('An Error Occurred', 'Invoice detail unavailable');
                        }
                    }).catch(error => {
                            self.onRequestError('An Error Occurred', error?.response?.data?.message);
                    });
            },
            async getInvoicesWithBalances() {
                await this.$store.dispatch('getInvoices', false);

                this.invoicesWithBalances = cloneDeep(this.$store.state.invoices
                    .filter(invoice => invoice.playerId === this.playerId && invoice.balanceRemaining && invoice.balanceRemaining > 0 && invoice.id !== this.invoiceId)
                    .map(invoice => {
                        return {
                            id: invoice.id,
                            invoiceNo: invoice.invoiceNo,
                            balanceRemaining: invoice.balanceRemaining,
                            label: `${invoice.invoiceNo}: ${this.$options.filters.formatCurrencyDec(invoice.balanceRemaining)}`
                        };
                    }));

                this.invoicesWithBalancesSelects = this.invoicesWithBalances.map(invoice => invoice.label);
            },
            getPlayingContractSalaryDetails(data) {
                if (!this.playingContract) return [];

                switch(this.playingContract.nhlContractType) {
                    case this.ahlNhlTwoWayContractType:
                        this.ahlContractAmountField = 'ahlTwoWayAmount';
                        this.nhlContractAmountField = 'nhlTwoWayAmount';
                        return data.ahlNhlTwoWayContractDetails;
                    case this.ahlOneWayContractType:
                        this.ahlContractAmountField = 'ahlOneWayAmount';
                        return data.ahlOneWayContractDetails;
                    case this.ahlTwoWayContractType:
                        this.ahlContractAmountField = 'ahlTwoWayAmount';
                        this.echlContractAmountField = 'echlTwoWayAmount';
                        return data.ahlTwoWayContractDetails;
                    case this.nhlOneWayContractType:
                        this.nhlContractAmountField = 'nhlOneWayAmount';
                        return data.nhlOneWayContractDetails;
                    case this.nhlTwoWayContractType:
                        return data.nhlTwoWayContractDetails;
                    case this.echlContractType:
                        this.echlContractAmountField = 'echlAmount';
                        return data.echlContractDetails;
                    case this.elcContractType:
                        this.ahlContractAmountField = 'ahlSalary';
                        this.nhlContractAmountField = 'nhlSalary';
                        return data.elcContractDetails;
                }
            },
            async getSeasonalSettings(update = false) {
                await this.$store.dispatch('getSeasonalSettings', update);
            },
            goToSelectedContract() {
                this.$router.push(`/ContractDetail?id=${this.selectedActiveContract.contract.contractId}`);
            },
            invoiceItemDropdownSelected(evt, field, defaultValue, index) {
                let fields = field.split('.');

                if (fields.length === 2) {
                    if (evt.target.value === defaultValue) {
                        return this.invoiceItems[index][fields[0]][fields[1]] = null;
                    }

                    this.invoiceItems[index][fields[0]][fields[1]] = evt.target.value;
                } else {
                    if (evt.target.value === defaultValue) {                     
                        this.invoiceItems[index][field] = null;
                    } else {
                        this.invoiceItems[index][field] = evt.target.value;
                    }

                    if (field === 'name' && this.invoiceItems[index].category === this.invoiceItemCategoryBalanceRemaining) {
                        this.invoiceItems[index].status = this.invoicesWithBalances.find(invoice => invoice.label === this.invoiceItems[index].name).id;
                        this.addDefaultAmountDue(index);
                    }
                }
            },
            isEqualWith(unedited, edited) {
                return isEqualWith(unedited, edited, (a, b, key) => {
                    if ((a === null || a === '' || a === 0) && (b === null || b === '' || b === 0)) {
                        return true;
                    }

                    if (this.invoiceCurrencyFields.some(field => field === key)) {
                        if (Math.abs(a - b) < 1) return true;
                    }
                });
            },
            onAddInvoiceItem() {
                let newItem = cloneDeep(this.invoiceItemModel);
                newItem.invoiceId = this.invoiceId;
                newItem.itemId = Math.random().toString();

                this.invoiceItems.unshift(newItem);
            },
            onAddPayment() {
                if (!this.saveChangesBtnDisabled) {
                    return this.onRequestError('You Have Unsaved Changes', 'Please save your changes before entering a payment');
                }

                this.toggleInvoicePaymentDialog();
            },
            onBackToInvoices() {
                this.$router.push('/Invoices');
            },
            onContractBonusTabSelected(evt) {
                this.selectedContractBonusTab = evt.selected;
            },
            onDataTabSelected(evt) {
                this.selectedDataTab = evt.selected;
            },
            onDeleteInvoiceItem(item) {
                if (item.new) {
                    return this.invoiceItems.splice(this.invoiceItems.findIndex(i => i.itemId === item.itemId), 1);
                }

                this.confirmMsg = 'Are you sure you would like to delete this item?';
                this.deletedItem = item;
                this.deletedItemType = 'invoiceItem';

                this.onDeleteItem();
            },
            onDeleteItem() {
                this.confirmIconClass='fa-solid fa-trash-can';
                this.toggleConfirmDialog();
            },
            onDeletePayment(payment) {
                if (!this.saveChangesBtnDisabled) {
                    return this.onRequestError('You Have Unsaved Changes', 'Please save your changes before deleting payment')
                }

                this.confirmMsg = 'Are you sure you would like to delete this payment?';
                this.deletedItem = payment;
                this.deletedItemType = 'invoicePayment';

                this.onDeleteItem();
            },
            onDetailTabSelected(evt) {
                this.selectedDetailTab = evt.selected;
            },
            onDiscardChanges() {
                this.assignEditableInvoiceProps();
            },
            onEditorFocus() {
                let noteHtml = this.$refs.noteseditor.getHTML();
                let noteText = noteHtml.replace(/<[^>]+>/g, '');

                if (noteText && noteText.trim() === this.defaultEditorContent) {
                    this.$refs.noteseditor.setHTML('');
                }
            },
            onGeneratePDF() {
                if (!this.saveChangesBtnDisabled) {
                    return this.onRequestError('You Have Unsaved Changes', 'Please save your changes before generating a PDF')
                }

                let unorderedItems = cloneDeep(this.invoiceItems);

                this.invoicePdfItems = [];

                let managementFeeItems = unorderedItems.filter(item => item.category === this.invoiceItemCategoryManagementFee);
                if (managementFeeItems.length > 0) {
                    this.invoicePdfItems = [...managementFeeItems];
                }

                let escrowItems = unorderedItems.filter(item => item.category === this.invoiceItemCategoryEscrow || item.category === this.invoiceItemCategoryEscrowBonus);
                if (escrowItems.length > 0) {
                    this.invoicePdfItems = [...this.invoicePdfItems, ...escrowItems];
                }

                let otherItems = unorderedItems.filter(item => item.category !== this.invoiceItemCategoryManagementFee && item.category !== this.invoiceItemCategoryEscrow && item.category !== this.invoiceItemCategoryEscrowBonus);
                if (otherItems.length > 0) {
                    this.invoicePdfItems = [...this.invoicePdfItems, ...otherItems];
                }

                this.playerEarningsTotal = 0;

                this.invoicePdfItems.forEach(item => {
                    item.amountDue = this.$options.filters.formatCurrencyDec(item.amountDue);
                    this.playerEarningsTotal += item.playerEarnings;

                    item.playerEarnings = this.$options.filters.formatCurrencyDec(item.playerEarnings);
                    item.dateEarned = item.dateEarned ? item.dateEarned.toLocaleDateString() : '';
                    item.quantity = item.quantity.toString();
                    item.rate = item.rate ? `${item.rate.toString()}%` : ''

                    if (item.amountDue.includes('-')) {
                        item.amountDue = `(${item.amountDue.split('-')[1]})`;
                    }

                    if (item.playerEarnings.includes('-')) {
                        item.playerEarnings = `(${item.playerEarnings.split('-')[1]})`;
                    }

                    if (item.category === this.invoiceItemCategoryBalanceRemaining && item.name) {
                        item.name = item.name.split(':')[0];
                    }
                });

                this.playerEarningsTotal = this.$options.filters.formatCurrencyDec(this.playerEarningsTotal);

                this.generatePDF();
            },
            async onGetContractDetail() {
                if (this.selectedContractItem.title === 'Select a Contract') return;

                await this.getContractDetail();
            },
            onInvoiceHistoryFieldSelected(evt) {
                this.selectedHistoryField = evt.value;
            },
            onInvoiceHistoryFilterChange(evt) {
                this.filteredInvoiceHistoryFields = filterBy(this.invoiceHistoryFields, evt.filter);
            },
            onInvoiceStatusUpdated() {
                if (this.invoiceUpdatedStatus === 'Paid in Part' && this.uneditedInvoice.status !== 'Paid in Part' && this.uneditedInvoice.status !== 'Paid') {
                    if (!this.saveChangesBtnDisabled) {
                        this.invoiceUpdatedStatus = this.invoice.status;
                        return this.onRequestError('You Have Unsaved Changes', 'Please save your changes before updating status to Paid in Part');
                    }

                    return this.toggleInvoicePaymentDialog();
                }

                if (this.invoiceUpdatedStatus === 'Paid' && this.uneditedInvoice.status !== 'Paid') {
                    if (!this.saveChangesBtnDisabled) {
                        this.invoiceUpdatedStatus = this.invoice.status;
                        return this.onRequestError('You Have Unsaved Changes', 'Please save your changes before updating status to Paid');
                    }

                    this.invoice.balanceRemaining = 0;
                    return this.toggleInvoicePaymentDateDialog();
                }

                this.invoice.status = this.invoiceUpdatedStatus;
            },
            onPaymentCanceled() {
                this.invoiceUpdatedStatus = this.invoice.status;

                this.toggleInvoicePaymentDialog();
            },
            onPaymentDateCanceled() {
                this.invoiceUpdatedStatus = this.invoice.status;

                this.toggleInvoicePaymentDateDialog();
            },
            onPaymentDateEntered(datePaid) {
                this.toggleInvoicePaymentDateDialog();

                this.invoice.balanceRemaining = 0;
                this.invoice.status = this.invoiceUpdatedStatus;
                this.invoice.datePaid = datePaid;

                let request = {
                    playerId: this.player.id,
                    invoiceId: this.invoice.id,
                    invoiceUpdated: true,
                    invoice: cloneDeep(this.invoice)
                }

                this.updateInvoice(request);
            },
            onPaymentEntered(payment) {
                this.toggleInvoicePaymentDialog();
                this.isLoading = true;

                this.invoice.balanceRemaining -= payment.paymentAmount;

                this.invoiceUpdatedStatus = this.calculateInvoiceStatus(this.invoice);

                this.invoice.status = this.invoiceUpdatedStatus;

                payment.invoiceId = this.invoice.id;

                let request = {
                    playerId: this.player.id,
                    invoiceId: this.invoice.id,
                    invoiceUpdated: true,
                    invoice: cloneDeep(this.invoice),
                    paymentsUpdated: true,
                    invoicePayments: [payment]
                }

                this.updateInvoice(request);
            },
            onRequestError(errorTitle, errorMsg) {
                this.isLoading = false;

                this.errorTitle = errorTitle;

                if (errorMsg) {
                    this.errorMsg = errorMsg;
                } else {
                    this.errorMsg = 'Please try again';
                }

                this.showErrorDialog = true;
            },
            async onUpdateInvoice() {
                await this.invoice$.$validate();

                if (this.invoice$.$error) return;

                this.isLoading = true;

                let request = {
                    playerId: this.player.id,
                    invoiceId: this.invoice.id
                }

                if (!this.isEqualWith(this.ahlSalaryDetails, this.uneditedAhlSalaryDetails)) {
                    request.ahlSalaryDetails = this.ahlSalaryDetails;
                    request.ahlSalaryUpdated = true;
                }

                if (!this.isEqualWith(this.invoice, this.uneditedInvoice)) {
                    request.invoice = cloneDeep(this.invoice);
                    request.invoiceUpdated = true;
                }

                if (!this.isEqualWith(this.invoiceContact, this.uneditedInvoiceContact)) {
                    request.invoiceContact = this.invoiceContact;
                    request.contactUpdated = true;
                }

                if (!this.isEqualWith(this.invoiceCompany, this.uneditedInvoiceCompany)) {
                    request.invoiceCompany = this.invoiceCompany;
                    request.companyUpdated = true;
                }

                let invoiceItems = cloneDeep(this.invoiceItems).filter(item => item.category).sort((a, b) => a.itemId.localeCompare(b.itemId));
                let uneditedInvoiceItems = cloneDeep(this.uneditedInvoiceItems).filter(item => item.category).sort((a, b) => a.itemId.localeCompare(b.itemId));
                if (!this.isEqualWith(invoiceItems, uneditedInvoiceItems)) {
                    request.invoiceItems = this.invoiceItems.filter(item => item.category);
                    request.itemsUpdated = true;
                }

                if (!this.isEqualWith(this.nhlSalaryDetails, this.uneditedNhlSalaryDetails)) {
                    request.nhlSalaryDetails = this.nhlSalaryDetails;
                    request.nhlSalaryUpdated = true;
                }

                if (!this.isEqualWith(this.echlSalaryDetails, this.uneditedEchlSalaryDetails)) {
                    request.echlSalaryDetails = this.echlSalaryDetails;
                    request.echlSalaryUpdated = true;
                }

                this.updateInvoice(request);
            },
            openPDF(src) {
                if (!src) return;
                
                let pdfWindow = window.open("");

                setTimeout(() => {
                    if (pdfWindow.document) {
                        pdfWindow.document.write("<iframe width='100%' height='100%' src='data:application/pdf;base64, " + encodeURI(src) + "'></iframe>");
                    }
                }, 2000);
            },
            showNotification(notificationMsg) {
                this.notificationMsg = notificationMsg;

                this.successNotification = true;

                setTimeout(() => {
                    this.successNotification = false;
                }, 4000);
            },
            toggleConfirmDialog() {
                this.showConfirmDialog = !this.showConfirmDialog;
            },
            toggleErrorDialog() {
                this.showErrorDialog = !this.showErrorDialog;

                if(!this.showErrorDialog) {
                    this.errorTitle = '';
                    this.errorMsg = '';
                }
            },
            toggleInvoicePaymentDateDialog() {
                this.showInvoicePaymentDateDialog = !this.showInvoicePaymentDateDialog
            },
            toggleInvoicePaymentDialog() {
                this.showInvoicePaymentDialog = !this.showInvoicePaymentDialog;
            },
            toggleSalaryDetails() {
                this.showSalaryDetails = !this.showSalaryDetails;
            },
            async updateInvoice(request) {
                if (request.invoice) {
                    request.invoice.lastUpdateBy = auth.user()?.name;
                }

                let self = this;

                let tokenResponse = await auth.acquireTokenResponse();

                return apiService.UpdateInvoice(tokenResponse.accessToken, request)
                    .then((res) => {
                        if (res.data.data) {
                            self.assignAndFormatData(res.data.data);
                            self.$store.dispatch('getInvoices', true);

                            self.isLoading = false;
                            self.showNotification('Invoice updated successfullly');
                        } else {
                            self.onRequestError('An Error Occurred', 'Failed to update invoice');
                        }
                    }).catch(error => {
                        console.log(error);
                        this.onRequestError('An Error Occurred', error?.response?.data?.message);
                    });
            }
        },
        filters: {
            formatCurrencyDec(val) {
                if (val === null || val === undefined || val === '') return '';

                if (val.toString().includes('$')) return val;

                if (isNaN(val)) {
                    val = 0;
                }

                let formatter = new Intl.NumberFormat('en-US', {
                    style: 'currency',
                    currency: 'USD',
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                });

                return formatter.format(val);
            }
        },
        components: {
            'view-header': viewHeader,
            'k-button': Button,
            'k-loader': Loader,
            'k-dialog': Dialog,
            'k-notification': Notification,
            'k-notification-group': NotificationGroup,
            'k-fade': Fade,
            'k-tabstrip': TabStrip,
            'k-slide': Slide,
            'k-dropdown': DropDownList,
            'k-datepicker': DatePicker,
            'k-input': Input,
            'k-numeric-input': NumericTextBox,
            'k-editor': Editor,
            'confirm-dialog': confirmDialog,
            'k-textarea': TextArea,
            'invoice-payment-dialog': invoicePaymentDialog,
            'invoice-payment-date-dialog': invoicePaymentDateDialog
        }
    }
</script>

<style>
    @import '/src/assets/css/defaultUpsertStyles.css';
    @import '/src/assets/css/createInvoiceDialog.css';
    @import '/src/assets/css/invoiceDetailView.css';
</style>