<template>
    <div class="main">
        <header-component v-if="$route.name !== 'documentview'" />
        <router-view />
    </div>
</template>

<script>
    import header from './components/header.vue';
    import '@progress/kendo-theme-default/dist/all.css';
    import './assets/css/kendoTheme.css';

    export default {
        components: {
            'header-component': header,
        }
    }
</script>
<style>
    @import '../src/assets/css/site.css';

    #app {
        font-family: Avenir, Helvetica, Arial, sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        /* text-align: center; */
        color: #2c3e50;
        /* height: calc(100vh - 25px); */
        height: 100vh;
    }

    nav {
        padding: 30px;
    }

    nav a {
        font-weight: bold;
        color: #2c3e50;
    }

    nav a.router-link-exact-active {
        color: #42b983;
    }
</style>
