<template>
    <div class="invoice-payment">
        <transition name="dialogfade">
            <k-dialog v-if="showDialog" class="default__dialog invoice-payment-dialog">
                <div class="icon__container">
                    <i class="fa-solid fa-money-check-dollar"></i>
                </div>
                <div class="header__container">
                    <svg viewBox="0 0 300 50" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" class="icon__top-bar" clip-rule="evenodd" d="M10 0C4.47715 0 0 4.47715 0 10V50H300V10C300 4.47715 295.523 0 290 0H184.388C184.388 9.01736 180.732 17.6654 174.226 24.0416C167.72 30.4179 158.895 34 149.694 34C140.492 34 131.668 30.4179 125.161 24.0416C118.655 17.6654 115 9.01737 115 5.13386e-06L149.694 0H10Z" fill="#D4B139"></path></svg>
                </div>
                <div class="enter-payment__header">
                    <h1 class="dialog--title">Enter Payment: <span>{{ invoice.fullName }} {{ invoice.invoiceNo }}</span></h1>
                </div>
                <div class="upsert-item__container invoice-payment">
                    <section class="upsert-item__section">
                        <div class="input__row pair">
                            <label class="input__label">
                                Current Balance Remaining
                                <k-numeric-input disabled v-model="invoice.balanceRemaining" :format="'c2'" :min="0"></k-numeric-input>
                            </label>
                            <label class="input__label">
                                Payment Amount
                                <k-numeric-input v-model="payment.paymentAmount" :format="'c2'" :min="0"></k-numeric-input>
                            </label>
                        </div>
                        <div class="input__row pair">
                            <label class="input__label">
                                Payment Date
                                <k-datepicker v-model="payment.paymentDate"></k-datepicker>
                            </label>
                            <label class="input__label">
                                Payment Invoice
                                <k-dropdown v-model="selectedPlayerInvoice"
                                            :data-items="playerInvoices"
                                            :text-field="'invoiceNo'"
                                            :data-item-key="'id'"
                                            @change="onSelectedInvoiceUpdated"></k-dropdown>
                            </label>
                        </div>
                        <div class="input__row">
                            <label class="input__label">
                                Note
                                <k-textarea v-model="payment.paymentNote"></k-textarea>
                            </label>
                        </div>
                        <div class="input__row">
                            <p class="balance--after-payment">
                                Balance Remaining After Payment: <span>{{ $options.filters.formatCurrencyDec(invoice.balanceRemaining - payment.paymentAmount) }}</span>
                            </p>
                        </div>
                    </section>

                    <k-action-bar>
                        <k-button class="item-save__btn" :disabled="savePaymentBtnDisabled" @click="onSaveClicked">Save</k-button>
                        <k-button class="item-cancel__btn" @click="onCancelClicked">Cancel</k-button>
                    </k-action-bar>
                </div>
            </k-dialog>
        </transition>
    </div>
</template>

<script>
    import { Dialog, DialogActionsBar } from '@progress/kendo-vue-dialogs';
    import { Button } from "@progress/kendo-vue-buttons";
    import { NumericTextBox, TextArea } from "@progress/kendo-vue-inputs";
    import { DatePicker } from '@progress/kendo-vue-dateinputs';
    import { DropDownList } from '@progress/kendo-vue-dropdowns';

    import cloneDeep from 'lodash.clonedeep';

    export default {
        name: 'invoicePaymentDialog',
        props: [ 'selectedInvoice', 'showDialog'],
        emits: ['dialogclosed', 'paymentEntered'],
        data() {
            return {
                invoice: null,
                payment: {},
                paymentModel: {
                    paymentAmount: null,
                    paymentDate: new Date(),
                    paymentNote: null,
                    paymentInvoiceId: null,
                    manuallyEntered: true
                },
                playerInvoices: [],
                selectedPlayerInvoice: null
            }
        },
        computed: {
            savePaymentBtnDisabled() {
                return !this.payment.paymentAmount;
            }
        },
        watch: {
            showDialog(val) {
                if (val) {
                    this.invoice = cloneDeep(this.selectedInvoice);
                    this.payment = cloneDeep(this.paymentModel);

                    this.getPlayerInvoices();
                } else {
                    this.resetDefaults();
                }
            }
        },
        methods: {
            async getPlayerInvoices() {
                await this.$store.dispatch('getInvoices', false);

                this.playerInvoices = cloneDeep(this.$store.state.invoices
                    .filter(invoice => invoice.playerId === this.invoice.playerId && ((invoice.status === 'Paid' || invoice.status === 'Paid in Part') || invoice.id === this.invoice.id))
                    .map(invoice => {
                        return {
                            id: invoice.id,
                            invoiceNo: invoice.invoiceNo
                        }
                    }));

                let selectedInvoice = this.playerInvoices.find(invoice => invoice.id === this.invoice.id);

                this.selectedPlayerInvoice = selectedInvoice ?? { id: null, invoiceNo: 'Select Invoice' };

                this.payment.paymentInvoiceId = this.selectedPlayerInvoice.id;
            },
            onCancelClicked() {
                this.$emit('dialogclosed');
            },
            onSaveClicked() {
                this.$emit('paymentEntered', this.payment);
            },
            onSelectedInvoiceUpdated(evt) {
                if (!this.selectedPlayerInvoice.id) {
                    return this.payment.paymentInvoiceId = null;
                }

                this.payment.paymentInvoiceId = this.selectedPlayerInvoice.id;
            },
            resetDefaults() {
                this.invoice = null,
                this.payment = {}
            }
        },
        filters: {
            formatCurrencyDec(val) {
                if (val === null || val === undefined || val === '') return '';

                if (val.toString().includes('$')) return val;

                if (isNaN(val)) {
                    val = 0;
                }

                let formatter = new Intl.NumberFormat('en-US', {
                    style: 'currency',
                    currency: 'USD',
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                });

                return formatter.format(val);
            }
        },
        components: {
            'k-dialog': Dialog,
            'k-action-bar': DialogActionsBar,
            'k-button': Button,
            'k-numeric-input': NumericTextBox,
            'k-datepicker': DatePicker,
            'k-dropdown': DropDownList,
            'k-textarea': TextArea
        }
    }
</script>

<style>
    @import '/src/assets/css/defaultUpsertStyles.css';
    @import '/src/assets/css/invoicePaymentDialog.css';
</style>