<template>
    <div class="seasonal-settings-dialog">
        <transition name="dialogfade">
            <k-dialog v-if="showDialog" class="default__dialog settings-dialog">
                <div class="icon__container">
                    <i class="fa-solid fa-gear"></i>
                </div>
                <svg viewBox="0 0 300 50" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" class="icon__top-bar" clip-rule="evenodd" d="M10 0C4.47715 0 0 4.47715 0 10V50H300V10C300 4.47715 295.523 0 290 0H184.388C184.388 9.01736 180.732 17.6654 174.226 24.0416C167.72 30.4179 158.895 34 149.694 34C140.492 34 131.668 30.4179 125.161 24.0416C118.655 17.6654 115 9.01737 115 5.13386e-06L149.694 0H10Z" fill="#D4B139"></path></svg>
                <div class="dialog-title__container">
                    <h1 class="dialog--title">Seasonal Settings</h1>
                    <k-dropdown :data-items="seasonalSettings"
                                class="seasonal-settings__dropdown"
                                v-model="selectedSeasonalSettings"
                                :text-field="'display'"></k-dropdown>
                    <k-button class="add-settings__btn" @click="onAddSettings"><i class="fa-sharp fa-solid fa-plus"></i> Add Settings</k-button>
                </div>

                <div class="upsert-item__container seasonal-settings">
                    <section class="upsert-item__section">
                    <p class="field--required">*Required</p>
                        <div class="input__row">
                            <k-checkbox :label="'Current'" v-model="selectedSeasonalSettings.isCurrent" @change="onCurrentChanged"></k-checkbox>
                        </div>
                        <div class="input__row pair">
                            <label class="input__label">
                                *Season Start Year
                                <k-numeric-input :placeholder="'Enter year'" v-model="selectedSeasonalSettings.seasonStartYear" :format="'0'" :min="0" @keydown="settings$.selectedSeasonalSettings.seasonStartYear.$reset()" :class="[{ 'error': settings$.selectedSeasonalSettings.seasonStartYear.$error }]"></k-numeric-input>
                                <span class="item-input--vuelidate-error" v-if="settings$.selectedSeasonalSettings.seasonStartYear.$error"><i>Start Year is required</i></span>
                                <span class="item-input--vuelidate-error" v-if="startDateWarning"><i>Start Year is in use</i></span>
                            </label>
                            <label class="input__label">
                                *Season End Year
                                <k-numeric-input :placeholder="'Enter year'" v-model="selectedSeasonalSettings.seasonEndYear" :format="'0'" :min="0" @keydown="settings$.selectedSeasonalSettings.seasonEndYear.$reset()" :class="[{ 'error': settings$.selectedSeasonalSettings.seasonEndYear.$error }]"></k-numeric-input>
                                <span class="item-input--vuelidate-error" v-if="settings$.selectedSeasonalSettings.seasonEndYear.$error"><i>End Year is required</i></span>
                                <span class="item-input--vuelidate-error" v-if="endDateWarning"><i>End Year is in use</i></span>
                            </label>
                        </div>
                        <div class="input__row">
                            <label class="input__label">
                                NHL Playable Days
                                <k-numeric-input :placeholder="'Enter days'" v-model="selectedSeasonalSettings.nhlPlayableDays" :format="'0'" :min="0"></k-numeric-input>
                            </label>
                        </div>
                        <div class="input__row">
                            <label class="input__label">
                                AHL Playable Days
                                <k-numeric-input :placeholder="'Enter days'" v-model="selectedSeasonalSettings.ahlPlayableDays" :format="'0'" :min="0"></k-numeric-input>
                            </label>
                        </div>
                        <div class="input__row">
                            <label class="input__label">
                                ECHL Playable Days
                                <k-numeric-input :placeholder="'Enter days'" v-model="selectedSeasonalSettings.echlPlayableDays" :format="'0'" :min="0"></k-numeric-input>
                            </label>
                        </div>
                        <div class="input__row">
                            <label class="input__label">
                                Escrow Percentage
                                <k-numeric-input :placeholder="'Enter days'" v-model="selectedSeasonalSettings.escrowPercentage" :format="'##.00 \\%'" :decimals="2" :min="0"></k-numeric-input>
                            </label>
                        </div>
                    </section>
                </div>
                <k-action-bar>
                    <k-button class="item-save__btn" @click="onSaveSettings" :disabled="saveBtnDisabled">Save</k-button>
                    <k-button class="item-cancel__btn" @click="onCancelClicked">Cancel</k-button>
                </k-action-bar>
            </k-dialog>
        </transition>

        <div class="loader-container" v-if="isLoading">
            <k-loader :size="'large'" :type="'infinite-spinner'"></k-loader>
        </div>

        <transition name="dialogfade">
            <k-dialog v-if="showErrorDialog" :title="errorTitle" class="error__dialog" @close="toggleErrorDialog">
                <p class="error-dialog__msg">{{ errorMsg }}</p>
            </k-dialog>
        </transition>

        <k-notification-group class="default-notification-container">
                <k-fade :appear="successNotification">
                    <k-notification v-if="successNotification"
                                    :type="{ style: 'success', icon: true }"
                                    :closable="true"
                                    @close="onCloseNotification('successNotification')">
                        <span>{{ notificationMsg }}</span>
                    </k-notification>
                </k-fade>
        </k-notification-group>
    </div>
</template>

<script>
    import { Dialog, DialogActionsBar } from '@progress/kendo-vue-dialogs';
    import { Button } from "@progress/kendo-vue-buttons";
    import { NumericTextBox, Checkbox } from "@progress/kendo-vue-inputs";
    import { Loader } from "@progress/kendo-vue-indicators";
    import { Notification, NotificationGroup } from "@progress/kendo-vue-notification";
    import { Fade } from "@progress/kendo-vue-animation";
    import { DropDownList } from '@progress/kendo-vue-dropdowns';

    import { useVuelidate } from '@vuelidate/core';
    import { required } from '@vuelidate/validators';

    import cloneDeep from 'lodash.clonedeep';

    import auth from '../authConfig';
    import apiService from '../api/api.service.js';

    export default {
        setup() {
            return { settings$: useVuelidate() }
        },
        name: 'seasonalSettingsDialog',
        props: ['showDialog'],
        emits: ['dialogclosed'],
        data () {
            return {
                endDateWarning: false,
                errorMsg: '',
                errorTitle: '',
                isLoading: false,
                notificationMsg: '',
                seasonalSettings: [],
                selectedSeasonalSettings: {},
                seasonalSettingsModel: {
                    ahlPlayableDays: null,
                    escrowPercentage: null,
                    nhlPlayableDays: null,
                    isCurrent: false,
                    seasonStartYear: null,
                    seasonEndYear: null,
                    settingsId: null,
                    display: 'Select Settings',
                    echlPlayableDays: null
                },
                showErrorDialog: false,
                startDateWarning: false,
                successNotification: false
            }
        },
        computed: {
            saveBtnDisabled() {
                return !(this.selectedSeasonalSettings.seasonStartYear && this.selectedSeasonalSettings.seasonEndYear);
            }
        },
        validations() {
            return {
                selectedSeasonalSettings: {
                    seasonStartYear: { required },
                    seasonEndYear: { required }
                }
            }
        },
        watch: {
            '$store.state.errorAlert'(val) {
                if (val) {
                    this.onRequestError(this.$store.state.errorTitle, this.$store.state.errorMsg);

                    let errorData = {
                        isError: false,
                        title: '',
                        msg: ''
                    }
                    
                    this.$store.commit('updateErrorAlert', errorData);
                }
            },
            showDialog(val) {
                if (val) {
                    this.getSettings();
                } else {
                    this.resetDefaults();
                }
            }
        },
        methods: {
            async getSettings(update = false) {
                this.isLoading = true;
                await this.$store.dispatch('getSeasonalSettings', update);

                this.seasonalSettings = cloneDeep(this.$store.state.allSeasonalSettings);
                
                if (this.seasonalSettings.length > 0) {
                    this.seasonalSettings.sort((a, b) => b.seasonStartYear - a.seasonStartYear);
                    this.selectedSeasonalSettings = this.seasonalSettings.find(settings => settings.isCurrent);

                    if (!this.selectedSeasonalSettings) {
                        this.selectedSeasonalSettings = this.seasonalSettings[0];
                    }
                }

                this.isLoading = false;
            },
            onAddSettings() {
                this.selectedSeasonalSettings = cloneDeep(this.seasonalSettingsModel);
            },
            onCancelClicked() {
                this.$emit('dialogclosed');
            },
            onCloseNotification(flag) {
                this[flag] = false;
            },
            onCurrentChanged() {
                if (this.selectedSeasonalSettings.isCurrent) {
                    this.seasonalSettings.forEach(setting => {
                        if (setting.settingsId !== this.selectedSeasonalSettings.settingsId) {
                            setting.isCurrent = false;
                        }
                    });
                }
            },
            onRequestError(errorTitle, errorMsg) {
                this.isLoading = false;

                this.errorTitle = errorTitle;

                if (errorMsg) {
                    this.errorMsg = errorMsg;
                } else {
                    this.errorMsg = 'Please try again';
                }

                this.showErrorDialog = true;
            },
            async onSaveSettings() {
                await this.settings$.$validate();

                if (this.settings$.selectedSeasonalSettings.$error) return;

                this.settings$.$reset();

                if (this.seasonalSettings.some(setting => setting.settingsId !== this.selectedSeasonalSettings.settingsId && setting.seasonStartYear === this.selectedSeasonalSettings.seasonStartYear)) {
                    this.startDateWarning = true;
                }

                if (this.seasonalSettings.some(setting => setting.settingsId !== this.selectedSeasonalSettings.settingsId && setting.seasonEndYear === this.selectedSeasonalSettings.seasonEndYear)) {
                    this.endDateWarning = true;
                }

                if (this.startDateWarning || this.endDateWarning) return;

                this.saveSettings();
            },
            resetDefaults() {
                this.selectedSeasonalSettings = {};
                this.seasonalSettings = [];
                this.startDateWarning = false;
                this.endDateWarning = false;
            },
            async saveSettings() {
                let self = this;
                self.isLoading = true;

                let request = cloneDeep(this.selectedSeasonalSettings);

                this.$emit('dialogclosed');
                
                let tokenResponse = await auth.acquireTokenResponse();

                return apiService.UpsertSeasonalSettings(tokenResponse.accessToken, request)
                    .then(async (res) => {
                        if (res.data.data) {
                            await self.getSettings(true);

                            self.isLoading = false;
                            self.showNotification('Settings updated successfully');
                        }
                    }).catch(error => {
                        console.log(error);
                        self.onRequestError('An Error Occurred', error?.response?.data?.message);
                    })

            },
            showNotification(notificationMsg) {
                this.notificationMsg = notificationMsg;

                this.successNotification = true;

                setTimeout(() => {
                    this.successNotification = false;
                }, 4000);
            },
            toggleErrorDialog() {
                this.showErrorDialog = !this.showErrorDialog;

                if(!this.showErrorDialog) {
                    this.errorTitle = '';
                    this.errorMsg = '';
                }
            }
        },
        components: {
            'k-dialog': Dialog,
            'k-action-bar': DialogActionsBar,
            'k-button': Button,
            'k-numeric-input': NumericTextBox,
            'k-loader': Loader,
            'k-notification': Notification,
            'k-notification-group': NotificationGroup,
            'k-fade': Fade,
            'k-checkbox': Checkbox,
            'k-dropdown': DropDownList
        }
    }
</script>

<style>
    @import '/src/assets/css/defaultUpsertStyles.css';
    @import '/src/assets/css/seasonalSettingsDialog.css';
</style>