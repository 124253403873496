<template >
    <div class="header" key="card">
        <div class="notes__header">
            <div class="nav__container">
                <div class="nav-logo__container">
                    <img class="gsh-logo" alt="GSH" src="../assets/player_connect_no_background.png">
                </div>

                <div class="navmenu">
                    <elite-prospects-search-component />
                    <div class="profile-card__wrap">
                        <div class="click-away__bg" v-show="isProfileCardShowing" @click="isProfileCardShowing = false">
                        </div>
                        <avatar class="profile-card__open-btn" type="image" :fill-mode="null" rounded="full"
                            @click="isProfileCardShowing = true">
                            <img v-if="user.photo" :src="user.photo" />
                            <img v-if="!user.photo" src="../assets/placeholder.svg" />
                        </avatar>
                        <card class="profile-card" v-show="isProfileCardShowing">
                            <cardHeader class="k-hbox">
                                <avatar type="image" :fill-mode="null" rounded="full">
                                    <img v-if="user.photo" :src="user.photo" />
                                    <img v-if="!user.photo" src="../assets/placeholder.svg" />
                                </avatar>
                                <div>
                                    <cardTitle>{{ user.displayName }}</cardTitle>
                                    <cardSubtitle>
                                        <p>{{ user.jobtitle }}</p>
                                    </cardSubtitle>
                                </div>
                            </cardHeader>
                            <cardBody>
                            </cardBody>
                            <cardActions>
                                <template v-slot:default>
                                    <p class="nav--version">Version : 1.26.24 - Live</p>
                                    <!-- <a href="" class="login__link">Sign
                                        out</a> -->
                                </template>
                            </cardActions>
                        </card>
                    </div>
                </div>
            </div>
        </div>

        <kendo-menu class="header-kendo-menu" :items="items" />


    </div>
</template>
  
<script>
import {
    Menu,
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    CardSubtitle,
    CardActions,
    Avatar,

} from "@progress/kendo-vue-layout";

import eliteProspectsSearchComponent from './eliteProspectsSearchComponent.vue';

import auth from '../authConfig.js';
import apiService from '../api/api.service.js';

export default {
    data() {
        return {
            isProfileCardShowing: false,
            userName: null,
            userRole: '',
            items: [
                {
                    text: "DASHBOARD",
                    url: '/'
                },
                {
                    text: "PLAYER MANAGEMENT",
                    url: '/PlayerManagement'
                },
                {
                    text: "CONTRACT MAGAGEMENT",
                    url: '/ContractManagement'
                },
                {
                    text: "INVOICES",
                    url: '/Invoices'
                },
                {
                    text: "CALENDAR",
                    url: '/Calendar'
                },
                {
                    text: "CITIZENSHIP DOCUMENTATION",
                    url: '/CitizenshipDocumentation'
                },
                {
                    text: "NO-TRADE REPORTING",
                    url: '/NoTradeReporting'
                },
                {
                    text: "SCOUTING REPORTS",
                    url: '/ScoutingReports'
                }
            ],
            user: {},
            userId: null
        }
    },
    async created() {
        const msalAppInstance = await auth.init();

        msalAppInstance.handleRedirectPromise().then(async (tokenRes) => {
            if (!tokenRes) {
                if (!auth.user()) {
                    auth.login();
                }
            }

            this.userId = auth.user()?.localAccountId;
            await this.getUser();
        });
    },
    methods: {
        async getUser() {
            let tokenResponse = await auth.acquireTokenResponse();

            return apiService.GetUser(tokenResponse.accessToken, this.userId) 
                .then((res) => {
                    this.user = res.data.data;
                    this.$store.commit('updateUser', res.data.data);
                }).catch(error => {
                    console.log(error);
                });
        },
        deepLogout() {
        }
    },
    components: {
        "kendo-menu": Menu,
        'card': Card,
        'cardHeader': CardHeader,
        'cardBody': CardBody,
        'cardTitle': CardTitle,
        'cardSubtitle': CardSubtitle,
        'cardActions': CardActions,
        'avatar': Avatar,
        'elite-prospects-search-component': eliteProspectsSearchComponent
    }
};
</script>

<style>
* {
    margin: 0px;
}

.nav__container {
    display: flex;
    align-items: center;
    width: 100%;
}

.nav-logo__container {
    /* IF A NEW LOGO IS MADE AND USE TO FIT BETTER WILL NEED TO ADJUST THE MARGIN */
    justify-content: flex-end;
    flex: 1
}

.nav-logo__container .gsh-logo {
    width: 310px;
}

.navmenu {
    flex: 0;
    padding-right: 10px;
    display: flex;
    align-items: center;
}


.header-kendo-menu {
    display: flex;
    flex-wrap: wrap;
}

.header-kendo-menu .k-menu {
    width: 100%;
    justify-content: space-evenly;
    display: flex;
    flex-wrap: wrap;
}

/* Add a media query for smaller screens */
@media (max-width: 830px) {
    .header-kendo-menu .k-menu {
        flex-direction: column;
        align-items: center;
        /* Switch to a column layout */
    }
}

.header-kendo-menu .k-menu-link-text {
    color: #D4B139;
    letter-spacing: 3px;
    font-size: 11px;
}

.header-kendo-menu .k-menu-link-text:hover {
    color: #d4b039bb;
}

.header-kendo-menu {
    background: #1F1E1C;
    padding: 13.5px 9px 5px;
    border-bottom: 0.2rem solid #D4B139;
}

.notes__header {
    display: flex;
    align-items: center;
    flex: 0;
    padding-top: 13.5px;
    padding-right: 9px;
    padding-left: 9px;
}

.header {
    background: #1F1E1C;
}

.header .router-link {
    text-decoration: none;
    margin-bottom: 3px;
    margin-left: 30px;
    color: var(--primary);
    font-size: 1.8rem;
    min-width: 112px;
}

.header .router-link:hover {
    color: var(--text-secondary-on-background);
}

.header .router-link.router-link-active {
    color: var(--accent);
    font-weight: 600;
}

.signout-btn-box {
    display: flex;
    align-items: center;
}

.signout-btn {
    display: block;
    font-weight: 500;
    text-align: center;
    white-space: nowrap;
    /* vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none; */
    background: none;
    padding: .7rem 1rem;
    margin-left: auto;
    border: none;
}

.signout-btn:hover {
    cursor: pointer;
    background: var(--accent-ltr);
    border-radius: 1rem;
}

.profile-card__wrap .click-away__bg {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 97
}

.profile-card__wrap .k-avatar {
    width: 42px;
    height: 42px;
    flex-basis: 42px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
}

.profile-card__wrap .profile-card__open-btn:hover {
    box-shadow: #051d3a45 0px 0px 3px 0px;
    /* box-shadow: #D4B139 0px 0px 3px 0px; */
    /* -webkit-box-shadow: #051d3a45 0px 0px 3px 0px; */
    -webkit-box-shadow: #D4B139 0px 0px 3px 0px;
}

.profile-card {
    /* Prevent kendo card default positioning */
    position: fixed !important;
    top: 5rem;
    right: 20px;
    width: 350px;
    z-index: 98;
    /* box-shadow: 0 0.8rem 1.6rem rgba(0, 0, 0, .15);
    -webkit-box-shadow: 0 0.8rem 1.6rem rgba(0, 0, 0, .15); */
    box-shadow: 0 0.8rem 1.6rem #D4B139;
    -webkit-box-shadow: 0 0.2rem 0.6rem #D4B139;
    background: #1F1E1C !important;
}

.profile-card .k-hbox {
    border-bottom: 1px solid #d4b0392c;
}

.profile-card .k-avatar {
    width: 70px;
    height: 70px;
    flex-basis: 70px;
    cursor: default;
}

.profile-card .k-card-title {
    font-size: 1.3rem;
    color: #D4B139;
}

.profile-card .k-card-subtitle {
    color: #D4B139;
}


.profile-card .card-item {
    width: 100%;
    display: flex;
    align-items: center;
    text-decoration: none;
    color: #D4B139;
}

.profile-card .card-item i {
    width: 2rem;
    text-align: center;
}

.profile-card .card-item .button {
    display: inline-block;
    font-weight: 500;
    line-height: 1.5;
    text-align: center;
    text-decoration: none;
    vertical-align: middle;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    background-color: transparent;
    border: 1px solid transparent;
    padding: .3375rem .675rem;
    font-size: 14.4px;
    border-radius: .6rem;
}

.profile-card .k-card-actions {
    justify-content: space-between;
    align-content: center;
    color: #1F1E1C;
    background: #D4B139;
    height: 40px;
    padding-left: 10px;
    padding-right: 10px;

}

.profile-card .card-item:hover i,
.profile-card .card-item:hover .button {
    color: #d4b039d0;
}

.profile-card .login__link {
    color: #1F1E1C;
    font-size: 12.6px;
    display: flex;
    align-items: center;
    text-decoration: none;
    font-weight: bold;

}

.profile-card .nav--version {
    color: #1F1E1C;
    font-size: 9.9px;
    display: flex;
    align-items: center;
    font-weight: bold;
}
</style>