<template>
    <div class="create-invoice">
        <transition name="dialogfade">
            <k-dialog v-if="showDialog" v-bind:class="['default__dialog', 'create-invoice-dialog', {'player-selected': !showCreateInvoicePlayer }]">
                <div class="icon__container">
                    <i class="fa-sharp fa-solid fa-file-invoice-dollar"></i>
                </div>
                <div class="header__container">
                    <svg v-if="showCreateInvoicePlayer" viewBox="0 0 300 35" x="0px" y="0px" xml:space="preserve" fill="none" xmlns="http://www.w3.org/2000/svg" style="enable-background:new 0 0 300 35;"><path fill-rule="evenodd" class="icon__top-bar" clip-rule="evenodd" d="M290,21.74H169.3a19.32,19.32,0,0,1-38.64,0H10c-5.52,0-10,2.53-10,5.66V50H300V27.4C300,24.27,295.52,21.74,290,21.74Z" transform="translate(0 -21.74)" fill="#D4B139"></path></svg>
                    <svg v-if="!showCreateInvoicePlayer" viewBox="0 0 300 20" x="0px" y="0px" xml:space="preserve" fill="none" xmlns="http://www.w3.org/2000/svg" style="enable-background:new 0 0 300 35;"><path fill-rule="evenodd" class="icon__top-bar" clip-rule="evenodd" d="M290,19.4H184.4h-15.1h-7.6c0,0,0,0.1,0,0.1c0,6.2-5,11.2-11.2,11.2c-6.2,0-11.2-5-11.2-11.2c0,0,0-0.1,0-0.1  h-8.6H115H10c-5.5,0-10,1.4-10,3.2V35h300V22.5C300,20.8,295.5,19.4,290,19.4z" transform="translate(0 -21.74)" fill="#D4B139"></path></svg>
                </div>
                <div class="create-invoice__header">
                    <h1 class="dialog--title">Create Invoice<span class="invoice--player" v-if="!showCreateInvoicePlayer && selectedPlayer.id">: {{ selectedPlayer.fullName }}</span></h1>
                    <k-button class="item-back__btn" @click="onBackToInvoicePlayer" v-if="!showCreateInvoicePlayer"><i class="fa-solid fa-left"></i> Back</k-button>
                </div>
                <div class="upsert-item__container create-invoice">
                    <div class="create-invoice__container">
                        <div class="invoice-detail__container">
                            <section class="select-invoice-player upsert-item__section" v-if="showCreateInvoicePlayer">
                                <p class="field--required">*Required</p>
                                <div class="input__row select-player">
                                    <label class="input__label">
                                        *Customer
                                        <k-dropdown :data-items="filteredPlayers" 
                                            :class="[{ 'error': invoice$.selectedPlayer.id.$error }]"
                                            v-model="selectedPlayer" 
                                            :text-field="'fullName'" 
                                            :filterable="true" 
                                            @change="onInvoicePlayerSelected"
                                            @filterchange="onPlayerFilterChange"></k-dropdown>
                                        <span class="item-input--vuelidate-error" v-if="invoice$.selectedPlayer.id.$error"><i>Customer is required</i></span>
                                    </label>
                                </div> 
                            </section>

                            <section class="upsert-item__section invoice-detail" v-if="!showCreateInvoicePlayer && invoice.nhlSalaryDetails">
                                <div class="total-due__container">
                                    <p>Total Due: <span>{{ $options.filters.formatCurrencyDec(invoice.totalDue) }}</span></p>
                                </div>
                                <k-tabstrip :selected="selectedTab" @select="onTabSelected" :tabs="invoiceTabs">
                                    <template v-slot:invoiceinformation>
                                        <div class="invoice-detail__tab-container">
                                            <div class="detail-input">
                                                <div class="invoice-title__container">
                                                    <i class="fa-duotone fa-memo-circle-info"></i>
                                                    <h2>Invoice Information</h2>
                                                    <p class="field--required">*Required</p>
                                                </div>
                                                <div class="input__row quad">
                                                    <label class="input__label">
                                                        *Customer
                                                        <k-dropdown :data-items="filteredPlayers" 
                                                            :class="[{ 'error': invoice$.selectedPlayer.id.$error }]"
                                                            v-model="selectedPlayer" 
                                                            :text-field="'fullName'" 
                                                            :filterable="true" 
                                                            @change="onInvoicePlayerSelected"
                                                            @filterchange="onPlayerFilterChange"></k-dropdown>
                                                        <span class="item-input--vuelidate-error" v-if="invoice$.selectedPlayer.id.$error"><i>Customer is required</i></span>
                                                    </label>
                                                    <label class="input__label">
                                                        *Invoice Number
                                                        <k-input v-model="invoice.invoiceNo" @keydown="invoice$.invoice.invoiceNo.$reset()" :class="[{ 'error': invoice$.invoice.invoiceNo.$error }]"/>
                                                        <span class="item-input--vuelidate-error" v-if="invoice$.invoice.invoiceNo.$error"><i>Invoice number is required</i></span>
                                                    </label>
                                                    <label class="input__label">
                                                        *Invoice Date
                                                        <k-datepicker v-model="invoice.invoiceDate" @focus="invoice$.invoice.invoiceDate.$reset()" :class="[{ 'error': invoice$.invoice.invoiceDate.$error }]"></k-datepicker>
                                                        <span class="item-input--vuelidate-error" v-if="invoice$.invoice.invoiceDate.$error"><i>Invoice date is required</i></span>
                                                    </label>
                                                    <label class="input__label">
                                                        *Payment Due Date
                                                        <k-datepicker v-model="invoice.paymentDue" @focus="invoice$.invoice.paymentDue.$reset()" :class="[{ 'error': invoice$.invoice.paymentDue.$error }]"></k-datepicker>
                                                        <span class="item-input--vuelidate-error" v-if="invoice$.invoice.paymentDue.$error"><i>Payment due date is required</i></span>
                                                    </label>
                                                </div>

                                                <div class="input__row">
                                                    <label class="input__label">
                                                        In Reference To
                                                        <k-textarea v-model="invoice.inReferenceTo"></k-textarea>
                                                    </label>
                                                </div>

                                                <h2 class="upsert-item__section--title">Invoice Contact</h2>
                                                <div class="input__row trio">
                                                    <label class="input__label">
                                                        First Name
                                                        <k-input v-model="invoice.invoiceContact.firstName" />
                                                    </label>
                                                    <label class="input__label">
                                                        Last Name
                                                        <k-input v-model="invoice.invoiceContact.lastName" />
                                                    </label>
                                                    <label class="input__label">
                                                        Title
                                                        <k-input v-model="invoice.invoiceContact.title" />
                                                    </label>
                                                </div>
                                                <div class="input__row trio">
                                                    <label class="input__label">
                                                        Phone
                                                        <k-input v-model="invoice.invoiceCompany.phone" @keydown="formatContactPhoneNumberInput" @input="invoice$.invoice.invoiceCompany.phone.$reset()" :class="[{ 'error': invoice$.invoice.invoiceCompany.phone.$error }]" />
                                                        <span class="item-input--vuelidate-error" v-if="invoice$.invoice.invoiceCompany.phone.$error"><i>Phone number is invalid</i></span>
                                                    </label>
                                                    <label class="input__label">
                                                        Ext
                                                        <k-input v-model="invoice.invoiceCompany.phoneExtension" />
                                                    </label>
                                                    <label class="input__label">
                                                        Email
                                                        <k-input v-model="invoice.invoiceContact.email" />
                                                    </label>
                                                </div>
                                                <div class="input__row pair">
                                                    <label class="input__label">
                                                        Address
                                                        <k-input v-model="invoice.invoiceCompany.address" />
                                                    </label>
                                                    <label class="input__label">
                                                        Additional (Suite, Unit, etc)
                                                        <k-input v-model="invoice.invoiceCompany.addressAdditional" />
                                                    </label>
                                                </div>
                                                <div class="input__row trio">
                                                    <label class="input__label">
                                                        City
                                                        <k-input v-model="invoice.invoiceCompany.city" />
                                                    </label>
                                                    <label class="input__label">
                                                        State
                                                        <k-dropdown :data-items="stateSelectsAbbr"
                                                                    v-model="invoice.invoiceCompany.state"
                                                                    :default-item="'Select State'"
                                                                    @change="defaultDropdownSelected($event, 'invoiceCompany.state', 'Select State')"></k-dropdown>
                                                    </label>
                                                    <label class="input__label">
                                                        Zip
                                                        <k-input v-model="invoice.invoiceCompany.zip" />
                                                    </label>
                                                </div>
                                            </div>
                                            <div class="invoice-notes__container">
                                                <div class="invoice-title__container" v-if="!showCreateInvoicePlayer">
                                                    <i class="fa-duotone fa-notes"></i>
                                                    <h2>Invoice Notes</h2>
                                                </div>
                                                <div class="editor__container">
                                                    <k-editor :tools="tools"
                                                            ref="noteseditor"
                                                            :content-style="{ height: '150px' }"
                                                            :resizable="true"
                                                            v-on:focus="onEditorFocus"
                                                            :default-content="defaultEditorContent"
                                                            :defaultEditMode="'div'" />
                                                    <div class="editor__container--submit">
                                                        <k-button class="submit-note__btn" @click="addInvoiceNote"><i class="fa-duotone fa-pen-line"></i> Submit </k-button>
                                                    </div>
                                                </div>
                                                <div v-if="invoice.invoiceNotes.length === 0" class="note-detail__container--empty">
                                                    <h4>No invoice notes available</h4>
                                                </div>
                                                <div v-if="invoice.invoiceNotes.length > 0" class="note-detail__container">
                                                    <div v-for="(note, index) in invoice.invoiceNotes" :key="index" class="note">
                                                        <div class="note-body" v-html="note.notes"></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </template>
                                    <template v-slot:invoiceitems>
                                        <div class="invoice-items__tab-container">
                                            <h2 class="upsert-item__section--title salary-details">Salary Details 
                                                <i class="fa-sharp fa-circle-info" @mouseover="toggleSalaryDetails" @mouseleave="toggleSalaryDetails"></i>
                                                <div v-bind:class="['salary__tooltip', { 'tooltip--show': showSalaryDetails }]">
                                                    <div v-if="playingContract">
                                                        <div class="contract-title__row">
                                                            <i class="fa-duotone fa-file-signature"></i>
                                                            <div class="contract-title-dates">
                                                                <h4>{{ playingContract.contractTitle }}</h4>
                                                                <p class="contract-date">Signed: <span>{{ new Date(playingContract.nhlContractStartYear).toLocaleDateString() }}</span> Expires: <span>{{ new Date(playingContract.nhlContractEndYear).toLocaleDateString() }}</span></p>
                                                            </div>
                                                        </div>

                                                        <div v-for="detail in playingContractSalaryDetails" :key="detail.detailId" class="salary-detail__container">
                                                            <div class="salary-detail__row">
                                                                <p class="detail-year">Yr <span>{{ detail.year }}</span></p>
                                                                <p v-if="showNhlSalaryDetails" class="detail-salary">NHL Base: <span>{{ $options.filters.formatCurrencyDec(detail[nhlContractAmountField]) }}</span></p>
                                                                <p v-if="showAhlSalaryDetails" class="detail-salary">AHL Base: <span>{{ $options.filters.formatCurrencyDec(detail[ahlContractAmountField]) }}</span></p>
                                                                <p v-if="showEchlSalaryDetails" class="detail-salary">ECHL: <span>{{ $options.filters.formatCurrencyDec(detail[echlContractAmountField]) }}</span></p>

                                                                <p v-if="showSalaryBufferColumn" class="detail-salary"></p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div v-if="!playingContract">
                                                        <div class="contract-title__row">
                                                            <i class="fa-duotone fa-folder-magnifying-glass"></i>
                                                            <div class="contract-title-dates">
                                                                <h4>No active playing contract available</h4>
                                                                <p class="contract-date"></p>
                                                            </div>                                                        
                                                        </div>
                                                    </div>
                                                </div>
                                            </h2>
                                            <div class="input__row trio" v-if="showNhlSalaryDetails">
                                                <label class="input__label">
                                                    NHL Base Salary
                                                    <k-numeric-input v-model="invoice.nhlSalaryDetails.baseSalary" :format="'c2'" :min="0"></k-numeric-input>
                                                </label>
                                                <label class="input__label">
                                                    Total Payable Days
                                                    <k-numeric-input v-model="invoice.nhlSalaryDetails.payableDays" :format="'n0'" :min="0"></k-numeric-input>
                                                </label>
                                                <label class="input__label">
                                                    Daily Salary
                                                    <k-numeric-input v-model="invoice.nhlSalaryDetails.dailySalary" :format="'c2'" :min="0"></k-numeric-input>
                                                </label>
                                            </div>

                                            <div class="input__row trio" v-if="showAhlSalaryDetails">
                                                <label class="input__label">
                                                    AHL Base Salary
                                                    <k-numeric-input v-model="invoice.ahlSalaryDetails.baseSalary" :format="'c2'" :min="0"></k-numeric-input>
                                                </label>
                                                <label class="input__label">
                                                    Total Payable Days 
                                                    <k-numeric-input v-model="invoice.ahlSalaryDetails.payableDays" :format="'n0'" :min="0"></k-numeric-input>
                                                </label>
                                                <label class="input__label">
                                                    Daily Salary
                                                    <k-numeric-input v-model="invoice.ahlSalaryDetails.dailySalary" :format="'c2'" :min="0"></k-numeric-input>
                                                </label>
                                            </div>

                                            <div class="input__row trio" v-if="showEchlSalaryDetails">
                                                <label class="input__label">
                                                    ECHL Base Salary
                                                    <k-numeric-input v-model="invoice.echlSalaryDetails.baseSalary" :format="'c2'" :min="0"></k-numeric-input>
                                                </label>
                                                <label class="input__label">
                                                    Total Payable Days 
                                                    <k-numeric-input v-model="invoice.echlSalaryDetails.payableDays" :format="'n0'" :min="0"></k-numeric-input>
                                                </label>
                                                <label class="input__label">
                                                    Daily Salary
                                                    <k-numeric-input v-model="invoice.echlSalaryDetails.dailySalary" :format="'c2'" :min="0"></k-numeric-input>
                                                </label>
                                            </div>
                                            <div class="invoice-title__container">
                                                <i class="fa-duotone fa-file-lines"></i>
                                                <h2>Invoice Items</h2>
                                            </div>
                                            <div class="input__row add-item">
                                                <label class="input__label add-item">
                                                    <span @click="onAddInvoiceItem"><i class="fa-sharp fa-solid fa-plus"></i> Add Invoice Item</span>
                                                </label>
                                            </div>
                                            <div :class="['invoice-items__container', { 'active': invoice.invoiceItems.length > 0 }]">
                                                <div class="invoice-item" v-for="(item, index) in invoice.invoiceItems" :key="item.itemId">
                                                    <k-slide :appear="invoice.invoiceItems[index] !== null">
                                                        <div class="input__row invoice-item-row">
                                                            <label class="input__label item-category">
                                                                *Category
                                                                <k-dropdown :data-items="invoiceItemCategorySelects"
                                                                            v-model="item.category"
                                                                            :default-item="'Select Category'"
                                                                            @change="defaultIndexedDropdownSelected($event, 'invoiceItems.category', 'Select Category', index)"></k-dropdown>
                                                            </label>
                                                            <label class="input__label item-quantity">
                                                                {{ calculateQuantityDisplay(item) }}
                                                                <k-numeric-input v-model="item.quantity" :format="'n0'" :min="0"></k-numeric-input>
                                                            </label>
                                                            <label class="input__label item-name">
                                                                Name
                                                                <k-dropdown v-if="item.category === invoiceItemCategoryManagementFee"
                                                                            :data-items="invoiceItemManagementFeeNameSelects"
                                                                            v-model="item.name"
                                                                            :default-item="'Select Name'"
                                                                            @change="defaultIndexedDropdownSelected($event, 'invoiceItems.name', 'Select Name', index)"></k-dropdown>
                                                                <k-dropdown v-if="item.category === invoiceItemCategoryBalanceRemaining"
                                                                            :data-items="invoicesWithBalancesSelects"
                                                                            v-model="item.name"
                                                                            :default-item="'Select Name'"
                                                                            @change="defaultIndexedDropdownSelected($event, 'invoiceItems.name', 'Select Name', index)"></k-dropdown>
                                                                <k-input v-if="item.category !== invoiceItemCategoryManagementFee && item.category !== invoiceItemCategoryBalanceRemaining" v-model="item.name" />
                                                            </label>
                                                            <label class="input__label item-description">
                                                                Description
                                                                <k-input v-model="item.description" />
                                                            </label>
                                                            <label class="input__label item-date">
                                                                Date Earned
                                                                <k-datepicker v-model="item.dateEarned"></k-datepicker>
                                                            </label>
                                                            <label class="input__label item-player-earnings">
                                                                {{ item.category === invoiceItemCategoryEndorsementFee || item.category === invoiceItemCategoryEscrow || item.category === invoiceItemCategoryEscrowBonus || item.category === invoiceItemCategoryManagementFee ? 'Player Earnings' : 'Amount' }}
                                                                <k-numeric-input :placeholder="'Enter amount'" v-model="item.playerEarnings" :format="'c2'"></k-numeric-input>
                                                            </label>
                                                            <label class="input__label item-rate">
                                                                Rate 
                                                                <k-numeric-input :placeholder="'Enter %'" v-model="item.rate" :format="'##.00 \\%'" :decimals="2" :min="0"></k-numeric-input>
                                                            </label>
                                                            <label class="input__label item-amount-due">
                                                                Amount Due
                                                                <k-numeric-input v-model="item.amountDue" :format="'c2'"></k-numeric-input>
                                                            </label>
                                                        </div>
                                                    </k-slide>
                                                    <div class="item-delete__container" @click="onDeleteInvoiceItem(item)" title="Delete Item"><i class="fa-solid fa-trash-can"></i></div>
                                                </div>
                                            </div>
                                        </div>
                                    </template>
                                    <template v-slot:contracts>
                                        <div class="contract-tab__container">
                                            <div class="invoice-title__container">
                                                <i class="fa-duotone fa-file-signature"></i>
                                                <h2>Active Contracts</h2>
                                                <k-dropdown :data-items="contractDropdownItems"
                                                            class="active-contracts__dropdown"
                                                            v-model="selectedContractItem"
                                                            :text-field="'title'"
                                                            :data-item-key="'id'"
                                                            @change="onGetContractDetail"></k-dropdown>
                                                <i v-if="selectedContractItem.title !== 'Select a Contract' && selectedActiveContract" title="View Contract Detail" class="detail-link fa-solid fa-pen-to-square" @click="goToSelectedContract"></i>
                                            </div>
                                            <div class="selected-contract__container" v-if="selectedContractItem.title !== 'Select a Contract' && selectedActiveContract">
                                                <div :class="['selected-contract__detail-container', { 'agency-detail': selectedActiveContract.contract.contractType === agencyContractType }]">
                                                    <div class="active-contract agency" v-if="selectedActiveContract.contract.contractType === agencyContractType">
                                                        <h2 class="contract--title first">Contract Information</h2>
                                                        <div class="active-contract__row pair">
                                                            <p class="contract-row__item">Signing Date: 
                                                                <span>
                                                                    {{ selectedActiveContract.contract.spacSigningDate ? new Date(selectedActiveContract.contract.spacSigningDate).toLocaleDateString() :  'N/A'}}
                                                                </span>
                                                            </p>
                                                            <p class="contract-row__item">Expiration Date: 
                                                                <span>
                                                                    {{ selectedActiveContract.contract.expirationDate ? new Date(selectedActiveContract.contract.expirationDate).toLocaleDateString() :  'N/A'}}
                                                                </span>
                                                            </p>
                                                        </div>
                                                        <div class="active-contract__row pair">
                                                            <p class="contract-row__item">Primary Agent: <span>{{ selectedActiveContract.contract.primaryAgent ?? 'N/A' }}</span></p>
                                                            <p class="contract-row__item">Secondary Agent: <span>{{ selectedActiveContract.contract.secondaryAgent ?? 'N/A' }}</span></p>
                                                        </div>
                                                        <div class="active-contract__row pair">
                                                            <p class="contract-row__item">Third Agent: <span>{{ selectedActiveContract.contract.thirdAgent ?? 'N/A' }}</span></p>
                                                        </div>
                                                        <h2 class="contract--title">Contract Commission Information</h2>
                                                        <div class="active-contract__row pair">
                                                            <p class="contract-row__item">Playing Commission Percent: <span>{{ selectedActiveContract.contract.playingCommissionPercent ? `${selectedActiveContract.contract.playingCommissionPercent} %` : 'N/A' }}</span></p>
                                                            <p class="contract-row__item">Endorsement Commission Percent: <span>{{ selectedActiveContract.contract.endorsementCommissionPercent ? `${selectedActiveContract.contract.endorsementCommissionPercent} %` : 'N/A' }}</span></p>
                                                        </div>
                                                    </div>
                                                    <div class="active-contract endorsement" v-if="selectedActiveContract.contract.contractType === endorsementContractType">
                                                        <h2 class="contract--title first">Contract Information</h2>
                                                        <div class="active-contract__row pair">
                                                            <p class="contract-row__item">Signing Date: 
                                                                <span>
                                                                    {{ selectedActiveContract.contract.endorsementSigningDate ? new Date(selectedActiveContract.contract.endorsementSigningDate).toLocaleDateString() :  'N/A'}}
                                                                </span>
                                                            </p>
                                                            <p class="contract-row__item">Expiration Date: 
                                                                <span>
                                                                    {{ selectedActiveContract.contract.endorsementExpirationDate ? new Date(selectedActiveContract.contract.endorsementExpirationDate).toLocaleDateString() :  'N/A'}}
                                                                </span>
                                                            </p>       
                                                        </div>
                                                        <div class="active-contract__row">
                                                            <p class="contract-row__item">Agency Contract: 
                                                                <span>
                                                                    {{ selectedContractAssociatedContract ? selectedContractAssociatedContract.contractTitle : 'N/A' }}
                                                                </span>
                                                            </p>   
                                                        </div>
                                                        <div class="active-contract__row pair">
                                                            <p class="contract-row__item">Endorsement Type: <span>{{ selectedActiveContract.contract.endorsementContractType ?? 'N/A' }}</span></p>
                                                            <p class="contract-row__item">Company Name: <span>{{ selectedActiveContract.contract.endorsementCompanyName ?? 'N/A' }}</span></p>
                                                        </div>
                                                        <div class="active-contract__row pair">
                                                            <p class="contract-row__item">Contract Amount: <span>{{ selectedActiveContract.contract.endorsementAmount ? $options.filters.formatCurrencyDec(selectedActiveContract.contract.endorsementAmount) : 'N/A' }}</span></p>
                                                            <p class="contract-row__item">Endorsement Commission Percent: <span>{{ selectedActiveContract.contract.endorsementCommissionPercent ? `${selectedActiveContract.contract.endorsementCommissionPercent} %` : 'N/A' }}</span></p>
                                                        </div>
                                                        <div class="active-contract__notes">
                                                            <p class="contract-notes__label">Notes:</p>
                                                            <div class="contract-notes__container">
                                                                <p>{{ selectedActiveContract.contract.endorsementNotes }}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="active-contract equipment" v-if="selectedActiveContract.contract.contractType === equipmentContractType">
                                                        <h2 class="contract--title first">Contract Information</h2>
                                                        <div class="active-contract__row pair">
                                                            <p class="contract-row__item">Equipment Contract: <span>{{ selectedActiveContract.contract.equipmentContract ?? 'N/A' }}</span></p>
                                                            <p class="contract-row__item">Agency Contract: 
                                                                <span>
                                                                    {{ selectedContractAssociatedContract ? selectedContractAssociatedContract.contractTitle : 'N/A' }}
                                                                </span>
                                                            </p>  
                                                        </div>
                                                        <div class="active-contract__row pair">
                                                            <p class="contract-row__item">Signing Date: 
                                                                <span>
                                                                    {{ selectedActiveContract.contract.equipmentSigningDate ? new Date(selectedActiveContract.contract.equipmentSigningDate).toLocaleDateString() :  'N/A'}}
                                                                </span>
                                                            </p>
                                                            <p class="contract-row__item">Expiration Date: 
                                                                <span>
                                                                    {{ selectedActiveContract.contract.equpmentExpirationDate ? new Date(selectedActiveContract.contract.equipmentExpirationDate).toLocaleDateString() :  'N/A'}}
                                                                </span>
                                                            </p>   
                                                        </div>
                                                        <div class="active-contract__row pair">
                                                            <p class="contract-row__item">Contract Amount: <span>{{ selectedActiveContract.contract.equipmentAmount ? $options.filters.formatCurrencyDec(selectedActiveContract.contract.equipmentAmount) : 'N/A' }}</span></p>
                                                            <p class="contract-row__item">Equipment Commission Percent: <span>{{ selectedActiveContract.contract.equipmentCommissionRate ? `${selectedActiveContract.contract.equipmentCommissionRate} %` : 'N/A' }}</span></p>
                                                        </div>
                                                        <div class="active-contract__notes">
                                                            <p class="contract-notes__label">Notes:</p>
                                                            <div class="contract-notes__container">
                                                                <p>{{ selectedActiveContract.contract.equipmentNotes }}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="active-contract playing" v-if="selectedActiveContract.contract.contractType === playingContractType">
                                                        <h2 class="contract--title first">Contract Information</h2>
                                                        <div class="active-contract__row pair">
                                                            <p class="contract-row__item">Signing Date: 
                                                                <span>
                                                                    {{ selectedActiveContract.contract.nhlContractStartYear ? new Date(selectedActiveContract.contract.nhlContractStartYear).toLocaleDateString() :  'N/A'}}
                                                                </span>
                                                            </p>
                                                            <p class="contract-row__item">Expiration Date: 
                                                                <span>
                                                                    {{ selectedActiveContract.contract.nhlContractEndYear ? new Date(selectedActiveContract.contract.nhlContractEndYear).toLocaleDateString() :  'N/A'}}
                                                                </span>
                                                            </p>   
                                                        </div>
                                                        <div class="active-contract__row pair">
                                                            <p class="contract-row__item">NHL Signing Club: <span>{{ selectedActiveContract.contract.nhlSigningClub ?? 'N/A' }}</span></p>
                                                            <p class="contract-row__item">Agency Contract: 
                                                                    <span>
                                                                        {{ selectedContractAssociatedContract ? selectedContractAssociatedContract.contractTitle : 'N/A' }}
                                                                    </span>
                                                            </p> 
                                                        </div>
                                                        <h2 class="contract--title">NHL Information</h2>
                                                        <div class="active-contract__row pair">
                                                            <p class="contract-row__item">NHL Contract Type: <span>{{ selectedActiveContract.contract.nhlContractType ?? 'N/A' }}</span></p>
                                                            <p class="contract-row__item">Exp Free Agent Status: <span>{{ selectedActiveContract.contract.nhlContractExpirationStatus ?? 'N/A' }}</span></p>
                                                        </div>
                                                        <div v-if="selectedActiveContract.contract.nhlContractType === nhlOneWayContractType || selectedActiveContract.contract.nhlContractType === ahlNhlTwoWayContractType">
                                                            <h2 class="contract--title">No-Trade Clause Information</h2>
                                                            <div class="active-contract__row pair">
                                                                <p class="contract-row__item">Type: <span>{{ selectedActiveContract.contract.noTradeClauseType ?? 'N/A' }}</span></p>
                                                                <p v-if="selectedActiveContract.contract.limitedModifiedNoTradeClause" class="contract-row__item">Due: <span>{{ selectedActiveContract.contract.limitedModifiedOption ?? 'N/A' }}</span></p>
                                                            </div>
                                                        </div>
                                                        <h2 class="contract--title">Salary Details</h2>
                                                        <div v-if="selectedActiveContract.contract.nhlContractType === ahlNhlTwoWayContractType" class="detail-years__container">
                                                            <div v-for="detail in selectedActiveContract.ahlNhlTwoWayContractDetails" :key="detail.detailId" class="detail-year__container">
                                                                <p class="salary-detail--year">Year {{ detail.year }}</p>
                                                                <div class="active-contract__row trio">
                                                                    <p class="contract-row__item">AHL Amount: <span>{{ detail.ahlTwoWayAmount ? $options.filters.formatCurrencyDec(detail.ahlTwoWayAmount) : 'N/A' }}</span></p>
                                                                    <p class="contract-row__item">AHL Payable Days: <span>{{ detail.ahlTotalPayableDays ?? 'N/A' }}</span></p>
                                                                    <p class="contract-row__item">AHL Daily Salary: <span>{{ detail.ahlDailySalary ? $options.filters.formatCurrencyDec(detail.ahlDailySalary) : 'N/A' }}</span></p>
                                                                </div>
                                                                <div class="active-contract__row trio">
                                                                    <p class="contract-row__item">NHL Amount: <span>{{ detail.nhlTwoWayAmount ? $options.filters.formatCurrencyDec(detail.nhlTwoWayAmount) : 'N/A' }}</span></p>
                                                                    <p class="contract-row__item">NHL Payable Days: <span>{{ detail.nhlTotalPayableDays ?? 'N/A' }}</span></p>
                                                                    <p class="contract-row__item">NHL Daily Salary: <span>{{ detail.nhlDailySalary ? $options.filters.formatCurrencyDec(detail.nhlDailySalary) : 'N/A' }}</span></p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div v-if="selectedActiveContract.contract.nhlContractType === ahlTwoWayContractType" class="detail-years__container">
                                                            <div v-for="detail in selectedActiveContract.ahlTwoWayContractDetails" :key="detail.detailId" class="detail-year__container">
                                                                <p class="salary-detail--year">Year {{ detail.year }}</p>
                                                                <div class="active-contract__row trio">
                                                                    <p class="contract-row__item">AHL Amount: <span>{{ detail.ahlTwoWayAmount ? $options.filters.formatCurrencyDec(detail.ahlTwoWayAmount) : 'N/A' }}</span></p>
                                                                    <p class="contract-row__item">AHL Payable Days: <span>{{ detail.ahlTotalPayableDays ?? 'N/A' }}</span></p>
                                                                    <p class="contract-row__item">AHL Daily Salary: <span>{{ detail.ahlDailySalary ? $options.filters.formatCurrencyDec(detail.ahlDailySalary) : 'N/A' }}</span></p>
                                                                </div>
                                                                <div class="active-contract__row trio">
                                                                    <p class="contract-row__item">ECHL Amount: <span>{{ detail.echlTwoWayAmount ? $options.filters.formatCurrencyDec(detail.echlTwoWayAmount) : 'N/A' }}</span></p>
                                                                    <p class="contract-row__item">ECHL Payable Days: <span>{{ detail.echlTotalPayableDays ?? 'N/A' }}</span></p>
                                                                    <p class="contract-row__item">ECHL Daily Salary: <span>{{ detail.echlDailySalary ? $options.filters.formatCurrencyDec(detail.echlDailySalary) : 'N/A' }}</span></p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div v-if="selectedActiveContract.contract.nhlContractType === ahlOneWayContractType" class="detail-years__container">
                                                            <div v-for="detail in selectedActiveContract.ahlOneWayContractDetails" :key="detail.detailId" class="detail-year__container">
                                                                <p class="salary-detail--year">Year {{ detail.year }}</p>
                                                                <div class="active-contract__row trio">
                                                                    <p class="contract-row__item">AHL Amount: <span>{{ detail.ahlOneWayAmount ? $options.filters.formatCurrencyDec(detail.ahlOneWayAmount) : 'N/A' }}</span></p>
                                                                    <p class="contract-row__item">AHL Payable Days: <span>{{ detail.ahlTotalPayableDays ?? 'N/A' }}</span></p>
                                                                    <p class="contract-row__item">AHL Daily Salary: <span>{{ detail.ahlOneWayDailySalary ? $options.filters.formatCurrencyDec(detail.ahlOneWayDailySalary) : 'N/A' }}</span></p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div v-if="selectedActiveContract.contract.nhlContractType === nhlOneWayContractType" class="detail-years__container">
                                                            <div v-for="detail in selectedActiveContract.nhlOneWayContractDetails" :key="detail.detailId" class="detail-year__container">
                                                                <p class="salary-detail--year">Year {{ detail.year }}</p>
                                                                <div class="active-contract__row trio">
                                                                    <p class="contract-row__item">NHL Amount: <span>{{ detail.nhlOneWayAmount ? $options.filters.formatCurrencyDec(detail.nhlOneWayAmount) : 'N/A' }}</span></p>
                                                                    <p class="contract-row__item">NHL Payable Days: <span>{{ detail.nhlTotalPayableDays ?? 'N/A' }}</span></p>
                                                                    <p class="contract-row__item">NHL Daily Salary: <span>{{ detail.nhlOneWayDailySalary ? $options.filters.formatCurrencyDec(detail.nhlOneWayDailySalary) : 'N/A' }}</span></p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div v-if="selectedActiveContract.contract.nhlContractType === echlContractType" class="detail-years__container">
                                                            <div v-for="detail in selectedActiveContract.echlContractDetails" :key="detail.detailId" class="detail-year__container">
                                                                <p class="salary-detail--year">Year {{ detail.year }}</p>
                                                                <div class="active-contract__row pair">
                                                                    <p class="contract-row__item">ECHL Amount: <span>{{ detail.echlAmount ? $options.filters.formatCurrencyDec(detail.echlAmount) : 'N/A' }}</span></p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div v-if="selectedActiveContract.contract.nhlContractType === elcContractType" class="detail-years__container">
                                                            <div v-for="detail in selectedActiveContract.elcContractDetails" :key="detail.detailId" class="detail-year__container">
                                                                <p class="salary-detail--year">Year {{ detail.year }}</p>
                                                                <div class="active-contract__row pair">
                                                                    <p class="contract-row__item">AHL Amount: <span>{{ detail.ahlSalary ? $options.filters.formatCurrencyDec(detail.ahlSalary) : 'N/A' }}</span></p>
                                                                    <p class="contract-row__item">NHL Amount: <span>{{ detail.nhlSalary ? $options.filters.formatCurrencyDec(detail.nhlSalary) : 'N/A' }}</span></p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div v-bind:class="['selected-contract__items-container', { 'playing-contract': selectedActiveContract.contract.contractType === playingContractType }]">
                                                    <div class="selected-contract__items active-contract" v-if="selectedActiveContract.contract.contractType === endorsementContractType">
                                                        <h2 class="contract--title first">Endorsement Payouts</h2>
                                                        <div class="items__container payouts">
                                                            <div class="active-contract__row trio" v-for="payout in selectedActiveContract.endorsementPayouts" :key="payout.payoutId">
                                                                <p class="contract-row__item">Payout Date: <span>{{ payout.payoutDate ? new Date(payout.payoutDate).toLocaleDateString() : 'N/A' }}</span></p>
                                                                <p class="contract-row__item">Payout Amount: <span>{{ payout.payoutAmount ? $options.filters.formatCurrencyDec(payout.payoutAmount) : 'N/A' }}</span></p>
                                                                <p class="contract-row__item"></p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="bonus-tabstrip__container active-contract" v-if="selectedActiveContract.contract.contractType === playingContractType">
                                                        <k-tabstrip :selected="selectedContractBonusTab" @select="onContractBonusTabSelected" :tabs="contractBonusTabs">
                                                            <template v-slot:performancebonus>
                                                                <div class="active-contract__row trio" v-for="bonus in selectedActiveContract.performanceBonuses" :key="bonus.bonusId">
                                                                    <p class="contract-row__item">Title: <span>{{ bonus.bonusTitle ?? 'N/A' }}</span></p>
                                                                    <p class="contract-row__item">Bonus Amount: <span>{{ bonus.bonusAmount ? $options.filters.formatCurrencyDec(bonus.bonusAmount) : 'N/A' }}</span></p>
                                                                    <p class="contract-row__item">Notes: <span>{{ bonus.notes ?? 'N/A' }}</span></p>
                                                                </div>
                                                                <div class="contract--no-bonus" v-if="selectedActiveContract.performanceBonuses.length === 0">
                                                                    <h4><i class="fa-duotone fa-money-check-dollar-pen"></i> No Bonuses Found</h4>
                                                                </div>
                                                            </template>
                                                            <template v-slot:nhlbonus>
                                                                <div class="active-contract__row trio" v-for="bonus in selectedActiveContract.nhlSigningBonuses" :key="bonus.bonusId">
                                                                    <p class="contract-row__item">Bonus Date: <span>{{ bonus.bonusDate ? new Date(bonus.bonusDate).toLocaleDateString() : 'N/A' }}</span></p>
                                                                    <p class="contract-row__item">Bonus Amount: <span>{{ bonus.bonusAmount ? $options.filters.formatCurrencyDec(bonus.bonusAmount) : 'N/A' }}</span></p>
                                                                    <p class="contract-row__item"></p>
                                                                </div>
                                                                <div class="contract--no-bonus" v-if="selectedActiveContract.nhlSigningBonuses.length === 0">
                                                                    <h4><i class="fa-duotone fa-money-check-dollar-pen"></i> No Bonuses Found</h4>
                                                                </div>
                                                            </template>
                                                            <template v-slot:elcbonus>
                                                                <div class="active-contract__row trio" v-for="bonus in selectedActiveContract.elcPerformanceBonuses" :key="bonus.bonusId">
                                                                    <p class="contract-row__item">Bonus Amount: <span>{{ bonus.bonusAmount ? $options.filters.formatCurrencyDec(bonus.bonusAmount) : 'N/A' }}</span></p>
                                                                    <p class="contract-row__item">Schedule Type: <span>{{ bonus.scheduleType ?? 'N/A' }}</span></p>
                                                                    <p class="contract-row__item">Notes: <span>{{ bonus.notes ?? 'N/A' }}</span></p>
                                                                </div>
                                                                <div class="contract--no-bonus" v-if="selectedActiveContract.elcPerformanceBonuses.length === 0">
                                                                    <h4><i class="fa-duotone fa-money-check-dollar-pen"></i> No Bonuses Found</h4>
                                                                </div>
                                                            </template>
                                                        </k-tabstrip>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="selected-contract__container no-contract-selected" v-if="selectedContractItem.title === 'Select a Contract'">
                                                <i class="fa-duotone fa-file-signature"></i>
                                                <p>No Contract Selected</p>
                                            </div>
                                        </div>
                                    </template>
                                </k-tabstrip>
                            </section>
                        </div>
                    </div>

                    <k-action-bar>
                        <k-button v-if="!showCreateInvoicePlayer" class="item-save__btn" @click="onSaveClicked">Save</k-button>
                        <k-button class="item-cancel__btn" @click="onCloseDialog">Cancel</k-button>
                    </k-action-bar>
                </div>
                <div class="loader-container" v-if="isLoadingContracts">
                    <k-loader :size="'large'" :type="'infinite-spinner'"></k-loader>
                </div>
            </k-dialog>
        </transition>

        <div class="loader-container" v-if="isLoading">
            <k-loader :size="'large'" :type="'infinite-spinner'"></k-loader>
        </div>

        <transition name="dialogfade">
            <k-dialog v-if="showErrorDialog" :title="errorTitle" class="error__dialog" @close="toggleErrorDialog">
                <p class="error-dialog__msg">{{ errorMsg }}</p>
            </k-dialog>
        </transition>

        <k-notification-group class="default-notification-container">
                <k-fade :appear="successNotification">
                    <k-notification v-if="successNotification"
                                    :type="{ style: 'success', icon: true }"
                                    :closable="true"
                                    @close="onCloseNotification('successNotification')">
                        <span>{{ notificationMsg }}</span>
                    </k-notification>
                </k-fade>
        </k-notification-group>
    </div>
</template>

<script>
    import { Dialog, DialogActionsBar } from '@progress/kendo-vue-dialogs';
    import { Loader } from "@progress/kendo-vue-indicators";
    import { Notification, NotificationGroup } from "@progress/kendo-vue-notification";
    import { Fade } from "@progress/kendo-vue-animation";
    import { Button } from "@progress/kendo-vue-buttons";
    import { DropDownList } from '@progress/kendo-vue-dropdowns';
    import { DatePicker } from '@progress/kendo-vue-dateinputs';
    import { Input, NumericTextBox, TextArea } from "@progress/kendo-vue-inputs";
    import { Editor } from '@progress/kendo-vue-editor';
    import { TabStrip } from '@progress/kendo-vue-layout';
    import { Slide } from '@progress/kendo-vue-animation';

    import { filterBy } from '@progress/kendo-data-query';

    import { useVuelidate } from '@vuelidate/core';
    import { required } from '@vuelidate/validators';

    import cloneDeep from 'lodash.clonedeep';
    import date from 'date-and-time';

    import contractHelpers from '../helpers/contractHelpers.js';
    import auth from '../authConfig';
    import apiService from '../api/api.service.js';
    import invoiceHelpers from '../helpers/invoiceHelpers.js';

    const phoneValidator = (val) => {
        if (!val) return true;

        const phoneRegEx = `^((\\(\\d{3}\\))|\\d{3})[- ]?\\d{3}[- ]?\\d{4}$`;
        return val.match(phoneRegEx);
    }

    export default {
        setup() {
            return { invoice$: useVuelidate() }
        },
        name: 'createInvoiceDialog',
        props: ['showDialog'],
        emits: ['dialogclosed', 'invoicecreated'],
        data() {
            return {
                agencyContract: null,
                ahlContractAmountField: '',
                allPlayers: [],
                contractBonusTabs: [
                    {
                        title: 'Signing Bonus',
                        content: 'nhlbonus'
                    },
                    {
                        title: 'NHL Bonus',
                        content: 'performancebonus'
                    },
                    {
                        title: 'ELC Bonus',
                        content: 'elcbonus'
                    }
                ],
                contractDropdownItems: [],
                defaultEditorContent: 'Add your note here!',
                echlContractAmountField: '',
                errorMsg: '',
                errorTitle: '',
                filteredPlayers: [],
                invoice: {},
                invoiceItemModel: {
                    itemId: null,
                    invoiceId: null,
                    amountDue: null,
                    category: null,
                    comments: null,
                    description: null,
                    expenses: null,
                    playerEarnings: null,
                    name: null,
                    quantity: null,
                    rate: null,
                    status: 'included',
                    dateEarned: null
                },
                invoiceModel: {
                    id: null,
                    playerId: null,
                    invoiceNo: null,
                    invoiceDate: null,
                    paymentDue: null,
                    status: 'Pending',
                    totalDue: 0,
                    totalDueThisInvoice: null,
                    balanceRemaining: null,
                    initialDeposit: null,
                    createdOn: null,
                    updatedOn: null,
                    inReferenceTo: 'Signed NHL Playing Contract and NHLPA Standard Player Agent Contract',
                    lastUpdateBy: null,
                    invoiceCompany: {
                        invoiceId: null,
                        name: null,
                        email: null,
                        phone: null,
                        phoneExtension: null,
                        address: null,
                        addressAdditional: null,
                        city: null,
                        state: null,
                        zip: null
                    },
                    invoiceContact: {
                        invoiceId: null,
                        firstName: null,
                        lastName: null,
                        title: null,
                        email: null,
                        phone: null,
                        phoneExtension: null,
                        address: null,
                        addressAdditional: null,
                        city: null,
                        state: null,
                        zip: null
                    },
                    linkedInvoices: [],
                    invoiceItems: [],
                    invoiceNotes: [],
                    nhlSalaryDetails: {
                        baseSalary: null,
                        payableDays: null,
                        firstPayPeriod: null,
                        lastPayPeriod: null
                    },
                    ahlSalaryDetails: {
                        baseSalary: null,
                        payableDays: null,
                        firstPayPeriod: null,
                        lastPayPeriod: null
                    },
                    echlSalaryDetails: {
                        baseSalary: null,
                        payableDays: null
                    }
                },
                invoicesWithBalances: [],
                invoicesWithBalancesSelects: [],
                invoiceTabs: [
                    {
                        title: 'Information',
                        content: 'invoiceinformation'
                    },
                    {
                        title: 'Items',
                        content: 'invoiceitems'
                    },
                    {
                        title: 'Active Contracts',
                        content: 'contracts'
                    }
                ],
                isLoading: false,
                isLoadingContracts: false,
                nhlContractAmountField: '',
                notificationMsg: '',
                playingContract: null,
                playingContractSalaryDetails: [],
                playingContractYear: null,
                selectedActiveContract: null,
                selectedContractBonusTab: 0,
                selectedContractAssociatedContract: null,
                selectedContractItem: { title: 'Select a Contract', id: 1 },
                selectedContractItemModel: { title: 'Select a Contract', id: 1 },
                selectedPlayer: { id: null, fullName: 'Select Player' },
                selectedPlayerContracts: [],
                selectedTab: 0,
                showCreateInvoicePlayer: false,
                showErrorDialog: false,
                showSalaryDetails: false,
                successNotification: false,
                tools: [
                    ["Bold", "Italic", "Underline"],
                    ["Undo", "Redo"],
                    ["Link", "Unlink"],
                    ["AlignLeft", "AlignCenter", "AlignRight"],
                    ["OrderedList", "UnorderedList", "Indent", "Outdent"],
                ]
            }
        },
        computed: {
            agencyContractType() {
                return contractHelpers.agencyContractType;
            },
            ahlNhlTwoWayContractType() {
                return contractHelpers.ahlNhlTwoWayContractType;
            },
            ahlOneWayContractType() {
                return contractHelpers.ahlOneWayContractType;
            },
            ahlTwoWayContractType() {
                return contractHelpers.ahlTwoWayContractType;
            },
            echlContractType() {
                return contractHelpers.echlContractType;
            },
            elcContractType() {
                return contractHelpers.elcContractType;
            },
            endorsementContractType() {
                return contractHelpers.endorsementContractType;
            },
            equipmentContractType() {
                return contractHelpers.equipmentContractType;
            },
            invoiceItemCategoryBalanceRemaining() {
                return invoiceHelpers.invoiceItemCategoryBalanceRemaining;
            },
            invoiceItemCategoryCredit() {
                return invoiceHelpers.invoiceItemCategoryCredit;
            },
            invoiceItemCategoryEndorsementFee() {
                return invoiceHelpers.invoiceItemCategoryEndorsementFee;
            },
            invoiceItemCategoryEscrow() {
                return invoiceHelpers.invoiceItemCategoryEscrow;
            },
            invoiceItemCategoryEscrowBonus() {
                return invoiceHelpers.invoiceItemCategoryEscrowBonus;
            },
            invoiceItemCategoryExpense() {
                return invoiceHelpers.invoiceItemCategoryExpense;
            },
            invoiceItemCategoryManagementFee() {
                return invoiceHelpers.invoiceItemCategoryManagementFee;
            },
            invoiceItemCategoryMiscellaneous() {
                return invoiceHelpers.invoiceItemCategoryMiscellaneous;
            },
            invoiceItemCategorySelects() {
                return invoiceHelpers.invoiceItemCategorySelects;
            },
            invoiceItemManagementFeeNameSelects() {
                return invoiceHelpers.invoiceItemManagementFeeNameSelects;
            },
            invoiceItemNameSigningBonus() {
                return invoiceHelpers.invoiceItemNameSigningBonus;
            },
            invoiceItemNameNHLSalaryDays() {
                return invoiceHelpers.invoiceItemNameNHLSalaryDays;
            },
            invoiceItemNameAHLSalaryDays() {
                return invoiceHelpers.invoiceItemNameAHLSalaryDays;
            },
            invoiceItemNameECHLSalaryDays() {
                return invoiceHelpers.invoiceItemNameECHLSalaryDays;
            },
            invoiceItemNamePerformanceBonus() {
                return invoiceHelpers.invoiceItemNamePerformanceBonus;
            },
            invoiceItemNameNHLAHLSalaryDays() {
                return invoiceHelpers.invoiceItemNameNHLAHLSalaryDays;
            },
            nhlOneWayContractType() {
                return contractHelpers.nhlOneWayContractType;
            },
            nhlTwoWayContractType() {
                return contractHelpers.nhlTwoWayContractType;
            },
            playingContractType() {
                return contractHelpers.playingContractType;
            },
            showAhlSalaryDetails() {
                return this.playingContract && (this.playingContract.nhlContractType === this.ahlNhlTwoWayContractType || this.playingContract.nhlContractType === this.ahlOneWayContractType || this.playingContract.nhlContractType === this.elcContractType || this.playingContract.nhlContractType === this.ahlTwoWayContractType);
            },
            showEchlSalaryDetails() {
                return this.playingContract && (this.playingContract.nhlContractType === this.echlContractType || this.playingContract.nhlContractType === this.ahlTwoWayContractType);
            },
            showNhlSalaryDetails() {
                return this.playingContract && (this.playingContract.nhlContractType === this.ahlNhlTwoWayContractType || this.playingContract.nhlContractType === this.nhlOneWayContractType || this.playingContract.nhlContractType === this.elcContractType);
            },
            showSalaryBufferColumn() {
                return [this.showAhlSalaryDetails, this.showEchlSalaryDetails, this.showNhlSalaryDetails].filter(item => item).length === 1;
            },
            stateSelectsAbbr() {
                return invoiceHelpers.stateSelectsAbbr;
            }
        },
        validations() {
            return {
                selectedPlayer: {
                    id: { required}
                },
                invoice: {
                    invoiceDate: { required },
                    invoiceNo: { required },
                    paymentDue: { required },
                    invoiceCompany: {
                        phone: { phoneValidator }
                    }
                }
            }
        },
        watch: {
            'invoice.invoiceItems': {
                handler(val) {
                    this.calculateItemsAmountDue();
                },
                deep: true
            },
            'invoice.nhlSalaryDetails': {
                handler(val) {
                    if (!val) return;

                    if (val.baseSalary && val.payableDays) {
                        this.invoice.nhlSalaryDetails.dailySalary = parseInt(val.baseSalary) / parseInt(val.payableDays);
                    } else {
                        this.invoice.nhlSalaryDetails.dailySalary = null;
                    }

                    this.calculateItemsAmountDue();
                },
                deep: true
            },
            'invoice.ahlSalaryDetails': {
                handler(val) {
                    if (!val) return;

                    if (val.baseSalary && val.payableDays) {
                        this.invoice.ahlSalaryDetails.dailySalary = parseInt(val.baseSalary) / parseInt(val.payableDays);
                    } else {
                        this.invoice.ahlSalaryDetails.dailySalary = null;
                    }

                    this.calculateItemsAmountDue();
                },
                deep: true
            },
            'invoice.echlSalaryDetails': {
                handler(val) {
                    if (!val) return;

                    if (val.baseSalary && val.payableDays) {
                        this.invoice.echlSalaryDetails.dailySalary = parseInt(val.baseSalary) / parseInt(val.payableDays);
                    } else {
                        this.invoice.echlSalaryDetails.dailySalary = null;
                    }

                    this.calculateItemsAmountDue();
                },
                deep: true
            },
            showDialog(val) {
                if (val) {
                    this.showCreateInvoicePlayer = true;
                    this.getPlayers();
                    this.getSeasonalSettings();
                } else {
                    this.resetDefaults();
                }
            }
        },
        methods: {
            addDefaultAmountDue(itemIndex) {
                let selectedInvoice = this.invoicesWithBalances.find(invoice => invoice.label === this.invoice.invoiceItems[itemIndex].name);

                if (selectedInvoice) {
                    this.invoice.invoiceItems[itemIndex].playerEarnings = selectedInvoice.balanceRemaining;
                    this.invoice.invoiceItems[itemIndex].comments = selectedInvoice.id;
                } else {
                    this.invoice.invoiceItems[itemIndex].playerEarnings = null;
                    this.invoice.invoiceItems[itemIndex].comments = null;
                }
            },
            addInvoiceNote() {
                let noteHtml = this.$refs.noteseditor.getHTML();
                let noteText = noteHtml.replace(/<[^>]+>/g, '');

                if (noteText && noteText.trim() !== 'Add your note here!') {

                    let note = {
                        notes: noteHtml,
                        invoiceId: null,
                        noteId: null,
                        userName: auth.user()?.name
                    };

                    this.invoice.invoiceNotes.unshift(note);
                    this.$refs.noteseditor.setHTML(this.defaultEditorContent);
                }
            },
            calculateItemQuantity(item) {
                if (!item.category) return item.quantity ? item.quantity : 0;

                if (item.category !== this.invoiceItemCategoryManagementFee) return item.quantity ? item.quantity : 1;

                if (item.name && (item.name === this.invoiceItemNamePerformanceBonus || item.name === this.invoiceItemNameSigningBonus)) return item.quantity ? item.quantity : 1;

                return item.quantity ? item.quantity : 0;
            },
            calculateItemsAmountDue() {
                let totalDue = 0;

                this.invoice.invoiceItems.forEach(item => {
                    let rate = item.rate ?? 100;
                    let qty = this.calculateItemQuantity(item);

                    let amountDue = 0;
                
                    if (item.category === this.invoiceItemCategoryEscrow) {
                        let escrowItem = this.invoice.invoiceItems.find(i => i.category === this.invoiceItemCategoryManagementFee && i.name === this.invoiceItemNameNHLSalaryDays);
                        
                        if (escrowItem) {
                            item.rate = this.$store.state.seasonalSettings.escrowPercentage;
                            item.playerEarnings = -(escrowItem.playerEarnings * (this.$store.state.seasonalSettings.escrowPercentage / 100));
                            amountDue = (item.playerEarnings * (escrowItem.rate / 100));
                        } else {
                            item.playerEarnings = 0;
                        }
                    } else {
                        if (item.category === this.invoiceItemCategoryEscrowBonus) {
                            let bonusEscrowItem = this.invoice.invoiceItems.find(i => i.category === this.invoiceItemCategoryManagementFee && i.name === this.invoiceItemNameSigningBonus);

                            if (bonusEscrowItem) {
                                item.rate = this.$store.state.seasonalSettings.escrowPercentage;
                                item.playerEarnings = -(bonusEscrowItem.playerEarnings * (this.$store.state.seasonalSettings.escrowPercentage / 100));
                                amountDue = (item.playerEarnings * (bonusEscrowItem.rate / 100));
                            } else {
                                item.playerEarnings = 0;
                            }
                        } else {
                            if (item.category === this.invoiceItemCategoryEndorsementFee && this.agencyContract && this.agencyContract.endorsementCommissionPercent) {
                                item.rate = item.rate ?? this.agencyContract.endorsementCommissionPercent;
                            }

                            if (item.category === this.invoiceItemCategoryManagementFee && this.agencyContract && this.agencyContract.playingCommissionPercent) {
                                item.rate = item.rate ?? this.agencyContract.playingCommissionPercent;
                            }

                            if (item.category === this.invoiceItemCategoryManagementFee && (item.name === this.invoiceItemNameNHLSalaryDays || item.name === this.invoiceItemNameNHLAHLSalaryDays) && this.selectedPlayer.id) {
                                if (this.invoice.nhlSalaryDetails.dailySalary) {
                                    item.playerEarnings = this.invoice.nhlSalaryDetails.dailySalary * item.quantity;
                                }
                                
                                amountDue = item.playerEarnings * ((item.rate || rate) / 100);
                                item.description = item.description ?? `${item.quantity} Days`;
                            }

                            if (item.category === this.invoiceItemCategoryManagementFee && item.name === this.invoiceItemNameAHLSalaryDays && this.selectedPlayer.id) {
                                if (this.invoice.ahlSalaryDetails.dailySalary) {
                                    item.playerEarnings = this.invoice.ahlSalaryDetails.dailySalary * item.quantity;
                                }

                                amountDue = item.playerEarnings * ((item.rate || rate) / 100);
                                item.description = item.description ?? `${item.quantity} Days`;
                            }

                            if (item.category === this.invoiceItemCategoryManagementFee && item.name === this.invoiceItemNameECHLSalaryDays && this.selectedPlayer.id) {
                                if (this.invoice.echlSalaryDetails.dailySalary) {
                                    item.playerEarnings = this.invoice.echlSalaryDetails.dailySalary * item.quantity;
                                }

                                amountDue = item.playerEarnings * ((item.rate || rate) / 100);
                                item.description = item.description ?? `${item.quantity} Days`;
                            }

                            if (item.playerEarnings && item.category !== this.invoiceItemCategoryEscrow && item.category !== this.invoiceItemCategoryEscrowBonus) {
                                if (item.category !== this.invoiceItemCategoryManagementFee || (item.category === this.invoiceItemCategoryManagementFee && (item.name !== this.invoiceItemNameNHLSalaryDays && item.name !== this.invoiceItemNameNHLAHLSalaryDays && item.name !== this.invoiceItemNameAHLSalaryDays && item.name !== this.invoiceItemNameECHLSalaryDays))) {
                                    amountDue = (item.playerEarnings * (rate / 100)) * qty;
                                } 
                            }
                        }
                    }
                    
                    item.amountDue = amountDue
                    item.quantity = qty;

                    totalDue += item.amountDue;
                });

                    this.invoice.totalDue = totalDue;
                    this.invoice.totalDueThisInvoice = totalDue;
            },
            calculateQuantityDisplay(item) {
                if (item.category !== this.invoiceItemCategoryManagementFee) return 'Qty';

                if (item.name === this.invoiceItemNameNHLSalaryDays || item.name === this.invoiceItemNameAHLSalaryDays || item.name === this.invoiceItemNameNHLAHLSalaryDays || item.name === this.invoiceItemNameECHLSalaryDays) return 'Days';

                return 'Qty';
            },
            async createInvoice(request) {
                let self = this;

                this.isLoading = true;
                this.$emit('dialogclosed');

                let tokenResponse = await auth.acquireTokenResponse();

                return apiService.CreateInvoice(tokenResponse.accessToken, request) 
                    .then(async () => {
                        await this.$store.dispatch('getInvoices', true);

                        self.isLoading = false;
                        self.showNotification('Invoice created successfully');

                        self.$emit('invoicecreated');
                    }).catch(error => {
                        console.log(error);
                        self.onRequestError('An Error Occurred', error?.response?.data?.message);
                    });
            },
            defaultDropdownSelected(evt, field, defaultValue) {
                let fields = field.split('.');

                if (fields.length === 2) {
                    if (evt.target.value === defaultValue) {
                        return this.invoice[fields[0]][fields[1]] = null;
                    }

                    this.invoice[fields[0]][fields[1]] = evt.target.value;
                } else {
                    if (evt.target.value === defaultValue) {
                        return this.invoice[field] = null;
                    }

                    this.invoice[field] = evt.target.value;
                }
            },
            defaultIndexedDropdownSelected(evt, field, defaultValue, index) {
                let fields = field.split('.');

                if (fields.length === 2) {
                    if (evt.target.value === defaultValue) {
                        this.invoice[fields[0]][index][fields[1]] = null;
                    } else {
                        this.invoice[fields[0]][index][fields[1]] = evt.target.value;
                    }

                    if (fields[0] === 'invoiceItems' && fields[1] === 'name' && this.invoice.invoiceItems[index].category === this.invoiceItemCategoryBalanceRemaining) {
                        this.invoice[fields[0]][index].status = this.invoicesWithBalances.find(invoice => invoice.label === this.invoice[fields[0]][index].name).id;
                        this.addDefaultAmountDue(index);
                    }
                } else {
                    if (evt.target.value === defaultValue) {
                        return this.invoice[field][index] = null;
                    }

                    this.invoice[field][index] = evt.target.value;
                }
            },
            formatContactPhoneNumberInput() {
                if (!this.invoice.invoiceCompany.phone) return;

                const phoneNumber = this.invoice.invoiceCompany.phone.replace(/[^\d]/g, '');
                const phoneNumberLength = phoneNumber.length;

                if (phoneNumberLength < 4) return this.invoice.invoiceCompany.phone = phoneNumber;

                if (phoneNumberLength < 7) {
                    return this.invoice.invoiceCompany.phone = `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
                }

                this.invoice.invoiceCompany.phone = `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6, 10)}`;

            },
            async getContractDetail() {
                let self = this;
                self.isLoading = true;

                let tokenResponse = await auth.acquireTokenResponse();

                return apiService.GetContractDetail(tokenResponse.accessToken, this.selectedContractItem.id)
                    .then(res => {
                        if (res.data.data) {
                            self.selectedActiveContract = res.data.data;

                            self.selectedActiveContract.endorsementPayouts.sort((a, b) => new Date(b.payoutDate) - new Date(a.payoutDate));
                            self.selectedActiveContract.elcPerformanceBonuses.sort((a, b) => new Date(b.bonusDate) - new Date(a.bonusDate));
                            self.selectedActiveContract.nhlSigningBonuses.sort((a, b) => new Date(b.bonusDate) - new Date(a.bonusDate));
                            self.selectedActiveContract.performanceBonuses.sort((a, b) => new Date(b.bonusDate) - new Date(a.bonusDate));

                            self.selectedActiveContract.ahlNhlTwoWayContractDetails.sort((a, b) => a.year - b.year);
                            self.selectedActiveContract.ahlOneWayContractDetails.sort((a, b) => a.year - b.year);
                            self.selectedActiveContract.echlContractDetails.sort((a, b) => a.year - b.year);
                            self.selectedActiveContract.elcContractDetails.sort((a, b) => a.year - b.year);
                            self.selectedActiveContract.nhlOneWayContractDetails.sort((a, b) => a.year - b.year);

                            if (self.selectedActiveContract.contract.contractType != self.agencyContractType) {
                                self.selectedContractAssociatedContract = self.selectedPlayerContracts.find(contract => contract.contractId === self.selectedActiveContract.contract.associatedContractId);
                            }

                            self.isLoading = false;
                        } else {
                            self.onRequestError('An Error Occurred', 'Contract detail unavailable');
                        }
                    }).catch(error => {
                        console.log(error);
                        this.onRequestError('An Error Occurred', error?.response?.data?.message);
                    });
            }, 
            getInvoiceDefaults() {
                this.invoice = cloneDeep(this.invoiceModel);

                let today = new Date();
                const currentYear = today.getFullYear();
                let invoicesThisYear = this.$store.state.invoices.filter(invoice => invoice.invoiceNo.split(' - ')[0] === currentYear.toString());
                let invoiceNumber = '0001';

                if (invoicesThisYear.length > 0) {
                    invoiceNumber = ((invoicesThisYear.length + 1).toString()).padStart(4, '0')
                } 

                this.invoice.invoiceNo = `${currentYear} - ${invoiceNumber}`;

                let defaultDueDate = today.setDate(today.getDate() + 30);

                this.invoice.paymentDue = new Date(defaultDueDate);
            },
            async getInvoicesWithBalances(playerId) {
                await this.$store.dispatch('getInvoices', false);

                this.invoicesWithBalances = cloneDeep(this.$store.state.invoices
                    .filter(invoice => invoice.playerId === playerId && invoice.balanceRemaining && invoice.balanceRemaining > 0)
                    .map(invoice => {
                        return {
                            id: invoice.id,
                            invoiceNo: invoice.invoiceNo,
                            balanceRemaining: invoice.balanceRemaining,
                            label: `${invoice.invoiceNo}: ${this.$options.filters.formatCurrencyDec(invoice.balanceRemaining)}`
                        };
                    }));

                this.invoicesWithBalancesSelects = this.invoicesWithBalances.map(invoice => invoice.label);
            },
            async getPlayerContracts(playerId) {
                await this.$store.dispatch('getContracts', false);
                this.selectedPlayerContracts = cloneDeep(this.$store.state.contracts.filter(contract => contract.playerId === playerId && (!contract.expiration_field || contract.expiration_field >= new Date())));
                this.selectedPlayerContracts.sort((a, b) => b.expiration_field - a.expiration_field);

                if (this.selectedPlayerContracts.length > 0) {
                    let agencyContracts = this.selectedPlayerContracts.filter(contract => contract.contractType === this.agencyContractType);

                    if (agencyContracts.length > 0) {
                        if (this.playingContract?.associatedContractId) {
                            this.agencyContract = agencyContracts.find(contract => contract.contractId === this.playingContract.associatedContractId);
                        } else {
                            this.agencyContract = agencyContracts.sort((a, b) => b.spacSigningDate - a.spacSigningDate)[0];
                        }

                        this.selectedPlayerContracts = this.selectedPlayerContracts.filter(contract => contract.contractType !== this.agencyContractType || contract.contractId === this.agencyContract.contractId);
                    }

                    this.contractDropdownItems = this.selectedPlayerContracts.map(contract => {
                        return {
                            title: contract.contractTitle,
                            id: contract.contractId 
                        }
                    });
                }

                this.contractDropdownItems.unshift(this.selectedContractItem);
            },
            async getPlayers(update = false) {   
                let self = this;
                this.isLoading = true;

                let tokenResponse = await auth.acquireTokenResponse();

                return apiService.GetPlayersWithActiveContracts(tokenResponse.accessToken)
                    .then(res => {
                        self.allPlayers = res.data.data;

                        self.allPlayers.forEach(player => {
                            player.fullName = `${player.firstName} ${player.lastName}`;
                        });

                        self.allPlayers.sort((a, b) => a.fullName.localeCompare(b.fullName));

                        self.allPlayers.unshift({ id: null, fullName: 'Select Player' });

                        self.filteredPlayers = cloneDeep(this.allPlayers); 

                        self.isLoading = false;
                    }).catch(error => {
                        console.log(error);
                        self.onRequestError('An Error Occurred', error?.response?.data?.message);
                    });
            },
            async getPlayingContract(playerId) {
                let self = this;

                let tokenResponse = await auth.acquireTokenResponse();

                return apiService.GetPlayingContract(tokenResponse.accessToken, playerId) 
                    .then(async (res) => {
                        self.playingContract = res.data.data.contract;

                        self.playingContractSalaryDetails = self.getPlayingContractSalaryDetails(res.data.data);
                        self.playingContractSalaryDetails.sort((a, b) => a.year - b.year);
                    }).catch(error => {
                        console.log(error);
                        self.onRequestError('An Error Occurred', error?.response?.data?.message);
                    });
            },
            getPlayingContractSalaryDetails(data) {
                if (!this.playingContract) return [];

                switch(this.playingContract.nhlContractType) {
                    case this.ahlNhlTwoWayContractType:
                        this.ahlContractAmountField = 'ahlTwoWayAmount';
                        this.nhlContractAmountField = 'nhlTwoWayAmount';
                        return data.ahlNhlTwoWayContractDetails;
                    case this.ahlOneWayContractType:
                        this.ahlContractAmountField = 'ahlOneWayAmount';
                        return data.ahlOneWayContractDetails;
                    case this.ahlTwoWayContractType:
                        this.ahlContractAmountField = 'ahlTwoWayAmount';
                        this.echlContractAmountField = 'echlTwoWayAmount';
                        return data.ahlTwoWayContractDetails;
                    case this.nhlOneWayContractType:
                        this.nhlContractAmountField = 'nhlOneWayAmount';
                        return data.nhlOneWayContractDetails;
                    case this.nhlTwoWayContractType:
                        return data.nhlTwoWayContractDetails;
                    case this.echlContractType:
                        this.echlContractAmountField = 'echlAmount';
                        return data.echlContractDetails;
                    case this.elcContractType:
                        this.ahlContractAmountField = 'ahlSalary';
                        this.nhlContractAmountField = 'nhlSalary';
                        return data.elcContractDetails;
                }
            },
            getPlayingContractYear () {
                if (!this.playingContract) return;

                let yearsDiff = (date.subtract(new Date(), new Date(this.playingContract.nhlContractStartYear)).toDays()) / 365;

                return this.playingContractSalaryDetails.find(detail => detail.year === Math.ceil(Math.abs(yearsDiff)));
            },
            async getSeasonalSettings(update = false) {
                await this.$store.dispatch('getSeasonalSettings', update);
            },
            goToSelectedContract() {
                this.$router.push(`/ContractDetail?id=${this.selectedActiveContract.contract.contractId}`);
            },
            onAddInvoiceItem() {
                let newItem = cloneDeep(this.invoiceItemModel);
                newItem.itemId = Math.random().toString();

                this.invoice.invoiceItems.unshift(newItem);
            },
            onBackToInvoicePlayer() {
                this.resetDefaults();
            },
            onCloseDialog() {
                this.$emit('dialogclosed');
            },
            onCloseNotification(flag) {
                this[flag] = false;
            },
            onContractBonusTabSelected(evt) {
                this.selectedContractBonusTab = evt.selected;
            },
            onDeleteInvoiceItem(item) {
                this.invoice.invoiceItems.splice(this.invoice.invoiceItems.findIndex(i => i.itemId === item.itemId), 1);
            },
            async onGetContractDetail() {
                if (this.selectedContractItem.title === 'Select a Contract') return;

                await this.getContractDetail();
            },
            onEditorFocus() {
                let noteHtml = this.$refs.noteseditor.getHTML();
                let noteText = noteHtml.replace(/<[^>]+>/g, '');

                if (noteText && noteText.trim() === this.defaultEditorContent) {
                    this.$refs.noteseditor.setHTML('');
                }
            },
            async onInvoicePlayerSelected(evt) {
                if (evt.value.fullName === 'Select Player') {
                    this.invoice.playerId = null;
                    return this.invoice$.selectedPlayer.$validate();
                }

                this.invoice$.selectedPlayer.id.$reset();

                this.getInvoiceDefaults();

                let player = this.allPlayers.find(player => player.id === evt.value.id);

                this.invoice.playerId = evt.value.id;
                this.invoice.invoiceCompany.address = player.billingAddress;
                this.invoice.invoiceCompany.addressAdditional = player.billingContactAddressAdditional;
                this.invoice.invoiceCompany.phone = player.billingContactPhone;
                this.invoice.invoiceCompany.phoneExtension = player.billingContactPhoneExt;
                this.invoice.invoiceCompany.city = player.billingContactCity;
                this.invoice.invoiceCompany.state = player.billingContactState;
                this.invoice.invoiceCompany.zip = player.billingContactZip;
                this.invoice.invoiceCompany.name = player.fullName;
                
                this.invoice.invoiceContact.firstName = player.firstName;
                this.invoice.invoiceContact.lastName = player.lastName;
                this.invoice.invoiceContact.email = player.billingContactEmail;

                this.isLoadingContracts = true;
                await this.getPlayingContract(player.id);
                await this.getPlayerContracts(player.id);
                await this.getInvoicesWithBalances(player.id);
                this.isLoadingContracts = false;

                let currentSalaryDetails = this.getPlayingContractYear();

                if (currentSalaryDetails) {
                    if (this.showNhlSalaryDetails) {
                        this.invoice.nhlSalaryDetails.baseSalary = currentSalaryDetails[this.nhlContractAmountField]; 
                        this.invoice.nhlSalaryDetails.payableDays = this.$store.state.seasonalSettings.nhlPlayableDays;
                    }

                    if (this.showAhlSalaryDetails) {
                        this.invoice.ahlSalaryDetails.baseSalary = currentSalaryDetails[this.ahlContractAmountField] 
                        this.invoice.ahlSalaryDetails.payableDays = this.$store.state.seasonalSettings.ahlPlayableDays;
                    }

                    if (this.showEchlSalaryDetails) {
                        this.invoice.echlSalaryDetails.baseSalary = currentSalaryDetails[this.echlContractAmountField];
                        this.invoice.echlSalaryDetails.payableDays = this.$store.state.seasonalSettings.echlPlayableDays;
                    }
                }

                this.showCreateInvoicePlayer = false;
            },
            onPlayerFilterChange(evt) {
                this.filteredPlayers = filterBy(this.allPlayers, evt.filter);
            },
            onRequestError(errorTitle, errorMsg) {
                this.isLoading = false;
                this.isLoadingContracts = false;

                this.errorTitle = errorTitle;

                if (errorMsg) {
                    this.errorMsg = errorMsg;
                } else {
                    this.errorMsg = 'Please try again';
                }

                this.showErrorDialog = true;
            },
            async onSaveClicked() {
                await this.invoice$.$validate();

                if (this.invoice$.selectedPlayer.$error || this.invoice$.invoice.$error) return;

                this.invoice.playerId = this.selectedPlayer.id;

                this.invoice.lastUpdateBy = auth.user()?.name;
                
                this.createInvoice({
                    invoice: cloneDeep(this.invoice),
                    ahlSalaryDetails: cloneDeep(this.invoice.ahlSalaryDetails),
                    echlSalaryDetails: cloneDeep(this.invoice.echlSalaryDetails),
                    nhlSalaryDetails: cloneDeep(this.invoice.nhlSalaryDetails),
                    invoiceContact: cloneDeep(this.invoice.invoiceContact),
                    invoiceCompany: cloneDeep(this.invoice.invoiceCompany),
                    invoiceItems: cloneDeep(this.invoice.invoiceItems.filter(item => item.category)),
                    invoiceNotes: cloneDeep(this.invoice.invoiceNotes)
                });

            },
            onTabSelected(evt) {
                this.selectedTab = evt.selected;
            },
            resetDefaults() {
                this.invoice$.$reset();

                this.showCreateInvoicePlayer = true;

                this.selectedPlayer = { id: null, fullName: 'Select Player' };
                this.invoice = cloneDeep(this.invoiceModel);
                
                this.selectedContractItem = cloneDeep(this.selectedContractItemModel);
                this.selectedActiveContract = null;

                this.selectedTab = 0;
            },
            showNotification(notificationMsg) {
                this.notificationMsg = notificationMsg;

                this.successNotification = true;

                setTimeout(() => {
                    this.successNotification = false;
                }, 4000);
            },
            toggleErrorDialog() {
                this.showErrorDialog = !this.showErrorDialog;

                if(!this.showErrorDialog) {
                    this.errorTitle = '';
                    this.errorMsg = '';
                }
            },
            toggleSalaryDetails() {
                this.showSalaryDetails = !this.showSalaryDetails;
            }
        },
        filters: {
            formatCurrencyDec(val) {
                if (val === null || val === undefined || val === '') return '';

                if (val.toString().includes('$')) return val;

                if (isNaN(val)) {
                    val = 0;
                }

                let formatter = new Intl.NumberFormat('en-US', {
                    style: 'currency',
                    currency: 'USD',
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                });

                return formatter.format(val);
            }
        },
        components: {
            'k-dialog': Dialog,
            'k-action-bar': DialogActionsBar,
            'k-loader': Loader,
            'k-notification': Notification,
            'k-notification-group': NotificationGroup,
            'k-fade': Fade,
            'k-button': Button,
            'k-dropdown': DropDownList,
            'k-datepicker': DatePicker,
            'k-input': Input,
            'k-numeric-input': NumericTextBox,
            'k-editor': Editor,
            'k-tabstrip': TabStrip,
            'k-slide': Slide,
            'k-textarea': TextArea
        }
    }
</script>

<style>
    @import '/src/assets/css/defaultUpsertStyles.css';
    @import '/src/assets/css/createInvoiceDialog.css';
</style>