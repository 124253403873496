<template>
    <div class="upsert-player-association">
        <transition name="dialogfade">
            <k-dialog v-if="showDialog" class="default__dialog upsert-player-association-dialog">
                <div class="icon__container">
                    <i class="fa-solid fa-people-group"></i>
                </div>
                <div class="header__container">
                    <svg viewBox="0 0 300 50" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" class="icon__top-bar" clip-rule="evenodd" d="M10 0C4.47715 0 0 4.47715 0 10V50H300V10C300 4.47715 295.523 0 290 0H184.388C184.388 9.01736 180.732 17.6654 174.226 24.0416C167.72 30.4179 158.895 34 149.694 34C140.492 34 131.668 30.4179 125.161 24.0416C118.655 17.6654 115 9.01737 115 5.13386e-06L149.694 0H10Z" fill="#D4B139"></path></svg>
                </div>
                <div class="create-document__header">
                    <h1 class="dialog--title">{{ editMode ? 'Edit Association' : 'Create Association' }}</h1>
                </div>
                <div class="upsert-item__container create-association">
                    <section class="upsert-item__section">
                        <div class="input__row">
                            <label class="input__label">
                                Relationship
                                <k-input v-model="association.relationship" />
                            </label>
                        </div>
                        <div class="input__row pair">
                            <label class="input__label">
                                First Name
                                <k-input v-model="association.firstName" />
                            </label>
                            <label class="input__label">
                                Last Name
                                <k-input v-model="association.lastName" />
                            </label>
                        </div>
                        <div class="input__row pair">
                            <label class="input__label">
                                Phone
                                <k-input v-model="association.phone" @keydown="formatContactPhoneNumberInput" @input="association$.association.phone.$reset()" :class="[{ 'error': association$.association.phone.$error }]"/>
                                <span class="item-input--vuelidate-error" v-if="association$.association.phone.$error"><i>Phone number is invalid</i></span>
                            </label>
                            <label class="input__label">
                                Email
                                <k-input v-model="association.email" @keydown="association$.association.email.$reset()" :class="[{ 'error': association$.association.email.$error }]" />
                                <span class="item-input--vuelidate-error" v-if="association$.association.email.$error"><i>Email is invalid</i></span>
                            </label>
                        </div>
                        <div class="input__row">
                            <label class="input__label">
                                Street Address
                                <k-input v-model="association.address" />
                            </label>
                        </div>
                        <div class="input__row trio">
                            <label class="input__label">
                                City
                                <k-input v-model="association.city" />
                            </label>
                            <label class="input__label">
                                State
                                <k-input v-model="association.state" />
                            </label>
                            <label class="input__label">
                                Zip
                                <k-input v-model="association.zip" />
                            </label>
                        </div>
                    </section>
                    <k-action-bar>
                        <k-button class="item-save__btn" @click="onSaveClicked">Save</k-button>
                        <k-button class="item-cancel__btn" @click="onCancelClicked">Cancel</k-button>
                    </k-action-bar>
                </div>
            </k-dialog>
        </transition>

        <div class="loader-container" v-if="isLoading">
            <k-loader :size="'large'" :type="'infinite-spinner'"></k-loader>
        </div>

        <transition name="dialogfade">
            <k-dialog v-if="showErrorDialog" :title="errorTitle" class="error__dialog" @close="toggleErrorDialog">
                <p class="error-dialog__msg">{{ errorMsg }}</p>
            </k-dialog>
        </transition>

        <k-notification-group class="default-notification-container">
                <k-fade :appear="successNotification">
                    <k-notification v-if="successNotification"
                                    :type="{ style: 'success', icon: true }"
                                    :closable="true"
                                    @close="onCloseNotification('successNotification')">
                        <span>{{ notificationMsg }}</span>
                    </k-notification>
                </k-fade>
        </k-notification-group>
    </div>
</template>

<script>
    import { Dialog, DialogActionsBar } from '@progress/kendo-vue-dialogs';
    import { Button } from "@progress/kendo-vue-buttons";
    import { Loader } from "@progress/kendo-vue-indicators";
    import { Notification, NotificationGroup } from "@progress/kendo-vue-notification";
    import { Fade } from "@progress/kendo-vue-animation";
    import { Input } from "@progress/kendo-vue-inputs";

    import { useVuelidate } from '@vuelidate/core';
    import { email } from '@vuelidate/validators';

    import cloneDeep from 'lodash.clonedeep';

    import auth from '../authConfig';
    import apiService from '../api/api.service.js';

    const phoneValidator = (val) => {
        if (!val) return true;

        const phoneRegEx = `^((\\(\\d{3}\\))|\\d{3})[- ]?\\d{3}[- ]?\\d{4}$`;
        return val.match(phoneRegEx);
    }

    export default {
        setup() {
            return { association$: useVuelidate() }
        },
        name: 'upsertPlayerAssociationDialog',
        props: ['addMode', 'editedAssociation', 'editMode', 'playerId', 'showDialog'],
        emits: ['dialogclosed', 'associationsupdated'],
        data () {
            return {
                association: {},
                associationModel: {
                    firstName: null,
                    lastName: null,
                    email: null,
                    phone: null,
                    address: null,
                    city: null,
                    state: null,
                    zip: null,
                    relationship: null,
                    playerId: null
                },
                errorMsg: '',
                errorTitle: '',
                isLoading: false,
                notificationMsg: '',
                showErrorDialog: false,
                successNotification: false
            }
        },
        validations() {
            return {
                association: {
                    email: { email },
                    phone: { phoneValidator }
                }
            }
        },
        watch: {
            showDialog(val) {
                if (val) {
                    this.assignAssociation();
                } else {
                    this.resetDefaults();
                }
            }
        },
        methods: {
            assignAssociation() {
                if (this.editMode) {
                    return this.association = cloneDeep(this.editedAssociation);
                }

                this.association = cloneDeep(this.associationModel);
                this.association.playerId = this.playerId;
            },
            formatContactPhoneNumberInput() {
                if (!this.association.phone) return;

                const phoneNumber = this.association.phone.replace(/[^\d]/g, '');
                const phoneNumberLength = phoneNumber.length;

                if (phoneNumberLength < 4) return this.association.phone = phoneNumber;

                if (phoneNumberLength < 7) {
                    return this.association.phone = `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
                }

                this.association.phone = `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6, 10)}`;
            },
            onCancelClicked() {
                this.$emit('dialogclosed');
            }, 
            onCloseNotification(flag) {
                this[flag] = false;
            },
            onRequestError(errorTitle, errorMsg) {
                this.isLoading = false;

                this.errorTitle = errorTitle;

                if (errorMsg) {
                    this.errorMsg = errorMsg;
                } else {
                    this.errorMsg = 'Please try again';
                }

                this.showErrorDialog = true;
            },
            async onSaveClicked() {
                await this.association$.$validate();

                if (this.association$.association.$error) return;

                this.association$.$reset();

                let request = {
                    association: cloneDeep(this.association),
                    edit: this.editMode
                }

                this.upsertPlayerAssociation(request);
            },
            resetDefaults() {
                this.association = {};
            },
            showNotification(notificationMsg) {
                this.notificationMsg = notificationMsg;

                this.successNotification = true;

                setTimeout(() => {
                    this.successNotification = false;
                }, 4000);
            },
            toggleErrorDialog() {
                this.showErrorDialog = !this.showErrorDialog;

                if(!this.showErrorDialog) {
                    this.errorTitle = '';
                    this.errorMsg = '';
                }
            },
            async upsertPlayerAssociation(request) {
                let self = this;

                this.isLoading = true;
                this.$emit('dialogclosed');

                let tokenResponse = await auth.acquireTokenResponse();

                return apiService.UpsertPlayerAssociation(tokenResponse.accessToken, request) 
                    .then((res) => {
                        self.isLoading = false;
                        self.showNotification('Family updated successfully');

                        self.$emit('associationsupdated', res.data.data);
                    }).catch(error => {
                        console.log(error);
                        self.onRequestError('An Error Occurred', error?.response?.data?.message);
                    });
            }
        },
        components: {
            'k-dialog': Dialog,
            'k-action-bar': DialogActionsBar,
            'k-button': Button,
            'k-loader': Loader,
            'k-notification': Notification,
            'k-notification-group': NotificationGroup,
            'k-fade': Fade, 
            'k-input': Input
        }
    }
</script>

<style>
    @import '/src/assets/css/defaultUpsertStyles.css';
    @import '/src/assets/css/upsertPlayerAssociationDialog.css';
</style>