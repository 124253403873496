<template>
    <div class="view-header__container">
        <div class="view-header">
            <div :class="['view-icon', { 'player-pic': playerPic }]">
                <slot name="viewicon"></slot>
            </div>
            <div class="view-title">{{ title }} <span v-if="subtitle" class="view-subtitle">| {{ subtitle }}</span></div>
            <div class="view-header__filters">
                <slot name="viewfilters"></slot>
            </div>
        </div>
        <div class="view-header__actions">
            <slot name="viewactions"></slot>
        </div>
    </div>
</template>

<script>
    export default {
        props: ['playerPic', 'subtitle', 'title'],
        name: 'viewHeader',
        data() {
            return {

            }
        }
    }
</script>

<style>
    @import '/src/assets/css/viewHeader.css';
</style>