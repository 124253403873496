<template>
    <div class="edit-player-document">
        <transition name="dialogfade">
            <k-dialog v-if="showDialog" class="default__dialog edit-player-document-dialog">
                <div class="icon__container">
                    <i class="fa-solid fa-file"></i>
                </div>
                <div class="header__container">
                    <svg viewBox="0 0 300 50" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" class="icon__top-bar" clip-rule="evenodd" d="M10 0C4.47715 0 0 4.47715 0 10V50H300V10C300 4.47715 295.523 0 290 0H184.388C184.388 9.01736 180.732 17.6654 174.226 24.0416C167.72 30.4179 158.895 34 149.694 34C140.492 34 131.668 30.4179 125.161 24.0416C118.655 17.6654 115 9.01737 115 5.13386e-06L149.694 0H10Z" fill="#D4B139"></path></svg>
                </div>
                <div class="create-document__header">
                    <h1 class="dialog--title">Edit Document</h1>
                </div>
                <div class="upsert-item__container">
                    <section class="upsert-item__section">
                        <p class="field--required">*Required</p>
                        <div class="input__row pair">
                            <label class="input__label">
                                *Document Name
                                <k-input v-model="document.documentName" @keydown="document$.document.documentName.$reset()" :class="[{ 'error': document$.document.documentName.$error }]" />
                                <span class="item-input--vuelidate-error" v-if="document$.document.documentName.$error"><i>Name is required</i></span>
                            </label>
                            <label class="input__label">
                                *Upload Documents To
                                <k-dropdown :data-items="documentRootFolders"
                                            :text-field="'name'"
                                            v-model="selectedDocumentFolder"
                                            @change="onDocumentFolderSelected"
                                            :class="['upload-folder__dropdown', { 'error': document$.document.documentFolder.$error }]"></k-dropdown>
                                <span class="item-input--vuelidate-error" v-if="document$.document.documentFolder.$error"><i>Folder is required</i></span>
                            </label>
                        </div>
                        <div class="input__row">
                            <label class="input__label">
                                Document Description
                                <k-input v-model="document.documentDescription" />
                            </label>
                        </div>
                    </section>
                    <k-action-bar>
                        <k-button class="item-save__btn" @click="onSaveClicked" :disabled="saveChangesBtnDisabled">Save</k-button>
                        <k-button class="item-cancel__btn" @click="onCancelClicked">Cancel</k-button>
                    </k-action-bar>
                </div>
            </k-dialog>
        </transition>

        <div class="loader-container" v-if="isLoading">
            <k-loader :size="'large'" :type="'infinite-spinner'"></k-loader>
        </div>

        <transition name="dialogfade">
            <k-dialog v-if="showErrorDialog" :title="errorTitle" class="error__dialog" @close="toggleErrorDialog">
                <p class="error-dialog__msg">{{ errorMsg }}</p>
            </k-dialog>
        </transition>

        <k-notification-group class="default-notification-container">
                <k-fade :appear="successNotification">
                    <k-notification v-if="successNotification"
                                    :type="{ style: 'success', icon: true }"
                                    :closable="true"
                                    @close="onCloseNotification('successNotification')">
                        <span>{{ notificationMsg }}</span>
                    </k-notification>
                </k-fade>
        </k-notification-group>
    </div>
</template>

<script>
    import { Dialog, DialogActionsBar } from '@progress/kendo-vue-dialogs';
    import { Button } from "@progress/kendo-vue-buttons";
    import { Loader } from "@progress/kendo-vue-indicators";
    import { Notification, NotificationGroup } from "@progress/kendo-vue-notification";
    import { Fade } from "@progress/kendo-vue-animation";
    import { Input } from "@progress/kendo-vue-inputs";
    import { DropDownList } from '@progress/kendo-vue-dropdowns';
    
    import cloneDeep from 'lodash.clonedeep';
    import isEqualWith from 'lodash.isequalwith';

    import { useVuelidate } from '@vuelidate/core';
    import { required } from '@vuelidate/validators';

    import auth from '../authConfig';
    import apiService from '../api/api.service.js';

    export default {
        setup() {
            return { document$: useVuelidate() }
        },
        name: 'editPlayerDocumentDialog',
        props: ['editedDocument', 'playerId', 'showDialog'],
        emits: ['dialogclosed', 'documentupdated'],
        data () {
            return {
                document: {},
                documentRootFolders: [],
                errorMsg: '',
                errorTitle: '',
                isLoading: false,
                notificationMsg: '',
                selectedDocumentFolder: null,
                showErrorDialog: false,
                successNotification: false,
            }
        }, 
        validations() {
            return {
                document: {
                    documentName: { required },
                    documentFolder: { required }
                }
            }
        },
        computed: {
            saveChangesBtnDisabled() {
                return this.isEqualWith(this.editedDocument, this.document);
            }
        },
        watch: {
            showDialog(val) {
                if (val) {
                    this.document = cloneDeep(this.editedDocument);
                    this.getDocumentRootFolders();
                } else {
                    this.resetDefaults();
                }
            }
        },
        methods: {
            async getDocumentRootFolders() {
                let self = this;
                self.isLoading = true;

                let tokenResponse = await auth.acquireTokenResponse();

                return apiService.GetDocumentRootFolders(tokenResponse.accessToken)
                    .then((res) => {
                        self.documentRootFolders = res.data.data ?? [];
                        self.selectedDocumentFolder = self.documentRootFolders.find(folder => folder.name === self.document.documentFolder);
                        self.isLoading = false;
                    }).catch(error => {
                        console.log(error);
                    });

            },
            isEqualWith(unedited, edited) {
                return isEqualWith(unedited, edited, (a, b) => {
                    if ((a === null || a === '' || a === 0) && (b === null || b === '' || b === 0)) return true;
                });
            },
            onCancelClicked() {
                this.$emit('dialogclosed');
            }, 
            onCloseNotification(flag) {
                this[flag] = false;
            },
            onDocumentFolderSelected() {
                if (!this.selectedDocumentFolder.id) return;

                this.document$.document.documentFolder.$reset();
                this.document.documentFolder = this.selectedDocumentFolder.name;
            },
            onRequestError(errorTitle, errorMsg) {
                this.isLoading = false;

                this.errorTitle = errorTitle;

                if (errorMsg) {
                    this.errorMsg = errorMsg;
                } else {
                    this.errorMsg = 'Please try again';
                }

                this.showErrorDialog = true;
            },
            async onSaveClicked() {
                await this.document$.$validate();

                if (this.document$.document.$error) return;

                let request = cloneDeep(this.document);

                let fileCodeAndExtension = request.documentPath.split('~')[1];
                request.documentFullName = `${this.document.documentName}~${fileCodeAndExtension}`;
                request.documentPath = `${this.document.documentFolder}/${request.documentName}~${fileCodeAndExtension}`;

                if (this.document.documentFolder !== this.editedDocument.documentFolder) {
                    request.isMoved = true;
                    request.newFolderId = this.selectedDocumentFolder.id;
                }

                if (this.document.documentName !== this.editedDocument.documentName) {
                    request.isRenamed = true;
                }

                this.updateDocument(request);
            },
            resetDefaults() {
                this.document$.$reset();
                this.document = {};
            },
            showNotification(notificationMsg) {
                this.notificationMsg = notificationMsg;

                this.successNotification = true;

                setTimeout(() => {
                    this.successNotification = false;
                }, 4000);
            },
            toggleErrorDialog() {
                this.showErrorDialog = !this.showErrorDialog;

                if(!this.showErrorDialog) {
                    this.errorTitle = '';
                    this.errorMsg = '';
                }
            },
            async updateDocument(document) {
                let self = this;

                this.isLoading = true;
                this.$emit('dialogclosed');

                let tokenResponse = await auth.acquireTokenResponse();

                return apiService.UpdateHockeyDocument(tokenResponse.accessToken, document)
                    .then((res) => {
                        self.isLoading = false;
                        self.showNotification('Document updated successfully');

                        self.$emit('documentupdated', res.data.data.sort((a, b) => a.documentName.localeCompare(b.documentName)));
                    }).catch(error => {
                        console.log(error);
                        this.onRequestError('An Error Occurred', error?.response?.data?.message);
                    });
            }
        },
        components: {
            'k-dialog': Dialog,
            'k-action-bar': DialogActionsBar,
            'k-button': Button,
            'k-loader': Loader,
            'k-notification': Notification,
            'k-notification-group': NotificationGroup,
            'k-fade': Fade,
            'k-input': Input,
            'k-dropdown': DropDownList
        }
    }
</script>

<style>
    @import '/src/assets/css/defaultUpsertStyles.css';
    @import '/src/assets/css/editPlayerDocumentDialog.css';
</style>