<template>
    <div class="create-player">
        <transition name="dialogfade">
            <k-dialog v-if="showDialog" class="default__dialog create-player-dialog">
                <div class="icon__container">
                    <i class="fa-solid fa-user"></i>
                </div>
                <div class="header__container">
                    <svg viewBox="0 0 300 50" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" class="icon__top-bar" clip-rule="evenodd" d="M10 0C4.47715 0 0 4.47715 0 10V50H300V10C300 4.47715 295.523 0 290 0H184.388C184.388 9.01736 180.732 17.6654 174.226 24.0416C167.72 30.4179 158.895 34 149.694 34C140.492 34 131.668 30.4179 125.161 24.0416C118.655 17.6654 115 9.01737 115 5.13386e-06L149.694 0H10Z" fill="#D4B139"></path></svg>
                </div>
                <h1 class="dialog--title">Create Player</h1>
                <div class="upsert-item__container create-player">
                    <p class="field--required">*Required</p>
                    <section class="upsert-item__section player-information">
                        <h2 class="upsert-item__section--title">Player Information</h2>
                        <div class="input__row trio">
                            <label class="input__label">
                                *First Name
                                <k-input v-model="newPlayer.firstName" @keydown="player$.newPlayer.firstName.$reset()" :class="[{ 'error': player$.newPlayer.firstName.$error }]" />
                                <span class="item-input--vuelidate-error" v-if="player$.newPlayer.firstName.$error"><i>First name is required</i></span>
                            </label>
                            <label class="input__label">
                                *Last Name
                                <k-input v-model="newPlayer.lastName" @keydown="player$.newPlayer.lastName.$reset()" :class="[{ 'error': player$.newPlayer.lastName.$error }]" />
                                <span class="item-input--vuelidate-error" v-if="player$.newPlayer.lastName.$error"><i>Last Name is required</i></span>
                            </label>
                            <label class="input__label">
                                *Status
                                <k-dropdown :data-items="statusSelects" :default-item="'Select Status'" @change="onStatusSelected" :class="[{ 'error': player$.newPlayer.status.$error }]"></k-dropdown>
                                <span class="item-input--vuelidate-error" v-if="player$.newPlayer.status.$error"><i>Status is required</i></span>
                            </label>
                        </div>
                        <div class="input__row pair">
                            <label class="input__label">
                                Date of Birth
                                <k-datepicker v-model="newPlayer.dob_formatted"></k-datepicker>
                            </label>
                            <label class="input__label">
                                Citizenship
                                <k-input v-model="newPlayer.citzenship" />
                            </label>
                        </div>
                        <div class="input__row pair">
                            <label class="input__label">
                                Draft Eligible Year
                                <k-numeric-input :placeholder="'Enter year'" v-model="draftEligibleYear" :format="'0'" :min="1000" @change="onDraftEligibleYearEntered"></k-numeric-input>
                            </label>
                            <label class="input__label">
                                Projected Draft Round
                                <k-numeric-input :placeholder="'Enter round'" v-model="newPlayer.projectedDraftRound" :format="'0'" :min="1" :max="7"></k-numeric-input>
                            </label>
                        </div>
                        <div class="input__row pair">
                            <label class="input__label">
                                *Position
                                <!--<k-dropdown :data-items="positionSelects" :default-item="'Select Position'" @change="onPositionSelected" :class="[{ 'error': player$.newPlayer.posistion.$error }]"></k-dropdown>-->
                                <k-multiselect :data-items="positionSelects" v-model="playerPositions" :placeholder="'Select Position'"></k-multiselect>
                                <span class="item-input--vuelidate-error" v-if="player$.playerPositions.$error"><i>Position is required</i></span>
                            </label>
                            <label class="input__label">
                                AAV
                                <k-numeric-input v-model="newPlayer.annualContractValue" :format="'c2'" :min="0"></k-numeric-input>
                            </label>
                        </div>
                    </section>

                    <section class="upsert-item__section billing-information">
                        <h2 class="upsert-item__section--title">Billing Information</h2>
                        <div class="input__row pair">
                            <label class="input__label">
                                Billing Contact Name
                                <k-input v-model="newPlayer.billingContactName" />
                            </label>
                            <label class="input__label">
                                Billing Contact Email
                                <k-input v-model="newPlayer.billingContactEmail" @keydown="player$.newPlayer.billingContactEmail.$reset()" :class="[{ 'error': player$.newPlayer.billingContactEmail.$error }]" />
                                <span class="item-input--vuelidate-error" v-if="player$.newPlayer.billingContactEmail.$error"><i>Email is invalid</i></span>
                            </label>
                        </div>
                        <div class="input__row pair">
                            <label class="input__label">
                                Phone
                                <k-input v-model="newPlayer.billingContactPhone" @keydown="formatContactPhoneNumberInput" @input="player$.newPlayer.billingContactPhone.$reset()" :class="[{ 'error': player$.newPlayer.billingContactPhone.$error }]" />
                                <span class="item-input--vuelidate-error" v-if="player$.newPlayer.billingContactPhone.$error"><i>Phone number is invalid</i></span>
                            </label>
                            <label class="input__label">
                                Ext
                                <k-input v-model="newPlayer.billingContactPhoneExt" />
                            </label>
                        </div>
                        <div class="input__row pair">
                            <label class="input__label">
                                Billing Address
                                <k-input v-model="newPlayer.billingAddress" />
                            </label>
                            <label class="input__label">
                                Additional (Suite, Unit, etc)
                                <k-input v-model="newPlayer.billingContactAddressAdditional" />
                            </label>
                        </div>
                        <div class="input__row trio">
                            <label class="input__label">
                                City
                                <k-input v-model="newPlayer.billingContactCity" />
                            </label>
                            <label class="input__label">
                                State
                                <k-dropdown :data-items="stateSelectsAbbr"
                                            v-model="newPlayer.billingContactState"
                                            :default-item="'Select State'"
                                            @change="defaultDropdownSelected($event, 'billingContactState', 'Select State')"></k-dropdown>
                            </label>
                            <label class="input__label">
                                Zip
                                <k-input v-model="newPlayer.billingContactZip" />
                            </label>
                        </div>
                    </section>

                    <section class="upsert-item__section team-information">
                        <h2 class="upsert-item__section--title">Team Information</h2>
                        <div class="input__row pair">
                            <label class="input__label">
                                Current League
                                <k-input v-model="newPlayer.currentLeague" />
                            </label>
                            <label class="input__label">
                                Current Team
                                <k-input v-model="newPlayer.currentTeam" />
                            </label>
                        </div>
                    </section>

                    <section class="upsert-item__section team-information">
                        <h2 class="upsert-item__section--title">NHL Rights</h2>
                        <div class="input__row pair">
                            <label class="input__label">
                                NHL Team
                                <k-dropdown :data-items="nhlTeamSelects"
                                            v-model="newPlayer.nhlRightsTeam"
                                            :default-item="'Select Team'"
                                            @change="defaultDropdownSelected($event, 'nhlRightsTeam', 'Select Team')"></k-dropdown>
                            </label>
                            <label class="input__label">
                                Year Drafted
                                <k-numeric-input v-model="newPlayer.nhlRightsYearDrafted" :format="'0'" :min="1000"></k-numeric-input>
                            </label>
                        </div>
                    </section>

                    <k-action-bar>
                        <k-button class="item-save__btn" @click="onSaveClicked">Save</k-button>
                        <k-button class="item-cancel__btn" @click="onCancelClicked">Cancel</k-button>
                    </k-action-bar>
                </div>
            </k-dialog>
        </transition>

        <div class="loader-container" v-if="isLoading">
            <k-loader :size="'large'" :type="'infinite-spinner'"></k-loader>
        </div>

        <transition name="dialogfade">
            <k-dialog v-if="showErrorDialog" :title="errorTitle" class="error__dialog" @close="toggleErrorDialog">
                <p class="error-dialog__msg">{{ errorMsg }}</p>
            </k-dialog>
        </transition>

        <k-notification-group class="default-notification-container">
                <k-fade :appear="successNotification">
                    <k-notification v-if="successNotification"
                                    :type="{ style: 'success', icon: true }"
                                    :closable="true"
                                    @close="onCloseNotification('successNotification')">
                        <span>{{ notificationMsg }}</span>
                    </k-notification>
                </k-fade>
        </k-notification-group>
    </div>
</template>

<script>
    import { Dialog, DialogActionsBar } from '@progress/kendo-vue-dialogs';
    import { Button } from "@progress/kendo-vue-buttons";
    import { DatePicker } from '@progress/kendo-vue-dateinputs';
    import { Input, NumericTextBox } from "@progress/kendo-vue-inputs";
    import { DropDownList, MultiSelect } from '@progress/kendo-vue-dropdowns';
    import { Loader } from "@progress/kendo-vue-indicators";
    import { Notification, NotificationGroup } from "@progress/kendo-vue-notification";
    import { Fade } from "@progress/kendo-vue-animation";

    import { useVuelidate } from '@vuelidate/core';
    import { required, email, minLength } from '@vuelidate/validators';

    import cloneDeep from 'lodash.clonedeep';

    import auth from '../authConfig';
    import apiService from '../api/api.service.js';
    
    import playerHelpers from '../helpers/playerHelpers.js';
    import invoiceHelpers from '../helpers/invoiceHelpers.js';

    const phoneValidator = (val) => {
        if (!val) return true;

        const phoneRegEx = `^((\\(\\d{3}\\))|\\d{3})[- ]?\\d{3}[- ]?\\d{4}$`;
        return val.match(phoneRegEx);
    }

    export default {
        setup() {
            return { player$: useVuelidate() }
        },
        name: 'createPlayerDialog',
        props: ['playerFirstName', 'playerLastName', 'showDialog'],
        emits: ['dialogclosed', 'playercreated'],
        data () {
            return {
                draftEligibleYear: null,
                errorMsg: '',
                errorTitle: '',
                isLoading: false,
                newPlayer: {},
                notificationMsg: '',
                playerPositions: [],
                showErrorDialog: false,
                successNotification: false
            }
        },
        computed: {
            newPlayerModel() {
                return playerHelpers.newPlayerModel;
            },
            nhlTeamSelects() {
                return playerHelpers.nhlTeamSelects;
            },
            playerDateFields() {
                return playerHelpers.playerDateFields;
            },
            positionSelects() {
                return playerHelpers.playerPositionSelects;
            },
            stateSelectsAbbr() {
                return invoiceHelpers.stateSelectsAbbr;
            },
            statusSelects() {
                return playerHelpers.playerStatusSelects;
            }
        },
        validations() {
            return {
                newPlayer: {
                    firstName: { required },
                    lastName: { required },
                    billingContactEmail: { email },
                    status: { required },
                    billingContactPhone: { phoneValidator }
                },
                playerPositions: {
                    required,
                    minLength: minLength(1)
                }
            }
        },
        watch: {
            showDialog(val) {
                if (val) {
                    this.player$.$reset();

                    this.newPlayer = cloneDeep(this.newPlayerModel);

                    if (this.playerFirstName || this.playerLastName) {
                        this.newPlayer.firstName = this.playerFirstName;
                        this.newPlayer.lastName = this.playerLastName;
                    }

                    this.playerPositions = [];
                }
            }
        },
        methods: {
            async createPlayer(player) {
                let self = this;
                this.isLoading = true;
                
                this.$emit('dialogclosed');

                this.playerDateFields.forEach(field => {
                    player[field] = player[`${field}_formatted`];
                });

                let tokenResponse = await auth.acquireTokenResponse();

                return apiService.CreatePlayer(tokenResponse.accessToken, player) 
                    .then(async () => {
                        await this.$store.dispatch('getPlayers', true);

                        self.isLoading = false;
                        self.showNotification('Player created successfully');

                        self.$emit('playercreated');
                    }).catch(error => {
                        console.log(error);
                        self.onRequestError('An Error Occurred', error?.response?.data?.message);
                    });
            },
            defaultDropdownSelected(evt, field, defaultValue) {
                let fields = field.split('.');

                if (fields.length === 2) {
                    if (evt.target.value === defaultValue) {
                        return this.newPlayer[fields[0]][fields[1]] = null;
                    }

                    this.newPlayer[fields[0]][fields[1]] = evt.target.value;
                } else {
                    if (evt.target.value === defaultValue) {
                        return this.newPlayer[field] = null;
                    }

                    this.newPlayer[field] = evt.target.value;
                }
            },
            formatContactPhoneNumberInput() {
                if (!this.newPlayer.billingContactPhone) return;

                const phoneNumber = this.newPlayer.billingContactPhone.replace(/[^\d]/g, '');
                const phoneNumberLength = phoneNumber.length;

                if (phoneNumberLength < 4) return this.newPlayer.billingContactPhone = phoneNumber;

                if (phoneNumberLength < 7) {
                    return this.newPlayer.billingContactPhone = `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
                }

                this.newPlayer.billingContactPhone = `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6, 10)}`;
            },
            onCancelClicked() {
                this.$emit('dialogclosed');
            },
            onCloseNotification(flag) {
                this[flag] = false;
            },
            onDraftEligibleYearEntered(evt) {
                if (!evt.value) {
                    this.newPlayer.draftEligibleDate_formatted = null;
                }

                if (evt.value < 1000) return;

                this.newPlayer.draftEligibleDate_formatted = new Date(evt.value, 5, 1);
            },
            onPositionSelected(evt) {
                if (evt.value === 'Select Position') {
                    return this.newPlayer.posistion = '';
                }

                this.newPlayer.posistion = evt.value;
                this.player$.newPlayer.posistion.$reset();
            },
            onRequestError(errorTitle, errorMsg) {
                this.isLoading = false;

                this.errorTitle = errorTitle;

                if (errorMsg) {
                    this.errorMsg = errorMsg;
                } else {
                    this.errorMsg = 'Please try again';
                }

                this.showErrorDialog = true;
            },
            async onSaveClicked() {
                await this.player$.$validate();

                if (this.player$.newPlayer.$error || this.player$.playerPositions.$error) return;

                this.player$.$reset();
                let player = cloneDeep(this.newPlayer);
                player.positions = cloneDeep(this.playerPositions);

                this.createPlayer(cloneDeep(player));
            },
            onStatusSelected(evt) {
                if (evt.value === 'Select Status') {
                    return this.newPlayer.status = '';
                }

                this.newPlayer.status = evt.value;
                this.player$.newPlayer.status.$reset();
            },
            showNotification(notificationMsg) {
                this.notificationMsg = notificationMsg;

                this.successNotification = true;

                setTimeout(() => {
                    this.successNotification = false;
                }, 4000);
            },
            toggleErrorDialog() {
                this.showErrorDialog = !this.showErrorDialog;

                if(!this.showErrorDialog) {
                    this.errorTitle = '';
                    this.errorMsg = '';
                }
            }
        },
        components: {
            'k-dialog': Dialog,
            'k-action-bar': DialogActionsBar,
            'k-button': Button,
            'k-datepicker': DatePicker,
            'k-numeric-input': NumericTextBox,
            'k-input': Input,
            'k-dropdown': DropDownList,
            'k-loader': Loader,
            'k-notification': Notification,
            'k-notification-group': NotificationGroup,
            'k-fade': Fade,
            'k-multiselect': MultiSelect
        }
    }
</script>

<style>
    @import '/src/assets/css/createPlayerDialog.css';
</style>