export default {
    documentFolderNames: {
        autoFolderName: 'Auto',
        contractsFolderName: 'Contracts',
        endorsementsFolderName: 'Endorsements',
        identityDocumentsFolderName: 'Identity Documents',
        incomeFolderName: 'Income',
        insuranceFolderName: 'Insurance',
        invoicesFolderName: 'Invoices',
        picturesFolderName: 'Pictures',
        miscellaneousFolderName: 'Miscellaneous',
        scoutingReportsFolderName: 'Scouting Reports'
    }

}