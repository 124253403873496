<template>
    <div class="agent-dashboard-component">
        <div class="card-column k-card-list">
            <agent-dashboard-card :card-title="'Expiring Contracts'" 
                                  :grid-items="contracts"
                                  :grid-columns="contractsColumns"
                                  :card-initial-sort="[{ field: 'fullName', dir: 'asc' }]"
                                  :card-icon-class="'fa-duotone fa-layer-group'"
                                  :current-season="`${currentSeasonStart} / ${currentSeasonEnd}`"
                                  :next-season="`${currentSeasonStart + 1} / ${currentSeasonEnd + 1}`"
                                  :current-season-start="currentSeasonStart"
                                  :card-type="'contract'"
                                  :agent-dashboard="true"
                                  @itemclicked="onContractClicked"></agent-dashboard-card>
            <agent-dashboard-card :card-title="'Upcoming UFA'"
                                  :grid-items="ufaContracts"
                                  :grid-columns="contractsColumns"
                                  :card-initial-sort="[{ field: 'fullName', dir: 'asc' }]"
                                  :card-icon-class="'fa-duotone fa-hockey-sticks'"
                                  :current-season="`${currentSeasonStart} / ${currentSeasonEnd}`"
                                  :next-season="`${currentSeasonStart + 1} / ${currentSeasonEnd + 1}`"
                                  :current-season-start="currentSeasonStart"
                                  :card-type="'contract'"
                                  :agent-dashboard="true"
                                  @itemclicked="onFreeAgentClicked"></agent-dashboard-card>
        </div>
        <div class="card-column k-card-list">
            <agent-dashboard-card :card-title="'Draft Eligible'" 
                                  :grid-items="draftEligiblePlayers"
                                  :grid-columns="draftEligiblePlayersColumns"
                                  :card-initial-sort="[{ field: 'draftOrder', dir: 'asc' }]"
                                  :card-icon-class="'fa-duotone fa-layer-group'"
                                  :current-season="`${currentSeasonStart + 1} / ${currentSeasonEnd + 1}`"
                                  :next-season="`${currentSeasonStart + 2} / ${currentSeasonEnd + 2}`"
                                  :current-season-start="currentSeasonStart + 1"
                                  :card-type="'draft'" 
                                  :agent-dashboard="true"
                                  @itemclicked="onDraftEligibleClicked"
                                  @toggledraftreorderdialog="toggleDraftReorderDialog"
                                  @drafteligibleyearselected="setSelectedDraftEligibleYear"></agent-dashboard-card>
            <agent-dashboard-card :card-title="'Upcoming RFA'"
                                  :grid-items="rfaContracts"
                                  :grid-columns="rfaColumns"
                                  :card-initial-sort="[{ field: 'fullName', dir: 'asc' }]"
                                  :card-icon-class="'fa-duotone fa-hockey-sticks'"
                                  :current-season="`${currentSeasonStart} / ${currentSeasonEnd}`"
                                  :next-season="`${currentSeasonStart + 1} / ${currentSeasonEnd + 1}`"
                                  :current-season-start="currentSeasonStart"
                                  :card-type="'contract'"
                                  :agent-dashboard="true"
                                  @itemclicked="onFreeAgentClicked"></agent-dashboard-card>
        </div>

        <transition name="dialogfade">
            <draft-reorder-dialog :show-dialog="showDraftReorderDialog"
                                  :selected-year="selectedDraftEligibleYear"
                                  :players="draftEligiblePlayers"
                                  @dialogclosed="toggleDraftReorderDialog"
                                  @draftorderupdated="onDraftOrderUpdated"></draft-reorder-dialog>
        </transition>

        <div class="loader-container" v-if="isLoading">
            <k-loader :size="'large'" :type="'infinite-spinner'"></k-loader>
        </div>

        <transition name="dialogfade">
            <k-dialog v-if="showErrorDialog" :title="errorTitle" class="error__dialog" @close="toggleErrorDialog">
                <p class="error-dialog__msg">{{ errorMsg }}</p>
            </k-dialog>
        </transition> 

        <agent-detail-dialog :show-dialog="showAgentDetailDialog"
                             :detail-data="detailDialogData"
                             :detail-type="detailDialogType"
                             @dialogclosed="toggleAgentDetailDialog"></agent-detail-dialog>
    </div>
</template>

<script>
    import { Dialog } from '@progress/kendo-vue-dialogs';
    import { Loader } from "@progress/kendo-vue-indicators";

    import agentDashboardCard from '../components/agentDashboardCard.vue';
    import agentDetailDialog from './agentDetailDialog.vue';
    import draftReorderDialog from './draftReorderDialog.vue';

    import auth from '../authConfig';
    import apiService from '../api/api.service.js';

    import formatHelpers from '../helpers/formatHelpers.js';
    import playerHelpers from '@/helpers/playerHelpers';

    export default {
        name: 'agentDashboardComponent',
        data () {
            return {
                contracts: [],
                contractsColumns: [
                    { field: 'fullName', title: 'Name', filterable: false, width: '225' },
                    { field: 'playerAge', title: 'Age', type: 'number', filter: 'numeric', width: '100', filterable: false },
                    { field: 'contractType', title: 'Contract', filterable: false },
                    { field: 'aav', title: 'AAV', type: 'number', filter: 'numeric', format: '{0:c0}', filterable: false }
                ],
                currentSeasonEnd: null,
                currentSeasonStart: null,
                detailDialogData: null,
                detailDialogType: null,
                draftEligiblePlayersColumns: [
                    { field: 'fullName', title: 'Name', filterable: false, width: '225' },
                    { field: 'playerAge', title: 'Age', type: 'number', filter: 'numeric', width: '100', filterable: false },
                    { field: 'projectedDraftRound', title: 'Proj. Round', width: '150', type: 'number', filter: 'numeric', filterable: false },
                    { field: 'currentLeague', title: 'League', filterable: false },
                    { field: 'positionString', title: 'Pos', filterable: false }
                ],
                draftEligiblePlayers: [],
                errorMsg: '',
                errorTitle: '',
                isLoading: false,
                rfaColumns: [
                    { field: 'fullName', title: 'Name', filterable: false, width: '225' },
                    { field: 'playerAge', title: 'Age', type: 'number', filter: 'numeric', width: '100', filterable: false },
                    { field: 'contractType', title: 'Contract', filterable: false },
                    { field: 'aav', title: 'AAV', type: 'number', filter: 'numeric', format: '{0:c0}', filterable: false },
                    { field: 'expirationStatus', title: 'Type', filterable: false },
                ],
                rfaContracts: [],
                rfaTypes: ['RFA', 'RFA ARB Rights'],
                ufaContracts: [],
                ufaType: 'UFA',
                selectedDraftEligibleYear: null,
                showAgentDetailDialog: false,
                showDraftReorderDialog: false,
                showErrorDialog: false
            }
        },
        watch: {
            '$store.state.errorAlert'(val) {
                if (val) {
                    this.onRequestError(this.$store.state.errorTitle, this.$store.state.errorMsg);

                    let errorData = {
                        isError: false,
                        title: '',
                        msg: ''
                    }
                    
                    this.$store.commit('updateErrorAlert', errorData);
                }
            }
        },
        async created () {
            this.getCurrentSeason();

            await this.$store.dispatch('getDashboardContractsData', this.currentSeasonStart);

            this.getDraftEligiblePlayers();
            this.assignContractsCards();
        },
        methods: {
            assignContractsCards() {
                this.contracts = this.$store.state.dashboardContractsData;

                this.rfaContracts = this.$store.state.dashboardContractsData.filter(contract => this.rfaTypes.some(type => contract.expirationStatus === type)); 

                this.ufaContracts = this.$store.state.dashboardContractsData.filter(contract => contract.expirationStatus === this.ufaType);
            },
            getCurrentSeason() {
                let currentMonth = new Date().getMonth();
                let currentYear = new Date().getFullYear();

                if (currentMonth >= 6) {
                    this.currentSeasonStart = currentYear;
                    this.currentSeasonEnd = currentYear + 1;
                } else {
                    this.currentSeasonStart = currentYear - 1;
                    this.currentSeasonEnd = currentYear;
                }

                this.selectedDraftEligibleYear = this.currentSeasonStart + 1;
            },
            async getDraftEligiblePlayers() {
                let tokenResponse = await auth.acquireTokenResponse();

                return apiService.GetDraftEligiblePlayers(tokenResponse.accessToken, 10)
                    .then((res) => {
                        if (res.data.data) {
                            this.processDraftEligiblePlayers(res.data.data);
                        }
                    }).catch(error => {
                        this.onRequestError('An Error Occurred', error?.response?.data?.message);
                    });
            },
            onContractClicked(evt) {
                this.toggleAgentDetailDialog(evt, 'contract');
            },
            onDraftEligibleClicked(evt) {
                evt.dataItem.playerId = evt.dataItem.id;
                this.toggleAgentDetailDialog(evt, 'player');
            },
            onDraftOrderUpdated(players) {
                this.processDraftEligiblePlayers(players);
            },
            onFreeAgentClicked(evt) {
                this.toggleAgentDetailDialog(evt, 'player');
            },
            onRequestError(errorTitle, errorMsg) {
                this.isLoading = false;

                this.errorTitle = errorTitle;

                if (errorMsg) {
                    this.errorMsg = errorMsg;
                } else {
                    this.errorMsg = 'Please try again';
                }

                this.showErrorDialog = true;
            },
            processDraftEligiblePlayers(playerData) {
                playerData.forEach(player => {
                    player.dob = formatHelpers.formatDate(player.dob);
                    player.draftEligibleDate = formatHelpers.formatDate(player.draftEligibleDate);
                    player.fullName = `${player.lastName}, ${player.firstName}`;
                    player.positionString = playerHelpers.getAbbreviatedPositions(player.positions);
                    player.draftOrder = player.draftOrder ?? player.projectedDraftRound;
                });

                playerData.sort((a, b) => {
                    return a.draftOrder - b.draftOrder;
                });

                this.draftEligiblePlayers = playerData;
            },
            setSelectedDraftEligibleYear(year) {
                this.selectedDraftEligibleYear = year;
            },
            toggleAgentDetailDialog(evt, detailType) {
                if (!this.showAgentDetailDialog) {
                    this.detailDialogType = detailType;
                    this.detailDialogData = evt.dataItem;
                } else {
                    this.detailDialogType = null;
                    this.detailDialogData = null;
                }

                this.showAgentDetailDialog = !this.showAgentDetailDialog;
            },
            toggleErrorDialog() {
                this.showErrorDialog = !this.showErrorDialog;

                if(!this.showErrorDialog) {
                    this.errorTitle = '';
                    this.errorMsg = '';
                }
            },
            toggleDraftReorderDialog() {
                this.showDraftReorderDialog = !this.showDraftReorderDialog;
            }
        },
        components: {
            'agent-dashboard-card': agentDashboardCard,
            'k-loader': Loader,
            'k-dialog': Dialog,
            'agent-detail-dialog': agentDetailDialog,
            'draft-reorder-dialog': draftReorderDialog
        }
    }
</script>

<style scoped>
    @import '/src/assets/css/agentDashboardComponent.css';
</style>
