<template>
    <div class="contract-detail-view">
        <view-header :title="uneditedContract.contractTitle ? uneditedContract.contractTitle : ''">
            <template v-slot:viewicon>
                <i class="fa-duotone fa-file-signature"></i>
            </template>
            <template v-slot:viewactions>
                <div class="action-btns">
                    <k-button class="save-changes__btn" :disabled="saveChangesBtnDisabled" @click="onUpdateContract"><i class="fa-sharp fa-solid fa-floppy-disk"></i> Save Changes</k-button>
                    <k-button class="discard-changes__btn" :disabled="saveChangesBtnDisabled" @click="onDiscardChanges"><i class="fa-sharp fa-solid fa-rotate-left"></i> Discard Changes</k-button>
                    <k-button v-if="!agentDetail" class="discard-changes__btn" @click="onBackToContracts"><i class="fa-solid fa-left"></i> Back to Contracts</k-button>
                    <k-button v-if="agentDetail" class="discard-changes__btn" @click="onBackToDashboard"><i class="fa-solid fa-left"></i> Back to Dashboard</k-button>
                </div>
            </template>
        </view-header>
        <div class="contract-detail__container">
            <section :class="['upsert-item__container', 'contract-info', { 'wide': detailTabs.length === 0 }]">
                <div class="upsert-item__section contract-information">
                    <h2 class="upsert-item__section--title first">Contract Information <span class="field--required">*Required</span></h2>
                    <div class="upsert-item__section">
                        <div class="input__row pair">
                            <label class="input__label">
                                *Contract Name
                                <k-input v-model="contract.contractTitle" :disabled="contract.contractType === playingContractType || contract.contractType === endorsementContractType" @keydown="contract$.contract.contractTitle.$reset()" :class="[{ 'error': contract$.contract.contractTitle.$error }]" />
                                <span class="item-input--vuelidate-error" v-if="contract$.contract.contractTitle.$error"><i>Contract name is required</i></span>
                            </label>
                            <label class="input__label">
                                *Player
                                <k-dropdown :data-items="filteredPlayers" 
                                    disabled
                                    :class="[{ 'error': contract$.selectedPlayer.id.$error }]"
                                    v-model="selectedPlayer" 
                                    :text-field="'fullName'" 
                                    :filterable="true" 
                                    @change="contract$.selectedPlayer.id.$reset()"
                                    @filterchange="onPlayerFilterChange"></k-dropdown>
                                <span class="item-input--vuelidate-error" v-if="contract$.selectedPlayer.id.$error"><i>Player is required</i></span>
                            </label>
                        </div>
                        <div class="input__row" v-if="contract.contractType !== agencyContractType">
                            <label class="input__label">
                                Associated Agency Contract
                                <k-dropdown :data-items="playerContracts"
                                    :default-item="{ contractTitle: 'Select Contract', contractId: null }"
                                    v-model="associatedAgencyContract"
                                    :text-field="'contractTitle'"
                                    :data-item-key="'contractId'"
                                    @change="onAssociatedContractSelected"></k-dropdown>
                            </label>
                        </div>
                        <div class="input__row pair" v-if="showTotalContractValue">
                            <label class="input__label">
                                Total Contract Value
                                <k-numeric-input disabled v-model="totalContractValue" :format="'c2'" :min="0"></k-numeric-input>
                            </label>
                            <label class="input__label">
                                Total Commission
                                <k-numeric-input disabled v-model="totalCommission" :format="'c2'" :min="0"></k-numeric-input>
                            </label>
                        </div>
                        <div class="document-action__btns">
                            <p v-if="contractDocuments.length > 0" class="document-action__btn" @click="onOpenDocument"><i class="fa-duotone fa-folder-open"></i> Open Associated Document</p>
                            <p v-if="contractDocuments.length > 0" class="document-action__btn" @click="toggleUploadDocumentDialog"><i class="fa-duotone fa-pen-to-square"></i> Edit Associated Document</p>
                        </div>
                        <p v-if="contractDocuments.length === 0" class="document-action__btn"><span @click="toggleUploadDocumentDialog"><i class="fa-sharp fa-solid fa-plus"></i> Add Associated Document</span></p>
                    </div>

                    <div v-if="contract.contractType === agencyContractType" class="upsert-item__section">
                        <div class="input__row pair">
                            <label class="input__label">
                                *Signing Date
                                <k-datepicker v-model="contract.spacSigningDate_formatted" @change="buildAgencyContractName" @focus="contract$.contract.spacSigningDate_formatted.$reset()" :class="[{ 'error': contract$.contract.spacSigningDate_formatted.$error }]"></k-datepicker>
                                <span class="item-input--vuelidate-error" v-if="contract$.contract.spacSigningDate_formatted.$error"><i>Signing date is required</i></span>
                            </label>
                            <label class="input__label">
                                Expiration Date
                                <k-datepicker v-model="contract.expirationDate_formatted"></k-datepicker>
                            </label>
                        </div>
                        <div class="input__row trio">
                            <label class="input__label">
                                Primary Agent
                                <k-dropdown v-if="contract.primaryAgent"
                                            :data-items="contractAgentSelects"
                                            v-model="contract.primaryAgent"></k-dropdown>

                                <k-dropdown v-if="!contract.primaryAgent"
                                            :data-items="contractAgentSelects"
                                            :default-item="'Select Agent'"
                                            @change="defaultDropdownSelected($event, 'primaryAgent', 'Select Agent')"></k-dropdown>
                            </label>
                            <label class="input__label">
                                Secondary Agent
                                <k-dropdown v-if="contract.secondaryAgent"
                                            :data-items="contractAgentSelects"
                                            v-model="contract.secondaryAgent"></k-dropdown>

                                <k-dropdown v-if="!contract.secondaryAgent"
                                            :data-items="contractAgentSelects"
                                            :default-item="'Select Agent'"
                                            @change="defaultDropdownSelected($event, 'secondaryAgent', 'Select Agent')"></k-dropdown>
                            </label>
                            <label class="input__label">
                                Third Agent
                                <k-dropdown v-if="contract.thirdAgent"
                                            :data-items="contractAgentSelects"
                                            v-model="contract.thirdAgent"></k-dropdown>

                                <k-dropdown v-if="!contract.thirdAgent"
                                            :data-items="contractAgentSelects"
                                            :default-item="'Select Agent'"
                                            @change="defaultDropdownSelected($event, 'thirdAgent', 'Select Agent')"></k-dropdown>
                            </label>
                        </div>
                        <h2 class="upsert-item__section--title">Contract Commission Information</h2>
                        <div class="input__row pair">
                            <label class="input__label">
                                Playing Commission Percent
                                <k-numeric-input :placeholder="'Enter percent'" v-model="contract.playingCommissionPercent" :format="'##.00 \\%'" :decimals="2" :min="0"></k-numeric-input>
                            </label>
                            <label class="input__label">
                                Endorsement Commission Percent
                                <k-numeric-input :placeholder="'Enter percent'" v-model="contract.endorsementCommissionPercent" :format="'##.00 \\%'" :decimals="2" :min="0"></k-numeric-input>
                            </label>
                        </div>
                    </div>

                    <div v-if="contract.contractType === endorsementContractType" class="upsert-item__section">
                        <div class="input__row pair">
                            <label class="input__label">
                                Endorsement Type
                                <k-dropdown v-if="contract.endorsementContractType"
                                            :data-items="endorsementContractTypeSelects"
                                            v-model="contract.endorsementContractType"></k-dropdown>
                                            
                                <k-dropdown  v-if="!contract.endorsementContractType"
                                            :data-items="endorsementContractTypeSelects"
                                            :default-item="'Select Type'"
                                            @change="defaultDropdownSelected($event, 'endorsementContractType', 'Select Type')"></k-dropdown>
                            </label>
                            <label class="input__label">
                                *Company Name
                                <k-input v-model="contract.endorsementCompanyName" @input="buildEndorsementContractName" @keydown="contract$.contract.endorsementCompanyName.$reset()" :class="[{ 'error': contract$.contract.endorsementCompanyName.$error }]" />
                                <span class="item-input--vuelidate-error" v-if="contract$.contract.endorsementCompanyName.$error"><i>Company name is required</i></span>
                            </label>
                        </div>
                        <div class="input__row pair">
                            <label class="input__label">
                                Contract Amount
                                <k-numeric-input :placeholder="'Enter amount'" v-model="contract.endorsementAmount" :format="'c2'" :min="0"></k-numeric-input>
                            </label>
                            <label class="input__label">
                                Commission
                                <k-numeric-input :placeholder="'Enter percent'" v-model="contract.endorsementCommissionPercent" :format="'##.00 \\%'" :decimals="2" :min="0"></k-numeric-input>
                            </label>
                        </div>
                        <div class="input__row pair">
                            <label class="input__label">
                                *Signing Date
                                <k-datepicker v-model="contract.endorsementSigningDate_formatted" @change="buildEndorsementContractName" @focus="contract$.contract.endorsementSigningDate_formatted.$reset()" :class="[{ 'error': contract$.contract.endorsementSigningDate_formatted.$error }]"></k-datepicker>
                                <span class="item-input--vuelidate-error" v-if="contract$.contract.endorsementSigningDate_formatted.$error"><i>Signing date is required</i></span>
                            </label>
                            <label class="input__label">
                                Expiration Date
                                <k-datepicker v-model="contract.endorsementExpirationDate_formatted"></k-datepicker>
                            </label>
                        </div>
                        <div class="input__row">
                            <label class="input__label">
                                Notes
                                <k-textarea v-model="contract.endorsementNotes" :resizable="'vertical'" :rows="5"></k-textarea>
                            </label>
                        </div>
                    </div>

                    <div v-if="contract.contractType === equipmentContractType" class="upsert-item__section">
                        <div class="input__row">
                            <label class="input__label">
                                *Equipment Contract
                                <k-dropdown v-if="contract.equipmentContract"
                                    :data-items="equipmentContractSelects" 
                                    v-model="contract.equipmentContract"
                                    @change="onEquipmentContractSelected($event, 'equipmentContract', 'Select Equipment Contract')"></k-dropdown>

                                <k-dropdown v-if="!contract.equipmentContract"
                                    :data-items="equipmentContractSelects" 
                                    :default-item="'Select Equipment Contract'" 
                                    @change="defaultDropdownSelected($event, 'equipmentContract', 'Select Equipment Contract')"></k-dropdown>
                                    <span class="item-input--vuelidate-error" v-if="contract$.contract.equipmentContract.$error"><i>Equipment contract is required</i></span>
                            </label>
                        </div>
                        <div class="input__row pair">
                            <label class="input__label">
                                Contract Amount
                                <k-numeric-input :placeholder="'Enter amount'" v-model="contract.equipmentAmount" :format="'c2'" :min="0"></k-numeric-input>
                            </label>
                            <label class="input__label">
                                Commission
                                <k-numeric-input :placeholder="'Enter percent'" v-model="contract.equipmentCommissionRate" :format="'##.00 \\%'" :decimals="2" :min="0"></k-numeric-input>
                            </label>
                        </div>
                        <div class="input__row pair">
                            <label class="input__label">
                                *Signing Date
                                <k-datepicker v-model="contract.equipmentSigningDate_formatted" @change="buildEquipmentContractName" @focus="contract$.contract.equipmentSigningDate_formatted.$reset()" :class="[{ 'error': contract$.contract.equipmentSigningDate_formatted.$error }]"></k-datepicker>
                                <span class="item-input--vuelidate-error" v-if="contract$.contract.equipmentSigningDate_formatted.$error"><i>Signing date is required</i></span>
                            </label>
                            <label class="input__label">
                                Expiration Date
                                <k-datepicker v-model="contract.equipmentExpirationDate_formatted"></k-datepicker>
                            </label>
                        </div>
                        <div class="input__row">
                            <label class="input__label">
                                Notes
                                <k-textarea v-model="contract.equipmentNotes" :resizable="'vertical'" :rows="5"></k-textarea>
                            </label>
                        </div>
                    </div>

                    <div v-if="contract.contractType === playingContractType" class="upsert-item__section">
                        <div class="input__row trio">
                            <label class="input__label">
                                NHL Signing Club
                                <k-input v-model="contract.nhlSigningClub" />
                            </label>
                            <label class="input__label">
                                *Starting Date
                                <k-datepicker v-model="contract.nhlContractStartYear_formatted" @change="buildPlayingContractName" @focus="contract$.contract.nhlContractStartYear_formatted.$reset()" :class="[{ 'error': contract$.contract.nhlContractStartYear_formatted.$error }]"></k-datepicker>
                                <span class="item-input--vuelidate-error" v-if="contract$.contract.nhlContractStartYear_formatted.$error"><i>Start date is required</i></span>
                            </label>
                            <label class="input__label">
                                *Ending Date
                                <k-datepicker v-model="contract.nhlContractEndYear_formatted" @change="buildPlayingContractName" @focus="contract$.contract.nhlContractStartYear_formatted.$reset()" :class="[{ 'error': contract$.contract.nhlContractEndYear_formatted.$error }]"></k-datepicker>
                                <span class="item-input--vuelidate-error" v-if="contract$.contract.nhlContractEndYear_formatted.$error"><i>End date is required</i></span>
                            </label>
                        </div>

                        <h2 class="upsert-item__section--title">NHL Information</h2>
                        <div class="input__row pair">
                            <label class="input__label">
                                *NHL Contract Type
                                <k-dropdown :data-items="contractNhlTypeSelects"
                                            disabled
                                            v-model="contract.nhlContractType"
                                            @change="onNhlContractTypeSelected($event, 'nhlContractType', 'Select Contract Type')"></k-dropdown>
                                <span class="item-input--vuelidate-error" v-if="contract$.contract.nhlContractType.$error"><i>Contract type is required</i></span>
                            </label>
                            <label class="input__label">
                                Expiration Free Agent Status
                                <k-dropdown v-if="contract.nhlContractExpirationStatus"
                                            :data-items="contractNhlExpirationStatusSelects"
                                            v-model="contract.nhlContractExpirationStatus"></k-dropdown>

                                <k-dropdown v-if="!contract.nhlContractExpirationStatus"
                                            :data-items="contractNhlExpirationStatusSelects"
                                            :default-item="'Select Status'"
                                            @change="defaultDropdownSelected($event, 'nhlContractExpirationStatus', 'Select Status')"></k-dropdown>
                            </label>
                        </div>
                    </div>

                    <div v-if="contract.nhlContractType === nhlOneWayContractType || contract.nhlContractType === ahlNhlTwoWayContractType">
                        <h2 class="upsert-item__section--title">No-Trade Clause Information</h2>
                        <div class="input__row trio" v-if="contract.nhlContractType === nhlOneWayContractType || contract.nhlContractType === ahlNhlTwoWayContractType">
                            <k-checkbox class="input__label" v-model="contract.noTradeClause" :label="'No Trade Clause'"></k-checkbox>
                            <k-checkbox class="input__label" v-model="contract.noMoveClause" :label="'No Move Clause'"></k-checkbox>
                            <k-checkbox class="input__label" v-model="contract.limitedModifiedNoTradeClause" :label="'Limited/Modified No Trade'"></k-checkbox>
                        </div>
                        <div v-if="contract.limitedModifiedNoTradeClause" class="input__row pair">
                            <label class="input__label">
                                <span v-if="contract.limitedModifiedOption === 'Specified Date'">*</span>Start Date
                                <k-datepicker v-model="contract.limitedModifiedStartDate_formatted" class="trade-clause__date-picker" @focus="contract$.contract.limitedModifiedStartDate_formatted.$reset()" :class="[{ 'error': contract$.contract.limitedModifiedStartDate_formatted.$error }]"></k-datepicker>
                                <span class="item-input--vuelidate-error" v-if="contract$.contract.limitedModifiedStartDate_formatted.$error"><i>Start date is required</i></span>
                            </label>
                            <label class="input__label">
                                <span v-if="contract.limitedModifiedOption === 'Specified Date'">*</span>End Date
                                <k-datepicker v-model="contract.limitedModifiedEndDate_formatted" class="trade-clause__date-picker" @focus="contract$.contract.limitedModifiedEndDate_formatted.$reset()" :class="[{ 'error': contract$.contract.limitedModifiedEndDate_formatted.$error }]"></k-datepicker>
                                <span class="item-input--vuelidate-error" v-if="contract$.contract.limitedModifiedEndDate_formatted.$error"><i>End date is required</i></span>
                            </label>
                        </div>
                        <div v-if="contract.limitedModifiedNoTradeClause" :class="['input__row', 'pair', { 'trio': contract.limitedModifiedOption === 'Specified Date' && noTradeClauses?.length == 0 }]">
                            <label class="input__label">
                                Type
                                <k-dropdown :data-items="limitedModifiedNoTradeClauseTypes"
                                            :default-item="'Select Type'"
                                            v-model="contract.limitedModifiedOption"></k-dropdown>
                            </label>
                            <label class="input__label" v-if="noTradeClauses?.length === 0">
                                Number of Teams
                                <k-numeric-input :placeholder="'Enter amount'" v-model="contract.limitedModifiedNumberTeams" :min="0"></k-numeric-input>
                            </label>
                            <label class="input__label" v-if="contract.limitedModifiedOption === 'Specified Date'">
                                <span v-if="contract.limitedModifiedOption === 'Specified Date'">*</span>Due 
                                <k-datepicker v-model="contract.limitedModifiedDate_formatted" :format="'MM / dd'" class="trade-clause__date-picker" @focus="contract$.contract.limitedModifiedDate_formatted.$reset()" :class="[{ 'error': contract$.contract.limitedModifiedDate_formatted.$error }]"></k-datepicker>
                                <span class="item-input--vuelidate-error" v-if="contract$.contract.limitedModifiedDate_formatted.$error"><i>Due date is required</i></span>
                            </label>
                        </div>
                        <div v-if="uneditedNoTradeClauses.length > 0 && showNoTradeClauses" class="clauses__container">
                            <h2 class="upsert-item__section--title">No-Trade Reporting</h2>
                            <div class="clause__container" v-for="clause in noTradeClauses" :key="clause.clauseId">
                                <div class="input__row pair">
                                    <label class="input__label">
                                        Due Date
                                        <k-datepicker v-model="clause.dueDate_formatted" disabled></k-datepicker>
                                    </label>
                                    <label class="input__label">
                                        Status
                                        <k-dropdown class="status__dropdown"
                                                    v-model="clause.status"
                                                    :data-items="noTradeClauseStatusSelects"></k-dropdown>
                                    </label>
                                </div>
                                <div class="input__row pair">
                                    <label class="input__label">
                                        Number of Teams
                                        <k-numeric-input :placeholder="'Enter amount'" v-model="clause.numberTeams" :min="0"></k-numeric-input>
                                    </label>
                                    <label class="input__label">
                                        Report To (Team Name)
                                        <k-input v-model="clause.reportToTeam" />
                                    </label>
                                </div>
                            </div>                           
                        </div>
                    </div>
                </div>
            </section>
            <section class="player-detail upsert-item__section">
                <h2 class="upsert-item__section--title first">Player Information</h2>
                <section class="player-detail__section">
                    <div class="contract-title__container">
                        <i class="fa-duotone fa-user"></i>
                        <h2>{{ player.firstName }} {{ player.lastName }}</h2>
                        <span class="go-to__link" @click="goToPlayer" title="View Player Detail"><i class="fa-solid fa-pen-to-square"></i></span>
                    </div>
                    <h2 class="section--title first">Player Information</h2>
                    <div class="detail__row pair">
                        <div class="detail__item">
                            <h3>Date of Birth:</h3>
                            <p>{{ player.dob ? new Date(player.dob).toLocaleDateString() : 'N/A' }}</p>
                        </div>
                        <div class="detail__item">
                            <h3>Citizenship:</h3>
                            <p>{{ player.citzenship ? player.citzenship : 'N/A' }}</p>
                        </div>
                    </div>
                    <div class="detail__row pair">
                        <div class="detail__item">
                            <h3>Position:</h3>
                            <p>{{ player.posistion ? player.posistion : 'N/A' }}</p>
                        </div>
                        <div class="detail__item">
                            <h3>Annual Contract Value:</h3>
                            <p v-if="!player.aav">{{ player.annualContractValue ? $options.filters.formatCurrencyDec(player.annualContractValue) : 'N/A' }}</p>
                            <p v-if="player.aav">{{ player.aav ? $options.filters.formatCurrencyDec(player.aav) : 'N/A' }}</p>
                        </div>
                    </div>
                    <hr />
                    <h2 class="section--title">Team Information</h2>
                    <div class="detail__row pair">
                        <div class="detail__item">
                            <h3>Current League:</h3>
                            <p>{{ player.currentLeague ? player.currentLeague : 'N/A' }}</p>
                        </div>
                        <div class="detail__item">
                            <h3>Current Team:</h3>
                            <p>{{ player.currentTeam ? player.currentTeam : 'N/A' }}</p>
                        </div>
                    </div>
                    <hr />
                    <h2 class="section--title">NHL Rights</h2>
                    <div class="detail__row pair">
                        <div class="detail__item">
                            <h3>NHL Team:</h3>
                            <p>{{ player.nhlRightsTeam ? player.nhlRightsTeam : 'N/A' }}</p>
                        </div>
                        <div class="detail__item">
                            <h3>Rights Date:</h3>
                            <p>{{ player.nhlRightsDate ? new Date(player.nhlRightsDate).toLocaleDateString() : 'N/A' }}</p>
                        </div>
                    </div>
                </section>

                <section class="player-detail__section" v-if="milestone">
                    <div class="contract-title__container">
                        <i class="fa-duotone fa-hockey-sticks"></i>
                        <h2>Current Statistics</h2>
                    </div>
                    <div class="detail__row pair">
                        <div class="detail__item">
                            <h3>Games Played:</h3>
                            <p>{{ milestone.gamesPlayed ? milestone.gamesPlayed : 'N/A' }}</p>
                        </div>
                        <div class="detail__item">
                            <h3>Goals:</h3>
                            <p>{{ milestone.goals ? milestone.goals : 'N/A' }}</p>
                        </div>
                    </div>
                    <div class="detail__row pair">
                        <div class="detail__item">
                            <h3>Assists:</h3>
                            <p>{{ milestone.assists ? milestone.assists : 'N/A' }}</p>
                        </div>
                        <div class="detail__item">
                            <h3>Points:</h3>
                            <p>{{ milestone.points ? milestone.points : 'N/A' }}</p>
                        </div>
                    </div>
                    <div class="detail__row pair">
                        <div class="detail__item">
                            <h3>Plus / Minus:</h3>
                            <p>{{ milestone.plusMinus ? milestone.plusMinus : 'N/A' }}</p>
                        </div>
                        <div class="detail__item">
                            <h3>PIM:</h3>
                            <p>{{ milestone.penaltyMinutes ? milestone.penaltyMinutes : 'N/A' }}</p>
                        </div>
                    </div>
                    <div class="detail__row pair">
                        <div class="detail__item">
                            <h3>Shots:</h3>
                            <p>{{ milestone.shots ? milestone.shots : 'N/A' }}</p>
                        </div>
                        <div class="detail__item">
                            <h3>Power Play Goals:</h3>
                            <p>{{ milestone.powerPlayGoals ? milestone.powerPlayGoals : 'N/A' }}</p>
                        </div>
                    </div>
                    <div class="detail__row pair">
                        <div class="detail__item">
                            <h3>Power Play Points:</h3>
                            <p>{{ milestone.powerPlayPoints ? milestone.powerPlayPoints : 'N/A' }}</p>
                        </div>
                        <div class="detail__item">
                            <h3>Shorthanded Goals:</h3>
                            <p>{{ milestone.shorthandedGoals ? milestone.shorthandedGoals : 'N/A' }}</p>
                        </div>
                    </div>
                    <div class="detail__row pair">
                        <div class="detail__item">
                            <h3>Shorthanded Points:</h3>
                            <p>{{ milestone.shorthandedPoints ? milestone.shorthandedPoints : 'N/A' }}</p>
                        </div>
                        <div class="detail__item">
                            <h3>Game Winning Goals:</h3>
                            <p>{{ milestone.gameWinningGoals ? milestone.gameWinningGoals : 'N/A' }}</p>
                        </div>
                    </div>
                    <div class="detail__row pair">
                        <div class="detail__item">
                            <h3>Overtime Goals:</h3>
                            <p>{{ milestone.overtimeGoals ? milestone.overtimeGoals : 'N/A' }}</p>
                        </div>
                        <div class="detail__item">
                            <h3>Shooting Percentage:</h3>
                            <p>{{ milestone.shootingPercentage ? milestone.shootingPercentage + ' %' : 'N/A' }}</p>
                        </div>
                    </div>
                </section>

                <section class="player-detail__section" v-if="!milestone && !isLoading">
                    <div class="contract-title__container">
                        <i class="fa-duotone fa-hockey-sticks"></i>
                        <h2>Current Statistics</h2>
                    </div>
                    <div class="no-milestones__container">
                        <h4><i class="fa-duotone fa-folder-magnifying-glass"></i> No statistics found</h4>
                    </div>
                </section>
            </section>
            <section class="contract-detail" v-if="detailTabs.length > 0"> 
                <k-tabstrip :selected="selectedTab" @select="onTabSelected" :tabs="detailTabs">
                    <template v-slot:payouts>
                        <div class="contract-title__container">
                            <i class="fa-duotone fa-money-check-dollar-pen"></i>
                            <h2>Endorsement Payouts</h2>
                        </div>
                        <div class="input__row add-item">
                            <label class="input__label add-item" @click="onAddEndorsementPayout">
                                <i class="fa-sharp fa-solid fa-plus"></i> Add Payout
                            </label>
                        </div>
                        <div :class="['optional-items__container', { 'active': endorsementPayouts.length > 0 }]">
                            <div class="optional-item single row" v-for="(payout, index) in endorsementPayouts" :key="payout.payoutId">
                                <div class="input__row pair">
                                    <k-slide :appear="endorsementPayouts[index] !== null">
                                        <label class="input__label">
                                            *{{ $options.filters.toOrdinal(index) }} Payout Amount
                                            <k-numeric-input :placeholder="'Enter amount'" v-model="payout.payoutAmount" :format="'c2'" :min="0"></k-numeric-input>
                                        </label>
                                        <label class="input__label">
                                            *{{ $options.filters.toOrdinal(index) }} Payout Date
                                            <k-datepicker v-model="payout.payoutDate_formatted"></k-datepicker>
                                        </label>
                                    </k-slide> 
                                </div>
                                <div class="item-delete__container" @click="onDeleteEndorsementPayout(payout)" title="Delete Payout"><i class="fa-solid fa-trash-can"></i></div>
                            </div>
                        </div>
                    </template>
                    <template v-slot:productCredits>
                        <div class="contract-title__container">
                            <i class="fa-duotone fa-money-check-dollar-pen"></i>
                            <h2>Endorsement Product Credits</h2>
                        </div>
                        <div class="input__row add-item">
                            <label class="input__label add-item" @click="onAddEndorsementProductCredit">
                                <i class="fa-sharp fa-solid fa-plus"></i> Add Credit
                            </label>
                        </div>
                        <div :class="['optional-items__container', { 'active': endorsementProductCredits.length > 0 }]">
                            <div class="optional-item single row" v-for="(credit, index) in endorsementProductCredits" :key="credit.productCreditId">
                                <div class="input__row trio">
                                    <k-slide :appear="endorsementProductCredits[index] !== null">
                                        <label class="input__label">
                                            *{{ $options.filters.toOrdinal(index) }} Credit Amount
                                            <k-numeric-input :placeholder="'Enter amount'" v-model="credit.creditAmount" :format="'c2'" :min="0"></k-numeric-input>
                                        </label>
                                        <label class="input__label">
                                            *{{ $options.filters.toOrdinal(index) }} Start Date
                                            <k-datepicker v-model="credit.startDate_formatted"></k-datepicker>
                                        </label>
                                        <label class="input__label">
                                            *{{ $options.filters.toOrdinal(index) }} End Date
                                            <k-datepicker v-model="credit.endDate_formatted"></k-datepicker>
                                        </label>
                                    </k-slide> 
                                </div>
                                <div class="item-delete__container" @click="onDeleteEndorsementProductCredit(credit)" title="Delete Credit"><i class="fa-solid fa-trash-can"></i></div>
                            </div>
                        </div>
                    </template>
                    <template v-slot:performanceBonuses>
                        <div class="contract-title__container">
                            <i class="fa-duotone fa-money-check-dollar-pen"></i>
                            <h2>NHL Performance Bonuses</h2>
                        </div>
                        <div class="input__row add-item">
                            <label class="input__label add-item" @click="onAddPerformanceBonus">
                                <i class="fa-sharp fa-solid fa-plus"></i> Add NHL Performance Bonus
                            </label>
                        </div>
                        <div :class="['optional-items__container', { 'active': performanceBonuses.length > 0 }]">
                            <div class="optional-item performance" v-for="(bonus, index) in performanceBonuses" :key="bonus.bonusId">
                                <k-slide :appear="performanceBonuses[index] !== null && !isLoading">
                                    <div class="performance-bonus__container">
                                        <div class="bonus-rows__container">
                                            <div class="input__row pair">
                                                <label class="input__label">
                                                    *{{ $options.filters.toOrdinal(index) }} Bonus Title
                                                    <k-input v-model="bonus.bonusTitle" @keydown="bonus.hasBonusTitleError = false" :class="[{ 'error': bonus.hasBonusTitleError }]" />
                                                </label>
                                                <label class="input__label">
                                                    *{{ $options.filters.toOrdinal(index) }} Bonus Amount
                                                    <k-numeric-input :placeholder="'Enter amount'" v-model="bonus.bonusAmount" :format="'c2'" :min="0" @change="bonus.hasBonusAmountError = false" :class="[{ 'error': bonus.hasBonusAmountError }]"></k-numeric-input>
                                                </label>
                                            </div>
                                            <div class="input__row full">
                                                <label class="input__label">
                                                    {{ $options.filters.toOrdinal(index) }} Bonus Notes
                                                    <k-input v-model="bonus.notes" />
                                                </label>
                                            </div>
                                        </div>
                                        <div class="item-delete__container" @click="onDeletePerformanceBonus(bonus)" title="Delete Bonus"><i class="fa-solid fa-trash-can"></i></div>
                                    </div>
                                </k-slide>
                            </div>
                        </div>
                    </template>
                    <template v-slot:nhlSigningBonuses>
                        <div class="contract-title__container">
                            <i class="fa-duotone fa-money-check-dollar-pen"></i>
                            <h2>Signing Bonuses</h2>
                        </div>
                        <div class="input__row add-item">
                            <label class="input__label add-item" @click="onAddNhlSigningBonus">
                                <i class="fa-sharp fa-solid fa-plus"></i> Add Signing Bonus
                            </label>
                        </div>
                        <div :class="['optional-items__container', { 'active': nhlSigningBonuses.length > 0 }]">
                            <div class="optional-item single row" v-for="(bonus, index) in nhlSigningBonuses" :key="bonus.bonusId">
                                <div class="input__row pair">
                                    <k-slide :appear="nhlSigningBonuses[index] !== null && !isLoading">
                                        <label class="input__label">
                                            *{{ $options.filters.toOrdinal(index) }} Bonus Date
                                            <k-datepicker v-model="bonus.bonusDate_formatted"></k-datepicker>
                                        </label>
                                        <label class="input__label">
                                            *{{ $options.filters.toOrdinal(index) }} Bonus Amount
                                            <k-numeric-input :placeholder="'Enter amount'" v-model="bonus.bonusAmount" :format="'c2'" :min="0"></k-numeric-input>
                                        </label>
                                    </k-slide>
                                </div>
                                <div class="item-delete__container" @click="onDeleteNhlSigningBonus(bonus)" title="Delete Bonus"><i class="fa-solid fa-trash-can"></i></div>
                            </div>
                        </div>
                    </template>
                    <template v-slot:elcPerformanceBonuses>
                        <div class="contract-title__container">
                            <i class="fa-duotone fa-money-check-dollar-pen"></i>
                            <h2>ELC Performance Bonuses</h2>
                        </div>
                        <div class="input__row add-item">
                            <label class="input__label add-item" @click="onAddElcPerformanceBonus">
                                <i class="fa-sharp fa-solid fa-plus"></i> Add ELC Performance Bonus
                            </label>
                        </div>
                        <div :class="['optional-items__container', { 'active': elcPerformanceBonuses.length > 0 }]">
                            <div class="optional-item performance" v-for="(bonus, index) in elcPerformanceBonuses" :key="bonus.bonusId">
                                <k-slide :appear="elcPerformanceBonuses[index] !== null && !isLoading">
                                    <div class="performance-bonus__container">
                                        <div class="bonus-rows__container">
                                            <div class="input__row pair">
                                                <label class="input__label">
                                                    *Bonus Amount
                                                    <k-numeric-input :placeholder="'Enter amount'" v-model="bonus.bonusAmount" :format="'c2'" :min="0"></k-numeric-input>
                                                </label>
                                                <label class="input__label">
                                                    *Schedule Type
                                                    <k-dropdown v-if="bonus.scheduleType"
                                                                :data-items="elcBonusScheduleTypeSelects"
                                                                v-model="bonus.scheduleType"></k-dropdown>

                                                    <k-dropdown v-if="!bonus.scheduleType"
                                                                :data-items="elcBonusScheduleTypeSelects"
                                                                :default-item="'Select Type'"
                                                                @change="elcBonusScheduleSelected($event, 'Select Type', index)"></k-dropdown>
                                                </label>
                                            </div>
                                            <div class="input__row full">
                                                <label class="input__label">
                                                    Bonus Notes
                                                    <k-input v-model="bonus.notes" />
                                                </label>
                                            </div>
                                        </div>
                                        <div class="item-delete__container" @click="onDeleteElcPerformanceBonus(bonus)" title="Delete Bonus"><i class="fa-solid fa-trash-can"></i></div>
                                    </div>
                                </k-slide>
                            </div>
                        </div>
                    </template>
                    <template v-slot:salaryDetails>
                        <div class="contract-title__container">
                            <i class="fa-duotone fa-money-check-dollar"></i>
                            <h2>Yearly Salary Details</h2>
                        </div>
                        <div v-if="contract.nhlContractType === ahlNhlTwoWayContractType" :class="['optional-items__container', { 'active': ahlNhlTwoWayContractDetails.length > 0 }]">
                            <div v-for="detail in ahlNhlTwoWayContractDetails" :key="detail.detailId">
                                <p class="salary-season__label">Season: {{ `${detail.startYear} / ${detail.startYear + 1}` }}</p>
                                <div class="optional-item single two-way-contract">
                                    <div class="input__row trio">
                                        <label class="input__label">
                                            AHL Amount
                                            <k-numeric-input :placeholder="'Enter amount'" v-model="detail.ahlTwoWayAmount" :format="'c2'" :min="0"></k-numeric-input>
                                        </label>
                                        <label class="input__label">
                                            Total Payable Days
                                            <k-numeric-input :placeholder="'Enter days'" v-model="detail.ahlTotalPayableDays" :min="0"></k-numeric-input>
                                        </label>
                                        <label class="input__label">
                                            AHL Daily Salary
                                            <k-numeric-input :placeholder="'Enter amount'" v-model="detail.ahlDailySalary" :format="'c2'" :min="0"></k-numeric-input>
                                        </label>
                                    </div>
                                    <div class="input__row trio">
                                        <label class="input__label">
                                            NHL Amount
                                            <k-numeric-input :placeholder="'Enter amount'" v-model="detail.nhlTwoWayAmount" :format="'c2'" :min="0"></k-numeric-input>
                                        </label>
                                        <label class="input__label">
                                            Total Payable Days
                                            <k-numeric-input :placeholder="'Enter days'" v-model="detail.nhlTotalPayableDays" :min="0"></k-numeric-input>
                                        </label>
                                        <label class="input__label">
                                            NHL Daily Salary
                                            <k-numeric-input :placeholder="'Enter amount'" v-model="detail.nhlDailySalary" :format="'c2'" :min="0"></k-numeric-input>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-if="contract.nhlContractType === nhlOneWayContractType" :class="['optional-items__container', { 'active': nhlOneWayContractDetails.length > 0 }]">
                            <div class="optional-item single" v-for="detail in nhlOneWayContractDetails" :key="detail.detailId">
                                <p class="salary-season__label">Season: {{ `${detail.startYear} / ${detail.startYear + 1}` }}</p>
                                <div class="input__row trio">
                                    <label class="input__label">
                                        NHL Amount
                                        <k-numeric-input :placeholder="'Enter amount'" v-model="detail.nhlOneWayAmount" :format="'c2'" :min="0"></k-numeric-input>
                                    </label>
                                    <label class="input__label">
                                        Total Payable Days
                                        <k-numeric-input :placeholder="'Enter amount'" v-model="detail.nhlTotalPayableDays" :min="0"></k-numeric-input>
                                    </label>
                                    <label class="input__label">
                                        NHL Daily Salary
                                        <k-numeric-input :placeholder="'Enter amount'" v-model="detail.nhlOneWayDailySalary" :format="'c2'" :min="0"></k-numeric-input>
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div v-if="contract.nhlContractType === echlContractType" :class="['optional-items__container', { 'active': echlContractDetails.length > 0 }]">
                            <div class="optional-item single echl-contract">
                                <div class="input__row pair" v-for="detail in echlContractDetails" :key="detail.detailId">
                                    <label class="input__label">
                                        ECHL Amount (Season: {{ `${detail.startYear} / ${detail.startYear + 1}` }})
                                        <k-numeric-input :placeholder="'Enter amount'" v-model="detail.echlAmount" :format="'c2'" :min="0"></k-numeric-input>
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div v-if="contract.nhlContractType === elcContractType" :class="['optional-items__container', { 'active': elcContractDetails.length > 0 }]">
                            <div class="optional-item single" v-for="detail in elcContractDetails" :key="detail.detailId">
                                <p class="salary-season__label">Season: {{ `${detail.startYear} / ${detail.startYear + 1}` }}</p>
                                <div class="input__row pair">
                                    <label class="input__label">
                                        AHL Salary
                                        <k-numeric-input :placeholder="'Enter amount'" v-model="detail.ahlSalary" :format="'c2'" :min="0"></k-numeric-input>
                                    </label>
                                    <label class="input__label">
                                        NHL Salary
                                        <k-numeric-input :placeholder="'Enter amount'" v-model="detail.nhlSalary" :format="'c2'" :min="0"></k-numeric-input>
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div v-if="contract.nhlContractType === ahlOneWayContractType" :class="['optional-items__container', { 'active': ahlOneWayContractDetails.length > 0 }]">
                            <div class="optional-item single" v-for="detail in ahlOneWayContractDetails" :key="detail.detailId">
                                <p class="salary-season__label">Season: {{ `${detail.startYear} / ${detail.startYear + 1}` }}</p>
                                <div class="input__row trio">
                                    <label class="input__label">
                                        AHL Amount
                                        <k-numeric-input :placeholder="'Enter amount'" v-model="detail.ahlOneWayAmount" :format="'c2'" :min="0"></k-numeric-input>
                                    </label>
                                    <label class="input__label">
                                        Total Payable Days
                                        <k-numeric-input :placeholder="'Enter days'" v-model="detail.ahlTotalPayableDays" :min="0"></k-numeric-input>
                                    </label>
                                    <label class="input__label">
                                        AHL Daily Salary
                                        <k-numeric-input :placeholder="'Enter amount'" v-model="detail.ahlOneWayDailySalary" :format="'c2'" :min="0"></k-numeric-input>
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div v-if="contract.nhlContractType === ahlTwoWayContractType" :class="['optional-items__container', { 'active': ahlTwoWayContractDetails.length > 0 }]">
                            <div v-for="detail in ahlTwoWayContractDetails" :key="detail.detailId">
                                <p class="salary-season__label">Season: {{ `${detail.startYear} / ${detail.startYear + 1}` }}</p>
                                <div class="optional-item single two-way-contract">
                                    <div class="input__row trio">
                                        <label class="input__label">
                                            ECHL Amount
                                            <k-numeric-input :placeholder="'Enter amount'" v-model="detail.echlTwoWayAmount" :format="'c2'" :min="0"></k-numeric-input>
                                        </label>
                                        <label class="input__label">
                                            Total Payable Days
                                            <k-numeric-input :placeholder="'Enter days'" v-model="detail.echlTotalPayableDays" :min="0"></k-numeric-input>
                                        </label>
                                        <label class="input__label">
                                            ECHL Daily Salary
                                            <k-numeric-input :placeholder="'Enter amount'" v-model="detail.echlDailySalary" :format="'c2'" :min="0"></k-numeric-input>
                                        </label>
                                    </div>
                                    <div class="input__row trio">
                                        <label class="input__label">
                                            AHL Amount
                                            <k-numeric-input :placeholder="'Enter amount'" v-model="detail.ahlTwoWayAmount" :format="'c2'" :min="0"></k-numeric-input>
                                        </label>
                                        <label class="input__label">
                                            Total Payable Days
                                            <k-numeric-input :placeholder="'Enter days'" v-model="detail.ahlTotalPayableDays" :min="0"></k-numeric-input>
                                        </label>
                                        <label class="input__label">
                                            AHL Daily Salary
                                            <k-numeric-input :placeholder="'Enter amount'" v-model="detail.ahlDailySalary" :format="'c2'" :min="0"></k-numeric-input>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </template>
                </k-tabstrip>
            </section>
        </div>

        <upsert-document-dialog :show-dialog="showUploadDocumentDialog"
                                    :player-id="playerId"
                                    :contract-id="contractId"
                                    :default-folder-name="documentFolderNames.contractsFolderName"
                                    :current-document="contractDocuments.length > 0 ? contractDocuments[0] : null"
                                    @dialogclosed="toggleUploadDocumentDialog"
                                    @documentsuploaded="assignAndFormatData"></upsert-document-dialog>

        <confirm-dialog :showDialog="showConfirmDialog"
                        :dialog-Msg="confirmMsg"
                        :icon-class="confirmIconClass"
                        @dialogclosed="toggleConfirmDialog"
                        @cancelclicked="toggleConfirmDialog"
                        @confirmclicked="deleteItem"></confirm-dialog>

        <div class="loader-container" v-if="isLoading">
            <k-loader :size="'large'" :type="'infinite-spinner'"></k-loader>
        </div>

        <transition name="dialogfade">
            <k-dialog v-if="showErrorDialog" :title="errorTitle" class="error__dialog" @close="toggleErrorDialog">
                <p class="error-dialog__msg">{{ errorMsg }}</p>
            </k-dialog>
        </transition>

        <k-notification-group class="default-notification-container">
                <k-fade :appear="successNotification">
                    <k-notification v-if="successNotification"
                                    :type="{ style: 'success', icon: true }"
                                    :closable="true"
                                    @close="onCloseNotification('successNotification')">
                        <span>{{ notificationMsg }}</span>
                    </k-notification>
                </k-fade>
        </k-notification-group>
    </div>
</template>

<script>
    import { Loader } from "@progress/kendo-vue-indicators";
    import { DropDownList } from '@progress/kendo-vue-dropdowns';
    import { Input, NumericTextBox, TextArea, Checkbox } from "@progress/kendo-vue-inputs";
    import { DatePicker } from '@progress/kendo-vue-dateinputs';
    import { Button } from "@progress/kendo-vue-buttons";
    import { Slide, Fade } from '@progress/kendo-vue-animation';
    import { TabStrip } from '@progress/kendo-vue-layout';
    import { Dialog } from '@progress/kendo-vue-dialogs';
    import { Notification, NotificationGroup } from "@progress/kendo-vue-notification";

    import { filterBy } from '@progress/kendo-data-query';

    import { useVuelidate } from '@vuelidate/core';
    import { required, email, requiredIf } from '@vuelidate/validators';

    import viewHeader from '../components/viewHeader.vue';
    import confirmDialog from '../components/confirmDialog.vue';
    import upsertDocumentDialog from '../components/upsertDocumentDialog.vue';

    import isEqualWith from 'lodash.isequalwith';
    import cloneDeep from 'lodash.clonedeep';
    import date from 'date-and-time';

    import auth from '../authConfig';
    import apiService from '../api/api.service.js';
    import contractHelpers from '../helpers/contractHelpers.js';
    import formatHelpers from '../helpers/formatHelpers.js';
    import documentHelpers from '../helpers/documentHelpers.js';

    export default {
        setup() {
            return { contract$: useVuelidate() }
        },
        name: 'contractManagementView',
        props: ['agentDetail', 'detailContractId'],
        emits: ['returntodashboard'],
        data() {
            return {
                ahlNhlTwoWayContractDetails: [],
                ahlOneWayContractDetails: [],
                ahlTwoWayContractDetails: [],
                allContractTabs: [],
                allPlayers: [],
                apiPlayerId: '',
                apiPlayerTeamId: '',
                associatedAgencyContract: null,
                confirmIconClass: '',
                confirmMsg: '',
                contract: {},
                contractDocuments: [],
                contractId: '',
                deletedItem: null,
                deletedItemType: '',
                detailTabs: [],
                echlContractDetails: [],
                elcContractDetails: [],
                elcPerformanceBonuses: [],
                elcPerformanceBonusModel: { notes: null, bonusAmount: null, scheduleType: null, contractId: '', bonusId: '', new: true },
                endorsementContractTabs: [
                    { title: 'Payouts', content: 'payouts' },
                    { title: 'Product Credits', content: 'productCredits' }        
                ],
                endorsementPayouts: [],
                endorsementPayoutModel: { payoutAmount: null, payoutDate: null, payoutDate_formatted: null, contractId: '', payoutId: '', new: true },
                endorsementProductCredits: [],
                endorsementProductCreditModel: { creditAmount: null, startDate: null, startDate_formatted: null, endDate: null, endDate_formatted: null, contractId: '', productCreditId: '', new: true },
                equipmentContractTabs: [
                    { title: 'Payouts', content: 'payouts' },
                    { title: 'Product Credits', content: 'productCredits' }        
                ],
                errorMsg: '',
                errorTitle: '',
                filteredPlayers: [],
                isLoading: true,
                milestone: null,
                nhlOneWayContractDetails: [],
                nhlSigningBonuses: [],
                nhlSigningBonusModel: { bonusDate: null, bonusDate_formatted: null, bonusAmount: null, contractId: '', bonusId: '', new: true, playerId: null },
                notificationMsg: '',
                noTradeClauses: [],
                noTradeClauseModel: { dueDate: null, playerId: null, contractId: null, notes: null, status: null, reportToTeam: null },
                optionalEndorsementPayoutType: 'endorsementpayout',
                optionalEndorsementProductCreditType: 'endorsementproductcredit',
                optionalELCBonusType: 'elcbonus',
                optionalNHLBonusType: 'nhlbonus',
                optionalPerformanceBonusType: 'performancebonus',
                performanceBonuses: [],
                performanceBonusModel: { notes: null, bonusAmount: null, bonusTitle: null, bonusId: '', contractId: '', new: true },
                player: {},
                playerContracts: [],
                playingContractTabs: [
                    {
                        title: 'Salary Details',
                        content: 'salaryDetails'
                    },
                    {
                        title: 'Signing Bonus',
                        content: 'nhlSigningBonuses'
                    },
                    {
                        title: 'NHL Bonus',
                        content: 'performanceBonuses'
                    },
                    {
                        title: 'ELC Bonus',
                        content: 'elcPerformanceBonuses'
                    }
                ],
                playerId: null,
                salaryDetailCurrencyFields: ['ahlDailySalary', 'nhlDailySalary', 'ahlTwoWayAmount', 'nhlTwoWayAmount', 
                    'ahlOneWayDailySalary', 'nhlOneWayDailySalary', 'echlTwoWayAmount', 'ahlDailySalary', 'echlDailySalary'],
                selectedPlayer: {},
                selectedTab: 0,
                showConfirmDialog: false,
                showErrorDialog: false,
                showUploadDocumentDialog: false,
                successNotification: false,
                uneditedAhlNhlContractDetails: [],
                uneditedAhlOneWayContractDetails: [],
                uneditedAhlTwoWayContractDetails: [],
                uneditedContract: {},
                uneditedEchlContractDetails: [],
                uneditedElcContractDetails: [],
                uneditedElcPerformanceBonuses: [],
                uneditedEndorsementPayouts: [],
                uneditedEndorsementProductCredits: [],
                uneditedNhlOneWayContractDetails: [],
                uneditedNhlSigningBonuses: [],
                uneditedNoTradeClauses: [],
                uneditedPerformanceBonuses: [],
                uneditedPlayer: {}
            }
        },
        validations() {
            return {
                contract: {
                    contractTitle: { required },
                    contractType: { required },
                    billingContactEmail: { email },
                    nhlContractStartYear_formatted: { 
                        required: requiredIf(() => {
                            return this.contract.contractType === this.playingContractType;
                        })
                    },
                    nhlContractEndYear_formatted: { 
                        required: requiredIf(() => {
                            return this.contract.contractType === this.playingContractType;
                        })
                    },
                    nhlContractType: {
                        required: requiredIf(() => {
                            return this.contract.contractType === this.playingContractType;
                        })
                    },
                    endorsementSigningDate_formatted: {
                        required: requiredIf(() => {
                            return this.contract.contractType === this.endorsementContractType;
                        })
                    },
                    spacSigningDate_formatted: {
                        required: requiredIf(() => {
                            return this.contract.contractType === this.agencyContractType;
                        })
                    },
                    equipmentSigningDate_formatted: {
                        required: requiredIf(() => {
                            return this.contract.contractType === this.equipmentContractType;
                        })
                    },
                    equipmentContract: {
                        required: requiredIf(() => {
                            return this.contract.contractType === this.equipmentContractType;
                        })
                    },
                    endorsementCompanyName: {
                        required: requiredIf(() => {
                            return this.contract.contractType === this.endorsementContractType;
                        })
                    },
                    limitedModifiedDate_formatted: {
                        required: requiredIf(() => {
                            return (this.contract.limitedModifiedNoTradeClause && this.contract.limitedModifiedOption === 'Specified Date');
                        })
                    },
                    limitedModifiedStartDate_formatted: {
                        required: requiredIf(() => {
                            return (this.contract.limitedModifiedNoTradeClause && this.contract.limitedModifiedOption === 'Specified Date');
                        })
                    },
                    limitedModifiedEndDate_formatted: {
                        required: requiredIf(() => {
                            return (this.contract.limitedModifiedNoTradeClause && this.contract.limitedModifiedOption === 'Specified Date');
                        })
                    }
                },
                selectedPlayer: {
                    id: { required}
                }
            }
        },
        computed: {
            agencyContractType() {
                return contractHelpers.agencyContractType;
            },
            ahlNhlTwoWayContractType() {
                return contractHelpers.ahlNhlTwoWayContractType;
            },
            ahlOneWayContractType() {
                return contractHelpers.ahlOneWayContractType;
            },
            ahlTwoWayContractType() {
                return contractHelpers.ahlTwoWayContractType;
            },
            contractAgentSelects() {
                return contractHelpers.contractAgentSelects;
            },
            contractDateFields() {
                return contractHelpers.contractDateFields;
            },
            contractNhlExpirationStatusSelects() {
                return contractHelpers.contractNhlExpirationStatusSelects;
            },
            contractNhlTypeSelects() {
                return contractHelpers.contractNhlTypeSelects;
            },
            contractSpcBillingTypeSelects() {
                return contractHelpers.contractSpcBillingTypeSelects;
            },
            documentFolderNames() {
                return documentHelpers.documentFolderNames;
            },
            echlContractType() {
                return contractHelpers.echlContractType;
            },
            elcBonusScheduleTypeSelects() {
                return contractHelpers.elcBonusScheduleTypeSelects;
            },
            elcContractType() {
                return contractHelpers.elcContractType;
            },
            elcPerformanceBonusDateFields() {
                return contractHelpers.performanceBonusDateFields;
            },
            endorsementContractType() {
                return contractHelpers.endorsementContractType;
            },
            endorsementContractTypeSelects() {
                return contractHelpers.endorsementContractTypeSelects;
            },
            endorsementPayoutDateFields() {
                return contractHelpers.endorsementPayoutDateFields;
            },
            endorsementProductCreditDateFields() {
                return contractHelpers.endorsementProductCreditDateFields;
            },
            equipmentContractSelects() {
                return contractHelpers.contractEquipmentContractSelects;
            },
            equipmentContractType() {
                return contractHelpers.equipmentContractType;
            },
            limitedModifiedNoTradeClauseTypes() {
                return contractHelpers.limitedModifiedNoTradeClauseTypes;
            },
            nhlOneWayContractType() {
                return contractHelpers.nhlOneWayContractType;
            },
            nhlSigningBonusDateFields() {
                return contractHelpers.nhlSigningBonusDateFields;
            },
            nhlTwoWayContractType() {
                return contractHelpers.nhlTwoWayContractType;
            },
            noTradeClauseDateFields() {
                return contractHelpers.noTradeClauseDateFields;
            },
            noTradeClauseStatusSelects() {
                return contractHelpers.noTradeClauseStatusSelects;
            },
            performanceBonusDateFields() {
                return contractHelpers.performanceBonusDateFields;
            },
            playingContractType() {
                return contractHelpers.playingContractType;
            },
            saveChangesBtnDisabled() {
                let contractEqual = this.isEqualWith(this.contract, this.uneditedContract);

                let elcPerformanceBonusesEqual = this.isEqualWith(this.elcPerformanceBonuses, this.uneditedElcPerformanceBonuses);

                let endorsementPayoutsEqual = this.isEqualWith(this.endorsementPayouts, this.uneditedEndorsementPayouts);

                let endorsementProductCreditsEqual = this.isEqualWith(this.endorsementProductCredits, this.uneditedEndorsementProductCredits)

                let nhlSigningBonuesEqual = this.isEqualWith(this.nhlSigningBonuses, this.uneditedNhlSigningBonuses);

                let performanceBonusesEqual = this.isEqualWith(this.performanceBonuses, this.uneditedPerformanceBonuses);

                let ahlNhlTwoWayContractDetailsEqual = this.isEqualWith(this.ahlNhlTwoWayContractDetails, this.uneditedAhlNhlContractDetails);

                let ahlOneWayContractDetailsEqual = this.isEqualWith(this.ahlOneWayContractDetails, this.uneditedAhlOneWayContractDetails);

                let ahlTwoWayContractDetailsEqual = this.isEqualWith(this.ahlTwoWayContractDetails, this.uneditedAhlTwoWayContractDetails);

                let echlContractDetailsEqual = this.isEqualWith(this.echlContractDetails, this.uneditedEchlContractDetails);

                let elcContractDetailsEqual = this.isEqualWith(this.elcContractDetails, this.uneditedElcContractDetails);

                let nhlOneWayContractDetailsEqual = this.isEqualWith(this.nhlOneWayContractDetails, this.uneditedNhlOneWayContractDetails);

                let noTradeClausesEqual = this.isEqualWith(this.noTradeClauses, this.uneditedNoTradeClauses);

                if (contractEqual && elcPerformanceBonusesEqual && endorsementPayoutsEqual && endorsementProductCreditsEqual && nhlSigningBonuesEqual && performanceBonusesEqual
                    && ahlNhlTwoWayContractDetailsEqual && ahlOneWayContractDetailsEqual && ahlTwoWayContractDetailsEqual && echlContractDetailsEqual && elcContractDetailsEqual
                    && nhlOneWayContractDetailsEqual && noTradeClausesEqual) return true;

                return false;
            },
            showNoTradeClauses() {
                if (this.contract.limitedModifiedNoTradeClause && this.contract.limitedModifiedOption === 'Specified Date') {
                    return true;
                }
                
                return false;
            },
            showTotalContractValue() {
                return this.contract.contractType === 'Playing Contract';
            },
            totalCommission() {
                let agencyContract;

                let agencyContracts = cloneDeep(this.$store.state.contracts.filter(c => c.playerId === this.uneditedPlayer.id && c.contractType === this.agencyContractType));
                agencyContracts.sort((a, b) => b.signing_field - a.signing_field);
                
                if (agencyContracts.length > 0) {
                    agencyContract = agencyContracts.find(contract => contract.id === this.contract.associatedContractId);

                    if (!agencyContract) {
                        agencyContract = agencyContracts[0];
                    }

                    if (!agencyContract.playingCommissionPercent) return 0;

                    return this.totalContractValue * (agencyContract.playingCommissionPercent / 100);
                }

                return 0
            },
            totalContractValue() {
                if (this.contract.contractType === 'Playing Contract') {
                    let totalSalaries;

                    if (this.contract.nhlContractType === this.ahlNhlTwoWayContractType) {
                        totalSalaries = this.ahlNhlTwoWayContractDetails.reduce((prev, cur) => {
                            return prev + cur.nhlTwoWayAmount;
                        }, 0);
                    }

                    if (this.contract.nhlContractType === this.nhlOneWayContractType) {
                        totalSalaries = this.nhlOneWayContractDetails.reduce((prev, cur) => {
                            return prev + cur.nhlOneWayAmount;
                        }, 0);
                    }

                    if (this.contract.nhlContractType === this.ahlOneWayContractType) {
                        totalSalaries = this.ahlOneWayContractDetails.reduce((prev, cur) => {
                            return prev + cur.ahlOneWayAmount;
                        }, 0);
                    }

                    if (this.contract.nhlContractType === this.ahlTwoWayContractType) {
                        totalSalaries = this.ahlTwoWayContractDetails.reduce((prev, cur) => {
                            return prev + cur.ahlTwoWayAmount;
                        }, 0);
                    }

                    if (this.contract.nhlContractType === this.elcContractType) {
                        totalSalaries = this.elcContractDetails.reduce((prev, cur) => {
                            return prev + cur.nhlSalary;
                        }, 0);
                    }

                    let totalSigningBonuses = this.nhlSigningBonuses.reduce((prev, cur) => {
                        return prev + cur.bonusAmount;
                    }, 0);

                    return totalSalaries + totalSigningBonuses;
                }

                return 0;
            }
        },
        watch: {
            ahlOneWayContractDetails: {
                handler(val) {
                    if (val.length === 0) return;

                    val.forEach(item => {
                        if (item.ahlOneWayAmount) {
                            if (item.ahlTotalPayableDays) {
                                item.ahlOneWayDailySalary = item.ahlOneWayAmount / item.ahlTotalPayableDays;
                            } else {
                                item.ahlOneWayDailySalary = null;
                            }
                        }
                    });
                },
                deep: true
            },
            ahlTwoWayContractDetails: {
                handler(val) {
                    if (val.length === 0) return;

                    val.forEach(item => {
                        if (item.ahlTwoWayAmount) {
                            if (item.ahlTotalPayableDays) {
                                item.ahlDailySalary = item.ahlTwoWayAmount / item.ahlTotalPayableDays;
                            } else {
                                item.ahlDailySalary = null;
                            }
                        }

                        if (item.echlTwoWayAmount) {
                            if (item.echlTotalPayableDays) {
                                item.echlDailySalary = item.echlTwoWayAmount / item.echlTotalPayableDays;
                            } else {
                                item.echlDailySalary = null;
                            }
                        }
                    })
                }, 
                deep: true
            },
            ahlNhlTwoWayContractDetails: {
                handler(val) {
                    if (val.length === 0) return;

                    val.forEach(item => {
                        if (item.ahlTwoWayAmount) {
                            if (item.ahlTotalPayableDays) {
                                item.ahlDailySalary = item.ahlTwoWayAmount / item.ahlTotalPayableDays;
                            } else {
                                item.ahlDailySalary = null;
                            }
                        }

                        if (item.nhlTwoWayAmount) {
                            if (item.nhlTotalPayableDays) {
                                item.nhlDailySalary = item.nhlTwoWayAmount / item.nhlTotalPayableDays;
                            } else {
                                item.nhlDailySalary = null;
                            }
                        }
                    });
                },
                deep: true
            },
            nhlOneWayContractDetails: {
                handler(val) {
                    if (val.length === 0) return;

                    val.forEach(item => {
                        if (item.nhlOneWayAmount) {
                            if (item.nhlTotalPayableDays) {
                                item.nhlOneWayDailySalary = item.nhlOneWayAmount / item.nhlTotalPayableDays;
                            } else {
                                item.nhlOneWayDailySalary = null;
                            }
                        }
                    });
                },
                deep: true
            },
            'contract.limitedModifiedDate_formatted'(val) {
                if (val && val != this.uneditedContract.limitedModifiedDate) {
                    this.noTradeClauses.forEach(clause => {
                        clause.dueDate_formatted.setMonth(val.getMonth());
                        clause.dueDate_formatted.setDate(val.getDate());
                    });
                }
            },
            // 'contract.limitedModifiedNumberTeams'(val) {
            //     this.noTradeClauses.forEach(clause => {
            //         clause.numberTeams = val;
            //     });
            // }
        },
        async mounted() {
            await this.getNhlApiTeams();

            if (this.agentDetail) {
                this.contractId = this.detailContractId;
            } else {
                this.contractId = this.$route.query.id;
            }

            await this.getContractDetail();

            this.getPlayers()
        },
        methods: {
            async assignAndFormatData(data) {
                this.milestone = data.milestone;

                this.uneditedContract = data.contract;
                this.uneditedPlayer = data.player;
                this.playerId = this.uneditedPlayer.id;

                if (this.uneditedContract.contractType != this.agencyContractType) {
                    await this.getPlayerContracts();
                }

                this.uneditedElcPerformanceBonuses = data.elcPerformanceBonuses;
                this.uneditedEndorsementPayouts = data.endorsementPayouts;
                this.uneditedEndorsementProductCredits = data.endorsementProductCredits;
                this.uneditedNhlSigningBonuses = data.nhlSigningBonuses;
                this.uneditedPerformanceBonuses = data.performanceBonuses;
                this.uneditedAhlNhlContractDetails = data.ahlNhlTwoWayContractDetails;
                this.uneditedAhlOneWayContractDetails = data.ahlOneWayContractDetails;
                this.uneditedAhlTwoWayContractDetails = data.ahlTwoWayContractDetails;
                this.uneditedEchlContractDetails = data.echlContractDetails;
                this.uneditedElcContractDetails = data.elcContractDetails;
                this.uneditedNhlOneWayContractDetails = data.nhlOneWayContractDetails;
                this.uneditedNoTradeClauses = data.noTradeClauses;

                data.contractDocuments = this.formatHockeyDocumentDates(data.contractDocuments);
                this.contractDocuments = data.contractDocuments.sort((a, b) => a.documentName.localeCompare(b.documentName));

                this.uneditedEndorsementPayouts.forEach(payout => {
                    this.formatDateFields('endorsementPayout', payout);
                });

                this.uneditedEndorsementProductCredits.forEach(credit => {
                    this.formatDateFields('endorsementProductCredit', credit);
                })

                this.uneditedNhlSigningBonuses.forEach(bonus => {
                    this.formatDateFields('nhlSigningBonus', bonus);
                });

                this.uneditedNoTradeClauses.forEach(clause => {
                    this.formatDateFields('noTradeClause', clause);
                });

                this.uneditedNoTradeClauses.sort((a, b) => a.dueDate_formatted - b.dueDate_formatted);
                this.uneditedEndorsementPayouts.sort((a, b) => a.payoutDate_formatted - b.payoutDate_formatted);
                this.uneditedEndorsementProductCredits.sort((a, b) => a.startDate_formatted - b.startDate_formatted);
                this.uneditedNhlSigningBonuses.sort((a, b) => a.bonusDate_formatted - b.bonusDate_formatted);
                this.uneditedPerformanceBonuses.sort((a, b) => a.bonusDate_formatted - b.bonusDate_formatted);
                this.uneditedElcPerformanceBonuses.sort((a, b) => a.bonusDate_formatted - b.bonusDate_formatted);

                this.uneditedAhlNhlContractDetails.sort((a, b) => a.year - b.year);
                this.uneditedAhlOneWayContractDetails.sort((a, b) => a.year - b.year);
                this.uneditedAhlTwoWayContractDetails.sort((a, b) => a.year - b.year);
                this.uneditedEchlContractDetails.sort((a, b) => a.year - b.year);
                this.uneditedElcContractDetails.sort((a, b) => a.year - b.year);
                this.uneditedNhlOneWayContractDetails.sort((a, b) => a.year - b.year);

                this.formatDateFields('contract', this.uneditedContract);

                this.assignEditableContractProps();

                if (!this.milestone) {
                    await this.getPlayerMilestone();
                }
            },
            assignDetailTabs() {
                if (this.contract.contractType === this.endorsementContractType) {
                    return this.detailTabs = this.endorsementContractTabs;
                }

                if (this.contract.contractType === this.equipmentContractType) {
                    return this.detailTabs = this.equipmentContractTabs;
                }

                if (this.contract.contractType === this.playingContractType) {
                    return this.detailTabs = this.playingContractTabs;
                }

                return this.detailTabs = [];
            },
            assignEditableContractProps() {
                this.contract = cloneDeep(this.uneditedContract);
                this.player = cloneDeep(this.uneditedPlayer);
                this.elcPerformanceBonuses = cloneDeep(this.uneditedElcPerformanceBonuses);
                this.endorsementPayouts = cloneDeep(this.uneditedEndorsementPayouts);
                this.endorsementProductCredits = cloneDeep(this.uneditedEndorsementProductCredits);
                this.nhlSigningBonuses = cloneDeep(this.uneditedNhlSigningBonuses);
                this.performanceBonuses = cloneDeep(this.uneditedPerformanceBonuses);
                this.noTradeClauses = cloneDeep(this.uneditedNoTradeClauses);

                this.ahlNhlTwoWayContractDetails = cloneDeep(this.uneditedAhlNhlContractDetails);
                this.ahlOneWayContractDetails = cloneDeep(this.uneditedAhlOneWayContractDetails);
                this.ahlTwoWayContractDetails = cloneDeep(this.uneditedAhlTwoWayContractDetails);
                this.echlContractDetails = cloneDeep(this.uneditedEchlContractDetails);
                this.elcContractDetails = cloneDeep(this.uneditedElcContractDetails);
                this.nhlOneWayContractDetails = cloneDeep(this.uneditedNhlOneWayContractDetails);

                this.assignDetailTabs();
            },
            buildAgencyContractName() {
                if (this.selectedPlayer.id && this.contract.spacSigningDate_formatted) {
                    this.contract.contractTitle = `${this.selectedPlayer.fullName} - Agency - ${this.contract.spacSigningDate_formatted.getFullYear()}`;
                }
            },
            buildEndorsementContractName() {
                if (this.selectedPlayer.id && this.contract.endorsementSigningDate_formatted && this.contract.endorsementCompanyName) {
                    this.contract.contractTitle = `${this.selectedPlayer.fullName} -  ${this.contract.endorsementCompanyName} - ${this.contract.endorsementSigningDate_formatted.toLocaleDateString()}`;
                }
            },
            buildEquipmentContractName() {
                if (this.selectedPlayer.id && this.contract.equipmentSigningDate_formatted && this.contract.equipmentContract) {
                    this.contract.contractTitle = `${this.selectedPlayer.fullName} - ${this.contract.equipmentContract} - ${this.contract.equipmentSigningDate_formatted.toLocaleDateString()}`;
                }
            },
            buildPlayingContractName() {
                let contractAbbr = this.getNhlContractTypeAbbreviation();

                if (this.selectedPlayer.id && this.contract.nhlContractType && this.contract.nhlContractStartYear_formatted && this.contract.nhlContractEndYear_formatted) {
                    this.contract.contractTitle = `${this.selectedPlayer.fullName} - ${contractAbbr} - ${this.contract.nhlContractStartYear_formatted.getFullYear()}/${this.contract.nhlContractEndYear_formatted.getFullYear()}`;
                }
            },
            defaultDropdownSelected(evt, field, defaultValue) {
                if (evt.target.value === defaultValue) {
                    return this.contract[field] = null;
                }

                this.contract[field] = evt.target.value;
            },
            async deleteElcPerformanceBonus() {
                let self = this;
                let tokenResponse = await auth.acquireTokenResponse();

                return apiService.DeleteELCPerformanceBonus(tokenResponse.accessToken, this.deletedItem.bonusId, this.contract.contractId) 
                    .then((res) => {
                        self.uneditedElcPerformanceBonuses = res.data.data;

                        self.uneditedElcPerformanceBonuses.sort((a, b) => b.bonusAmount - a.bonusAmount);
                        self.elcPerformanceBonuses = cloneDeep(self.uneditedElcPerformanceBonuses);

                        self.isLoading = false;

                        self.showNotification('Bonus deleted successfully');
                    }).catch(error => {
                        console.log(error);
                        self.onRequestError('An Error Occurred', error?.response?.data?.message);
                    });
            },
            async deleteEndorsementPayout() {
                let self = this;
                let tokenResponse = await auth.acquireTokenResponse();

                return apiService.DeleteEndorsementPayout(tokenResponse.accessToken, this.deletedItem.payoutId, this.contract.contractId) 
                    .then((res) => {
                        self.uneditedEndorsementPayouts = res.data.data;

                        self.uneditedEndorsementPayouts.forEach(payout => {
                            self.formatDateFields('endorsementPayout', payout);
                        });

                        self.uneditedEndorsementPayouts.sort((a, b) => b.payoutDate_formatted - a.payoutDate_formatted);
                        self.endorsementPayouts = cloneDeep(self.uneditedEndorsementPayouts);

                        self.isLoading = false;

                        self.showNotification('Payout deleted successfully');
                    }).catch(error => {
                        console.log(error);
                        self.onRequestError('An Error Occurred', error?.response?.data?.message);
                    });
            },
            async deleteEndorsementProductCredit() {
                let self = this;
                let tokenResponse = await auth.acquireTokenResponse();

                return apiService.DeleteEndorsementProductCredit(tokenResponse.accessToken, this.deletedItem.productCreditId, this.contract.contractId) 
                    .then((res) => {
                        self.uneditedEndorsementProductCredits = res.data.data;

                        self.uneditedEndorsementProductCredits.forEach(credit => {
                            self.formatDateFields('endorsementProductCredit', credit);
                        });

                        self.uneditedEndorsementProductCredits.sort((a, b) => b.startDate_formatted - a.startDate_formatted);
                        self.endorsementProductCredits = cloneDeep(self.uneditedEndorsementProductCredits);

                        self.isLoading = false;

                        self.showNotification('Product credit deleted successfully');
                    }).catch(error => {
                        console.log(error);
                        self.onRequestError('An Error Occurred', error?.response?.data?.message);
                    });
            },
            deleteItem() {
                this.toggleConfirmDialog();
                this.isLoading = true;

                if (this.deletedItemType === this.optionalEndorsementPayoutType) {
                    this.deleteEndorsementPayout();
                }

                if (this.deletedItemType === this.optionalEndorsementProductCreditType) {
                    this.deleteEndorsementProductCredit();
                }

                if (this.deletedItemType === this.optionalPerformanceBonusType) {
                    this.deletePerformanceBonus();
                }

                if (this.deletedItemType === this.optionalELCBonusType) {
                    this.deleteElcPerformanceBonus();
                }

                if (this.deletedItemType === this.optionalNHLBonusType) {
                    this.deleteNhlSigningBonus();
                }
            },
            async deleteNhlSigningBonus() {
                let self = this;
                let tokenResponse = await auth.acquireTokenResponse();

                return apiService.DeleteNHLSigningBonus(tokenResponse.accessToken, this.deletedItem.bonusId, this.contract.contractId, this.playerId) 
                    .then(async (res) => {
                        if (res.data.data) {
                            self.assignAndFormatData(res.data.data);
                            await this.$store.dispatch('getContracts', true);

                            self.isLoading = false;

                            self.showNotification('Bonus deleted successfully');
                        } else {
                            self.onRequestError('An Error Occurred', 'Failed to update contract');
                        }
                    }).catch(error => {
                        console.log(error);
                        self.onRequestError('An Error Occurred', error?.response?.data?.message);
                    });
            },
            async deletePerformanceBonus() {
                let self = this;
                let tokenResponse = await auth.acquireTokenResponse();

                return apiService.DeletePerformanceBonus(tokenResponse.accessToken, this.deletedItem.bonusId, this.contract.contractId) 
                    .then((res) => {
                        self.uneditedPerformanceBonuses = res.data.data;

                        self.uneditedPerformanceBonuses.sort((a, b) => b.bonusAmount - a.bonusAmount);
                        self.performanceBonuses = cloneDeep(self.uneditedPerformanceBonuses);

                        self.isLoading = false;

                        self.showNotification('Bonus deleted successfully');
                    }).catch(error => {
                        console.log(error);
                        self.onRequestError('An Error Occurred', error?.response?.data?.message);
                    });
            },
            elcBonusScheduleSelected(evt, defaultValue, itemIndex) {
                if (evt.target.value === defaultValue) {
                    return this.elcPerformanceBonuses[itemIndex].scheduleType = null;
                }

                this.elcPerformanceBonuses[itemIndex].scheduleType = evt.target.value;
            },
            formatApiNumberFields(obj) {
                let milestoneModel = {
                    playerId: '',
                    gamesPlayed: obj.gamesPlayed,
                    goals: obj.goals,
                    assists: obj.assists,
                    points: obj.points,
                    penaltyMinutes: obj.pim,
                    powerPlayGoals: obj.powerPlayGoals,
                    powerPlayPoints: obj.powerPlayPoints,
                    shorthandedGoals: obj.shorthandedGoals,
                    shorthandedPoints: obj.shorthandedPoints,
                    gameWinningGoals: obj.gameWinningGoals,
                    overtimeGoals: obj.otGoals,
                    shots: obj.shots,
                    shootingPercentage: (obj.shootingPctg * 100).toFixed(2),
                    teamName: obj.teamName.default
                }

                return milestoneModel;
            },
            formatDateFields(category, obj) {
                if (obj) {
                    for(const [key] of Object.entries(obj)) {
                        if(this[`${category}DateFields`].some(field => field === key)) {
                            if (obj[key]) {
                                obj[`${key}_formatted`] = new Date(obj[key]);
                            } else {
                                obj[`${key}_formatted`] = null;
                            }
                        }
                    }
                }
            },
            formatHockeyDocumentDates(documents) {
                documents.forEach(doc => {
                    doc.uploadDate = formatHelpers.formatDate(doc.uploadDate);
                });            

                return documents;
            },
            generateNoTradeClauses() {
                let tradeClauseStartDate = cloneDeep(this.contract.limitedModifiedStartDate);

                this.contract.limitedModifiedDate.setFullYear(tradeClauseStartDate.getFullYear());

                if (this.contract.limitedModifiedDate < tradeClauseStartDate) {
                    this.contract.limitedModifiedDate = date.addYears(this.contract.limitedModifiedDate, 1);
                } 

                let noTradeClauses = [];
                let tradeClauseYears = this.contract.limitedModifiedEndDate_formatted.getFullYear() - this.contract.limitedModifiedStartDate_formatted.getFullYear();

                for(var i = 0; i <= tradeClauseYears; i++) {
                    noTradeClauses.push({ 
                        dueDate: new Date(this.contract.limitedModifiedDate.getFullYear() + i, this.contract.limitedModifiedDate.getMonth(), this.contract.limitedModifiedDate.getDate()),
                        playerId: this.selectedPlayer.id,
                        reportToTeam: this.uneditedPlayer.nhlRightsTeam,
                        numberTeams: this.contract.limitedModifiedNumberTeams,
                        contractId: this.contract.contractId,
                        status: 'Pending'
                    });
                }

                return noTradeClauses.filter(clause => clause.dueDate >= this.contract.limitedModifiedStartDate_formatted && clause.dueDate <= this.contract.limitedModifiedEndDate_formatted);
            },
            async getContractDetail() {
                let self = this;

                let tokenResponse = await auth.acquireTokenResponse();

                return apiService.GetContractDetail(tokenResponse.accessToken, this.contractId)
                    .then(res => {
                        if (res.data.data) {
                            console.log(res.data.data);
                            self.assignAndFormatData(res.data.data);
                            self.isLoading = false;
                        } else {
                            self.onRequestError('An Error Occurred', 'Contract detail unavailable');
                        }
                    }).catch(error => {
                        console.log(error);
                        this.onRequestError('An Error Occurred', error?.response?.data?.message);
                    });
            },
            async getNhlApiPlayerMilestones(playerId) {
                let tokenResponse = await auth.acquireTokenResponse();

                return apiService.getNhlApiPlayerMilestones(tokenResponse.accessToken, playerId)
                    .then((res) => {
                        return res.data.data.seasonTotals;
                    }).catch(error => {
                        console.log(error);
                    })
            },
            async getNhlApiTeams() {
                await this.$store.dispatch('getNhlApiTeams');
            },
            async getNhlApiTeamRoster(teamId) {
                let currentSeason = this.getNhlCurrentSeason();

                let tokenResponse = await auth.acquireTokenResponse();

                return apiService.GetNhlApiTeamRoster(tokenResponse.accessToken, teamId, currentSeason)
                    .then((res) => {
                        return [...res.data.data.defensemen, ...res.data.data.forwards, ...res.data.data.goalies];
                    }).catch(error => {
                        console.log(error);
                    });
            },
            getNhlContractTypeAbbreviation() {
                switch(this.contract.nhlContractType) {
                    case 'NHL One Way':
                        return 'NHLOW';
                    case 'NHL Two Way':
                        return 'NHLTW';
                    case 'AHL One Way':
                        return 'AHLOW';
                    case 'AHL Two Way':
                        return 'AHLTW';
                    default:
                        return this.contract.nhlContractType;
                }
            },
            getNhlCurrentSeason() {
                let currentMonth = new Date().getMonth();
                let currentYear = new Date().getFullYear();

                if (currentMonth >= 9) {
                    return `${currentYear}${currentYear + 1}`;
                } 

                return `${currentYear - 1}${currentYear}`;
            },
            async getPlayerContracts() {
                await this.$store.dispatch('getContracts', false);

                this.playerContracts = cloneDeep(this.$store.state.contracts.filter(c => c.playerId === this.uneditedPlayer.id && c.contractType === this.agencyContractType));
                if (this.uneditedContract.associatedContractId) {
                    this.associatedAgencyContract = this.playerContracts.find(c => c.contractId === this.uneditedContract.associatedContractId);
                }
            },
            async getPlayerMilestone() {
                let playerTeam = this.$store.state.nhlApiTeams.find(team => team.fullName === this.uneditedPlayer.currentTeam.trim());

                if (playerTeam) {
                    this.apiPlayerTeamId = playerTeam.triCode;

                    let teamRoster = await this.getNhlApiTeamRoster(this.apiPlayerTeamId); 

                    if (teamRoster && Array.isArray(teamRoster)) {
                        let player = teamRoster.find(player => `${player.firstName.default} ${player.lastName.default}` === `${this.uneditedPlayer.firstName.trim()} ${this.uneditedPlayer.lastName.trim()}`);
                        
                        if (player) {
                            let playerMilestones = await this.getNhlApiPlayerMilestones(player.id);

                            if (playerMilestones) {
                                playerMilestones = playerMilestones
                                        .filter(milestone => milestone.leagueAbbrev === 'NHL');

                                let currentSeason = this.getNhlCurrentSeason();

                                let currentMilestone = playerMilestones.find(m => m.season.toString() === currentSeason)

                                if (currentMilestone) {
                                    let formattedMilestone = this.formatApiNumberFields(currentMilestone);

                                    return this.milestone = formattedMilestone;
                                }
                            }
                        }
                    }
                }
            },
            async getPlayers(update = true) {     
                await this.$store.dispatch('getPlayers', update);

                this.allPlayers = this.$store.state.players.map(player => {
                    return {
                        id: player.id,
                        fullName: player.fullName
                    }
                });

                this.allPlayers.sort((a, b) => a.fullName.localeCompare(b.fullName));

                this.selectedPlayer = this.allPlayers.find(player => player.id === this.player.id);

                this.filteredPlayers = cloneDeep(this.allPlayers);
            },
            goToPlayer() {
                this.$router.push(`/PlayerDetail?id=${this.player.id}`);
            },
            isEqualWith(unedited, edited) {
                return isEqualWith(unedited, edited, (a, b, key) => {
                    if ((a === null || a === '' || a === 0) && (b === null || b === '' || b === 0)) return true;

                    if (this.salaryDetailCurrencyFields.some(field => field === key)) {
                        if (Math.abs(a - b) < 1) return true;
                    }
                });
            },
            onAddElcPerformanceBonus() {
                let newBonus = cloneDeep(this.elcPerformanceBonusModel);
                newBonus.bonusId = Math.random().toString();

                this.elcPerformanceBonuses.unshift(newBonus);
            },
            onAddEndorsementPayout() {
                let newPayout = cloneDeep(this.endorsementPayoutModel);
                newPayout.payoutId = Math.random().toString();

                this.endorsementPayouts.push(newPayout);
            },
            onAddEndorsementProductCredit() {
                let newProductCredit = cloneDeep(this.endorsementProductCreditModel);
                newProductCredit.productCreditId = Math.random().toString();

                this.endorsementProductCredits.push(newProductCredit);
            },
            onAddNhlSigningBonus() {
                let newBonus = cloneDeep(this.nhlSigningBonusModel);
                newBonus.bonusId = Math.random().toString();

                this.nhlSigningBonuses.push(newBonus);
            },
            onAddPerformanceBonus() {
                let newBonus = cloneDeep(this.performanceBonusModel);
                newBonus.bonusId = Math.random().toString();

                this.performanceBonuses.unshift(newBonus);
            },
            onAssociatedContractSelected(evt) {
                this.contract.associatedContractId = evt.target.value.contractId;
            },
            onBackToContracts() {
                this.$router.push('/ContractManagement');
            },
            onBackToDashboard() {
                this.$emit('returntodashboard');
            },
            onCloseNotification(flag) {
                this[flag] = false;
            },
            onDeleteElcPerformanceBonus(bonus) {
                if (bonus.new) {
                    return this.elcPerformanceBonuses.splice(this.elcPerformanceBonuses.findIndex(p => p.bonusId === bonus.bonusId), 1);
                } 

                this.confirmMsg = `Are you sure you would like to delete this bonus?`;
                this.deletedItemType = this.optionalELCBonusType;
                this.deletedItem = bonus;

                this.onDeleteItem();
            },
            onDeleteEndorsementPayout(payout) {
                if (payout.new) {
                    return this.endorsementPayouts.splice(this.endorsementPayouts.findIndex(p => p.payoutId === payout.payoutId), 1);
                } 

                this.confirmMsg = `Are you sure you would like to delete this payout?`;
                this.deletedItemType = this.optionalEndorsementPayoutType;
                this.deletedItem = payout;

                this.onDeleteItem();
            },
            onDeleteEndorsementProductCredit(credit) {
                if (credit.new) {
                    return this.endorsementProductCredits.splice(this.endorsementProductCredits.findIndex(c => c.productCreditId === credit.productCreditId), 1);
                }

                this.confirmMsg = `Are you sure you would like to delete this credit?`;
                this.deletedItemType = this.optionalEndorsementProductCreditType;
                this.deletedItem = credit;

                this.onDeleteItem();
            },
            onDeleteNhlSigningBonus(bonus) {
                if (bonus.new) {
                    return this.nhlSigningBonuses.splice(this.nhlSigningBonuses.findIndex(p => p.bonusId === bonus.bonusId), 1);
                } 

                this.confirmMsg = `Are you sure you would like to delete this bonus?`;
                this.deletedItemType = this.optionalNHLBonusType;
                this.deletedItem = bonus;

                this.onDeleteItem();
            },
            onDeletePerformanceBonus(bonus) {
                if (bonus.new) {
                    return this.performanceBonuses.splice(this.performanceBonuses.findIndex(p => p.bonusId === bonus.bonusId), 1);
                } 

                this.confirmMsg = `Are you sure you would like to delete <span>${bonus.bonusTitle}<span>?`;
                this.deletedItemType = this.optionalPerformanceBonusType;
                this.deletedItem = bonus;

                this.onDeleteItem();
            },
            onDeleteItem() {
                this.confirmIconClass='fa-solid fa-trash-can';
                this.toggleConfirmDialog();
            },
            onDiscardChanges() {
                this.assignEditableContractProps();
            },
            onEquipmentContractSelected(evt, field, defaultValue) {
                this.contract$.contract.equipmentContract.$reset();

                this.defaultDropdownSelected(evt, field, defaultValue);

                if (this.contract.equipmentContract) {
                    this.buildEquipmentContractName();
                }
            },
            onNhlContractTypeSelected(evt, field, defaultValue) {
                this.contract$.contract.nhlContractType.$reset();
                
                this.defaultDropdownSelected(evt, field, defaultValue);

                if (this.contract.nhlContractType) {
                    this.buildPlayingContractName();
                }
            },
            async onOpenDocument() {
                let selectedPlayerDocument = this.contractDocuments[0];
                let routeData = this.$router.resolve({name: 'documentview', query: { id: selectedPlayerDocument.documentId }});
                window.open(routeData.href, '_blank');
            },
            onPlayerFilterChange(evt) {
                this.filteredPlayers = filterBy(this.allPlayers, evt.filter);
            },
            onRequestError(errorTitle, errorMsg) {
                this.isLoading = false;

                this.errorTitle = errorTitle;

                if (errorMsg) {
                    this.errorMsg = errorMsg;
                } else {
                    this.errorMsg = 'Please try again';
                }

                this.showErrorDialog = true;
            },
            onTabSelected(evt) {
                this.selectedTab = evt.selected;
            },
            async onUpdateContract() {
                await this.contract$.$validate();

                if (this.contract$.contract.$error || this.contract$.selectedPlayer.$error) return;

                this.isLoading = true;

                let request = {
                    playerId: this.player.id,
                    contractId: this.contract.contractId
                };

                if (!this.isEqualWith(this.contract, this.uneditedContract)) {
                    this.contractDateFields.forEach(field => {
                        this.contract[field] = this.contract[`${field}_formatted`] ?? null;
                    });

                    if (!this.contract.limitedModifiedNoTradeClause && this.uneditedContract.limitedModifiedNoTradeClause) {
                        this.contract.limitedModifiedOption = null;
                        this.contract.limitedModifiedDate = null;
                        this.contract.limitedModifiedStartDate = null;
                        this.contract.limitedModifiedEndDate = null;
                        this.contract.limitedModifiedNumberTeams = null;
                        request.deleteNoTradeClauses = true;
                    }

                    request.contract = this.contract;
                    request.contractUpdated = true;
                }

                if (!this.isEqualWith(this.endorsementPayouts, this.uneditedEndorsementPayouts)) {
                    this.endorsementPayouts.forEach(payout => {
                        this.endorsementPayoutDateFields.forEach(field => {
                            payout[field] = payout[`${field}_formatted`] ?? null;
                        });
                    });

                    request.endorsementPayouts = this.endorsementPayouts.filter(payout => payout.payoutAmount && payout.payoutDate);
                    request.endorsementPayouts.forEach(payout => {
                        if (payout.new) {
                            payout.payoutId = '';
                        }
                    });

                    request.endorsementPayoutsUpdated = true;
                }

                if (!this.isEqualWith(this.endorsementProductCredits, this.uneditedEndorsementProductCredits)) {
                    this.endorsementProductCredits.forEach(credit => {
                        this.endorsementProductCreditDateFields.forEach(field => {
                            credit[field] = credit[`${field}_formatted`] ?? null;
                        });
                    });

                    request.endorsementProductCredits = this.endorsementProductCredits.filter(credit => credit.creditAmount && credit.startDate && credit.endDate);
                    request.endorsementProductCredits.forEach(credit => {
                        if (credit.new) {
                            credit.productCreditId = '';
                        }
                    });

                    request.endorsementProductCreditsUpdated = true;
                }

                if (!this.isEqualWith(this.performanceBonuses, this.uneditedPerformanceBonuses)) {
                    request.performanceBonuses = this.performanceBonuses.filter(bonus => bonus.bonusTitle && bonus.bonusAmount);
                    request.performanceBonuses.forEach(bonus => {
                        if (bonus.new) {
                            bonus.bonusId = '';
                        }
                    });

                    request.performanceBonusesUpdated = true;
                }

                if (!this.isEqualWith(this.nhlSigningBonuses, this.uneditedNhlSigningBonuses)) {
                    this.nhlSigningBonuses.forEach(bonus => {
                        this.nhlSigningBonusDateFields.forEach(field => {
                            bonus[field] = bonus[`${field}_formatted`] ?? null;
                        });
                    });

                    request.nhlSigningBonuses = this.nhlSigningBonuses.filter(bonus => bonus.bonusDate && bonus.bonusAmount);
                    request.nhlSigningBonuses.forEach(bonus => {
                        if (bonus.new) {
                            bonus.bonusId = '';
                        }
                    });

                    request.nhlSigningBonusesUpdated = true;
                }

                if (!this.isEqualWith(this.elcPerformanceBonuses, this.uneditedElcPerformanceBonuses)) {
                    request.elcPerformanceBonuses = this.elcPerformanceBonuses.filter(bonus => bonus.bonusAmount && bonus.scheduleType);
                    request.elcPerformanceBonuses.forEach(bonus => {
                        if (bonus.new) {
                            bonus.bonusId = '';
                        }
                    });
                    
                    request.elcPerformanceBonusesUpdated = true;
                }

                if (!this.isEqualWith(this.ahlNhlTwoWayContractDetails, this.uneditedAhlNhlContractDetails)) {
                    request.ahlNhlTwoWayContractDetails = this.ahlNhlTwoWayContractDetails;
                    request.ahlNhlTwoWayContractDetailsUpdated = true;
                }

                if (!this.isEqualWith(this.ahlOneWayContractDetails, this.uneditedAhlOneWayContractDetails)) {
                    request.ahlOneWayContractDetails = this.ahlOneWayContractDetails;
                    request.ahlOneWayContractDetailsUpdated = true;
                }

                if (!this.isEqualWith(this.ahlTwoWayContractDetails, this.uneditedAhlTwoWayContractDetails)) {
                    request.ahlTwoWayContractDetails = this.ahlTwoWayContractDetails;
                    request.ahlTwoWayContractDetailsUpdated = true;
                }

                if (!this.isEqualWith(this.echlContractDetails, this.uneditedEchlContractDetails)) {
                    request.echlContractDetails = this.echlContractDetails;
                    request.echlContractDetailsUpdated = true;
                }

                if (!this.isEqualWith(this.elcContractDetails, this.uneditedElcContractDetails)) {
                    request.elcContractDetails = this.elcContractDetails;
                    request.elcContractDetailsUpdated = true;
                }

                if (!this.isEqualWith(this.nhlOneWayContractDetails, this.uneditedNhlOneWayContractDetails)) {
                    request.nhlOneWayContractDetails = this.nhlOneWayContractDetails;
                    request.nhlOneWayContractDetailsUpdated = true;
                }

                if (this.contract.limitedModifiedOption === 'Upon Team Notice of Request' && this.uneditedContract.limitedModifiedOption === 'Specified Date') {
                    request.deleteNoTradeClauses = true;
                    this.contract.limitedModifiedDate = null;
                }

                if (!this.isEqualWith(this.noTradeClauses, this.uneditedNoTradeClauses)) {
                    this.noTradeClauses.forEach(clause => {
                        this.noTradeClauseDateFields.forEach(field => {
                            clause[field] = clause[`${field}_formatted`] ?? null;
                        });
                    });

                    request.noTradeClauses = this.noTradeClauses;
                    request.limitedModifiedNoTradeClauseUpdated = true;
                }

                if (this.contract.limitedModifiedOption === 'Specified Date' && this.uneditedContract.limitedModifiedOption != 'Specified Date') {
                    if (this.contract.limitedModifiedNoTradeClause && this.contract.limitedModifiedDate && this.contract.limitedModifiedStartDate && this.contract.limitedModifiedEndDate) {
                        request.limitedModifiedNoTradeClauseUpdated = true;

                        request.noTradeClauses = this.generateNoTradeClauses();
                    }
                } else {
                    request.noTradeClauses = this.noTradeClauses;
                }

                if (new Date(this.contract.limitedModifiedStartDate).getTime() !== new Date(this.uneditedContract.limitedModifiedStartDate).getTime()
                        || new Date(this.contract.limitedModifiedEndDate).getTime() !== new Date(this.uneditedContract.limitedModifiedEndDate).getTime()
                        || new Date(this.contract.limitedModifiedDate).getTime() !== new Date(this.uneditedContract.limitedModifiedDate).getTime()) {

                    if (this.contract.limitedModifiedNoTradeClause && this.contract.limitedModifiedDate && this.contract.limitedModifiedStartDate && this.contract.limitedModifiedEndDate) {
                        request.deletePendingNoTradeClauses = true;
                        request.limitedModifiedNoTradeClauseUpdated = true;

                        request.noTradeClauses = this.generateNoTradeClauses();
                    }
                }
                
                this.updateContract(request);
            },
            showNotification(notificationMsg) {
                this.notificationMsg = notificationMsg;

                this.successNotification = true;

                setTimeout(() => {
                    this.successNotification = false;
                }, 4000);
            },
            toggleConfirmDialog() {
                this.showConfirmDialog = !this.showConfirmDialog;
            },
            toggleErrorDialog() {
                this.showErrorDialog = !this.showErrorDialog;

                if(!this.showErrorDialog) {
                    this.errorTitle = '';
                    this.errorMsg = '';
                }
            },
            toggleUploadDocumentDialog() {
                if (!this.showUploadDocumentDialog && !this.saveChangesBtnDisabled) {
                    return this.onRequestError('You Have Unsaved Changes', 'Please save your changes before managing documents');
                }

                this.showUploadDocumentDialog = !this.showUploadDocumentDialog;
            },
            async updateContract(request) {
                let self = this;

                let tokenResponse = await auth.acquireTokenResponse();

                return apiService.UpdateContract(tokenResponse.accessToken, request)
                    .then(async (res) => {
                        if (res.data.data) {
                            self.assignAndFormatData(res.data.data);
                            await this.$store.dispatch('getContracts', true);

                            self.isLoading = false;
                            self.showNotification('Contract updated successfullly');
                        } else {
                            self.onRequestError('An Error Occurred', 'Failed to update contract');
                        }
                    }).catch(error => {
                        console.log(error);
                        self.onRequestError('An Error Occurred', error?.response?.data?.message);
                    });
            }
        },
        filters: {
            toOrdinal(val) {
                switch (val) {
                    case 0:
                        return "1st";
                    case 1:
                        return "2nd";
                    case 2:
                        return "3rd";
                    case 3:
                        return "4th";
                    case 4:
                        return "5th";
                    case 5:
                        return "6th";
                    case 6:
                        return "7th";
                    case 7:
                        return "8th"
                }
            },
            formatCurrency(val) {
                if (val === null || val === undefined || val === '') return '';

                if (val.toString().includes('$')) return val;

                if (isNaN(val)) {
                    val = 0;
                }

                let formatter = new Intl.NumberFormat('en-US', {
                    style: 'currency',
                    currency: 'USD',
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0
                });

                return formatter.format(val);
            },
            formatCurrencyDec(val) {
                if (val === null || val === undefined || val === '') return '';

                if (val.toString().includes('$')) return val;

                if (isNaN(val)) {
                    val = 0;
                }

                let formatter = new Intl.NumberFormat('en-US', {
                    style: 'currency',
                    currency: 'USD',
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                });

                return formatter.format(val);
            }
        },
        components: {
            'k-dropdown': DropDownList,
            'view-header': viewHeader,
            'k-button': Button,
            'k-input': Input,
            'k-datepicker': DatePicker,
            'k-numeric-input': NumericTextBox,
            'k-textarea': TextArea,
            'k-slide': Slide,
            'k-tabstrip': TabStrip,
            'k-loader': Loader,
            'k-dialog': Dialog,
            'k-notification': Notification,
            'k-notification-group': NotificationGroup,
            'k-fade': Fade,
            'confirm-dialog': confirmDialog,
            'k-checkbox': Checkbox,
            'upsert-document-dialog': upsertDocumentDialog
        }
    }
</script>

<style>
    @import '/src/assets/css/defaultUpsertStyles.css';
    @import '/src/assets/css/contractDetailView.css';
</style>