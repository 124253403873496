<template>
        <transition name="dialogfade">
            <k-dialog v-if="showDialog" class="default__dialog agent-detail-dialog">
                <contract-detail-view v-if="detailType==='contract'" 
                                      :detail-contract-id="detailData.contractId"
                                      :agent-detail="true"
                                      @returntodashboard="onDialogClosed"></contract-detail-view>
                <player-detail-view v-if="detailType==='player'" 
                                      :detail-player-id="detailData.playerId"
                                      :agent-detail="true"
                                      @returntodashboard="onDialogClosed"></player-detail-view>
            </k-dialog>
        </transition>
</template>

<script>
    import { Dialog } from '@progress/kendo-vue-dialogs';

    import ContractDetailView from '@/views/ContractDetailView.vue';
    import PlayerDetailView from '@/views/PlayerDetailView.vue';

    export default {
        name: 'agentDetailDialog',
        props: ['detailData', 'detailType', 'showDialog'],
        emits: ['dialogclosed'],
        data() {
            return {

            }
        },
        watch: {
        },
        mounted() {
        },
        methods: {
            onDialogClosed() {
                this.$emit('dialogclosed');
            }
        },
        components: {
            'k-dialog': Dialog,
            'contract-detail-view': ContractDetailView,
            'player-detail-view': PlayerDetailView
        }
    }
</script>

<style>
    @import '/src/assets/css/agentDetailDialog.css';
</style>
