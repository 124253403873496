<template>
    <div class="elite-prospects-search-dialog">
        <transition name="dialogfade">
            <k-dialog v-if="showDialog && detailData" class="default__dialog search-dialog">
                <section class="search-section section--header" id="search-header">
                    <div class="player-image-container">
                        <img v-if="player.imageUrl" class="player-image" :src="player.imageUrl" />
                        <img v-if="!player.imageUrl" class="player-image" src="../assets/player-logo-gold.svg" />
                    </div>
                    <div class="player-title-container">
                        <div class="player-name-team">
                            <h3 class="player-name">{{ player?.name }}</h3>
                            <h4 class="player-team-league">{{ player?.latestStats?.team?.name }} / {{ player?.latestStats?.leagueName}}</h4>
                        </div>
                    </div>
                    <!-- <k-dropdown class="jump-to-dropdown"
                                :data-items="jumpToSectionSelects"
                                :default-item="{ display: 'Jump To Section', id: null }"
                                :text-field="'display'"                      
                                v-model="selectedJumpToSection"></k-dropdown> -->
                    <div class="section-nav">
                        <div :class="['section-nav-item', { 'active': selectedJumpToSection?.display === section.display }]" @click="jumpToSection(section)" v-for="section in jumpToSectionSelects" :key="section.id">
                            <p class="nav-item-text">{{ section.display }}</p>
                        </div>
                    </div>
                    <div class="player-latest-stats-container" v-if="player?.latestGameLogsAggregated?.length > 0 && player?.position === 'G'">
                        <div class="latest-stats-row categories">
                            <div class="latest-stats-row-item games">Games</div>
                            <div class="latest-stats-row-item gp">GP</div>
                            <div class="latest-stats-row-item ga">GA</div>
                            <div class="latest-stats-row-item svs">SVS</div>
                            <div class="latest-stats-row-item svp">SV%</div>
                        </div>
                        <div class="latest-stats-row">
                            <div class="latest-stats-row-item games">Last 5 Games</div>
                            <div class="latest-stats-row-item gp">{{ player.latestGameLogsAggregated[0].stats?.gp }}</div>
                            <div class="latest-stats-row-item ga">{{ player.latestGameLogsAggregated[0].stats?.ga }}</div>
                            <div class="latest-stats-row-item svs">{{ player.latestGameLogsAggregated[0].stats?.sv }}</div>
                            <div class="latest-stats-row-item svp">{{ player.latestGameLogsAggregated[0].stats?.svp }}</div>
                        </div>
                        <div class="latest-stats-row" v-if="player.latestGameLogsAggregated.length === 2">
                            <div class="latest-stats-row-item games">Last 10 Games</div>
                            <div class="latest-stats-row-item gp">{{ player.latestGameLogsAggregated[1].stats?.gp }}</div>
                            <div class="latest-stats-row-item ga">{{ player.latestGameLogsAggregated[1].stats?.ga }}</div>
                            <div class="latest-stats-row-item svs">{{ player.latestGameLogsAggregated[1].stats?.sv }}</div>
                            <div class="latest-stats-row-item svp">{{ player.latestGameLogsAggregated[1].stats?.svp }}</div>
                        </div>
                    </div>
                   <div class="player-latest-stats-container forward-defense" v-if="player?.latestGameLogsAggregated?.length > 0 && (player?.position === 'F' || player?.position === 'D')">
                        <div class="latest-stats-row categories">
                            <div class="latest-stats-row-item games">Games</div>
                            <div class="latest-stats-row-item gp">GP</div>
                            <div class="latest-stats-row-item g">G</div>
                            <div class="latest-stats-row-item a">A</div>
                            <div class="latest-stats-row-item pts">TP</div>
                            <div class="latest-stats-row-item pm">+/-</div>
                        </div>
                        <div class="latest-stats-row">
                            <div class="latest-stats-row-item games">Last 5 Games</div>
                            <div class="latest-stats-row-item gp">{{ player.latestGameLogsAggregated[0].stats?.gp }}</div>
                            <div class="latest-stats-row-item g">{{ player.latestGameLogsAggregated[0].stats?.g }}</div>
                            <div class="latest-stats-row-item a">{{ player.latestGameLogsAggregated[0].stats?.a }}</div>
                            <div class="latest-stats-row-item pts">{{ player.latestGameLogsAggregated[0].stats?.pts }}</div>
                            <div class="latest-stats-row-item pm">{{ player.latestGameLogsAggregated[0].stats?.pm }}</div>
                        </div>
                        <div class="latest-stats-row" v-if="player.latestGameLogsAggregated.length === 2">
                            <div class="latest-stats-row-item games">Last 10 Games</div>
                            <div class="latest-stats-row-item gp">{{ player.latestGameLogsAggregated[1].stats?.gp }}</div>
                            <div class="latest-stats-row-item ga">{{ player.latestGameLogsAggregated[1].stats?.g }}</div>
                            <div class="latest-stats-row-item a">{{ player.latestGameLogsAggregated[1].stats?.a }}</div>
                            <div class="latest-stats-row-item pts">{{ player.latestGameLogsAggregated[1].stats?.pts }}</div>
                            <div class="latest-stats-row-item pm">{{ player.latestGameLogsAggregated[1].stats?.pm }}</div>
                        </div>
                    </div>
                    <div class="dialog-close" @click="onCloseDialog" title="Close"><i class="fa-sharp fa-regular fa-xmark"></i></div>
                </section>
                <div class="all-sections-container">
                    <section class="search-section section--facts" id="section-facts">
                        <div :class="['basic-facts-row', { 'not-nhl': !isNHL }]">
                            <div class="basic-facts-row-item">
                                <p class="field">Date of Birth</p>
                                <p>{{ player.dateOfBirth? new Date(player.dateOfBirth).toLocaleDateString() : 'N/A' }}</p>
                            </div>
                            <div class="basic-facts-row-item">
                                <p class="field">Position</p>
                                <p>{{ player.position ?? 'N/A' }}</p>
                            </div>
                            <div class="basic-facts-row-item" v-if="isNHL">
                                <p class="field">Cap Hit</p>
                                <p>{{ player.capHit ?? 'N/A' }}</p>
                            </div>
                        </div>
                        <div :class="['basic-facts-row', { 'not-nhl': !isNHL }]">
                            <div class="basic-facts-row-item">
                                <p class="field">Age</p>
                                <p>{{ player.age ?? 'N/A' }}</p>
                            </div>
                            <div class="basic-facts-row-item">
                                <p class="field">Height</p>
                                <p>{{ player.height?.imperial ?? 'N/A' }} / {{ player.height?.metrics ? `${player.height.metrics} cm` : 'N/A' }}</p>
                            </div>
                            <div class="basic-facts-row-item" v-if="isNHL">
                                <p class="field">NHL Rights</p>
                                <p>{{ player.nhlRights?.team?.name ?? 'N/A' }}</p>
                            </div>
                        </div>
                        <div :class="['basic-facts-row', { 'not-nhl': !isNHL }]">
                            <div class="basic-facts-row-item">
                                <p class="field">Place of Birth</p>
                                <p>{{ player.placeOfBirth ?? 'N/A' }}</p>
                            </div>
                            <div class="basic-facts-row-item">
                                <p class="field">Weight</p>
                                <p>{{ player.weight?.imperial ? `${player?.weight.imperial} lbs` : 'N/A' }} / {{ player.weight?.metrics ? `${player.weight.metrics} kg` : 'N/A' }}</p>
                            </div>
                            <div class="basic-facts-row-item" v-if="isNHL">
                                <p class="field">Draft Pick</p>
                                <p v-if="draftSelections?.length > 0 && draftSelections[0].draftType?.league?.name === 'NHL'">{{ draftSelections[0].year }} round {{ draftSelections[0].round }} #{{ draftSelections[0].overall }} overall</p>
                                <p v-else>N/A</p>
                            </div>
                        </div>
                        <div :class="['basic-facts-row', { 'not-nhl': !isNHL }]">
                            <div class="basic-facts-row-item">
                                <p class="field">Nation</p>
                                <p>{{ player.nationality?.name ?? 'N/A' }}</p>
                            </div>
                            <div class="basic-facts-row-item" v-if="player.position === 'G'">
                                <p class="field">Catches</p>
                                <p>{{ player.catches ?? 'N/A' }}</p>
                            </div>
                            <div class="basic-facts-row-item" v-if="player.position === 'F' || player.position === 'D'">
                                <p class="field">Shoots</p>
                                <p>{{ player.shoots ?? 'N/A' }}</p>
                            </div>
                            <div class="basic-facts-row-item" v-if="isNHL">
                                <p class="field">Drafted By</p>
                                <p v-if="draftSelections?.length > 0 && draftSelections[0].draftType?.league?.name === 'NHL'">{{ draftSelections[0].teamName }}</p>
                                <p v-else>N/A</p>
                            </div>
                        </div>
                        <div :class="['basic-facts-row', { 'not-nhl': !isNHL }]">
                            <div class="basic-facts-row-item">
                                <p class="field">Youth Team</p>
                                <p>{{ player.youthTeam ?? 'N/A' }}</p>
                            </div>
                            <div class="basic-facts-row-item">
                                <p class="field">Contract</p>
                                <p>{{ player.contract ?? 'N/A' }}</p>
                            </div>
                            <div class="basic-facts-row-item" v-if="isNHL">
                                <p class="field">Agency</p>
                                <p v-if="player.agencies?.length > 0">{{ player.agencies[0].name }}</p>
                                <p v-else>N/A</p>
                            </div>
                        </div>
                    </section>
                    <section class="search-section section--player-description" id="section-player-description" v-if="player?.profileDescriptionAsHTML && player?.profileDescriptionAsHTML.replace(/<[^>]+>/g, '')?.length > 100">
                        <p class="player-description"><i class="fa-solid fa-quote-left"></i>{{ player?.profileDescriptionAsHTML.replace(/<[^>]+>/g, '') }}<i class="fa-solid fa-quote-right"></i></p>
                    </section>

                    <section class="search-section section--stats" id="section-stats" v-if="(stats?.length > 0 || leagueStats?.length > 0) && (!selectedJumpToSection || selectedJumpToSection.display === 'Statistics')">
                        <k-tabstrip :selected="selectedStatsTab" @select="onStatsTabSelected" :tabs="filteredStatsTabs">
                            <template v-slot:statistics>
                                <section class="search-section section--player-stats" v-if="stats?.length > 0" id="section-player-stats">
                                    <view-header :title="'Statistics'">
                                        <template v-slot:viewicon>
                                            <i class="fa-duotone fa-hockey-stick-puck"></i>
                                        </template>
                                        <template v-slot:viewfilters>
                                            <k-dropdown class="grid-filter__select"
                                                        :data-items="playerStatsSeasonTypeFilterSelects"
                                                        v-model="selectedPlayerStatsSeasonTypeFilter"></k-dropdown>
                                            <k-dropdown class="grid-filter__select"
                                                        :data-items="playerStatsAggregateFilterSelects"
                                                        v-model="selectedPlayerStatsAggregateFilter"></k-dropdown>
                                        </template>
                                    </view-header>
                                    <div :class="['grid-container', { 'all': selectedPlayerStatsSeasonTypeFilter === 'All Season Types' }, { 'reg': selectedPlayerStatsSeasonTypeFilter === 'Regular Season' }, { 'post': selectedPlayerStatsSeasonTypeFilter === 'Post Season' }]">
                                        <detail-view-grid :columns="playerStatsColumns"
                                                        :initial-sort="[{ field: 'season.slug', dir: 'desc' }]"
                                                        :item-id-field="'id'"
                                                        :items="selectedPlayerStatsAggregateFilter === 'Totals' ? stats : avgStats"
                                                        :row-clickable="false"
                                                        :delete-action="false"
                                                        :allow-export="true"
                                                        :export-title="`${player?.name} Statistics`"
                                                        :elite-prospects-search="true"></detail-view-grid>
                                    </div>
                                </section>
                            </template>
                            <template v-slot:careertotals>
                                <section class="search-section section--player-league-stats" v-if="leagueStats?.length > 0" id="section-player-league-stats">
                                    <view-header :title="'Career Totals'">
                                        <template v-slot:viewicon>
                                            <i class="fa-duotone fa-hockey-stick-puck"></i>
                                        </template>
                                        <template v-slot:viewfilters>
                                            <k-dropdown class="grid-filter__select"
                                                        :data-items="playerLeagueStatsFilterSelects"
                                                        v-model="selectedPlayerLeagueStatsFilter"></k-dropdown>
                                            <k-dropdown class="grid-filter__select"
                                                        :data-items="playerCareerTotalsFilterSelects"
                                                        v-model="selectedPlayerCareerTotalsFilter"></k-dropdown>
                                        </template>
                                    </view-header>
                                    <div :class="['grid-container', { 'all': selectedPlayerLeagueStatsFilter === 'All Season Types' }, { 'reg': selectedPlayerLeagueStatsFilter === 'Regular Season' }, { 'post': selectedPlayerLeagueStatsFilter === 'Post Season' }]">
                                        <detail-view-grid :columns="selectedPlayerCareerTotalsFilter === 'Leagues' ? playerLeagueStatsColumns : playerTeamStatsColumns"
                                                        :initial-sort="[{ field: 'leagueName', dir: 'asc' }]"
                                                        :item-id-field="'id'"
                                                        :items="selectedPlayerCareerTotalsFilter === 'Leagues' ? leagueStats : teamStats"
                                                        :row-clickable="false"
                                                        :delete-action="false"
                                                        :allow-export="true"
                                                        :export-title="`${player?.name} Career Totals`"
                                                        :elite-prospects-search="true"></detail-view-grid>
                                    </div>
                                </section>
                            </template>
                            <template v-slot:tournamentstatistics>
                                <section class="search-section section--player-tournament-stats" v-if="tournamentStats?.length > 0" id="section-player-tournament-stats">
                                    <view-header :title="'Tournaments'">
                                        <template v-slot:viewicon>
                                            <i class="fa-duotone fa-solid fa-trophy"></i>
                                        </template>
                                    </view-header>
                                    <div class="grid-container reg">
                                        <detail-view-grid :columns="playerStatsRegularSeasonColumns"
                                                        :initial-sort="[{ field: 'season.slug', dir: 'desc' }]"
                                                        :item-id-field="'id'"
                                                        :items="tournamentStats"
                                                        :row-clickable="false"
                                                        :delete-action="false"
                                                        :allow-export="true"
                                                        :export-title="`${player?.name} Tournament Statistics`"
                                                        :elite-prospects-search="true"></detail-view-grid>
                                    </div>
                                </section>
                            </template>
                        </k-tabstrip>
                    </section>

                    <section :class="['search-section', 'section--gamelogs', { 'df': player?.position === 'D' || player?.position === 'F' }, { 'g': player?.position === 'G' }]" id="section-gamelogs" v-if="gameLogs?.length > 0 && selectedJumpToSection?.display === 'Game Log'">
                        <k-listview class="game-logs-listview"
                                    :data-items="filteredGameLogs"
                                    :item="'gameLog'"
                                    :header="'gameLogsHeader'"
                                    :footer="'gameLogsFooter'">
                            <template v-slot:gameLog="{ props }">
                                <div class="game-logs-container" v-if="player?.position === 'D' || player?.position === 'F'">
                                    <div class="game-log-row df">
                                        <div class="row-item date">
                                            {{ props.dataItem.game.date.toLocaleDateString('en-us', { weekday: 'short' }) }} {{ props.dataItem.game.date.toLocaleDateString() }}
                                        </div>
                                        <div class="row-item team" :title="props.dataItem.teamName">
                                            <img v-if="props.dataItem.teamLogoUrl" :src="props.dataItem.teamLogoUrl" />
                                            <span v-if="!props.dataItem.teamLogoUrl">{{ props.dataItem.teamName }}</span>
                                        </div>
                                        <div class="row-item team" :title="props.dataItem.opponentName">
                                            <span class="home-away">{{ props.dataItem.gameType === 'home' ? 'vs' : '@' }}</span>
                                            <img v-if="props.dataItem.opponentLogoUrl" :src="props.dataItem.opponentLogoUrl" />
                                            <span v-if="!props.dataItem.opponentLogoUrl">{{ props.dataItem.opponentName }}</span>
                                        </div>
                                        <div class="row-item score">
                                            <span :class="['w-l', { 'l': props.dataItem.outcome === 'L' }]">{{ props.dataItem.outcome }}</span>
                                            <span>{{ props.dataItem.teamScore }} - {{ props.dataItem.opponentScore }}</span>
                                        </div>
                                        <div class="row-item stat">
                                            {{ props.dataItem.stats.g }}
                                        </div>
                                        <div class="row-item stat">
                                            {{ props.dataItem.stats.a }}
                                        </div>
                                        <div class="row-item stat">
                                            {{ props.dataItem.stats.pts }}
                                        </div>
                                        <div class="row-item stat">
                                            {{ props.dataItem.stats.pim }}
                                        </div>
                                        <div class="row-item stat">
                                            {{ props.dataItem.stats.pm }}
                                        </div>
                                        <div class="row-item stat">
                                            {{ props.dataItem.stats.sog }}
                                        </div>
                                        <div class="row-item stat">
                                            {{ props.dataItem.stats.ppg }}
                                        </div>
                                        <div class="row-item stat">
                                            {{ props.dataItem.stats.shg }}
                                        </div>
                                        <div class="row-item stat">
                                            {{ props.dataItem.stats.toi }}
                                        </div>
                                    </div>
                                </div>
                            <div class="game-logs-container" v-if="player?.position === 'G'">
                                    <div class="game-log-row g">
                                        <div class="row-item date">
                                            {{ new Date(props.dataItem.game.date).toLocaleDateString('en-us', { weekday: 'short' })}} {{ new Date(props.dataItem.game.date).toLocaleDateString() }}
                                        </div>
                                        <div class="row-item team" :title="props.dataItem.teamName">
                                            <img v-if="props.dataItem.teamLogoUrl" :src="props.dataItem.teamLogoUrl" />
                                            <span v-if="!props.dataItem.teamLogoUrl">{{ props.dataItem.teamName }}</span>
                                        </div>
                                        <div class="row-item team" :title="props.dataItem.opponentName">
                                            <span class="home-away">{{ props.dataItem.gameType === 'home' ? 'vs' : '@' }}</span>
                                            <img v-if="props.dataItem.opponentLogoUrl" :src="props.dataItem.opponentLogoUrl" />
                                            <span v-if="!props.dataItem.opponentLogoUrl">{{ props.dataItem.opponentName }}</span>
                                        </div>
                                        <div class="row-item score">
                                            <span :class="['w-l', { 'l': props.dataItem.outcome === 'L' }]">{{ props.dataItem.outcome }}</span>
                                            <span>{{ props.dataItem.teamScore }} - {{ props.dataItem.opponentScore }}</span>
                                        </div>
                                        <div class="row-item stat">
                                            {{ props.dataItem.stats.ga }}
                                        </div>
                                        <div class="row-item stat">
                                            {{ props.dataItem.stats.sa }}
                                        </div>
                                        <div class="row-item stat">
                                            {{ props.dataItem.stats.sv }}
                                        </div>
                                        <div class="row-item stat">
                                            {{ props.dataItem.stats.svp }}
                                        </div>
                                        <div class="row-item stat">
                                            {{ props.dataItem.stats.toi }}
                                        </div>
                                    </div>
                                </div>
                            </template>
                            <template v-slot:gameLogsHeader>
                                <k-listview-header>
                                    <div class="title-select-container">
                                        <i class="fa-sharp-duotone fa-solid fa-hockey-sticks"></i>
                                        <h3>Game Log</h3>
                                        <k-dropdown class="grid-filter__select"
                                                    :data-items="gameLogSeasonSelects"
                                                    v-model="selectedGameLogSeason"></k-dropdown>
                                    </div>
                                    
                                    <div class="game-log-row row--header df" v-if="player?.position === 'D' || player?.position === 'F'">
                                        <div class="row-item date sortable" @click="sortGameLogs('game.date')">
                                            Date
                                            <span v-if="gameLogsSort && gameLogsSort[0]?.field === 'game.date' && gameLogsSort[0]?.dir === 'asc'"><i class="fa-regular fa-arrow-up"></i></span>
                                            <span v-if="gameLogsSort && gameLogsSort[0]?.field === 'game.date' && gameLogsSort[0]?.dir === 'des'"><i class="fa-regular fa-arrow-down"></i></span>
                                        </div>
                                        <div class="row-item team sortable" @click="sortGameLogs('teamName')">
                                            Team
                                            <span v-if="gameLogsSort && gameLogsSort[0]?.field === 'teamName' && gameLogsSort[0]?.dir === 'asc'"><i class="fa-regular fa-arrow-up"></i></span>
                                            <span v-if="gameLogsSort && gameLogsSort[0]?.field === 'teamName' && gameLogsSort[0]?.dir === 'des'"><i class="fa-regular fa-arrow-down"></i></span>
                                        </div>
                                        <div class="row-item team sortable" @click="sortGameLogs('opponentName')">
                                            Opp
                                            <span v-if="gameLogsSort && gameLogsSort[0]?.field === 'opponentName' && gameLogsSort[0]?.dir === 'asc'"><i class="fa-regular fa-arrow-up"></i></span>
                                            <span v-if="gameLogsSort && gameLogsSort[0]?.field === 'opponentName' && gameLogsSort[0]?.dir === 'des'"><i class="fa-regular fa-arrow-down"></i></span>
                                        </div>
                                        <div class="row-item score">
                                            Score
                                        </div>
                                        <div class="row-item stat sortable" @click="sortGameLogs('stats.g')">
                                            G
                                            <span v-if="gameLogsSort && gameLogsSort[0]?.field === 'stats.g' && gameLogsSort[0]?.dir === 'asc'"><i class="fa-regular fa-arrow-up"></i></span>
                                            <span v-if="gameLogsSort && gameLogsSort[0]?.field === 'stats.g' && gameLogsSort[0]?.dir === 'des'"><i class="fa-regular fa-arrow-down"></i></span>
                                        </div>
                                        <div class="row-item stat sortable" @click="sortGameLogs('stats.a')">
                                            A
                                            <span v-if="gameLogsSort && gameLogsSort[0]?.field === 'stats.a' && gameLogsSort[0]?.dir === 'asc'"><i class="fa-regular fa-arrow-up"></i></span>
                                            <span v-if="gameLogsSort && gameLogsSort[0]?.field === 'stats.a' && gameLogsSort[0]?.dir === 'des'"><i class="fa-regular fa-arrow-down"></i></span>
                                        </div>
                                        <div class="row-item stat sortable" @click="sortGameLogs('stats.pts')">
                                            TP
                                            <span v-if="gameLogsSort && gameLogsSort[0]?.field === 'stats.pts' && gameLogsSort[0]?.dir === 'asc'"><i class="fa-regular fa-arrow-up"></i></span>
                                            <span v-if="gameLogsSort && gameLogsSort[0]?.field === 'stats.pts' && gameLogsSort[0]?.dir === 'des'"><i class="fa-regular fa-arrow-down"></i></span>
                                        </div>
                                        <div class="row-item stat sortable" @click="sortGameLogs('stats.pim')">
                                            PIM
                                            <span v-if="gameLogsSort && gameLogsSort[0]?.field === 'stats.pim' && gameLogsSort[0]?.dir === 'asc'"><i class="fa-regular fa-arrow-up"></i></span>
                                            <span v-if="gameLogsSort && gameLogsSort[0]?.field === 'stats.pim' && gameLogsSort[0]?.dir === 'des'"><i class="fa-regular fa-arrow-down"></i></span>
                                        </div>
                                        <div class="row-item stat sortable" @click="sortGameLogs('stats.pm')">
                                            +/-
                                            <span v-if="gameLogsSort && gameLogsSort[0]?.field === 'stats.pm' && gameLogsSort[0]?.dir === 'asc'"><i class="fa-regular fa-arrow-up"></i></span>
                                            <span v-if="gameLogsSort && gameLogsSort[0]?.field === 'stats.pm' && gameLogsSort[0]?.dir === 'des'"><i class="fa-regular fa-arrow-down"></i></span>
                                        </div>
                                        <div class="row-item stat sortable" @click="sortGameLogs('stats.sog')">
                                            SOG
                                            <span v-if="gameLogsSort && gameLogsSort[0]?.field === 'stats.sog' && gameLogsSort[0]?.dir === 'asc'"><i class="fa-regular fa-arrow-up"></i></span>
                                            <span v-if="gameLogsSort && gameLogsSort[0]?.field === 'stats.sog' && gameLogsSort[0]?.dir === 'des'"><i class="fa-regular fa-arrow-down"></i></span>
                                        </div>
                                        <div class="row-item stat sortable" @click="sortGameLogs('stats.ppg')">
                                            PPG
                                            <span v-if="gameLogsSort && gameLogsSort[0]?.field === 'stats.ppg' && gameLogsSort[0]?.dir === 'asc'"><i class="fa-regular fa-arrow-up"></i></span>
                                            <span v-if="gameLogsSort && gameLogsSort[0]?.field === 'stats.ppg' && gameLogsSort[0]?.dir === 'des'"><i class="fa-regular fa-arrow-down"></i></span>
                                        </div>
                                        <div class="row-item stat sortable" @click="sortGameLogs('stats.shg')">
                                            SHG
                                            <span v-if="gameLogsSort && gameLogsSort[0]?.field === 'stats.shg' && gameLogsSort[0]?.dir === 'asc'"><i class="fa-regular fa-arrow-up"></i></span>
                                            <span v-if="gameLogsSort && gameLogsSort[0]?.field === 'stats.shg' && gameLogsSort[0]?.dir === 'des'"><i class="fa-regular fa-arrow-down"></i></span>
                                        </div>
                                        <div class="row-item stat sortable" @click="sortGameLogs('stats.toi')">
                                            TOI
                                            <span v-if="gameLogsSort && gameLogsSort[0]?.field === 'stats.toi' && gameLogsSort[0]?.dir === 'asc'"><i class="fa-regular fa-arrow-up"></i></span>
                                            <span v-if="gameLogsSort && gameLogsSort[0]?.field === 'stats.toi' && gameLogsSort[0]?.dir === 'des'"><i class="fa-regular fa-arrow-down"></i></span>
                                        </div>
                                    </div>
                                    <div class="game-log-row row--header g" v-if="player?.position === 'G'">
                                        <div class="row-item date sortable" @click="sortGameLogs('game.date')">
                                            Date
                                            <span v-if="gameLogsSort && gameLogsSort[0]?.field === 'game.date' && gameLogsSort[0]?.dir === 'asc'"><i class="fa-regular fa-arrow-up"></i></span>
                                            <span v-if="gameLogsSort && gameLogsSort[0]?.field === 'game.date' && gameLogsSort[0]?.dir === 'des'"><i class="fa-regular fa-arrow-down"></i></span>
                                        </div>
                                        <div class="row-item team sortable" @click="sortGameLogs('teamName')">
                                            Team
                                            <span v-if="gameLogsSort && gameLogsSort[0]?.field === 'teamName' && gameLogsSort[0]?.dir === 'asc'"><i class="fa-regular fa-arrow-up"></i></span>
                                            <span v-if="gameLogsSort && gameLogsSort[0]?.field === 'teamName' && gameLogsSort[0]?.dir === 'des'"><i class="fa-regular fa-arrow-down"></i></span>
                                        </div>
                                        <div class="row-item team sortable" @click="sortGameLogs('opponentName')">
                                            Opp
                                            <span v-if="gameLogsSort && gameLogsSort[0]?.field === 'opponentName' && gameLogsSort[0]?.dir === 'asc'"><i class="fa-regular fa-arrow-up"></i></span>
                                            <span v-if="gameLogsSort && gameLogsSort[0]?.field === 'opponentName' && gameLogsSort[0]?.dir === 'des'"><i class="fa-regular fa-arrow-down"></i></span>
                                        </div>
                                        <div class="row-item score">
                                            Score
                                        </div>
                                        <div class="row-item stat sortable" @click="sortGameLogs('stats.ga')">
                                            GA
                                            <span v-if="gameLogsSort && gameLogsSort[0]?.field === 'stats.ga' && gameLogsSort[0]?.dir === 'asc'"><i class="fa-regular fa-arrow-up"></i></span>
                                            <span v-if="gameLogsSort && gameLogsSort[0]?.field === 'stats.ga' && gameLogsSort[0]?.dir === 'des'"><i class="fa-regular fa-arrow-down"></i></span>
                                        </div>
                                        <div class="row-item stat sortable" @click="sortGameLogs('stats.sa')">
                                            SA
                                            <span v-if="gameLogsSort && gameLogsSort[0]?.field === 'stats.sa' && gameLogsSort[0]?.dir === 'asc'"><i class="fa-regular fa-arrow-up"></i></span>
                                            <span v-if="gameLogsSort && gameLogsSort[0]?.field === 'stats.sa' && gameLogsSort[0]?.dir === 'des'"><i class="fa-regular fa-arrow-down"></i></span>
                                        </div>
                                        <div class="row-item stat sortable" @click="sortGameLogs('stats.sv')">
                                            SV
                                            <span v-if="gameLogsSort && gameLogsSort[0]?.field === 'stats.sv' && gameLogsSort[0]?.dir === 'asc'"><i class="fa-regular fa-arrow-up"></i></span>
                                            <span v-if="gameLogsSort && gameLogsSort[0]?.field === 'stats.sv' && gameLogsSort[0]?.dir === 'des'"><i class="fa-regular fa-arrow-down"></i></span>
                                        </div>
                                        <div class="row-item stat sortable" @click="sortGameLogs('stats.svp')">
                                            SV%
                                            <span v-if="gameLogsSort && gameLogsSort[0]?.field === 'stats.svp' && gameLogsSort[0]?.dir === 'asc'"><i class="fa-regular fa-arrow-up"></i></span>
                                            <span v-if="gameLogsSort && gameLogsSort[0]?.field === 'stats.svp' && gameLogsSort[0]?.dir === 'des'"><i class="fa-regular fa-arrow-down"></i></span>
                                        </div>
                                        <div class="row-item stat sortable" @click="sortGameLogs('stats.toi')">
                                            TOI
                                            <span v-if="gameLogsSort && gameLogsSort[0]?.field === 'stats.toi' && gameLogsSort[0]?.dir === 'asc'"><i class="fa-regular fa-arrow-up"></i></span>
                                            <span v-if="gameLogsSort && gameLogsSort[0]?.field === 'stats.toi' && gameLogsSort[0]?.dir === 'des'"><i class="fa-regular fa-arrow-down"></i></span>
                                        </div>
                                    </div>
                                </k-listview-header>
                            </template>
                            <template v-slot:gameLogsFooter>
                                <k-listview-footer></k-listview-footer>
                            </template>
                        </k-listview>
                    </section>

                    <section class="search-section section--highlights" v-if="awards?.length > 0 && selectedJumpToSection?.display === 'Highlights'" id="section-awards">
                        <k-listview class="highlights-listview"
                                    :data-items="selectedAwardsFilter === 'By Season' ? awardsBySeason : awardsByLeague"
                                    :item="'award'"
                                    :header="'awardsHeader'"
                                    :footer="'awardsFooter'">
                            <template v-slot:award="{ props }">
                                <div class="awards-container" v-if="selectedAwardsFilter === 'By Season'">
                                    <div class="award-row">
                                        <div class="row-item season">
                                            {{ props.dataItem.season }}
                                        </div>
                                        <div class="row-item award">
                                            <div class="award-name" v-for="(award, index) in props.dataItem.awards" :key="index">
                                                {{ award }}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="awards-container" v-if="selectedAwardsFilter === 'By League'">
                                    <div class="award-row">
                                        <div class="row-item league">
                                            {{ props.dataItem.league ?? 'Misc' }}
                                        </div>
                                        <div class="row-item award">
                                            <div class="award-name" v-for="(award, index) in props.dataItem.awards" :key="index">
                                                {{ award.awardName }} <span class="seasons-list">({{ award.awardSeasons.join(', ') }})</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </template>
                            <template v-slot:awardsHeader>
                                <div class="title-select-container">
                                    <i class="fa-duotone fa-solid fa-trophy"></i>
                                    <h3>Career Highlights</h3>
                                    <k-dropdown class="grid-filter__select"
                                                :data-items="awardsFilterSelects"
                                                v-model="selectedAwardsFilter"></k-dropdown>
                                </div>

                                <div class="award-row row--header">
                                    <div class="row-item season">
                                        Season
                                    </div>
                                    <div class="row-item award">
                                        Award(s)
                                    </div>
                                </div>
                            </template>
                        </k-listview>
                    </section>

                    <section class="search-section section--transfers" v-if="transfers?.length > 0 && selectedJumpToSection?.display === 'Transactions'" id="section-transfers">
                        <k-listview class="transfers-listview"
                                    :data-items="filteredTransfers"
                                    :item="'transfer'"
                                    :header="'transfersHeader'"
                                    :footer="'transfersFooter'">
                            <template v-slot:transfer="{ props }">
                                <div class="transfers-container">
                                    <div class="transfer-row">
                                        <div class="row-item date">
                                            {{ props.dataItem.date.toLocaleDateString() }}
                                        </div>
                                        <div class="row-item status">
                                            {{ props.dataItem.type ? props.dataItem.type.charAt(0).toUpperCase() + props.dataItem.type.slice(1) : '' }}
                                        </div>
                                        <div class="row-item to-from">
                                            {{ props.dataItem.fromTeam?.name }}
                                        </div>
                                        <div class="row-item to-from">
                                            {{ props.dataItem.toTeam?.name }}
                                        </div>
                                        <div class="row-item to-from">
                                            {{ props.dataItem.extra }}
                                        </div>
                                    </div>
                                </div>
                            </template>
                            <template v-slot:transfersHeader>
                                <div class="title-select-container">
                                    <i class="fa-duotone fa-solid fa-right-left"></i>
                                    <h3>Transactions</h3>
                                </div>

                                <div class="transfer-row row--header">
                                    <div class="row-item date sortable" @click="sortTransfers('date')">
                                        Date
                                        <span v-if="transfersSort && transfersSort[0]?.field === 'date' && transfersSort[0]?.dir === 'asc'"><i class="fa-regular fa-arrow-up"></i></span>
                                        <span v-if="transfersSort && transfersSort[0]?.field === 'date' && transfersSort[0]?.dir === 'des'"><i class="fa-regular fa-arrow-down"></i></span>
                                    </div>
                                    <div class="row-item status sortable" @click="sortTransfers('type')">
                                        Status
                                        <span v-if="transfersSort && transfersSort[0]?.field === 'type' && transfersSort[0]?.dir === 'asc'"><i class="fa-regular fa-arrow-up"></i></span>
                                        <span v-if="transfersSort && transfersSort[0]?.field === 'type' && transfersSort[0]?.dir === 'des'"><i class="fa-regular fa-arrow-down"></i></span>
                                    </div>
                                    <div class="row-item to-from sortable" @click="sortTransfers('fromTeam.name')">
                                        From
                                        <span v-if="transfersSort && transfersSort[0]?.field === 'fromTeam.name' && transfersSort[0]?.dir === 'asc'"><i class="fa-regular fa-arrow-up"></i></span>
                                        <span v-if="transfersSort && transfersSort[0]?.field === 'fromTeam.name' && transfersSort[0]?.dir === 'des'"><i class="fa-regular fa-arrow-down"></i></span>
                                    </div>
                                    <div class="row-item to-from sortable" @click="sortTransfers('toTeam.name')">
                                        To
                                        <span v-if="transfersSort && transfersSort[0]?.field === 'toTeam.name' && transfersSort[0]?.dir === 'asc'"><i class="fa-regular fa-arrow-up"></i></span>
                                        <span v-if="transfersSort && transfersSort[0]?.field === 'toTeam.name' && transfersSort[0]?.dir === 'des'"><i class="fa-regular fa-arrow-down"></i></span>
                                    </div>
                                    <div class="row-item length sortable" @click="sortTransfers('extra')">
                                        Detail
                                        <span v-if="transfersSort && transfersSort[0]?.field === 'extra' && transfersSort[0]?.dir === 'asc'"><i class="fa-regular fa-arrow-up"></i></span>
                                        <span v-if="transfersSort && transfersSort[0]?.field === 'extra' && transfersSort[0]?.dir === 'des'"><i class="fa-regular fa-arrow-down"></i></span>
                                    </div>
                                </div>
                            </template>
                        </k-listview>
                    </section>
                </div>
            </k-dialog>
        </transition>
    </div>
</template>

<script>
    import { Dialog } from '@progress/kendo-vue-dialogs';
    import { DropDownList } from '@progress/kendo-vue-dropdowns';
    import { TabStrip } from '@progress/kendo-vue-layout';
    import {
        ListView,
        ListViewFooter,
        ListViewHeader,
    } from '@progress/kendo-vue-listview';

    import { orderBy } from '@progress/kendo-data-query';

    import detailViewGrid from '../components/detailViewGrid.vue';
    import viewHeader from '../components/viewHeader.vue';

    import cloneDeep from 'lodash.clonedeep';

    export default {
        name: 'eliteProspectsSearchDialog',
        props: ['detailData','showDialog'],
        emits: ['dialogclosed'],
        data () {
            return {
                awardsFilterSelects: ['By Season', 'By League'],
                gameLogsSort: null,
                filteredGameLogs: [],
                filteredTransfers: [],
                playerCareerTotalsFilterSelects: ['Leagues', 'Teams'],
                playerLeagueStatsFilterSelects: ['All Season Types', 'Regular Season', 'Post Season'],
                playerStatsSeasonTypeFilterSelects: ['All Season Types', 'Regular Season', 'Post Season'],
                selectedAwardsFilter: 'By Season',
                selectedGameLogSeason: null,
                selectedJumpToSection: { display: 'Statistics', id: 'section-stats' },
                selectedPlayerCareerTotalsFilter: 'Leagues',
                selectedPlayerLeagueStatsFilter: 'All Season Types',
                selectedPlayerStatsAggregateFilter: 'Totals',
                selectedPlayerStatsSeasonTypeFilter: 'All Season Types',
                selectedStatsTab: 0,
                statsTabs: [
                    {
                        title: 'Statistics',
                        content: 'statistics'
                    },
                    { 
                        title: 'Career Totals',
                        content: 'careertotals'
                    },
                    {
                        title: 'Tournament Statistics',
                        content: 'tournamentstatistics'
                    }
                ],
                transfersSort: null
            }
        },
        computed: {
            avgStats() {
                return this.detailData?.avgStats?.data ?? [];
            },
            awards() {
                return this.detailData?.awards?.data ?? [];
            },
            awardsByLeague() {
                let awardsByLeague = [];

                if (this.awards.length > 0) {
                    let leagues = [...new Set(this.awards.map(award => award.awardTypeLeague))];

                    leagues.forEach(league => {
                        let awardNames = [...new Set(this.awards.filter(award => (award.awardTypeLeague === league || award.awardTypeName?.startsWith(league))).map(a => a.awardTypeName))];

                        if (!league) {
                            awardNames = awardNames.filter(name => !leagues.some(league => name.startsWith(league)));
                        }

                        if (awardNames.length > 0) {
                            awardsByLeague.push({
                                league: league,
                                awards: awardNames.map(awardName => {
                                    return {
                                        awardName: awardName,
                                        awardSeasons: this.awards.filter(a => a.awardTypeName === awardName).map(a => a.season?.slug)
                                    }
                                })
                            });
                        }
                    });
                }

                return awardsByLeague;
            },
            awardsBySeason() {
                let awardsBySeason = [];

                if (this.awards.length > 0) {
                    let seasons = [...new Set(this.awards.map(award => award.season?.slug))];

                    seasons.forEach(season => {
                        awardsBySeason.push({
                            season: season,
                            awards: this.awards.filter(award => award.season?.slug === season).map(award => award.awardTypeName)
                        });
                    });
                }

                return awardsBySeason;
            },
            draftSelections() {
                return this.detailData?.draftSelections?.data ?? [];
            },
            filteredStatsTabs() {
                let tabs = [];

                if (this.stats?.length > 0) {
                    tabs.push(this.statsTabs.find(tab => tab.content === 'statistics'));
                }

                if (this.leagueStats?.length > 0 || this.teamStats?.length > 0) {
                    tabs.push(this.statsTabs.find(tab => tab.content === 'careertotals'));
                }

                if (this.tournamentStats?.length > 0) {
                    tabs.push(this.statsTabs.find(tab => tab.content === 'tournamentstatistics'));
                }

                return tabs;
            },
            gameLogs() {
                return this.detailData?.gameLogs?.data ?? [];
            },
            gameLogSeasonSelects() {
                if (this.gameLogs?.length > 0) {
                    return [...new Set(this.gameLogs.map(log => log.game?.season?.slug))];
                }

                return [];
            },
            isNHL() {
                return this.detailData?.player?.data?.nhlRights;
            },
            jumpToSectionSelects() {
                let selects = [];

                if (this.stats?.length > 0 || this.leagueStats?.length > 0) {
                    selects.push({
                        display: 'Statistics',
                        id: 'section-stats'
                    });
                }

                if (this.gameLogs?.length > 0) {
                    selects.push({
                        display: 'Game Log',
                        id: 'section-gamelogs'
                    });
                }

                if (this.awards?.length > 0) {
                    selects.push({
                        display: 'Highlights',
                        id: 'section-awards'
                    });
                }

                if (this.transfers?.length > 0) {
                    selects.push({
                        display: 'Transactions',
                        id: 'section-transfers'
                    });
                }

                return selects;
            },
            leagueStats() {
                return this.detailData?.leagueStats?.data ?? [];
            },
            player() {
                return this.detailData?.player?.data;
            },
            playerLeagueStatsColumns() {
                if (this.selectedPlayerLeagueStatsFilter === 'All Season Types') {
                    return [{ field: 'leagueName', title: 'League' }, ...this.playerLeagueStatsRegularSeasonColumns, ...this.playerLeagueStatsPostSeasonColumns];
                }

                if (this.selectedPlayerLeagueStatsFilter === 'Regular Season') {
                    return [{ field: 'leagueName', title: 'League' }, ...this.playerLeagueStatsRegularSeasonColumns];
                }

                return [{ field: 'leagueName', title: 'League' }, ...this.playerLeagueStatsPostSeasonColumns];
            },
            playerLeagueStatsPostSeasonColumns() {
                if (!this.player?.position) return [];

                if (this.player?.position === 'G') {
                    return [
                        { field: 'numberOfPostseasonSeasons', title: 'Years' },
                        { field: 'postseasonStats.gp', title: 'GP', filter: 'numeric', type: 'number' },
                        { field: 'postseasonStats.gaa', title: 'GAA', filter: 'numeric', type: 'number' },
                        { field: 'postseasonStats.svpp', title: 'SV%', filter: 'numeric', type: 'number' },
                        { field: 'postseasonStats.so', title: 'SO', filter: 'numeric', type: 'number' },
                        { field: 'postseasonStats.wlt', title: 'W-L-T', sortable: false }
                    ]
                }

                if (this.player?.position === 'F' || this.player?.position === 'D') {
                    return [
                        { field: 'numberOfPostseasonSeasons', title: 'Years' },
                        { field: 'postseasonStats.gp', title: 'GP', filter: 'numeric', type: 'number' },
                        { field: 'postseasonStats.g', title: 'G', filter: 'numeric', type: 'number' },
                        { field: 'postseasonStats.a', title: 'A', filter: 'numeric', type: 'number' },
                        { field: 'postseasonStats.pts', title: 'TP', filter: 'numeric', type: 'number' },
                        { field: 'postseasontStats.ppg', title: 'PPG', filter: 'numeric', type: 'number' },
                        { field: 'postseasonStats.pim', title: 'PIM', filter: 'numeric', type: 'number' },
                        { field: 'postseasonStats.pm', title: '+/-', filter: 'numeric', type: 'number' }
                    ]
                }

                return [];
            },
            playerLeagueStatsRegularSeasonColumns() {
                if (!this.player?.position) return [];

                if (this.player?.position === 'G') {
                    return [
                        { field: 'numberOfRegularSeasons', title: 'Years' },
                        { field: 'regularStats.gp', title: 'GP', filter: 'numeric', type: 'number' },
                        { field: 'regularStats.gaa', title: 'GAA', filter: 'numeric', type: 'number' },
                        { field: 'regularStats.svpp', title: 'SV%', filter: 'numeric', type: 'number' },
                        { field: 'regularStats.so', title: 'SO', filter: 'numeric', type: 'number' },
                        { field: 'regularStats.wlt', title: 'W-L-T', sortable: false }
                    ]
                }

                if (this.player?.position === 'F' || this.player?.position === 'D') {
                    return [
                        { field: 'numberOfRegularSeasons', title: 'Years' },
                        { field: 'regularStats.gp', title: 'GP', filter: 'numeric', type: 'number' },
                        { field: 'regularStats.g', title: 'G', filter: 'numeric', type: 'number' },
                        { field: 'regularStats.a', title: 'A', filter: 'numeric', type: 'number' },
                        { field: 'regularStats.pts', title: 'TP', filter: 'numeric', type: 'number' },
                        { field: 'regularStats.ppg', title: 'PPG', filter: 'numeric', type: 'number' },
                        { field: 'regularStats.pim', title: 'PIM', filter: 'numeric', type: 'number' },
                        { field: 'regularStats.pm', title: '+/-', filter: 'numeric', type: 'number' }
                    ]
                }

                return [];
            },
            playerStatsAggregateFilterSelects() {
                if (this.avgStats.length > 0) {
                    return ['Totals', 'Average'];
                }

                return ['Totals'];
            },
            playerStatsColumns() {
                if (this.selectedPlayerStatsSeasonTypeFilter === 'All Season Types') {
                    if (this.player?.position === 'G') {
                        return [...this.playerStatsRegularSeasonColumns.filter(col => col.title != 'TOI'), ...this.playerStatsPostSeasonColumns.filter(col => col.title != 'TOI')];
                    }

                    return [...this.playerStatsRegularSeasonColumns, ...this.playerStatsPostSeasonColumns];
                }

                if (this.selectedPlayerStatsSeasonTypeFilter === 'Regular Season') {
                    return this.playerStatsRegularSeasonColumns;
                }

                return [{ field: 'season.slug', title: 'Season' }, { field: 'team.name', title: 'Team' }, { field: 'leagueName', title: 'League' },...this.playerStatsPostSeasonColumns];
            },
            playerStatsPostSeasonColumns() {
                if (!this.player?.position) return [];

                if (this.player?.position === 'G') {
                    return [
                        { field: 'postseasonTypeCapitalized', title: 'Post' },
                        { field: 'postseasonStats.gp', title: 'GP', filter: 'numeric', type: 'number' },
                        { field: 'postseasonStats.gaa', title: 'GAA', filter: 'numeric', type: 'number' },
                        { field: 'postseasonStats.svpp', title: 'SV%', filter: 'numeric', type: 'number' },
                        { field: 'postseasonStats.so', title: 'SO', filter: 'numeric', type: 'number' },
                        { field: 'postseasonStats.wlt', title: 'W-L-T', sortable: false },
                        { field: 'postseasonStats.toi', title: 'TOI' }
                    ]
                }

                if (this.player?.position === 'F' || this.player?.position === 'D') {
                    let cols = [
                        { field: 'postseasonTypeCapitalized', title: 'Post' },
                        { field: 'postseasonStats.gp', title: 'GP', filter: 'numeric', type: 'number' },
                        { field: 'postseasonStats.g', title: 'G', filter: 'numeric', type: 'number' },
                        { field: 'postseasonStats.a', title: 'A', filter: 'numeric', type: 'number' },
                        { field: 'postseasonStats.pts', title: 'TP', filter: 'numeric', type: 'number' },
                        { field: 'postseasonStats.pim', title: 'PIM', filter: 'numeric', type: 'number' }           
                    ]

                    if (this.selectedPlayerStatsAggregateFilter === 'Totals') {
                        return [...cols, { field: 'postseasonStats.pm', title: '+/-', filter: 'numeric', type: 'number' }]
                    } 

                    return cols;
                }

                return [];
            },
            playerStatsRegularSeasonColumns() {
                if (!this.player?.position) return [];

                if (this.player?.position === 'G') {
                    return [
                        { field: 'season.slug', title: 'Season' },
                        { field: 'team.name', title: 'Team' },
                        { field: 'leagueName', title: 'League' },
                        { field: 'regularStats.gp', title: 'GP', filter: 'numeric', type: 'number' },
                        { field: 'regularStats.gaa', title: 'GAA', filter: 'numeric', type: 'number' },
                        { field: 'regularStats.svpp', title: 'SV%', filter: 'numeric', type: 'number' },
                        { field: 'regularStats.so', title: 'SO', filter: 'numeric', type: 'number' },
                        { field: 'regularStats.wlt', title: 'W-L-T', sortable: false },
                        { field: 'regularStats.toi', title: 'TOI' }
                    ]
                }

                if (this.player?.position === 'F' || this.player?.position === 'D') {
                    let cols = [
                        { field: 'season.slug', title: 'Season' },
                        { field: 'team.name', title: 'Team' },
                        { field: 'leagueName', title: 'League' },
                        { field: 'regularStats.gp', title: 'GP', filter: 'numeric', type: 'number' },
                        { field: 'regularStats.g', title: 'G', filter: 'numeric', type: 'number' },
                        { field: 'regularStats.a', title: 'A', filter: 'numeric', type: 'number' },
                        { field: 'regularStats.pts', title: 'TP', filter: 'numeric', type: 'number' },
                        { field: 'regularStats.pim', title: 'PIM', filter: 'numeric', type: 'number' }
                    ]

                    if (this.selectedPlayerStatsAggregateFilter === 'Totals') {
                        return [...cols, { field: 'regularStats.pm', title: '+/-', filter: 'numeric', type: 'number' }]
                    } 

                    return cols;
                }

                return [];
            },
            playerTeamStatsColumns() {
                if (this.selectedPlayerLeagueStatsFilter === 'All Season Types') {
                    return [{ field: 'teamName', title: 'Team' }, ...this.playerLeagueStatsRegularSeasonColumns, ...this.playerLeagueStatsPostSeasonColumns];
                }

                if (this.selectedPlayerLeagueStatsFilter === 'Regular Season') {
                    return [{ field: 'teamName', title: 'Team' }, ...this.playerLeagueStatsRegularSeasonColumns];
                }

                return [{ field: 'teamName', title: 'Team' }, ...this.playerLeagueStatsPostSeasonColumns];
            },
            stats() {
                return this.detailData?.stats?.data?.filter(stat => stat.leagueType !== 'cup') ?? [];
            },
            teamStats() {
                return this.detailData?.teamStats?.data ?? [];
            },
            tournamentStats() {
                return this.detailData?.stats?.data?.filter(stat => stat.leagueType === 'cup') ?? [];
            },
            transfers() {
                return this.detailData?.transfers?.data ?? [];
            }
        },
        watch: {
            selectedGameLogSeason(val) {
                this.filteredGameLogs = this.gameLogs?.filter(log => log.game?.season?.slug === val);
            },
            showDialog(val) {
                if (val) {
                    this.setScrollStyling();
                    this.setSelectedGameLogSeason();
                    this.filteredTransfers = cloneDeep(this.transfers);
                } else {
                    this.resetDefaults();
                }
            }
        },
        mounted() {
            this.setSelectedGameLogSeason();
        },
        methods: {
            jumpToSection(section) {
                this.selectedJumpToSection = section;
            },
            onCloseDialog() {
                this.$emit('dialogclosed');
            },
            onStatsTabSelected(evt) {
                this.selectedStatsTab = evt.selected;
            },
            resetDefaults() {
                this.selectedPlayerLeagueStatsFilter = 'All Season Types';
                this.selectedPlayerStatsSeasonTypeFilter = 'All Season Types';
                this.selectedPlayerStatsAggregateFilter = 'Totals';
                this.selectedPlayerCareerTotalsFilter = 'Leagues';
                this.selectedAwardsFilter = 'By Season';
                this.selectedJumpToSection = { display: 'Statistics', id: 'section-stats' };

                this.selectedStatsTab = 0;
                this.gameLogsSort = null;
                this.transfersSort = null;
            },
            setScrollStyling() {
                this.$nextTick(() => {
                    let dialogContent = document.querySelector('.search-dialog .k-dialog-content');
                    var imageContainer = document.querySelector('.player-image-container');

                    if(dialogContent && imageContainer) {
                        dialogContent.onscroll = () => {
                            if (dialogContent.scrollTop >= 50) {
                                imageContainer.style.width = '0px';
                                imageContainer.style.height = '0px';
                                imageContainer.style.borderWidth = '0px';
                            } else {
                                imageContainer.style.width = '300px';
                                imageContainer.style.height = '300px';
                                imageContainer.style.borderWidth = '5px';
                            }
                        }
                    }
                })
            },
            setSelectedGameLogSeason() {
                this.selectedGameLogSeason = this.gameLogSeasonSelects[0];
                this.filteredGameLogs = this.gameLogs?.filter(log => log.game?.season?.slug === this.selectedGameLogSeason);
            },
            sortGameLogs(field) {
                if (this.gameLogsSort) {
                    if (this.gameLogsSort[0].field === field) {
                        this.gameLogsSort[0].dir = this.gameLogsSort[0].dir === 'des' ? 'asc' : 'des';
                    } else {
                        this.gameLogsSort[0].field = field;
                        this.gameLogsSort[0].dir = 'asc';
                    }
                } else {
                    this.gameLogsSort = [{ field: field, dir: 'asc' }];
                }

                this.filteredGameLogs = orderBy(this.filteredGameLogs, this.gameLogsSort);
            },
            sortTransfers(field) {
                if (this.transfersSort) {
                    if (this.transfersSort[0].field === field) {
                        this.transfersSort[0].dir = this.transfersSort[0].dir === 'des' ? 'asc' : 'des';
                    } else {
                        this.transfersSort[0].field = field;
                        this.transfersSort[0].dir = 'asc';
                    }
                } else {
                    this.transfersSort = [{ field: field, dir: 'asc' }];
                }

                this.filteredTransfers = orderBy(this.filteredTransfers, this.transfersSort);
            }
        },
        components: {
            'k-dialog': Dialog,
            'detail-view-grid': detailViewGrid,
            'view-header': viewHeader,
            'k-dropdown': DropDownList,
            'k-tabstrip': TabStrip,
            'k-listview': ListView,
            'k-listview-header': ListViewHeader,
            'k-listview-footer': ListViewFooter
        }
    }
</script>

<style>
    @import '/src/assets/css/eliteProspectsSearchDialog.css';
</style>