<template>
    <div class="draft-reorder">
        <transition name="dialogfade">
            <k-dialog v-if="showDialog" class="default__dialog create-contract-dialog draft-reorder-dialog">
                <div class="icon__container">
                    <i class="fa-regular fa-arrow-up-arrow-down"></i>
                </div>
                <div class="header__container">
                    <svg viewBox="0 0 300 35" x="0px" y="0px" xml:space="preserve" fill="none" xmlns="http://www.w3.org/2000/svg" style="enable-background:new 0 0 300 35;"><path fill-rule="evenodd" class="icon__top-bar" clip-rule="evenodd" d="M290,21.74H169.3a19.32,19.32,0,0,1-38.64,0H10c-5.52,0-10,2.53-10,5.66V50H300V27.4C300,24.27,295.52,21.74,290,21.74Z" transform="translate(0 -21.74)" fill="#D4B139"></path></svg>
                </div>
                <div class="create-contract__header">
                    <h1 class="dialog--title">Draft Eligible Card Order</h1>
                </div>
                <div class="upsert-item__container">
                    <div class="players__container">
                        <div class="rows__header">
                            <p class="column-name">Name</p>
                            <p class="column-name">Proj. Round</p>
                        </div>
                        <div class="player-rows__container">
                            <div class="player__row" v-for="(player, index) in eligiblePlayers" 
                                :key="player.id"
                                ref="playerRowContainer"
                                :data-id="player.id"
                                :data-index="index"
                                draggable="true"
                                @dragover.prevent="allowDrop"
                                @dragstart="onDragStart($event, index)"
                                @drop="onDrop($event, index)">
                                <p class="player-data">{{ player.fullName }}</p>
                                <p class="player-data">{{ player.projectedDraftRound }}</p>
                        </div>
                        </div>
                    </div>
                    <k-action-bar>
                        <k-button class="item-save__btn" :disabled="saveOrderBtnDisabled" @click="onSaveClicked" title="Save Order">Save Order</k-button>
                        <k-button class="item-cancel__btn" @click="onCloseDialog" title="Close">Close</k-button>
                    </k-action-bar>
                </div>
                <div class="loader-container" v-if="isLoading">
                    <k-loader :size="'large'" :type="'infinite-spinner'"></k-loader>
                </div>
            </k-dialog>
        </transition>

        <transition name="dialogfade">
            <k-dialog v-if="showErrorDialog" :title="errorTitle" class="error__dialog" @close="toggleErrorDialog">
                <p class="error-dialog__msg">{{ errorMsg }}</p>
            </k-dialog>
        </transition>

        <k-notification-group class="default-notification-container">
                <k-fade :appear="successNotification">
                    <k-notification v-if="successNotification"
                                    :type="{ style: 'success', icon: true }"
                                    :closable="true"
                                    @close="onCloseNotification('successNotification')">
                        <span>{{ notificationMsg }}</span>
                    </k-notification>
                </k-fade>
        </k-notification-group>
    </div>
</template>

<script>
import { Dialog, DialogActionsBar } from '@progress/kendo-vue-dialogs';
import { Button } from "@progress/kendo-vue-buttons";
import { Loader } from "@progress/kendo-vue-indicators";
import { Notification, NotificationGroup } from "@progress/kendo-vue-notification";
import { Fade } from '@progress/kendo-vue-animation';

import cloneDeep from 'lodash.clonedeep';
import isEqual from 'lodash.isequal';

import auth from '../authConfig';
import apiService from '../api/api.service.js';

export default {
    name: 'draftReorderDialog',
    props: ['players', 'selectedYear', 'showDialog'],
    emits: ['dialogclosed', 'draftorderupdated'],
    data() {
        return {
            draggedIndex: null,
            eligiblePlayers: [],
            errorMsg: '',
            errorTitle: '',
            isLoading: false,
            notificationMsg: '',
            showErrorDialog: false,
            successNotification: false,
            unorderedEligiblePlayers: []
        }
    },
    computed: {
        saveOrderBtnDisabled() {
            return isEqual(this.eligiblePlayers, this.unorderedEligiblePlayers);
        }
    },
    watch: {
        showDialog(val) {
            if (val) {
                this.unorderedEligiblePlayers = this.players.filter(player => player.draftEligibleDate.getFullYear() === this.selectedYear);
                this.eligiblePlayers = cloneDeep(this.unorderedEligiblePlayers);
            }
        }
    },
    methods: {
        allowDrop(evt) {
            const target = evt.target.closest('.player__row');

            this.$refs.playerRowContainer.forEach(item => {
                if (item.dataset.id === target.dataset.id) {
                    if (this.draggedIndex < target.dataset.index) {
                        item.style.paddingBottom = '35px';
                    }

                    if (this.draggedIndex > target.dataset.index) {
                        item.style.paddingTop = '35px';
                    }
                } else {
                    item.style.paddingTop = item.style.paddingBottom = '5px';
                }
            })
        },
        onCloseDialog() {
            this.$emit('dialogclosed');
        },
        onCloseNotification(flag) {
            this[flag] = false;
        },
        onDragStart(evt, index) {
            this.draggedIndex = index;
        },
        onDrop(evt, index) {
            this.$refs.playerRowContainer.forEach(item => {
                item.style.paddingTop = item.style.paddingBottom = '5px';                
            });

            if (this.draggedIndex === index) return;

            const item = this.eligiblePlayers[this.draggedIndex];

            this.eligiblePlayers.splice(this.draggedIndex, 1);
            this.eligiblePlayers.splice(index, 0, item);
            this.draggedIndex = -1;
        },
        onRequestError(errorTitle, errorMsg) {
            this.isLoading = false;

            this.errorTitle = errorTitle;

            if (errorMsg) {
                this.errorMsg = errorMsg;
            } else {
                this.errorMsg = 'Please try again';
            }

            this.showErrorDialog = true;
        },
        onSaveClicked() {
            this.isLoading = true;

            this.eligiblePlayers.forEach((player, index) => {
                player.draftOrder = index;
            });

            let request = this.eligiblePlayers.map(player => {
                return {
                    id: player.id,
                    draftOrder: player.draftOrder
                }
            });

            this.updatePlayersDraftOrder(request);
        },
        showNotification(notificationMsg) {
            this.notificationMsg = notificationMsg;

            this.successNotification = true;

            setTimeout(() => {
                this.successNotification = false;
            }, 4000);
        },
        async updatePlayersDraftOrder(request) {
            let tokenResponse = await auth.acquireTokenResponse();

            return apiService.UpdatePlayersDraftOrder(tokenResponse.accessToken, request)
                .then((res) => {
                    if (res.data.data) {
                        this.$emit('draftorderupdated', cloneDeep(res.data.data));
                    }
                    
                    this.isLoading = false;
                    this.$emit('dialogclosed'); 
                    this.showNotification('Draft order updated successfully');
                }).catch(error => {
                    this.onRequestError('An Error Occurred', error?.response?.data?.message);
                });
        }
    },
    components: {
        'k-dialog': Dialog,
        'k-action-bar': DialogActionsBar,
        'k-button': Button,
        'k-loader': Loader,
        'k-notification': Notification,
        'k-notification-group': NotificationGroup,
        'k-fade': Fade
    }
}
</script>

<style>
    @import '/src/assets/css/defaultUpsertStyles.css';
    @import '/src/assets/css/draftReorderDialog.css';
</style>
