export default {
    draftDetailDateFields: ['dateDrafted', 'signByDate'],
    getAbbreviatedPositions(positions) {
        let abbrevPositions = positions.map(pos => {
            switch (pos.position) {
                case 'Center':
                    return 'C';
                case 'Right Wing':
                    return 'RW';
                case 'Left Wing':
                    return 'LW';
                case 'Forward':
                    return 'F';
                case 'Defense':
                    return 'D';
                case 'Left Defense':
                    return 'LD';
                case 'Right Defense':
                    return 'RD';
                case 'Goalie':
                    return 'G';
                default:
                    return pos.position;
            }     
        });

        return abbrevPositions.join(', ');
    },
    getPositionFullName(position) {
        switch (position) {
            case 'F':
                return 'Forward';
            case 'RW':
                return 'Right Wing';
            case 'LW':
                return 'Left Wing';
            case 'C':
                return 'Center';
            case 'D':
                return 'Defense';
            case 'LD':
                return 'Left Defense';
            case 'RD':
                return 'Right Defense';
            case 'G':
                return 'Goalie';
            default:
                return position;
        }
    },
    newPlayerModel: {
        id: '',
        firstName: '',
        lastName: '',
        citzenship: '',
        dob_formatted: null,
        age: null,
        posistion: '',
        type: '',
        status: '',
        billingAddress: '',
        billingContactEmail: '',
        billingContactName: '',
        currentLeague: '',
        currentTeam: '',
        nhlRightsTeam: '',
        nhlRightsDateDrafted_formatted: null,
        annualContractValue: 0,
        visaRequired: false,
        isActive: true,
        billingContactCity: '',
        billingContactState: null,
        billingContactZip: '',
        billingContactAddressAdditional: '',
        billingContactPhone: '',
        billingContactPhoneExt: '',
        draftEligibleDate_formatted: null,
        nhlRightsYearDrafted: null,
        projectedDraftRound: null
    },
    nhlTeamSelects: ['Anaheim Ducks', 'Arizona Coyotes', 'Boston Bruins', 'Buffalo Sabres', 'Calgary Flames', 'Carolina Hurricanes', 'Chicago Blackhawks', 
        'Colorado Avalanche', 'Columbus Blue Jackets', 'Dallas Stars', 'Detroit Red Wings', 'Edmonton Oilers', 'Florida Panthers', 'Los Angeles Kings', 'Minnesota Wild',
        'Montreal Canadiens', 'Nashville Predators', 'New Jersey Devils', 'New York Islanders', 'New York Rangers', 'Ottawa Senators', 'Philadelphia Flyers', 'Pittsburgh Penguins',
        'San Jose Sharks', 'Seattle Kraken', 'St. Louis Blues', 'Tampa Bay Lightning', 'Toronto Maple Leafs', 'Utah Hockey Club', 'Vancouver Canucks', 'Vegas Golden Knights', 'Washington Capitals',
        'Winnipeg Jets'],
    playerDateFields: ['dob', 'draftEligibleDate', 'nhlRightsDateDrafted', 'nhlRightsDate'],
    playerPositionSelects: ['Left Wing', 'Right Wing', 'Center', 'Left Defense', 'Right Defense', 'Goalie'],
    playerStatusSelects: ['Client', 'Target', 'Former Player'],
    rightsDetailDateFields: ['rightsDate']
}
