<template>
    <transition name="dialogfade">
        <k-dialog v-if="showDialog" class="default__dialog confirm-dialog" @close="onCloseDialog">
            <div class="icon__container">
                <i :class="iconClass"></i>
            </div>
            <svg viewBox="0 0 300 50" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" class="icon__top-bar" clip-rule="evenodd" d="M10 0C4.47715 0 0 4.47715 0 10V50H300V10C300 4.47715 295.523 0 290 0H184.388C184.388 9.01736 180.732 17.6654 174.226 24.0416C167.72 30.4179 158.895 34 149.694 34C140.492 34 131.668 30.4179 125.161 24.0416C118.655 17.6654 115 9.01737 115 5.13386e-06L149.694 0H10Z" fill="#D4B139"></path></svg>
            <h1 class="dialog--title">Please Confirm</h1>
            <div class="confirm-msg__container">
                <p class="confirm-dialog__msg" v-html="dialogMsg"></p>
            </div>
            <k-action-bar>
                <k-button class="item-confirm__btn" @click="onConfirmClicked">Confirm</k-button>
                <k-button class="item-cancel__btn" @click="onCancelClicked">Cancel</k-button>
            </k-action-bar>
        </k-dialog>
    </transition>
</template>

<script>
    import { Dialog, DialogActionsBar } from '@progress/kendo-vue-dialogs';
    import { Button } from "@progress/kendo-vue-buttons";

    export default {
        props: ['dialogMsg', 'iconClass', 'showDialog'],
        emits: ['cancelclicked', 'confirmclicked', 'dialogclosed'],
        name: 'confirmDialog',
        data() {
            return {
                dialogTitle: 'Please confirm'
            }
        },
        methods: {
            onCancelClicked() {
                this.$emit('cancelclicked');
            },
            onCloseDialog() {
                this.$emit('dialogclosed');
            },
            onConfirmClicked() {
                this.$emit('confirmclicked');
            }
        },
        components: {
            'k-dialog': Dialog,
            'k-action-bar': DialogActionsBar,
            'k-button': Button
        }
    }
</script>

<style>
    @import '/src/assets/css/confirmDialog.css';
</style>