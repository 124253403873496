export default {
    decimalSelects: [1, 1.5, 2, 2.5, 3, 3.5, 4, 4.5, 5 ],
    defensePositions: ['Defense', 'Left Defense', 'Right Defense'],
    defenseProjectionSelects: ['NHL All-Star', '1st pair in NHL', 'Top 4 D', 'Bottom 4 D', 'Depth', 'Minors', 'No pro potential'],
    forwardPositions: ['Center', 'Right Wing', 'Left Wing', 'Forward'],
    forwardProjectionSelects: ['NHL All-Star', '1st line in NHL', 'Top 6 F', 'Bottom 6 F', 'Depth', 'Minors', 'No pro potential'],
    goaliePositions: ['Goalie'],
    goalieProjectionSelects: ['NHL All-Star', 'Starter', 'Backup', 'Minors', 'No pro potential'],
    penaltyKillProjectionSelects: ['Unit 1', 'Unit 2', 'Neither'],
    playerOfInterestSelects: ['Yes', 'No'],
    powerPlayProjectionSelects: ['Unit 1', 'Unit 2', 'Neither'],
    scoutingReportDateFields: ['gameDate'],
    yesNoSelects: ['Yes', 'No']
}