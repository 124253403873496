<template>
    <div class="upload-documents">
        <transition name="dialogfade">
            <k-dialog v-if="showDialog" class="default__dialog upload-documents-dialog">
                <div class="icon__container">
                    <i class="fa-solid fa-upload"></i>
                </div>
                <div class="header__container">
                    <svg viewBox="0 0 300 50" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" class="icon__top-bar" clip-rule="evenodd" d="M10 0C4.47715 0 0 4.47715 0 10V50H300V10C300 4.47715 295.523 0 290 0H184.388C184.388 9.01736 180.732 17.6654 174.226 24.0416C167.72 30.4179 158.895 34 149.694 34C140.492 34 131.668 30.4179 125.161 24.0416C118.655 17.6654 115 9.01737 115 5.13386e-06L149.694 0H10Z" fill="#D4B139"></path></svg>
                </div>
                <div class="create-document__header">
                    <h1 class="dialog--title">Upload Documents</h1>
                    <i @click="onCancelClicked" title="Close" class="fa-sharp fa-solid fa-xmark item-cancel__btn"></i>
                </div>
                <div class="upsert-item__container create-association">
                    <k-stepper :value="wizardStep" :items="wizardSteps" @change="wizardChange"></k-stepper>
                        <section class="upsert-item__section" v-if="wizardStep === 0">

                            <div class="player-documents__upload-container">
                                <k-upload :files="files"
                                        :multiple="true"
                                        :batch="false"
                                        :showActionButtons="false"
                                        :autoUpload="false"
                                        @add="onFileAdd"
                                        @remove="onFileRemove"></k-upload>
                            </div>
                        </section>
                        <section class="upsert-item__section" v-show="wizardStep === 1">
                            <p class="field--required">*Required</p>
                            <div v-for="file in uploadRequest" :key="file.documentPath" class="file-detail__container">
                                <div class="file-detail__row pair">
                                    <div class="file-detail__item">
                                        <label>
                                            *Document Name
                                            <k-input v-model="file.documentName" />
                                        </label>
                                    </div>
                                    <div class="file-detail__item">
                                        <label>
                                            *Upload Documents To
                                            <k-dropdown :data-items="documentRootFolders"
                                                        :text-field="'name'"
                                                        :default-item="{ name: 'Select Folder', id: null }"
                                                        v-model="file.selectedDocumentFolder"
                                                        :class="['upload-folder__dropdown']"
                                                        @change="onDocumentFolderSelected(file)"></k-dropdown>
                                        </label>
                                    </div>
                                </div>
                                <div class="file-detail__row">
                                    <div class="file-detail__item">
                                        <label>
                                            Document Description
                                            <k-input v-model="file.documentDescription" />
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </section>
                    <k-action-bar>
                        <div class="action-btns">
                            <k-button class="wizard-prev__btn" v-if="wizardStep === 1" @click="wizardStep = 0">Previous</k-button>
                            <div class="action-btns-group">
                                <k-button class="item-save__btn" v-if="wizardStep === 1" @click="onUploadFiles" :disabled="uploadFilesBtnDisabled">Upload</k-button>
                                <k-button class="item-save__btn" v-if="wizardStep === 0" @click="wizardStep = 1" :disabled="nextStepBtnDisabled">Next</k-button>
                            </div>
                        </div>
                    </k-action-bar>
                </div>
            </k-dialog>
        </transition>

        <div class="loader-container" v-if="isLoading">
            <k-loader :size="'large'" :type="'infinite-spinner'"></k-loader>
        </div>

        <transition name="dialogfade">
            <k-dialog v-if="showErrorDialog" :title="errorTitle" class="error__dialog" @close="toggleErrorDialog">
                <p class="error-dialog__msg">{{ errorMsg }}</p>
            </k-dialog>
        </transition>

        <k-notification-group class="default-notification-container">
                <k-fade :appear="successNotification">
                    <k-notification v-if="successNotification"
                                    :type="{ style: 'success', icon: true }"
                                    :closable="true"
                                    @close="onCloseNotification('successNotification')">
                        <span>{{ notificationMsg }}</span>
                    </k-notification>
                </k-fade>
        </k-notification-group>
    </div>
</template>

<script>
    import { Dialog, DialogActionsBar } from '@progress/kendo-vue-dialogs';
    import { Button } from "@progress/kendo-vue-buttons";
    import { Loader } from "@progress/kendo-vue-indicators";
    import { Input } from "@progress/kendo-vue-inputs";
    import { Notification, NotificationGroup } from "@progress/kendo-vue-notification";
    import { Fade } from "@progress/kendo-vue-animation";
    import { Stepper } from '@progress/kendo-vue-layout';
    import { Upload } from '@progress/kendo-vue-upload';
    import { DropDownList } from '@progress/kendo-vue-dropdowns';

    import auth from '../authConfig';
    import apiService from '../api/api.service.js';

    export default {
        name: 'uploadDocumentsDialog',
        props: ['defaultFolderName', 'playerId', 'showDialog'],
        emits: ['dialogclosed', 'documentsuploaded'],
        data () {
            return {
                documentRootFolders: [],
                errorMsg: '',
                errorTitle: '',
                files: [],
                isLoading: false,
                notificationMsg: '',
                showErrorDialog: false,
                successNotification: false,
                uploadFolderDropdownValue: null,
                uploadRequest: [],
                wizardStep: 0,
                wizardSteps: [
                    {
                        label: 'Select Documents'
                    },
                    {
                        label: 'Document Details'
                    }
                ],
            }
        },
        computed: {
            nextStepBtnDisabled() {
                return !this.files || this.files.length === 0;
            },
            uploadFilesBtnDisabled() {
                let isFiles = this.files && this.files.length > 0;
                let isFolders = this.uploadRequest.every(file => file.documentFolder && file.documentFolder !== 'Select Folder');
                let isDocumentNames = this.uploadRequest.every(file => file.documentName);

                return !(isFiles && isFolders && isDocumentNames);
            }
        },
        watch: {
            showDialog(val) {
                if (val) {
                    this.getDocumentRootFolders();

                    this.$nextTick(() => {
                        this.addDropzoneHoverEvent()
                    });
                } else {
                    this.resetDefaults();
                }
            },
            wizardStep(val) {
                if (val === 1) {
                    this.formatUploadRequest();
                }
            }
        },
        methods: {
            addDropzoneHoverEvent() {
                let dropzone = document.querySelector('.k-dropzone');
                
                if (dropzone) {
                    dropzone.addEventListener('dragenter', () => {
                        dropzone.classList.add('hovered');
                    });

                    dropzone.addEventListener('dragleave', () => {
                        dropzone.classList.remove('hovered');
                    });

                    dropzone.addEventListener('dragover', (evt) => {
                        evt.preventDefault();
                    });
                }
            },
            formatUploadRequest() {
                Promise.all(this.files.map(async(file) => {
                    let selectedDocumentFolder = null;

                    if (this.defaultFolderName) {
                        selectedDocumentFolder = this.documentRootFolders.find(folder => folder.name === this.defaultFolderName);
                    }
                    let rawFile = file.getRawFile();

                    let fileBase64 = await this.getFileBase64(rawFile);

                    return {
                        base64File: fileBase64.split(',')[1],
                        documentFullName: rawFile.name,
                        documentExtension: file.extension,
                        documentName: rawFile.name.substring(0, rawFile.name.lastIndexOf('.')) || rawFile.name,
                        documentType: rawFile.type,
                        documentPath: `/${rawFile.name}`,
                        documentFolder: this.defaultFolderName ?? null,
                        documentDescription: '',
                        folderError: false,
                        selectedDocumentFolder: selectedDocumentFolder
                    };
                })).then(data => {
                    this.uploadRequest = data;
                })
            },
            async getDocumentRootFolders() {
                let self = this;
                self.isLoading = true;

                let tokenResponse = await auth.acquireTokenResponse();

                return apiService.GetDocumentRootFolders(tokenResponse.accessToken)
                    .then((res) => {
                        self.documentRootFolders = res.data.data ?? [];

                        self.isLoading = false;
                    }).catch(error => {
                        console.log(error);
                    });

            },
            getFileBase64(file) {
                return new Promise((resolve, reject) => {
                    const reader = new FileReader();
                    reader.readAsDataURL(file);
                    reader.onload = () => resolve(reader.result);
                    reader.onerror = reject;
                });
            },
            onCancelClicked() {
                this.$emit('dialogclosed');
            }, 
            onCloseNotification(flag) {
                this[flag] = false;
            },
            onDocumentFolderSelected(file) {
                if (!file.selectedDocumentFolder.id) return file.documentFolder = null;

                file.documentFolder = file.selectedDocumentFolder.name;
            },
            onFileAdd(evt) {
                this.files = evt.newState;
            },
            onFileRemove(evt) {
                this.files = evt.newState;
            },
            onRequestError(errorTitle, errorMsg) {
                this.isLoading = false;

                this.errorTitle = errorTitle;

                if (errorMsg) {
                    this.errorMsg = errorMsg;
                } else {
                    this.errorMsg = 'Please try again';
                }

                this.showErrorDialog = true;
            },
            async onUploadFiles() {
                this.isLoading = true;

                this.uploadRequest.forEach(file => {
                    let fileCode = this.playerId.split('-').join('');
                    file.documentFullName = `${file.documentName}~${fileCode}${file.documentExtension}`;
                    file.documentPath = `${file.documentFolder}/${file.documentName}~${fileCode}${file.documentExtension}`;
                });

                this.uploadDocuments(this.uploadRequest) 
            },
            resetDefaults() {
                this.files = [];
                this.wizardStep = 0;
                this.uploadRequest = [];
            },
            showNotification(notificationMsg) {
                this.notificationMsg = notificationMsg;

                this.successNotification = true;

                setTimeout(() => {
                    this.successNotification = false;
                }, 4000);
            },
            toggleErrorDialog() {
                this.showErrorDialog = !this.showErrorDialog;

                if(!this.showErrorDialog) {
                    this.errorTitle = '';
                    this.errorMsg = '';
                }
            },
            async uploadDocuments(documentsData) {
                let self = this;

                this.$emit('dialogclosed');

                let tokenResponse = await auth.acquireTokenResponse();

                return apiService.UploadHockeyDocuments(tokenResponse.accessToken, documentsData, this.playerId)
                    .then(res => {
                        self.isLoading = false;
                        self.showNotification('Documents uploaded successfully');

                        self.$emit('documentsuploaded', res.data.data.sort((a, b) => a.documentName.localeCompare(b.documentName)));
                    }).catch(error => {
                        console.log(error);
                        this.onRequestError('An Error Occurred', error?.response?.data?.message);
                    });
            },
            wizardChange(evt) {
                this.wizardStep = evt.value;
            }
        },
        components: {
            'k-dialog': Dialog,
            'k-action-bar': DialogActionsBar,
            'k-button': Button,
            'k-loader': Loader,
            'k-notification': Notification,
            'k-notification-group': NotificationGroup,
            'k-fade': Fade, 
            'k-stepper': Stepper,
            'k-upload': Upload,
            'k-dropdown': DropDownList,
            'k-input': Input
        }
    }
</script>

<style>
    @import '/src/assets/css/defaultUpsertStyles.css';
    @import '/src/assets/css/uploadDocumentsDialog.css';
</style>