<template>
    <div class="invoice-payment invoice-payment-date">
        <transition name="dialogfade">
            <k-dialog v-if="showDialog" class="default__dialog invoice-payment-dialog">
                <div class="icon__container">
                    <i class="fa-solid fa-calendar-days"></i>
                </div>
                <div class="header__container">
                    <svg viewBox="0 0 300 50" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" class="icon__top-bar" clip-rule="evenodd" d="M10 0C4.47715 0 0 4.47715 0 10V50H300V10C300 4.47715 295.523 0 290 0H184.388C184.388 9.01736 180.732 17.6654 174.226 24.0416C167.72 30.4179 158.895 34 149.694 34C140.492 34 131.668 30.4179 125.161 24.0416C118.655 17.6654 115 9.01737 115 5.13386e-06L149.694 0H10Z" fill="#D4B139"></path></svg>
                </div>
                <div class="enter-payment__header">
                    <h1 class="dialog--title">Enter Payment Date: <span>{{ invoice.fullName }} {{ invoice.invoiceNo }}</span></h1>
                </div>
                <div class="upsert-item__container invoice-payment">
                    <section class="upsert-item__section">
                        <div class="input__row pair">
                            <label class="input__label">
                                Payment Invoice
                                <k-input v-model="invoice.invoiceNo" disabled />
                            </label>
                            <label class="input__label">
                                Date Paid
                                <k-datepicker v-model="invoice.datePaid"></k-datepicker>
                            </label>
                        </div>
                    </section>

                    <k-action-bar>
                        <k-button class="item-save__btn" @click="onSaveClicked">Save</k-button>
                        <k-button class="item-cancel__btn" @click="onCancelClicked">Cancel</k-button>
                    </k-action-bar>
                </div>
            </k-dialog>
        </transition>
    </div>
</template>

<script>
    import { Dialog, DialogActionsBar } from '@progress/kendo-vue-dialogs';
    import { Button } from "@progress/kendo-vue-buttons";
    import { Input } from "@progress/kendo-vue-inputs";
    import { DatePicker } from '@progress/kendo-vue-dateinputs';

    import cloneDeep from 'lodash.clonedeep';

    export default {
        name: 'invoicePaymentDialog',
        props: [ 'selectedInvoice', 'showDialog'],
        emits: ['dateentered', 'dialogclosed'],
        data() {
            return {
                invoice: null,
                selectedPlayerInvoice: null
            }
        },
        watch: {
            showDialog(val) {
                if (val) {
                    this.invoice = cloneDeep(this.selectedInvoice);
                    this.invoice.datePaid = new Date();
                } else {
                    this.resetDefaults();
                }
            }
        },
        methods: {
            onCancelClicked() {
                this.$emit('dialogclosed');
            },
            onSaveClicked() {
                this.$emit('dateentered', this.invoice.datePaid);
            },
            resetDefaults() {
                this.invoice = null
            }
        },
        filters: {
            formatCurrencyDec(val) {
                if (val === null || val === undefined || val === '') return '';

                if (val.toString().includes('$')) return val;

                if (isNaN(val)) {
                    val = 0;
                }

                let formatter = new Intl.NumberFormat('en-US', {
                    style: 'currency',
                    currency: 'USD',
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                });

                return formatter.format(val);
            }
        },
        components: {
            'k-dialog': Dialog,
            'k-action-bar': DialogActionsBar,
            'k-button': Button,
            'k-datepicker': DatePicker,
            'k-input': Input
        }
    }
</script>

<style>
    @import '/src/assets/css/defaultUpsertStyles.css';
    @import '/src/assets/css/invoicePaymentDialog.css';
    @import '/src/assets/css/invoicePaymentDateDialog.css';
</style>