<template>
    <div class="dashboard-tab-component">
        <k-tabstrip :selected="selectedTab" @select="onTabSelected" :tabs="tabs">
            <template v-slot:tasksevents>
                <div class="tasks-events-tab__container upsert-item__container">
                    <div class="events__container">
                        <div class="title-container">
                            <h4><i class="fa-duotone fa-calendar-days"></i> Events</h4>
                        </div>
                        <div class="event-filters__container upsert-item__section">
                            <div class="input__row trio">
                                <label class="input__label date-range">
                                    Date Range
                                    <k-dropdown :data-items="dateRangeSelects" 
                                        v-model="selectedEventDateRange"></k-dropdown>
                                </label>
                                <label class="input__label">
                                    Player
                                    <k-dropdown :data-items="filteredEventPlayerSelects" 
                                        v-model="selectedEventPlayer"
                                        :filterable="true"
                                        @filterchange="onEventPlayerFilterChange"></k-dropdown>
                                </label>
                                <label class="input__label">
                                    Category
                                    <k-dropdown :data-items="categorySelects" 
                                        v-model="selectedEventCategory"></k-dropdown>
                                </label>
                            </div>
                        </div>
                        <div class="events-list__container">
                            <div v-for="event in filteredEvents" 
                                :key="event.eventId" 
                                :class="['event-item', { 'urgent': event.daysUntilDue <= 7 }, { 'accent': event.daysUntilDue > 7 && event.daysUntilDue <= 30 }, { 'accentlt': event.daysUntilDue > 30 && event.daysUntilDue <= 60 }, { 'accentltr': event.daysUntilDue > 60 }]">
                                <div class="event-detail">
                                    <div class="days-until">
                                        <p>{{ event.daysUntilDue }} {{ event.daysUntilDue === 1 ? 'Day' : 'Days' }}</p>
                                    </div>
                                    <div class="event-body">
                                        <h4 class="event-title">{{ event.fullName }} <span>| {{ event.title }} {{ new Date(event.eventDate).toLocaleDateString() }} <span v-if="event.category === 'Signing Bonus' && event.bonusAmount"> - {{currencyFormatter.format(event.bonusAmount)}}</span></span></h4>
                                        <div class="event-actions">
                                            <k-button class="pin-event__btn" :title="event.pinned ? 'Unpin Event' : 'Pin Event'" @click="updateEventPin(event)"><i :class="['fa-sharp', 'fa-solid', 'fa-thumbtack', { 'pinned': event.pinned }]"></i></k-button>
                                            <k-button class="add-task__btn" @click="onAddTaskFromEvent(event)" :disabled="allTaskPlayers?.length === 0">Add Task</k-button>
                                            <div class="task-count__container">
                                                <k-button v-if="event.taskCount > 0" @click="onExpandEventTasks(event)">{{ event.completedTaskCount }}/{{ event.taskCount }}</k-button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <k-slide :appear="event.expandTasks">
                                    <div v-if="event.expandTasks" class="event-tasks__panel">
                                        <div class="event-task" v-for="task in event.tasks" :key="task.taskId">
                                            <label class="input__label">
                                                <k-checkbox v-model="task.completed" @change="onCompleteTask($event, task.taskId)" :disabled="task.completed"></k-checkbox>
                                                {{ task.subject }}
                                            </label>
                                            <p class="task-due">Due {{ new Date(task.dueDate).toLocaleDateString() }}</p>
                                        </div>
                                    </div>
                                </k-slide>
                            </div>
                        </div>
                    </div>
                    <div class="tasks__container">
                        <div class="title-container">
                            <h4><i class="fa-duotone fa-list-check"></i> Tasks</h4>
                        </div>
                        <div class="list-create__container">
                            <div :class="['task-create__container', 'upsert-item__section', { 'active': selectedTaskPlayer.id }]">
                                <h5 class="task--header">{{ task.taskId ? 'Edit Task' : 'Create Task' }} <span class="field--required">*Required</span></h5>
                                <div class="input__row">
                                    <label class="input__label">
                                        *Subject
                                        <k-input v-model="task.subject" :class="[{ 'error': task$.task.subject.$error }]" @keydown="task$.task.subject.$reset()" />
                                        <span class="item-input--vuelidate-error" v-if="task$.task.subject.$error"><i>Subject is required</i></span>
                                    </label>
                                </div>
                                <div class="input__row">
                                    <label class="input__label">
                                        *Player
                                        <k-dropdown :data-items="filteredTaskPlayers" 
                                            :class="[{ 'error': task$.task.playerId.$error }]"
                                            v-model="selectedTaskPlayer" 
                                            :text-field="'fullName'" 
                                            :filterable="true" 
                                            @change="onTaskPlayerSelected"
                                            @filterchange="onTaskPlayerFilterChange"></k-dropdown>
                                        <span class="item-input--vuelidate-error" v-if="task$.task.playerId.$error"><i>Player is required</i></span>
                                    </label>
                                </div>
                                <div class="input__row pair">
                                    <label class="input__label">
                                        Assigned To
                                        <k-dropdown :data-items="userSelects"
                                            v-model="selectedTaskAssignee"
                                            :text-field="'displayName'"></k-dropdown>
                                    </label>
                                    <label class="input__label">
                                        *Due Date
                                        <k-datepicker v-model="task.dueDate" :class="[{ 'error': task$.task.dueDate.$error }]" @focus="task$.task.dueDate.$reset()"></k-datepicker>
                                        <span class="item-input--vuelidate-error" v-if="task$.task.dueDate.$error"><i>Due date is required</i></span>
                                    </label>
                                </div>
                                <div class="input__row pair">
                                    <label class="input__label">
                                        *Priority
                                        <k-dropdown :data-items="taskPrioritySelects"
                                                    :class="[{ 'error': task$.task.priority.$error }]"
                                                    :default-item="'Select Priority'"
                                                    v-model="task.priority"
                                                    @change="defaultDropdownSelected($event, 'priority', 'Select Priority')"></k-dropdown>
                                        <span class="item-input--vuelidate-error" v-if="task$.task.priority.$error"><i>Priority is required</i></span>
                                    </label>
                                    <label class="input__label">
                                        *Status
                                        <k-dropdown :data-items="taskStatusSelects"
                                                    :class="[{ 'error': task$.task.status.$error }]"
                                                    :default-item="'Select Status'"
                                                    v-model="task.status"
                                                    @change="defaultDropdownSelected($event, 'status', 'Select Status')"></k-dropdown>
                                        <span class="item-input--vuelidate-error" v-if="task$.task.status.$error"><i>Priority is required</i></span>
                                    </label>
                                </div>
                                <div class="input__row">
                                    <label class="input__label">
                                        Comments
                                        <k-textarea v-model="task.comments" :rows="4"></k-textarea>
                                    </label>
                                </div>
                                <div class="task-btns">
                                    <k-button class="clear-task__btn" @click="onCancelTask">Cancel</k-button>
                                    <k-button class="create-task__btn" @click="onCreateTask">{{ task.taskId ? 'Update Task' : 'Create Task' }}</k-button>
                                </div>
                            </div>
                            <div class="task-list__container">
                                <div class="task-filters__container upsert-item__section">
                                    <div class="input__row pair">
                                        <label class="input__label date-range">
                                            Due Date Range
                                            <k-dropdown :data-items="dateRangeSelects" 
                                                v-model="selectedTaskDateRange"></k-dropdown>
                                        </label>
                                        <label class="input__label">
                                            Player
                                            <k-dropdown :data-items="filteredTaskPlayerSelects" 
                                                v-model="selectedTaskFilterPlayer"
                                                :filterable="true"
                                                @filterchange="onTaskPlayerSelectFilterChange"></k-dropdown>
                                        </label>
                                    </div>
                                </div>
                                <div class="tasks-list__container" v-if="filteredTasks.length > 0">
                                    <div :class="['task-item', { 'urgent': task.daysUntilDue <= 7 }, { 'accent': task.daysUntilDue > 7 && task.daysUntilDue <= 30 }, { 'accentlt': task.daysUntilDue > 30 && task.daysUntilDue <= 60 }, { 'accentltr': task.daysUntilDue > 60 }]" 
                                        v-for="task in filteredTasks" 
                                        :key="task.taskId"
                                        @click="onEditTask($event, task.taskId)">
                                        <div class="check-container">
                                            <k-checkbox title="Complete Task" @change="onCompleteTask($event, task.taskId)"></k-checkbox> 
                                        </div>
                                        <div class="detail-rows">
                                            <div class="row">
                                                <p class="task-subject">{{ task.subject }}</p>
                                                <p class="task-date">{{ new Date(task.dueDate).toLocaleDateString() }}</p>
                                            </div>
                                            <div class="row">
                                                <p class="task-player">{{ task.fullName }}</p>
                                                <p class="task-status"><span v-if="task.assignedToName">{{ task.assignedToName}} | </span> {{ task.status }}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="tasks-list__container" v-if="filteredTasks.length === 0 && !isLoading">
                                    <p class="task-list--empty">No uncompleted tasks available</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
            <template v-slot:contracts>
                <div class="contracts-tab__container">
                    <view-header :title="'Contracts'">
                        <template v-slot:viewicon>
                            <i class="fa-duotone fa-layer-group"></i>
                        </template>
                    </view-header>
                    <detail-view-grid :columns="contractsColumns"
                                    :initial-sort="[{ field: 'fullName', dir: 'asc' }]"
                                    :item-id-field="'playerId'"
                                    :items="contracts"
                                    :row-clickable="true"
                                    :allow-export="true"
                                    :export-title="'Contracts'"
                                    :row-click-function="onGoToContract"
                                    :delete-action="false">
                    </detail-view-grid>
                </div>
            </template>
            <template v-slot:upcomingufa>
                <div class="contracts-tab__container" v-if="currentSeasonStart && currentSeasonEnd">
                    <detail-view-grid-season-select :card-title="'Upcoming UFA'"
                                                    :grid-items="ufaContracts"
                                                    :grid-columns="ufaColumns"
                                                    :card-initial-sort="[{ field: 'fullName', dir: 'asc' }]"
                                                    :card-icon-class="'fa-duotone fa-hockey-sticks'"
                                                    :current-season="`${currentSeasonStart} / ${currentSeasonEnd}`"
                                                    :next-season="`${currentSeasonStart + 1} / ${currentSeasonEnd + 1}`"
                                                    :current-season-start="currentSeasonStart"
                                                    :card-type="'contract'"
                                                    :agent-dashboard="false"
                                                    @itemclicked="onGoToPlayer"></detail-view-grid-season-select>
                </div>
            </template>
            <template v-slot:upcomingrfa>
                <div class="contracts-tab__container" v-if="currentSeasonStart && currentSeasonEnd">
                    <detail-view-grid-season-select :card-title="'Upcoming RFA'"
                                                    :grid-items="rfaContracts"
                                                    :grid-columns="rfaColumns"
                                                    :card-initial-sort="[{ field: 'fullName', dir: 'asc' }]"
                                                    :card-icon-class="'fa-duotone fa-hockey-sticks'"
                                                    :current-season="`${currentSeasonStart} / ${currentSeasonEnd}`"
                                                    :next-season="`${currentSeasonStart + 1} / ${currentSeasonEnd + 1}`"
                                                    :current-season-start="currentSeasonStart"
                                                    :card-type="'contract'"
                                                    :agent-dashboard="false"
                                                    @itemclicked="onGoToPlayer"></detail-view-grid-season-select>
                </div>
            </template>
            <template v-slot:drafteligible>
                <div class="contracts-tab__container" v-if="currentSeasonStart && currentSeasonEnd">
                    <detail-view-grid-season-select :card-title="'Draft Eligible'"
                                                    :grid-items="draftEligiblePlayers"
                                                    :grid-columns="draftEligiblePlayersColumns"
                                                    :card-icon-class="'fa-duotone fa-layer-group'"
                                                    :current-season="`${currentSeasonStart} / ${currentSeasonEnd}`"
                                                    :next-season="`${currentSeasonStart + 1} / ${currentSeasonEnd + 1}`"
                                                    :current-season-start="currentSeasonStart + 1"
                                                    :card-type="'draft'"
                                                    :agent-dashboard="false"
                                                    @itemclicked="onGoToPlayer"
                                                    @drafteligibleyearselected="setSelectedDraftEligibleYear"
                                                    @playerupdated="onPlayerUpdated"></detail-view-grid-season-select>
                </div>
            </template>
            <template v-slot:statistics>
                <div class="statistics-tab__container">
                    <view-header :title="'Statistics'">
                        <template v-slot:viewicon>
                            <i class="fa-duotone fa-hockey-stick-puck"></i>
                        </template>
                    </view-header>
                    <detail-view-grid :columns="statisticsColumns"
                                    :initial-sort="[{ field: 'points', dir: 'desc' }]"
                                    :item-id-field="'playerId'"
                                    :items="statistics"
                                    :row-clickable="true"
                                    :row-click-function="onGoToPlayer"
                                    :delete-action="false"
                                    :allow-export="true"
                                    :export-title="'Statistics'">
                    </detail-view-grid>
                </div>
            </template>
        </k-tabstrip>

        <div class="loader-container" v-if="isLoading">
            <k-loader :size="'large'" :type="'infinite-spinner'"></k-loader>
        </div>

        <transition name="dialogfade">
            <k-dialog v-if="showErrorDialog" :title="errorTitle" class="error__dialog" @close="toggleErrorDialog">
                <p class="error-dialog__msg">{{ errorMsg }}</p>
            </k-dialog>
        </transition> 

        <k-notification-group class="default-notification-container">
                <k-fade :appear="successNotification">
                    <k-notification v-if="successNotification"
                                    :type="{ style: 'success', icon: true }"
                                    :closable="true"
                                    @close="onCloseNotification('successNotification')">
                        <span>{{ notificationMsg }}</span>
                    </k-notification>
                </k-fade>
        </k-notification-group>
    </div>
</template>

<script>
    import { Dialog } from '@progress/kendo-vue-dialogs';
    import { TabStrip } from '@progress/kendo-vue-layout';
    import { Loader } from "@progress/kendo-vue-indicators";
    import { DropDownList } from '@progress/kendo-vue-dropdowns';
    import { Button } from "@progress/kendo-vue-buttons";
    import { Input, TextArea, Checkbox } from "@progress/kendo-vue-inputs";
    import { DatePicker } from '@progress/kendo-vue-dateinputs';
    import { Slide } from "@progress/kendo-vue-animation";
    import { Notification, NotificationGroup } from "@progress/kendo-vue-notification";
    import { Fade } from "@progress/kendo-vue-animation";

    import { filterBy } from '@progress/kendo-data-query';

    import detailViewGrid from '../components/detailViewGrid.vue';
    import viewHeader from '../components/viewHeader.vue';
    import detailViewGridSeasonSelect from '../components/detailViewGridSeasonSelect.vue';

    import { useVuelidate } from '@vuelidate/core';
    import { required } from '@vuelidate/validators';

    import cloneDeep from 'lodash.clonedeep';

    import auth from '../authConfig';
    import apiService from '../api/api.service.js';
    import eventsHelpers from '../helpers/eventsHelpers.js';
    import formatHelpers from '../helpers/formatHelpers.js';
    import taskHelpers from '../helpers/taskHelpers.js';
    import playerHelpers from '@/helpers/playerHelpers';

    export default {
        setup() {
            return { task$: useVuelidate() }
        },
        name: 'dashboardTabComponent',
        data () {
            return {
                allTaskPlayers: [],
                contracts: [],
                contractsColumns: [
                    { field: 'fullName', title: 'Name' },
                    { field: 'playerAge', title: 'Age', type: 'number', filter: 'numeric', width: '150' },
                    { field: 'contractType', title: 'Contract Type' },
                    { field: 'aav', title: 'AAV', type: 'number', filter: 'numeric', format: '{0:c0}' }                   
                ],
                contractDataModel: {
                    aav: null,
                    age: null,
                    amountYearFour: null,
                    amountYearOne: null,
                    amountYearThree: null,
                    amountYearTwo: null,
                    contractId: null,
                    contractType: null,
                    endDate: null,
                    expirationStatus: null,
                    fullName: null,
                    playerId: null,
                    startDate: null
                },
                currencyFormatter: new Intl.NumberFormat('en-US', {
                    style: 'currency',
                    currency: 'USD',
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0
                }),
                currentSeasonEnd: null,
                currentSeasonStart: null,
                draftEligiblePlayers: [],
                draftEligiblePlayersColumns: [
                    { field: 'fullName', title: 'Name', width: '225' },
                    { field: 'playerAge', title: 'Age', type: 'number', filter: 'numeric', width: '150' },
                    //{ field: 'projectedDraftRound', title: 'Proj. Round', width: '150', type: 'number', filter: 'numeric' },
                    { cell: 'projecteddraftround', width: '200', title: 'Proj. Round', type: 'number', filter: 'numeric' },
                    { field: 'currentLeague', title: 'League' },
                    { field: 'positionString', title: 'Pos' }
                ],
                errorMsg: '',
                errorTitle: '',
                eventPlayerSelects: [],
                events: [],
                filteredEventPlayerSelects: [],
                filteredTaskPlayers: [],
                filteredTaskPlayerSelects: [],
                isLoading: false,
                notificationMsg: '',
                rfaColumns: [
                    { field: 'fullName', title: 'Name', width: '225' },
                    { field: 'playerAge', title: 'Age', type: 'number', filter: 'numeric', width: '150' },
                    { field: 'contractType', title: 'Contract' },
                    { field: 'aav', title: 'AAV', type: 'number', filter: 'numeric', format: '{0:c0}' },
                    { field: 'expirationStatus', title: 'Type' },
                ],
                rfaContracts: [],
                rfaTypes: ['RFA', 'RFA ARB Rights'],
                selectedDraftEligibleYear: null,
                selectedEventCategory: 'All',
                selectedEventDateRange: '90 Days',
                selectedEventPlayer: 'All',
                selectedTab: 0,
                selectedTaskAssignee: { displayName: 'Select User', id: null },
                selectedTaskAssigneeModel: { displayName: 'Select User', id: null },
                selectedTaskDateRange: '90 Days',
                selectedTaskFilterPlayer: 'All',
                selectedTaskPlayer: { id: null, fullName: 'Select Player' },
                showErrorDialog: false,
                statistics: [],
                statisticsColumns: [
                    { field: 'fullName', title: 'Name', width: '200' },
                    { field: 'points', title: 'P', type: 'number', filter: 'numeric', filterable: false, headerCell: 'headerTemplate' },
                    { field: 'goals', title: 'G', type: 'number', filter: 'numeric', filterable: false, headerCell: 'headerTemplate' },
                    { field: 'assists', title: 'A', type: 'number', filter: 'numeric', filterable: false, headerCell: 'headerTemplate' },
                    { field: 'gamesPlayed', title: 'GP', type: 'number', filter: 'numeric', filterable: false, headerCell: 'headerTemplate' },
                    { field: 'penaltyMinutes', title: 'PIM', type: 'number', filter: 'numeric', filterable: false, headerCell: 'headerTemplate' },
                    { field: 'powerPlayGoals', title: 'PPG', type: 'number', filter: 'numeric', filterable: false, headerCell: 'headerTemplate' },
                    { field: 'powerPlayPoints', title: 'PPP', type: 'number', filter: 'numeric', filterable: false, headerCell: 'headerTemplate' },
                    { field: 'shorthandedGoals', title: 'SHG', type: 'number', filter: 'numeric', filterable: false, headerCell: 'headerTemplate' },
                    { field: 'shorthandedPoints', title: 'SHP', type: 'number', filter: 'numeric', filterable: false, headerCell: 'headerTemplate' },
                    { field: 'gameWinningGoals', title: 'GWG', type: 'number', filter: 'numeric', filterable: false, headerCell: 'headerTemplate' },
                    { field: 'overtimeGoals', title: 'OTG', type: 'number', filter: 'numeric', filterable: false, headerCell: 'headerTemplate' },
                    { field: 'shots', title: 'S', type: 'number', filter: 'numeric', filterable: false, headerCell: 'headerTemplate' },
                    { field: 'shootingPercentage', title: 'S%', format: "{0: #.###\\%}", type: 'number', filter: 'numeric', filterable: false, headerCell: 'headerTemplate' }
                ],
                successNotification: false,
                tabs:[
                    {
                        title: 'Tasks & Events',
                        content: 'tasksevents'
                    },
                    {
                        title: 'Contracts',
                        content: 'contracts'
                    },
                    {
                        title: 'Upcoming UFA',
                        content: 'upcomingufa'
                    },
                    {
                        title: 'Upcoming RFA',
                        content: 'upcomingrfa'
                    },
                    {
                        title: 'Draft Eligible',
                        content: 'drafteligible'
                    },
                    {
                        title: 'Statistics',
                        content: 'statistics'
                    }
                ],
                task: {},
                taskModel: {
                    taskId: null,
                    subject: null,
                    dueDate: null,
                    status: 'Not Started',
                    priority: 'Normal',
                    comments: null,
                    updatedBy: null,
                    playerId: null,
                    eventId: null,
                    contractId: null,
                    citizenshipDocId: null,
                    noTradeClauseId: null,
                    injuryId: null,
                    assignedToId: null,
                    assignedToName: null
                },
                taskPlayerSelects: [],
                tasks: [],
                ufaColumns: [
                    { field: 'fullName', title: 'Name', width: '225' },
                    { field: 'playerAge', title: 'Age', type: 'number', filter: 'numeric', width: '150' },
                    { field: 'contractType', title: 'Contract' },
                    { field: 'aav', title: 'AAV', type: 'number', filter: 'numeric', format: '{0:c0}' }
                ],
                ufaContracts: [],
                ufaType: 'UFA',
                userSelects: []
            }
        },
        validations() {
            return {
                task: {
                    playerId: { required },
                    dueDate: { required },
                    subject: { required },
                    priority: { required },
                    status: { required }
                }
            }
        },
        computed: {
            categorySelects() {
                return eventsHelpers.categorySelects
            },
            dateRangeSelects() {
                return eventsHelpers.dateRangeSelects;
            },
            filteredEvents() {
                let events = cloneDeep(this.events);

                if (this.selectedEventDateRange === 'Today') {
                    events = events.filter(event => formatHelpers.formatDate(event.eventDate).getTime() === formatHelpers.formatDate(new Date()).getTime());
                }

                if (this.selectedEventDateRange === 'This Week') {
                    events = events.filter(event => {
                        return formatHelpers.formatDate(event.eventDate) >= this.getFirstDayOfWeek() && formatHelpers.formatDate(event.eventDate) <= this.getLastDayOfWeek();
                    });
                    console.log(events)
                }

                if (this.selectedEventDateRange === '30 Days') {
                    events = events.filter(event => {
                        return event.daysUntilDue >= 0 && event.daysUntilDue <= 30;
                    });
                }

                if (this.selectedEventDateRange === '30-60 Days') {
                    events = events.filter(event => {
                        return event.daysUntilDue >= 30 && event.daysUntilDue <= 60;
                    });
                }

                if (this.selectedEventDateRange === '60 Days') {
                    events = events.filter(event => {
                        return event.daysUntilDue >= 0 && event.daysUntilDue <= 60;
                    });
                }

                if (this.selectedEventDateRange === '60-90 Days') {
                    events = events.filter(event => {
                        return event.daysUntilDue >= 60 && event.daysUntilDue <= 90;
                    });
                }

                if (this.selectedEventDateRange === '90 Days') {
                    events = events.filter(event => {
                        return event.daysUntilDue >= 0 && event.daysUntilDue <= 90;
                    });
                }

                if (this.selectedEventCategory != 'All') {
                    events = events.filter(event => event.category === this.selectedEventCategory);
                }

                if (this.selectedEventPlayer != 'All') {
                    events = events.filter(event => event.fullName === this.selectedEventPlayer);
                }

                return [...events.filter(event => event.pinned), ...events.filter(event => !event.pinned)];
            },
            filteredTasks() {
                let tasks = cloneDeep(this.tasks);

                if (this.selectedTaskDateRange === 'Today') {
                    tasks = tasks.filter(task => task.daysUntilDue === 0);
                }

                if (this.selectedTaskDateRange === 'This Week') {
                    tasks = tasks.filter(task => {
                        return formatHelpers.formatDate(task.eventDate) >= this.getFirstDayOfWeek() && formatHelpers.formatDate(task.eventDate) <= this.getLastDayOfWeek();
                    });
                }

                if (this.selectedTaskDateRange === '30 Days') {
                    tasks = tasks.filter(task => {
                        return task.daysUntilDue >= 0 && task.daysUntilDue <= 30;
                    });
                }

                if (this.selectedTaskDateRange === '30-60 Days') {
                    tasks = tasks.filter(task => {
                        return task.daysUntilDue >= 30 && task.daysUntilDue <= 60;
                    });
                }

                if (this.selectedTaskDateRange === '60 Days') {
                    tasks = tasks.filter(task => {
                        return task.daysUntilDue >= 0 && task.daysUntilDue <= 60;
                    });
                }

                if (this.selectedTaskDateRange === '60-90 Days') {
                    tasks = tasks.filter(task => {
                        return task.daysUntilDue >= 60 && task.daysUntilDue <= 90;
                    });
                }

                if (this.selectedTaskDateRange === '90 Days') {
                    tasks = tasks.filter(task => {
                        return task.daysUntilDue >= 0 && task.daysUntilDue <= 90;
                    });
                }

                if (this.selectedTaskFilterPlayer != 'All') {
                    tasks = tasks.filter(task => task.fullName === this.selectedTaskFilterPlayer );
                }

                return tasks.filter(task => task.status !== 'Completed');
            },
            taskPrioritySelects() {
                return taskHelpers.prioritySelects;
            },
            taskStatusSelects() {
                return taskHelpers.statusSelects;
            }
        },
        watch: {
            selectedTab(val) {
                if (val === 1 && this.contracts.length === 0) {
                    this.getDashboardContractsData();
                }

                if (val === 2 && this.ufaContracts.length === 0) {
                    this.getDashboardContractsData();
                }

                if (val === 3 && this.rfaContracts.length === 0) {
                    this.getDashboardContractsData();
                }

                if (val === 4) {
                    this.getDraftEligiblePlayers();
                }

                if (val === 5 && this.statistics.length === 0) {
                    this.getDashboardStatisticsData();
                } 
            },
            selectedTaskAssignee(val) {
                if (val.id) {
                    this.task.assignedToId = val.id;
                    this.task.assignedToName = val.displayName;
                } else {
                    this.task.assignedToId = null;
                    this.task.assignedToName = null;
                }
            },
            '$store.state.errorAlert'(val) {
                if (val) {
                    this.onRequestError(this.$store.state.errorTitle, this.$store.state.errorMsg);

                    let errorData = {
                        isError: false,
                        title: '',
                        msg: ''
                    }
                    
                    this.$store.commit('updateErrorAlert', errorData);
                }
            }
        },
        async created() {
            const msalAppInstance = await auth.init();

            msalAppInstance.handleRedirectPromise().then(async (tokenRes) => {
                if (!tokenRes) {
                    if (!auth.user()) {
                        await auth.login();
                    }
                }

                this.getContractSeasonColumns();

                await this.getPlayerEvents();
                await this.getTasks();
                await this.getPlayers();
                await this.getAzureUsers();
                this.assignEventTasks();
                this.task = cloneDeep(this.taskModel);
            });
        },
        async mounted () {
        },
        methods: {
            assignContractsCards() {
                this.contracts = this.$store.state.dashboardContractsData;
                console.log(this.contracts)

                this.rfaContracts = this.$store.state.dashboardContractsData.filter(contract => this.rfaTypes.some(type => contract.expirationStatus === type)); 

                this.ufaContracts = this.$store.state.dashboardContractsData.filter(contract => contract.expirationStatus === this.ufaType);
            },
            assignEventTasks() {
                let uncompletedTasks = this.tasks.filter(task => task.status !== 'Completed');

                this.taskPlayerSelects = [...new Set(uncompletedTasks.map(event => event.fullName))].sort();
                this.taskPlayerSelects.unshift('All');
                this.filteredTaskPlayerSelects = cloneDeep(this.taskPlayerSelects);

                this.tasks.sort((a, b) => new Date(a.dueDate) - new Date(b.dueDate));
                this.tasks.forEach(task => {
                    task.completed = task.status === 'Completed';
                });

                this.events.forEach(event => {
                    event.tasks = this.tasks.filter(task => task.eventId === event.eventId);
                    event.taskCount = event.tasks.length;
                    event.completedTaskCount = event.tasks.filter(task => task.status === 'Completed').length;
                    event.expandTasks = false;
                });
            },
            defaultDropdownSelected(evt, field, defaultValue) {
                if (evt.target.value === defaultValue) {
                    return this.task[field] = null;
                }

                this.task[field] = evt.target.value;

                if (this.task[field]) {
                    this.task$.task[field].$reset();
                }
            },
            async getAzureUsers() {
                let tokenResponse = await auth.acquireTokenResponse();

                return apiService.GetAzureUsers(tokenResponse.accessToken) 
                    .then((res) => {
                        this.userSelects = res.data.data;
                    }).catch(error => {
                        console.log(error);
                    });
            },
            getContractSeasonColumns() {
                let currentMonth = new Date().getMonth();
                let currentYear = new Date().getFullYear();

                if (currentMonth >= 6) {
                    this.currentSeasonStart = currentYear;
                    this.currentSeasonEnd = currentYear + 1;
                } else {
                    this.currentSeasonStart = currentYear - 1;
                    this.currentSeasonEnd = currentYear;
                }

                for (let i = 0; i < 4; i++) {
                    let yearText;
                    switch(i) {
                        case 0:
                            yearText = 'One';
                            break;
                        case 1:
                            yearText = 'Two'; 
                            break;
                        case 2:
                            yearText = 'Three';
                            break;
                        case 3:
                            yearText = 'Four';
                            break;
                    }

                    this.contractsColumns.push( { cell: `amountYear${yearText}`, width: '150', title: `${this.currentSeasonStart + i} / ${this.currentSeasonEnd + i}`, filterable: false, columnMenu: false, sortable: false, type: 'numeric' }); 
                }
            },
            async getDashboardContractsData() {
                this.isLoading = true;
                
                await this.$store.dispatch('getDashboardContractsData', this.currentSeasonStart);
                this.assignContractsCards();

                this.isLoading = false;
            },
            async getDashboardStatisticsData() {
                let self = this;

                this.isLoading = true;

                let tokenResponse = await auth.acquireTokenResponse();

                return apiService.GetDashboardStatisticsData(tokenResponse.accessToken)
                    .then((res) => {
                        self.statistics = res.data.data;

                        self.isLoading = false;
                    }).catch(error => {
                        console.log(error);
                        self.onRequestError('An Error Occurred', error?.response?.data?.message);
                    });
            },
            async getDraftEligiblePlayers() {
                let tokenResponse = await auth.acquireTokenResponse();

                return apiService.GetDraftEligiblePlayers(tokenResponse.accessToken, 10, true)
                    .then((res) => {
                        if (res.data.data) {
                            this.processDraftEligiblePlayers(res.data.data);
                        }
                    }).catch(error => {
                        this.onRequestError('An Error Occurred', error?.response?.data?.message);
                    });
            },
            getFirstDayOfWeek: function (date = null) {
                if (!date) {
                    date = new Date();
                }

                let dayOfWeek = date.getDay();
                let firstDayOfWeek = new Date(date);

                let diff = dayOfWeek >= 1
                    ? dayOfWeek - 1
                    : 6 - dayOfWeek;

                firstDayOfWeek.setDate(date.getDate() - diff);
                firstDayOfWeek.setHours(0, 0, 0, 0);

                return firstDayOfWeek;
            },
            getLastDayOfWeek: function (date = null) {
                if (!date) {
                    date = new Date();
                }

                let dayOfWeek = date.getDay();
                let lastDayOfWeek = new Date(date);

                let diff = (7 + (7 - dayOfWeek)) % 7;

                lastDayOfWeek.setDate(date.getDate() + diff);
                lastDayOfWeek.setHours(0, 0, 0, 0);

                return lastDayOfWeek;
            },
            async getPlayerEvents() {
                let self = this;

                this.isLoading = true;

                let tokenResponse = await auth.acquireTokenResponse();

                return apiService.GetPlayerEvents(tokenResponse.accessToken)
                    .then((res) => {
                        self.events = res.data.data.sort((a, b) => a.daysUntilDue - b.daysUntilDue);

                        self.eventPlayerSelects = [...new Set(self.events.map(event => event.fullName))].sort();
                        self.eventPlayerSelects.unshift('All');
                        self.filteredEventPlayerSelects = cloneDeep(self.eventPlayerSelects);

                        self.isLoading = false;
                    }).catch(error => {
                        console.log(error);
                        self.onRequestError('An Error Occurred', error?.response?.data?.message);
                    });
            },
            async getPlayers(update = true) {     
                await this.$store.dispatch('getPlayers', update);

                this.allTaskPlayers = this.$store.state.players.map(player => {
                    return {
                        id: player.id,
                        fullName: player.fullName,
                    }
                });

                this.allTaskPlayers.sort((a, b) => a.fullName.localeCompare(b.fullName));

                this.allTaskPlayers.unshift({ id: null, fullName: 'Select Player' });

                this.filteredTaskPlayers = cloneDeep(this.allTaskPlayers);
            },
            async getTasks() {
               let self = this;

                this.isLoading = true;

                let tokenResponse = await auth.acquireTokenResponse();

                return apiService.GetUncompletedTasks(tokenResponse.accessToken)
                    .then((res) => {
                        res.data.data.sort((a, b) => new Date(a.dueDate) - new Date(b.dueDate));

                        self.tasks = res.data.data;
                        self.isLoading = false;
                    }).catch(error => {
                        console.log(error);
                        self.onRequestError('An Error Occurred', error?.response?.data?.message);
                    });
            },
            onAddTaskFromEvent(event) {
                this.selectedTaskPlayer = this.allTaskPlayers.find(player => player.id === event.playerId);
                this.task.playerId = this.allTaskPlayers.find(player => player.id === event.playerId).id;
                this.task.dueDate = new Date(event.eventDate);
                this.task.subject = `${event.fullName} ${event.category}`;
                this.task.citizenshipDocId = event.citizenshipDocId;
                this.task.contactId = event.contactId;
                this.task.eventId = event.eventId;
                this.task.injuryId = event.injuryId;
                this.task.noTradeClauseId = event.noTradeClauseId;
            },
            onCancelTask() {
                this.selectedTaskPlayer = { id: null, fullName: 'Select Player' };
                this.selectedTaskAssignee = cloneDeep(this.selectedTaskAssigneeModel);
                this.task = cloneDeep(this.taskModel);
                this.task$.$reset();
            },
            onCloseNotification(flag) {
                this[flag] = false;
            },
            onCompleteTask(evt, taskId) {
                let completedTask = this.tasks.find(task => task.taskId === taskId);
                
                if (completedTask) {
                    completedTask.status = 'Completed';

                    this.upsertTask(completedTask);
                }            
            },
            async onCreateTask() {
                await this.task$.$validate();

                if (this.task$.task.$error) return;

                this.task$.task.$reset();

                this.upsertTask(this.task);
            },
            onEditTask(evt, taskId) {
                if (Array.from(evt.target.classList).some(c => c === 'k-checkbox')) return;

                let selectedTask = this.tasks.find(task => task.taskId === taskId);

                if (selectedTask) {
                    selectedTask.dueDate = new Date(selectedTask.dueDate);
                    this.task = cloneDeep(selectedTask);
                    this.selectedTaskPlayer = this.allTaskPlayers.find(player => player.id === selectedTask.playerId);

                    if (selectedTask.assignedToId) {
                        this.selectedTaskAssignee = this.userSelects.find(user => user.id === selectedTask.assignedToId);
                    } else {
                        this.selectedTaskAssignee = cloneDeep(this.selectedTaskAssigneeModel);
                    }
                }
            },
            onEventPlayerFilterChange(evt) {
                this.filteredEventPlayerSelects = filterBy(this.eventPlayerSelects, evt.filter);
            },
            onExpandEventTasks(event) {
                let sourceEvent = this.events.find(e => e.eventId === event.eventId);

                if (sourceEvent) {
                    sourceEvent.expandTasks = !sourceEvent.expandTasks;
                }
            },
            onGoToContract(evt) {
                this.$router.push(`/ContractDetail?id=${evt.dataItem.contractId}`);
            },
            onGoToPlayer(evt) {
                if (!evt.event?.target?.classList?.contains('k-table-td')) return;

                this.$router.push(`/PlayerDetail?id=${evt.dataItem.playerId ?? evt.dataItem.id}`);
            },
            async onPlayerUpdated(request) {
                this.isLoading = true;
                await this.updatePlayer(request);
                await this.getDraftEligiblePlayers();
                this.isLoading = false;
            },
            onRequestError(errorTitle, errorMsg) {
                this.isLoading = false;

                this.errorTitle = errorTitle;

                if (errorMsg) {
                    this.errorMsg = errorMsg;
                } else {
                    this.errorMsg = 'Please try again';
                }

                this.showErrorDialog = true;
            },
            onTabSelected(evt) {
                this.selectedTab = evt.selected;
            },
            onTaskPlayerFilterChange(evt) {
                this.filteredTaskPlayers = filterBy(this.allTaskPlayers, evt.filter);
            },
            onTaskPlayerSelected(evt) {
                this.task.playerId = evt.value.id;

                if (this.task.playerId) {
                    this.task$.task.playerId.$reset();
                }
            },
            onTaskPlayerSelectFilterChange(evt) {
                this.filteredTaskPlayerSelects = filterBy(this.taskPlayerSelects, evt.filter);
            },
            processDraftEligiblePlayers(playerData) {
                playerData.forEach(player => {
                    player.dob = formatHelpers.formatDate(player.dob);
                    player.draftEligibleDate = formatHelpers.formatDate(player.draftEligibleDate);
                    player.fullName = `${player.lastName}, ${player.firstName}`;
                    player.positionString = playerHelpers.getAbbreviatedPositions(player.positions);
                    player.draftOrder = player.draftOrder ?? player.projectedDraftRound;
                });

                let playersWithRounds = playerData.filter(p => p.projectedDraftRound);

                playersWithRounds.sort((a, b) => {
                    return a.projectedDraftRound - b.projectedDraftRound;
                });

                this.draftEligiblePlayers = [...playersWithRounds, ...playerData.filter(p => !p.projectedDraftRound)];
            },
            setSelectedDraftEligibleYear(year) {
                this.selectedDraftEligibleYear = year;
            },
            showNotification(notificationMsg) {
                this.notificationMsg = notificationMsg;

                this.successNotification = true;

                setTimeout(() => {
                    this.successNotification = false;
                }, 4000);
            },
            toggleErrorDialog() {
                this.showErrorDialog = !this.showErrorDialog;

                if(!this.showErrorDialog) {
                    this.errorTitle = '';
                    this.errorMsg = '';
                }
            },
            async updateEventPin(event) {
                let request = {
                    pinned: !event.pinned,
                    eventId: event.eventId
                }

                let self = this;

                this.isLoading = true;

                let tokenResponse = await auth.acquireTokenResponse();

                return apiService.UpdateEventPin(tokenResponse.accessToken, request)
                    .then((res) => {
                        self.events = res.data.data.sort((a, b) => a.daysUntilDue - b.daysUntilDue);
                        self.assignEventTasks();

                        self.isLoading = false;
                    }).catch(error => {
                        console.log(error);
                        self.onRequestError('An Error Occurred', error?.response?.data?.message);
                    });
            },
            async updatePlayer(request) {
                let self = this;

                let tokenResponse = await auth.acquireTokenResponse();

                return apiService.UpdatePlayer(tokenResponse.accessToken, request)
                    .then(async (res) => {
                    }).catch(error => {
                        console.log(error);
                        this.onRequestError('An Error Occurred', error?.response?.data?.message);
                    });
            },
            async upsertTask(task) {
                let self = this;

                this.isLoading = true;
                
                let tokenResponse = await auth.acquireTokenResponse();
                task.updatedBy = auth.user()?.name;

                return apiService.UpsertTask(tokenResponse.accessToken, task)
                    .then((res) => {
                        res.data.data.sort((a, b) => new Date(a.dueDate) - new Date(b.dueDate));

                        self.tasks = res.data.data;

                        self.assignEventTasks();

                        self.isLoading = false;
                        self.showNotification('Tasks updated successfully');

                        self.onCancelTask();
                    }).catch(error => {
                        console.log(error);
                        self.onRequestError('An Error Occurred', error?.response?.data?.message);
                    });
            }
        },
        components: {
            'k-tabstrip': TabStrip,
            'k-loader': Loader,
            'detail-view-grid': detailViewGrid,
            'view-header': viewHeader,
            'k-dialog': Dialog,
            'k-dropdown': DropDownList,
            'k-button': Button,
            'k-input': Input,
            'k-datepicker': DatePicker,
            'k-textarea': TextArea,
            'k-slide': Slide,
            'k-checkbox': Checkbox,
            'k-notification': Notification,
            'k-notification-group': NotificationGroup,
            'k-fade': Fade,
            'detail-view-grid-season-select': detailViewGridSeasonSelect
        }
    }
</script>

<style>
    @import '/src/assets/css/defaultUpsertStyles.css';
    @import '/src/assets/css/dashboardTabComponent.css';
</style>