<template>
    <div class="upsert-citizenship-document">
        <transition name="dialogfade">
            <k-dialog v-if="showDialog" v-bind:class="['default__dialog', 'upsert-citizenship-document-dialog', { 'type-selected': !showCreateDocumentType }]">
                <div class="icon__container">
                    <i class="fa-solid fa-passport"></i>
                </div>
                <div class="header__container">
                    <svg viewBox="0 0 300 50" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" class="icon__top-bar" clip-rule="evenodd" d="M10 0C4.47715 0 0 4.47715 0 10V50H300V10C300 4.47715 295.523 0 290 0H184.388C184.388 9.01736 180.732 17.6654 174.226 24.0416C167.72 30.4179 158.895 34 149.694 34C140.492 34 131.668 30.4179 125.161 24.0416C118.655 17.6654 115 9.01737 115 5.13386e-06L149.694 0H10Z" fill="#D4B139"></path></svg>
                </div>
                <div class="create-document__header">
                    <h1 class="dialog--title">{{ editMode ? 'Edit Document' : 'Create Document' }}<span class="document--type" v-if="!showCreateDocumentType"> - {{ documentType }}</span></h1>
                    <k-button class="item-back__btn" @click="onBackToDocumentType" v-if="!showCreateDocumentType && !editMode"><i class="fa-solid fa-left"></i> Back</k-button>
                </div>
                <div class="upsert-item__container create-document">
                    <section class="upsert-item__section select-document-type" v-if="showCreateDocumentType">
                        <div class="input__row">
                            <label class="input__label">
                                Document Type
                                <k-dropdown :data-items="documentTypeSelects" :default-item="'Select Document Type'" @change="onDocumentTypeSelected"></k-dropdown>
                            </labeL>
                        </div>
                    </section>

                    <div v-if="!showCreateDocumentType" class="upsert-item">
                        <div class="mode-toggle">
                            <p :class="['toggle-label', { 'active': playerMode }]">Player</p>
                            <k-switch :on-label="''" :off-label="''" v-model="playerMode" :size="'small'" :disabled="editMode"></k-switch>
                            <p :class="['toggle-label', { 'active': !playerMode }]">Family</p>
                        </div>
                        <section class="upsert-item__section" v-if="playerMode">
                            <p class="field--required">*Required</p>
                            <div class="input__row select-player">
                                <label class="input__label">
                                    *Player
                                    <k-dropdown :data-items="filteredPlayers" 
                                        :class="[{ 'error': document$.selectedPlayer.id.$error }]"
                                        v-model="selectedPlayer" 
                                        :text-field="'fullName'" 
                                        :filterable="true"  
                                        :disabled="addMode || editMode"
                                        @change="document$.selectedPlayer.id.$reset()"
                                        @filterchange="onPlayerFilterChange"></k-dropdown>
                                    <span class="item-input--vuelidate-error" v-if="document$.selectedPlayer.id.$error"><i>Player is required</i></span>
                                </label>
                            </div> 
                        </section>
                        <section class="upsert-item__section" v-if="!playerMode">
                            <p class="field--required">*Required</p>
                            <div class="input__row pair select-player">
                                <label class="input__label">
                                    *Associate to Player
                                    <k-dropdown :data-items="filteredPlayers" 
                                        :class="[{ 'error': document$.selectedPlayer.id.$error }]"
                                        v-model="selectedPlayer" 
                                        :text-field="'fullName'" 
                                        :filterable="true"  
                                        :disabled="addMode || editMode"
                                        @change="document$.selectedPlayer.id.$reset()"
                                        @filterchange="onPlayerFilterChange"></k-dropdown>
                                    <span class="item-input--vuelidate-error" v-if="document$.selectedPlayer.id.$error"><i>Player is required</i></span>
                                </label>
                                <label class="input__label">
                                    *Family Member Name
                                    <k-input v-model="document.familyMemberName" />
                                    <span class="item-input--vuelidate-error" v-if="document$.document.familyMemberName.$error"><i>Name is required</i></span>
                                </label>
                            </div> 
                        </section>

                        <section v-if="documentType === greenCardDocumentType" class="upsert-item__section">
                            <div class="input__row pair">
                                <label class="input__label">
                                    Country of Origin
                                    <k-input v-model="document.countryOfOrigin" />
                                </label>
                                <label class="input__label">
                                    Receipt Number
                                    <k-input v-model="document.receiptNumber" />
                                </label>
                            </div>
                            <div class="input__row pair">
                                <label class="input__label">
                                    Issue Date
                                    <k-datepicker v-model="document.issuedDate"></k-datepicker>
                                </label>
                                <label class="input__label">
                                    Expiration Date
                                    <k-datepicker v-model="document.expiringDate"></k-datepicker>
                                </label>
                            </div>
                        </section>

                        <section v-if="documentType === passportDocumentType" class="upsert-item__section">
                            <div class="input__row pair">
                                <label class="input__label">
                                    Country of Origin
                                    <k-input v-model="document.countryOfOrigin" />
                                </label>
                                <label class="input__label">
                                    Passport Number
                                    <k-input v-model="document.passportNumber" />
                                </label>
                            </div>
                            <div class="input__row pair">
                                <label class="input__label">
                                    Issue Date
                                    <k-datepicker v-model="document.issuedDate"></k-datepicker>
                                </label>
                                <label class="input__label">
                                    Expiration Date
                                    <k-datepicker v-model="document.expiringDate"></k-datepicker>
                                </label>
                            </div>
                        </section>

                        <section v-if="documentType === visaDocumentType" class="upsert-item__section">
                            <div class="input__row pair">
                                <label class="input__label">
                                    Country of Origin
                                    <k-input v-model="document.countryOfOrigin" />
                                </label>
                                <label class="input__label">
                                    Visa Number
                                    <k-input v-model="document.visaNumber" />
                                </label>
                            </div>
                            <div class="input__row pair">
                                <label class="input__label">
                                    Visa Type
                                    <k-dropdown v-if="!editMode"
                                                :data-items="visaTypeSelects"
                                                :default-item="'Select Type'"
                                                @change="defaultDropdownSelected($event, 'visaType', 'Select Type')"></k-dropdown>
                                    <k-dropdown v-if="editMode"
                                                :data-items="visaTypeSelects"
                                                v-model="document.visaType"></k-dropdown>
                                </label>
                            </div>
                            <div class="input__row pair">
                                <label class="input__label">
                                    Issue Date
                                    <k-datepicker v-model="document.issuedDate"></k-datepicker>
                                </label>
                                <label class="input__label">
                                    Expiration Date
                                    <k-datepicker v-model="document.expiringDate"></k-datepicker>
                                </label>
                            </div>
                        </section>
                    </div>
                    
                    <k-action-bar>
                        <k-button class="item-save__btn" @click="onSaveClicked">Save</k-button>
                        <k-button class="item-cancel__btn" @click="onCancelClicked">Cancel</k-button>
                    </k-action-bar>
                </div>
            </k-dialog>
        </transition>

        <div class="loader-container" v-if="isLoading">
            <k-loader :size="'large'" :type="'infinite-spinner'"></k-loader>
        </div>

        <transition name="dialogfade">
            <k-dialog v-if="showErrorDialog" :title="errorTitle" class="error__dialog" @close="toggleErrorDialog">
                <p class="error-dialog__msg">{{ errorMsg }}</p>
            </k-dialog>
        </transition>

        <k-notification-group class="default-notification-container">
                <k-fade :appear="successNotification">
                    <k-notification v-if="successNotification"
                                    :type="{ style: 'success', icon: true }"
                                    :closable="true"
                                    @close="onCloseNotification('successNotification')">
                        <span>{{ notificationMsg }}</span>
                    </k-notification>
                </k-fade>
        </k-notification-group>
    </div>
</template>

<script>
    import { Dialog, DialogActionsBar } from '@progress/kendo-vue-dialogs';
    import { Button } from "@progress/kendo-vue-buttons";
    import { DropDownList } from '@progress/kendo-vue-dropdowns';
    import { DatePicker } from '@progress/kendo-vue-dateinputs';
    import { Input } from "@progress/kendo-vue-inputs";
    import { Loader } from "@progress/kendo-vue-indicators";
    import { Notification, NotificationGroup } from "@progress/kendo-vue-notification";
    import { Fade } from "@progress/kendo-vue-animation";
    import { Switch } from "@progress/kendo-vue-inputs";

    import { filterBy } from '@progress/kendo-data-query';

    import { useVuelidate } from '@vuelidate/core';
    import { required, requiredIf } from '@vuelidate/validators';

    import cloneDeep from 'lodash.clonedeep';

    import citizenshipDocHelpers from '../helpers/citizenshipDocHelpers.js';
    import auth from '../authConfig';
    import apiService from '../api/api.service.js';

    export default {
        setup() {
            return { document$: useVuelidate() }
        },
        name: 'upsertCitizenshipDocumentDialog',
        props: ['addMode', 'editedDocument', 'editMode', 'playerId', 'showDialog'],
        emits: ['dialogclosed', 'documentsupdated'],
        data () {
            return {
                allPlayers: [],
                filteredPlayers: [],
                document: {},
                documentType: '',
                errorMsg: '',
                errorTitle: '',
                greencardModel: {
                    player_id: null,
                    countryOfOrigin: null,
                    issuedDate: null,
                    expiringDate: null,
                    receiptNumber: null,
                    familyMemberName: null,
                    familyRecord: false
                },
                isLoading: false,
                notificationMsg: '',
                passportModel: {
                    player_id: null,
                    countryOfOrigin: null,
                    issuedDate: null,
                    expiringDate: null,
                    passportNumber: null,
                    familyMemberName: null,
                    familyRecord: false
                },
                playerMode: true,
                selectedPlayer: { id: null, fullName: 'Select Player' },
                showCreateDocumentType: true,
                showErrorDialog: false,
                successNotification: false,
                visaModel: {
                    player_id: null,
                    countryOfOrigin: null,
                    issuedDate: null,
                    expiringDate: null,
                    visaNumber: null,
                    visaType: null,
                    familyMemberName: null,
                    familyRecord: false
                }
            }
        },
        validations() {
            return {
                selectedPlayer: {
                    id: { required }
                },
                document: {
                    familyMemberName: {
                        required: requiredIf(() => {
                            return !this.playerMode;
                        })
                    }
                }
            }
        },
        computed: {
            documentTypeSelects() {
                return citizenshipDocHelpers.documentTypeSelects;
            },
            greenCardDocumentType() {
                return citizenshipDocHelpers.greenCardDocumentType;
            },
            passportDocumentType() {
                return citizenshipDocHelpers.passportDocumentType;
            },
            visaDocumentType() {
                return citizenshipDocHelpers.visaDocumentType;
            },
            visaTypeSelects() {
                return citizenshipDocHelpers.visaTypeSelects;
            }
        },
        watch: {
            playerMode(val) {
                this.document.familyRecord = !this.playerMode;
            },
            showDialog(val) {
                if (val) {
                    if (this.editMode) {
                        this.showCreateDocumentType = false;
                        this.assignDocument();
                    }

                    this.getPlayers();
                } else {
                    this.resetDefaults();
                }
            }
        },
        methods: {
            assignDocument() {
                this.document = cloneDeep(this.editedDocument);
                this.documentType = this.document.type;
                this.selectedPlayer = { id: this.document.player_id, fullName: this.document.fullName };
                if (this.document.familyRecord) {
                    this.playerMode = false;
                }
            },
            async createDocument(document, documentType, documentTypeKey) {
                let self = this;

                this.isLoading = true;
                this.$emit('dialogclosed');

                let request = {
                    documentType: documentType
                }

                request[documentTypeKey] = document;

                let tokenResponse = await auth.acquireTokenResponse();

                return apiService.CreateCitizenshipDocument(tokenResponse.accessToken, request) 
                    .then(async () => {
                        await this.$store.dispatch('getCitizenshipDocuments', true);

                        self.isLoading = false;
                        self.showNotification('Document created successfully');

                        self.$emit('documentsupdated');
                    }).catch(error => {
                        console.log(error);
                        self.onRequestError('An Error Occurred', error?.response?.data?.message);
                    });
            },
            defaultDropdownSelected(evt, field, defaultValue) {
                if (evt.target.value === defaultValue) {
                    return this.document[field] = null;
                }

                this.document[field] = evt.target.value;
            },
            async getPlayers(update = false) {     
                await this.$store.dispatch('getPlayers', update);

                this.allPlayers = this.$store.state.players.map(player => {
                    return {
                        id: player.id,
                        fullName: player.fullName
                    }
                });

                this.allPlayers.sort((a, b) => a.fullName.localeCompare(b.fullName));

                this.allPlayers.unshift({ id: null, fullName: 'Select Player' });

                this.filteredPlayers = cloneDeep(this.allPlayers);
            },
            onBackToDocumentType() {
                this.showCreateDocumentType = true;

                this.resetDefaults();
            },
            onCancelClicked() {
                this.$emit('dialogclosed');
            },
            onCloseNotification(flag) {
                this[flag] = false;
            },
            onDocumentTypeSelected(evt) {
                if (evt.value === 'Select Document Type') {
                    return this.documentType = '';
                }

                this.documentType = evt.value;

                this.document = cloneDeep(this[`${evt.value.split(' ').join('').toLowerCase()}Model`]);

                if (this.addMode) {
                    this.selectedPlayer = this.allPlayers.find(player => player.id === this.playerId);
                }

                this.showCreateDocumentType = false;
            },
            onPlayerFilterChange(evt) {
                this.filteredPlayers = filterBy(this.allPlayers, evt.filter);
            },
            onRequestError(errorTitle, errorMsg) {
                this.isLoading = false;

                this.errorTitle = errorTitle;

                if (errorMsg) {
                    this.errorMsg = errorMsg;
                } else {
                    this.errorMsg = 'Please try again';
                }

                this.showErrorDialog = true;
            },
            async onSaveClicked() {
                await this.document$.$validate();

                if (this.document$.selectedPlayer.$error || this.document$.document.$error) return;

                this.document.player_id = this.selectedPlayer.id;

                if (!this.editMode) {
                    return this.createDocument(cloneDeep(this.document), this.documentType, this.documentType.split(' ').join('').toLowerCase());
                }

                this.updateDocument(cloneDeep(this.document), this.documentType, this.documentType.split(' ').join('').toLowerCase());
            },
            resetDefaults() {
                this.document$.$reset();
                this.document = {};
                this.documentType = '';
                this.selectedPlayer = { id: null, fullName: 'Select Player' };
                this.showCreateDocumentType = true;
                this.playerMode = true;
            },
            showNotification(notificationMsg) {
                this.notificationMsg = notificationMsg;

                this.successNotification = true;

                setTimeout(() => {
                    this.successNotification = false;
                }, 4000);
            },
            toggleErrorDialog() {
                this.showErrorDialog = !this.showErrorDialog;

                if(!this.showErrorDialog) {
                    this.errorTitle = '';
                    this.errorMsg = '';
                }
            },
            async updateDocument(document, documentType, documentTypeKey) {
                let self = this;

                this.isLoading = true;
                this.$emit('dialogclosed');

                let request = {
                    documentType: documentType
                }

                request[documentTypeKey] = document;

                let tokenResponse = await auth.acquireTokenResponse();

                return apiService.UpdateCitizenshipDocument(tokenResponse.accessToken, request) 
                    .then(async () => {
                        await this.$store.dispatch('getCitizenshipDocuments', true);

                        self.isLoading = false;
                        self.showNotification('Document updated successfully');

                        self.$emit('documentsupdated');
                    }).catch(error => {
                        console.log(error);
                        self.onRequestError('An Error Occurred', error?.response?.data?.message);
                    });
            }
        },
        components: {
            'k-dropdown': DropDownList,
            'k-dialog': Dialog,
            'k-action-bar': DialogActionsBar,
            'k-button': Button,
            'k-datepicker': DatePicker,
            'k-input': Input,
            'k-loader': Loader,
            'k-notification': Notification,
            'k-notification-group': NotificationGroup,
            'k-fade': Fade, 
            'k-switch': Switch
        }
    }
</script>

<style>
    @import '/src/assets/css/defaultUpsertStyles.css';
    @import '/src/assets/css/upsertCitizenshipDocumentDialog.css';
</style>