export default {
    formatDate(stringDate) {
        if (stringDate) {
            let date = new Date(new Date(stringDate).setHours(0,0,0,0)).toString();
            return new Date(date);
        }

        return null;
    },
    formatDateTime(stringDate) {
        if (stringDate) {
            return new Date(stringDate);
        }

        return null;
    }
}

