<template>
    <div class="create-contract">
        <transition name="dialogfade">
            <k-dialog v-if="showDialog" v-bind:class="['default__dialog', 'create-contract-dialog', { 'type-selected': !showCreateContractType }]">
                <div class="icon__container">
                    <i class="fa-solid fa-file-signature"></i>
                </div>
                <div class="header__container">
                    <svg viewBox="0 0 300 35" x="0px" y="0px" xml:space="preserve" fill="none" xmlns="http://www.w3.org/2000/svg" style="enable-background:new 0 0 300 35;"><path fill-rule="evenodd" class="icon__top-bar" clip-rule="evenodd" d="M290,21.74H169.3a19.32,19.32,0,0,1-38.64,0H10c-5.52,0-10,2.53-10,5.66V50H300V27.4C300,24.27,295.52,21.74,290,21.74Z" transform="translate(0 -21.74)" fill="#D4B139"></path></svg>
                </div>
                <div class="create-contract__header">
                    <h1 class="dialog--title">Create Contract<span class="contract--type" v-if="!showCreateContractType"> - {{ contract.contractType }}</span></h1>
                    <k-button class="item-back__btn" @click="onBackToContractType" v-if="!showCreateContractType"><i class="fa-solid fa-left"></i> Back</k-button>
                </div>
                <div class="upsert-item__container create-contract">
                    <div class="select-contract-type upsert-item__section" v-if="showCreateContractType">
                        <div class="input__row">
                            <label class="input__label">
                                Contract Type
                                <k-dropdown :data-items="contractTypeSelects" :default-item="'Select Contract Type'" @change="onContractTypeSelected"></k-dropdown>
                            </labeL>
                        </div>
                    </div>

                    <div v-if="!showCreateContractType" class="upsert-item">
                        <p class="field--required">*Required</p>
                        <div class="upsert-item__section">
                            <div :class="['input__row', { 'pair': contract.contractType === agencyContractType }, { 'trio': contract.contractType !== agencyContractType }]"> 
                                <label class="input__label">
                                    *Contract Name
                                    <k-input v-model="contract.contractTitle" disabled @keydown="contract$.contract.contractTitle.$reset()" :class="[{ 'error': contract$.contract.contractTitle.$error }]" />
                                    <span class="item-input--vuelidate-error" v-if="contract$.contract.contractTitle.$error"><i>Contract name is required</i></span>
                                </label>
                                <label class="input__label">
                                    *Player
                                    <k-dropdown :data-items="filteredPlayers" 
                                        :class="[{ 'error': contract$.selectedPlayer.id.$error }]"
                                        v-model="selectedPlayer" 
                                        :text-field="'fullName'" 
                                        :filterable="true" 
                                        @change="onContractPlayerSelected"
                                        @filterchange="onPlayerFilterChange"></k-dropdown>
                                    <span class="item-input--vuelidate-error" v-if="contract$.selectedPlayer.id.$error"><i>Player is required</i></span>
                                </label>
                                <label class="input__label" v-if="contract.contractType !== agencyContractType">
                                    Associated Agency Contract
                                    <k-dropdown :data-items="selectedPlayerContracts.filter(c => c.contractType === agencyContractType)"
                                        :default-item="{ contractTitle: 'Select Contract', contractId: null }"
                                        :text-field="'contractTitle'"
                                        :data-item-key="'contractId'"
                                        @change="onAssociatedContractSelected"></k-dropdown>
                                    <span class="item-input--vuelidate-error" v-if="!isLoadingContracts && selectedPlayer.id && selectedPlayerContracts.filter(c => c.contractType === agencyContractType).length === 0"><i>No agency contracts found</i></span>
                                </label>
                            </div>
                        </div>

                        <div v-if="contract.contractType === agencyContractType" class="upsert-item__section">
                            <div class="input__row pair">
                                <label class="input__label">
                                    *Signing Date
                                    <k-datepicker v-model="contract.spacSigningDate_formatted" @change="buildAgencyContractName" @focus="contract$.contract.spacSigningDate_formatted.$reset()" :class="[{ 'error': contract$.contract.spacSigningDate_formatted.$error }]"></k-datepicker>
                                    <span class="item-input--vuelidate-error" v-if="contract$.contract.spacSigningDate_formatted.$error"><i>Signing date is required</i></span>
                                </label>
                                <label class="input__label">
                                    Expiration Date
                                    <k-datepicker v-model="contract.expirationDate_formatted"></k-datepicker>
                                </label>
                            </div>
                            <div class="input__row trio">
                                <label class="input__label">
                                    Primary Agent
                                    <k-dropdown :data-items="contractAgentSelects"
                                                :default-item="'Select Agent'"
                                                @change="defaultDropdownSelected($event, 'primaryAgent', 'Select Agent')"></k-dropdown>
                                </label>
                                <label class="input__label">
                                    Secondary Agent
                                    <k-dropdown :data-items="contractAgentSelects"
                                                :default-item="'Select Agent'"
                                                @change="defaultDropdownSelected($event, 'secondaryAgent', 'Select Agent')"></k-dropdown>
                                </label>
                                <label class="input__label">
                                    Third Agent
                                    <k-dropdown :data-items="contractAgentSelects"
                                                :default-item="'Select Agent'"
                                                @change="defaultDropdownSelected($event, 'thirdAgent', 'Select Agent')"></k-dropdown>
                                </label>
                            </div>
                            <h2 class="upsert-item__section--title">Contract Commission Information</h2>
                            <div class="input__row pair">
                                <label class="input__label">
                                    Playing Commission Percent
                                    <k-numeric-input :placeholder="'Enter percent'" v-model="contract.playingCommissionPercent" :format="'##.00 \\%'" :decimals="2" :min="0"></k-numeric-input>
                                </label>
                                <label class="input__label">
                                    Endorsement Commission Percent
                                    <k-numeric-input :placeholder="'Enter percent'" v-model="contract.endorsementCommissionPercent" :format="'##.00 \\%'" :decimals="2" :min="0"></k-numeric-input>
                                </label>
                            </div>
                        </div>

                        <div v-if="contract.contractType === endorsementContractType" class="upsert-item__section">
                            <div class="input__row pair">
                                <label class="input__label">
                                    Endorsement Type
                                    <k-dropdown :data-items="endorsementContractTypeSelects"
                                                :default-item="'Select Type'"
                                                @change="defaultDropdownSelected($event, 'endorsementContractType', 'Select Type')"></k-dropdown>
                                </label>
                                <label class="input__label">
                                    *Company Name
                                    <k-input v-model="contract.endorsementCompanyName" @input="buildEndorsementContractName" @keydown="contract$.contract.endorsementCompanyName.$reset()" :class="[{ 'error': contract$.contract.endorsementCompanyName.$error }]" />
                                    <span class="item-input--vuelidate-error" v-if="contract$.contract.endorsementCompanyName.$error"><i>Company name is required</i></span>
                                </label>
                            </div>
                            <div class="input__row pair">
                                <label class="input__label">
                                    Contract Amount
                                    <k-numeric-input :placeholder="'Enter amount'" v-model="contract.endorsementAmount" :format="'c2'" :min="0"></k-numeric-input>
                                </label>
                                <label class="input__label">
                                    Commission
                                    <k-numeric-input :placeholder="'Enter percent'" v-model="contract.endorsementCommissionPercent" :format="'##.00 \\%'" :decimals="2" :min="0"></k-numeric-input>
                                </label>
                            </div>
                            <div class="input__row pair">
                                <label class="input__label">
                                    *Signing Date
                                    <k-datepicker v-model="contract.endorsementSigningDate_formatted" @change="buildEndorsementContractName" @focus="contract$.contract.endorsementSigningDate_formatted.$reset()" :class="[{ 'error': contract$.contract.endorsementSigningDate_formatted.$error }]"></k-datepicker>
                                    <span class="item-input--vuelidate-error" v-if="contract$.contract.endorsementSigningDate_formatted.$error"><i>Signing date is required</i></span>
                                </label>
                                <label class="input__label">
                                    Expiration Date
                                    <k-datepicker v-model="contract.endorsementExpirationDate_formatted"></k-datepicker>
                                </label>
                            </div>
                            <div class="input__row">
                                <label class="input__label">
                                    Notes
                                    <k-textarea v-model="contract.endorsementNotes" :resizable="'vertical'" :rows="5"></k-textarea>
                                </label>
                            </div>
                            <div class="input__row add-item">
                                <label class="input__label add-item" @click="onAddEndorsementPayout">
                                    <i class="fa-sharp fa-solid fa-plus"></i> Add Payout
                                </label>
                            </div>
                            <div :class="['optional-items__container', { 'active': endorsementPayouts.length > 0 }]">
                                <div class="optional-item" v-for="(payout, index) in endorsementPayouts" :key="payout.payoutId">
                                    <div class="input__row pair">
                                        <k-slide :appear="endorsementPayouts[index] !== null">
                                            <label class="input__label">
                                                *{{ $options.filters.toOrdinal(index) }} Payout Amount
                                                <k-numeric-input :placeholder="'Enter amount'" v-model="payout.payoutAmount" :format="'c2'" :min="0" @change="payout.hasPayoutAmountError = false" :class="[{ 'error': payout.hasPayoutAmountError }]"></k-numeric-input>
                                            </label>
                                            <label class="input__label">
                                                *{{ $options.filters.toOrdinal(index) }} Payout Date
                                                <k-datepicker v-model="payout.payoutDate" @focus="payout.hasPayoutDateError = false" :class="[{ 'error': payout.hasPayoutDateError }]"></k-datepicker>
                                            </label>
                                        </k-slide>
                                    </div>
                                    <div class="item-delete__container" @click="onDeleteEndorsementPayout(payout)" title="Delete Payout"><i class="fa-solid fa-trash-can"></i></div>
                                </div>
                            </div>
                            <div class="input__row add-item">
                                <label class="input__label add-item" @click="onAddEndorsementProductCredit">
                                    <i class="fa-sharp fa-solid fa-plus"></i> Add Product Credit
                                </label>
                            </div>
                            <div :class="['optional-items__container', { 'active': endorsementProductCredits.length > 0 }]">
                                <div class="optional-item" v-for="(credit, index) in endorsementProductCredits" :key="credit.productCreditId">
                                    <div class="input__row trio">
                                        <k-slide :appear="endorsementProductCredits[index] !== null">
                                            <label class="input__label">
                                                *{{ $options.filters.toOrdinal(index) }} Credit Amount
                                                <k-numeric-input :placeholder="'Enter amount'" v-model="credit.creditAmount" :format="'c2'" :min="0" @change="credit.hasCreditAmountError = false" :class="[{ 'error': credit.hasCreditAmountError }]"></k-numeric-input>
                                            </label>
                                            <label class="input__label">
                                                *{{ $options.filters.toOrdinal(index) }} Start Date
                                                <k-datepicker v-model="credit.startDate" @focus="credit.hasStartDateError = false" :class="[{ 'error': credit.hasStartDateError }]"></k-datepicker>
                                            </label>
                                            <label class="input__label">
                                                *{{ $options.filters.toOrdinal(index) }} End Date
                                                <k-datepicker v-model="credit.endDate" @focus="credit.hasEndDateError = false" :class="[{ 'error': credit.hasEndDateError }]"></k-datepicker>
                                            </label>
                                        </k-slide>
                                    </div>
                                    <div class="item-delete__container" @click="onDeleteEndorsementProductCredit(credit)" title="Delete Credit"><i class="fa-solid fa-trash-can"></i></div>
                                </div>
                            </div>
                        </div>

                        <div v-if="contract.contractType === equipmentContractType" class="upsert-item__section">
                            <div class="input__row">
                                <label class="input__label">
                                    *Equipment Contract
                                    <k-dropdown :data-items="equipmentContractSelects" 
                                                :class="[{ 'error': contract$.contract.equipmentContract.$error }]"
                                                :default-item="'Select Equipment Contract'" 
                                                @change="onEquipmentContractSelected($event, 'equipmentContract', 'Select Equipment Contract')"></k-dropdown>
                                    <span class="item-input--vuelidate-error" v-if="contract$.contract.equipmentContract.$error"><i>Equipment contract is required</i></span>
                                </label>
                            </div>
                            <div class="input__row pair">
                                <label class="input__label">
                                    Contract Amount
                                    <k-numeric-input :placeholder="'Enter amount'" v-model="contract.equipmentAmount" :format="'c2'" :min="0"></k-numeric-input>
                                </label>
                                <label class="input__label">
                                    Commission
                                    <k-numeric-input :placeholder="'Enter percent'" v-model="contract.equipmentCommissionRate" :format="'##.00 \\%'" :decimals="2" :min="0"></k-numeric-input>
                                </label>
                            </div>
                            <div class="input__row pair">
                                <label class="input__label">
                                    *Signing Date
                                    <k-datepicker v-model="contract.equipmentSigningDate_formatted" @change="buildEquipmentContractName" @focus="contract$.contract.equipmentSigningDate_formatted.$reset()" :class="[{ 'error': contract$.contract.equipmentSigningDate_formatted.$error }]"></k-datepicker>
                                    <span class="item-input--vuelidate-error" v-if="contract$.contract.equipmentSigningDate_formatted.$error"><i>Signing date is required</i></span>
                                </label>
                                <label class="input__label">
                                    Expiration Date
                                    <k-datepicker v-model="contract.equipmentExpirationDate_formatted"></k-datepicker>
                                </label>
                            </div>
                            <div class="input__row">
                                <label class="input__label">
                                    Notes
                                    <k-textarea v-model="contract.equipmentNotes" :resizable="'vertical'" :rows="5"></k-textarea>
                                </label>
                            </div>
                            <div class="input__row add-item">
                                <label class="input__label add-item" @click="onAddEndorsementPayout">
                                    <i class="fa-sharp fa-solid fa-plus"></i> Add Payout
                                </label>
                            </div>
                            <div :class="['optional-items__container', { 'active': endorsementPayouts.length > 0 }]">
                                <div class="optional-item" v-for="(payout, index) in endorsementPayouts" :key="payout.payoutId">
                                    <div class="input__row pair">
                                        <k-slide :appear="endorsementPayouts[index] !== null">
                                            <label class="input__label">
                                                *{{ $options.filters.toOrdinal(index) }} Payout Amount
                                                <k-numeric-input :placeholder="'Enter amount'" v-model="payout.payoutAmount" :format="'c2'" :min="0" @change="payout.hasPayoutAmountError = false" :class="[{ 'error': payout.hasPayoutAmountError }]"></k-numeric-input>
                                            </label>
                                            <label class="input__label">
                                                *{{ $options.filters.toOrdinal(index) }} Payout Date
                                                <k-datepicker v-model="payout.payoutDate" @focus="payout.hasPayoutDateError = false" :class="[{ 'error': payout.hasPayoutDateError }]"></k-datepicker>
                                            </label>
                                        </k-slide>
                                    </div>
                                    <div class="item-delete__container" @click="onDeleteEndorsementPayout(payout)" title="Delete Payout"><i class="fa-solid fa-trash-can"></i></div>
                                </div>
                            </div>
                            <div class="input__row add-item">
                                <label class="input__label add-item" @click="onAddEndorsementProductCredit">
                                    <i class="fa-sharp fa-solid fa-plus"></i> Add Product Credit
                                </label>
                            </div>
                            <div :class="['optional-items__container', { 'active': endorsementProductCredits.length > 0 }]">
                                <div class="optional-item" v-for="(credit, index) in endorsementProductCredits" :key="credit.productCreditId">
                                    <div class="input__row trio">
                                        <k-slide :appear="endorsementProductCredits[index] !== null">
                                            <label class="input__label">
                                                *{{ $options.filters.toOrdinal(index) }} Credit Amount
                                                <k-numeric-input :placeholder="'Enter amount'" v-model="credit.creditAmount" :format="'c2'" :min="0" @change="credit.hasCreditAmountError = false" :class="[{ 'error': credit.hasCreditAmountError }]"></k-numeric-input>
                                            </label>
                                            <label class="input__label">
                                                *{{ $options.filters.toOrdinal(index) }} Start Date
                                                <k-datepicker v-model="credit.startDate" @focus="credit.hasStartDateError = false" :class="[{ 'error': credit.hasStartDateError }]"></k-datepicker>
                                            </label>
                                            <label class="input__label">
                                                *{{ $options.filters.toOrdinal(index) }} End Date
                                                <k-datepicker v-model="credit.endDate" @focus="credit.hasEndDateError = false" :class="[{ 'error': credit.hasEndDateError }]"></k-datepicker>
                                            </label>
                                        </k-slide>
                                    </div>
                                    <div class="item-delete__container" @click="onDeleteEndorsementProductCredit(credit)" title="Delete Credit"><i class="fa-solid fa-trash-can"></i></div>
                                </div>
                            </div>
                        </div>

                        <div v-if="contract.contractType === playingContractType" class="upsert-item__section">
                            <div v-bind:class="['input__row', { 'add-item': nhlSigningBonuses.length > 0}]">
                                <label class="input__label add-item">
                                    <span @click="onAddNhlSigningBonus"><i class="fa-sharp fa-solid fa-plus"></i> Add Signing Bonus</span>
                                </label>
                            </div>
                            <div :class="['optional-items__container', { 'active': nhlSigningBonuses.length > 0 }]">
                                <div class="optional-item" v-for="(bonus, index) in nhlSigningBonuses" :key="bonus.bonusId">
                                    <div class="input__row pair">
                                        <k-slide :appear="nhlSigningBonuses[index] !== null">
                                            <label class="input__label">
                                                *{{ $options.filters.toOrdinal(index) }} Bonus Date
                                                <k-datepicker v-model="bonus.bonusDate" @focus="bonus.hasBonusDateError = false" :class="[{ 'error': bonus.hasBonusDateError }]"></k-datepicker>
                                            </label>
                                            <label class="input__label">
                                                *{{ $options.filters.toOrdinal(index) }} Bonus Amount
                                                <k-numeric-input :placeholder="'Enter amount'" v-model="bonus.bonusAmount" :format="'c2'" :min="0" @change="bonus.hasBonusAmountError = false" :class="[{ 'error': bonus.hasBonusAmountError }]"></k-numeric-input>
                                            </label>
                                        </k-slide>
                                    </div>
                                    <div class="item-delete__container" @click="onDeleteNhlSigningBonus(bonus)" title="Delete Bonus"><i class="fa-solid fa-trash-can"></i></div>
                                </div>
                            </div>
                            <div class="input__row trio">
                                <label class="input__label">
                                    NHL Signing Club
                                    <k-input v-model="contract.nhlSigningClub" />
                                </label>
                                <label class="input__label">
                                    *Starting Date
                                    <k-datepicker v-model="contract.nhlContractStartYear_formatted" @change="buildPlayingContractName" @focus="contract$.contract.nhlContractStartYear_formatted.$reset()" :class="[{ 'error': contract$.contract.nhlContractStartYear_formatted.$error }]"></k-datepicker>
                                    <span class="item-input--vuelidate-error" v-if="contract$.contract.nhlContractStartYear_formatted.$error"><i>Start date is required</i></span>
                                </label>
                                <label class="input__label">
                                    *Ending Date
                                    <k-datepicker v-model="contract.nhlContractEndYear_formatted" @change="buildPlayingContractName" @focus="contract$.contract.nhlContractStartYear_formatted.$reset()" :class="[{ 'error': contract$.contract.nhlContractEndYear_formatted.$error }]"></k-datepicker>
                                    <span class="item-input--vuelidate-error" v-if="contract$.contract.nhlContractEndYear_formatted.$error"><i>End date is required</i></span>
                                </label>
                            </div>
                            <div class="input__row add-item">
                                <label class="input__label add-item">
                                    <span @click="onAddPerformanceBonus">
                                        <i class="fa-sharp fa-solid fa-plus"></i> Add NHL Performance Bonus
                                    </span>
                                </label>
                            </div>
                            <div :class="['optional-items__container', { 'active': performanceBonuses.length > 0 }]">
                                <div class="optional-item" v-for="(bonus, index) in performanceBonuses" :key="bonus.bonusId">
                                    <k-slide :appear="performanceBonuses[index] !== null">
                                        <div class="bonus-rows__container">
                                            <div class="input__row pair">
                                                <label class="input__label">
                                                    *{{ $options.filters.toOrdinal(index) }} Bonus Title
                                                    <k-input v-model="bonus.bonusTitle" @keydown="bonus.hasBonusTitleError = false" :class="[{ 'error': bonus.hasBonusTitleError }]" />
                                                </label>
                                                <label class="input__label">
                                                    *{{ $options.filters.toOrdinal(index) }} Bonus Amount
                                                    <k-numeric-input :placeholder="'Enter amount'" v-model="bonus.bonusAmount" :format="'c2'" :min="0" @change="bonus.hasBonusAmountError = false" :class="[{ 'error': bonus.hasBonusAmountError }]"></k-numeric-input>
                                                </label>
                                            </div>
                                            <div class="input__row">
                                                <label class="input__label">
                                                    {{ $options.filters.toOrdinal(index) }} Bonus Notes
                                                    <k-input v-model="bonus.notes" />
                                                </label>
                                            </div>
                                        </div>
                                        <div class="item-delete__container" @click="onDeletePerformanceBonus(bonus)" title="Delete Bonus"><i class="fa-solid fa-trash-can"></i></div>
                                    </k-slide>
                                </div>
                            </div>
                            <h2 class="upsert-item__section--title">NHL Information</h2>
                            <div class="input__row pair">
                                <label class="input__label">
                                    *NHL Contract Type
                                    <k-dropdown :data-items="contractNhlTypeSelects"
                                                :class="[{ 'error': contract$.contract.nhlContractType.$error }]"
                                                :default-item="'Select Contract Type'"
                                                :disabled="!contract.nhlContractStartYear_formatted || !contract.nhlContractEndYear_formatted"
                                                @change="onNhlContractTypeSelected($event, 'nhlContractType', 'Select Contract Type')"></k-dropdown>
                                    <span class="item-input--vuelidate-error" v-if="contract$.contract.nhlContractType.$error"><i>Contract type is required</i></span>
                                </label>
                                <label class="input__label">
                                    Expiration Free Agent Status
                                    <k-dropdown :data-items="contractNhlExpirationStatusSelects"
                                                :default-item="'Select Status'"
                                                @change="defaultDropdownSelected($event, 'nhlContractExpirationStatus', 'Select Status')"></k-dropdown>
                                </label>
                            </div>
                            <k-slide :appear="contract.nhlContractType === elcContractType">
                                <div class="input__row pair" v-for="detail in elcContractDetails" :key="detail.year">
                                    <label class="input__label">
                                        AHL Salary ({{ `${detail.startYear} / ${detail.startYear + 1}`}})
                                        <k-numeric-input :placeholder="'Enter amount'" v-model="detail.ahlSalary" :format="'c2'" :min="0"></k-numeric-input>
                                    </label>
                                    <label class="input__label">
                                        NHL Salary ({{ `${detail.startYear} / ${detail.startYear + 1}`}})
                                        <k-numeric-input :placeholder="'Enter amount'" v-model="detail.nhlSalary" :format="'c2'" :min="0"></k-numeric-input>
                                    </label>
                                </div>
                            </k-slide>
                            <k-slide :appear="contract.nhlContractType === nhlOneWayContractType">
                                <div class="input__row trio" v-for="detail in nhlOneWayContractDetails" :key="detail.year">
                                    <label class="input__label">
                                        NHL One Way Amount ({{ `${detail.startYear} / ${detail.startYear + 1}`}})
                                        <k-numeric-input :placeholder="'Enter amount'" v-model="detail.nhlOneWayAmount" :format="'c2'" :min="0"></k-numeric-input>
                                    </label>
                                    <label class="input__label">
                                        Total Payable Days ({{ `${detail.startYear} / ${detail.startYear + 1}`}})
                                        <k-numeric-input :placeholder="'Enter amount'" v-model="detail.nhlTotalPayableDays" :min="0"></k-numeric-input>
                                    </label>
                                    <label class="input__label">
                                        NHL One Way Daily Salary ({{ `${detail.startYear} / ${detail.startYear + 1}`}})
                                        <k-numeric-input :placeholder="'Enter amount'" v-model="detail.nhlOneWayDailySalary" :format="'c2'" :min="0"></k-numeric-input>
                                    </label>
                                </div>
                            </k-slide>
                            <k-slide :appear="contract.nhlContractType === nhlTwoWayContractType">
                                <div class="input__row pair" v-for="detail in nhlTwoWayContractDetails" :key="detail.year">
                                    <label class="input__label">
                                        NHL Two Way Amount ({{ `${detail.startYear} / ${detail.startYear + 1}`}})
                                        <k-numeric-input :placeholder="'Enter amount'" v-model="detail.nhlTwoWayAmount" :format="'c2'" :min="0"></k-numeric-input>
                                    </label>
                                    <label class="input__label">
                                        NHL Two Way Daily Salary ({{ `${detail.startYear} / ${detail.startYear + 1}`}})
                                        <k-numeric-input :placeholder="'Enter amount'" v-model="detail.nhlTwoWayDailySalary" :format="'c2'" :min="0"></k-numeric-input>
                                    </label>
                                </div>
                            </k-slide>
                            <k-slide :appear="contract.nhlContractType === ahlOneWayContractType">
                                <div class="input__row trio" v-for="detail in ahlOneWayContractDetails" :key="detail.year">
                                    <label class="input__label">
                                        AHL One Way Amount ({{ `${detail.startYear} / ${detail.startYear + 1}`}})
                                        <k-numeric-input :placeholder="'Enter amount'" v-model="detail.ahlOneWayAmount" :format="'c2'" :min="0"></k-numeric-input>
                                    </label>
                                    <label class="input__label">
                                        Total Payable Days ({{ `${detail.startYear} / ${detail.startYear + 1}`}})
                                        <k-numeric-input :placeholder="'Enter days'" v-model="detail.ahlTotalPayableDays" :min="0"></k-numeric-input>
                                    </label>
                                    <label class="input__label">
                                        AHL One Way Daily Salary ({{ `${detail.startYear} / ${detail.startYear + 1}`}})
                                        <k-numeric-input :placeholder="'Enter amount'" v-model="detail.ahlOneWayDailySalary" :format="'c2'" :min="0"></k-numeric-input>
                                    </label>
                                </div>
                            </k-slide>
                            <k-slide :appear="contract.nhlContractType === ahlTwoWayContractType">
                                <div class="two-way__year-container" v-for="detail in ahlTwoWayContractDetails" :key="detail.year">
                                    <div class="input__row trio">
                                        <label class="input__label">
                                            ECHL Two Way Amount ({{ `${detail.startYear} / ${detail.startYear + 1}`}})
                                            <k-numeric-input :placeholder="'Enter amount'" v-model="detail.echlTwoWayAmount" :format="'c2'" :min="0"></k-numeric-input>
                                        </label>
                                        <label class="input__label">
                                            Total Payable Days ({{ `${detail.startYear} / ${detail.startYear + 1}`}})
                                            <k-numeric-input :placeholder="'Enter days'" v-model="detail.echlTotalPayableDays" :min="0"></k-numeric-input>
                                        </label>
                                        <label class="input__label">
                                            ECHL Daily Salary ({{ `${detail.startYear} / ${detail.startYear + 1}`}})
                                            <k-numeric-input :placeholder="'Enter amount'" v-model="detail.echlDailySalary" :format="'c2'" :min="0"></k-numeric-input>
                                        </label>
                                    </div>
                                    <div class="input__row trio">
                                        <label class="input__label">
                                            AHL Two Way Amount ({{ `${detail.startYear} / ${detail.startYear + 1}`}})
                                            <k-numeric-input :placeholder="'Enter amount'" v-model="detail.ahlTwoWayAmount" :format="'c2'" :min="0"></k-numeric-input>
                                        </label>
                                        <label class="input__label">
                                            Total Payable Days ({{ `${detail.startYear} / ${detail.startYear + 1}`}})
                                            <k-numeric-input :placeholder="'Enter days'" v-model="detail.ahlTotalPayableDays" :min="0"></k-numeric-input>
                                        </label>
                                        <label class="input__label">
                                            AHL Daily Salary ({{ `${detail.startYear} / ${detail.startYear + 1}`}})
                                            <k-numeric-input :placeholder="'Enter amount'" v-model="detail.ahlDailySalary" :format="'c2'" :min="0"></k-numeric-input>
                                        </label>
                                    </div>
                                </div>
                            </k-slide>
                            <k-slide :appear="contract.nhlContractType === ahlNhlTwoWayContractType">
                                <div class="two-way__year-container" v-for="detail in ahlNhlTwoWayContractDetails" :key="detail.year">
                                    <div class="input__row trio">
                                        <label class="input__label">
                                            AHL Two Way Amount ({{ `${detail.startYear} / ${detail.startYear + 1}`}})
                                            <k-numeric-input :placeholder="'Enter amount'" v-model="detail.ahlTwoWayAmount" :format="'c2'" :min="0"></k-numeric-input>
                                        </label>
                                        <label class="input__label">
                                            Total Payable Days ({{ `${detail.startYear} / ${detail.startYear + 1}`}})
                                            <k-numeric-input :placeholder="'Enter days'" v-model="detail.ahlTotalPayableDays" :min="0"></k-numeric-input>
                                        </label>
                                        <label class="input__label">
                                            AHL Daily Salary ({{ `${detail.startYear} / ${detail.startYear + 1}`}})
                                            <k-numeric-input :placeholder="'Enter amount'" v-model="detail.ahlDailySalary" :format="'c2'" :min="0"></k-numeric-input>
                                        </label>
                                    </div>
                                    <div class="input__row trio">
                                        <label class="input__label">
                                            NHL Two Way Amount ({{ `${detail.startYear} / ${detail.startYear + 1}`}})
                                            <k-numeric-input :placeholder="'Enter amount'" v-model="detail.nhlTwoWayAmount" :format="'c2'" :min="0"></k-numeric-input>
                                        </label>
                                        <label class="input__label">
                                            Total Payable Days ({{ `${detail.startYear} / ${detail.startYear + 1}`}})
                                            <k-numeric-input :placeholder="'Enter days'" v-model="detail.nhlTotalPayableDays" :min="0"></k-numeric-input>
                                        </label>
                                        <label class="input__label">
                                            NHL Daily Salary ({{ `${detail.startYear} / ${detail.startYear + 1}`}})
                                            <k-numeric-input :placeholder="'Enter amount'" v-model="detail.nhlDailySalary" :format="'c2'" :min="0"></k-numeric-input>
                                        </label>
                                    </div>
                                </div>
                            </k-slide>
                            <k-slide :appear="contract.nhlContractType === echlContractType">
                                <div class="input__row pair echl-contract">
                                    <label class="input__label" v-for="detail in echlContractDetails" :key="detail.year">
                                        ECHL Amount ({{ `${detail.startYear} / ${detail.startYear + 1}`}})
                                        <k-numeric-input :placeholder="'Enter amount'" v-model="detail.echlAmount" :format="'c2'" :min="0"></k-numeric-input>
                                    </label>
                                </div>
                            </k-slide>
                            <h2 class="upsert-item__section--title" v-if="contract.nhlContractType === elcContractType">ELC Performance Bonuses</h2>
                            <div class="input__row add-item" v-if="contract.nhlContractType === elcContractType">
                                <label class="input__label add-item">
                                    <span @click="onAddElcPerformanceBonus">
                                        <i class="fa-sharp fa-solid fa-plus"></i> Add ELC Performance Bonus
                                    </span>
                                </label>
                            </div>
                            <div :class="['optional-items__container', { 'active': elcPerformanceBonuses.length > 0 }]">
                                <div class="optional-item" v-for="(bonus, index) in elcPerformanceBonuses" :key="bonus.bonusId">
                                    <k-slide :appear="elcPerformanceBonuses[index] !== null">
                                        <div class="bonus-rows__container">
                                            <div class="input__row pair">
                                                    <label class="input__label">
                                                        *{{ $options.filters.toOrdinal(index) }} Bonus Amount
                                                        <k-numeric-input :placeholder="'Enter amount'" v-model="bonus.bonusAmount" :format="'c2'" :min="0" @focus="bonus.hasBonusAmountError = false" :class="[{ 'error': bonus.hasBonusAmountError }]"></k-numeric-input>
                                                    </label>
                                                    <label class="input__label">
                                                        *{{ $options.filters.toOrdinal(index) }} Schedule Type
                                                        <k-dropdown :data-items="elcBonusScheduleTypeSelects"
                                                                    :default-item="'Select Type'"
                                                                    :class="[{ 'error': bonus.hasScheduleTypeError }]"
                                                                    @change="elcBonusScheduleSelected($event, 'Select Type', index)"></k-dropdown>
                                                    </label>
                                            </div>
                                            <div class="input__row">
                                                <label class="input__label">
                                                    {{ $options.filters.toOrdinal(index) }} Bonus Notes
                                                    <k-input v-model="bonus.notes" />
                                                </label>
                                            </div>
                                        </div>
                                        <div class="item-delete__container" @click="onDeleteElcPerformanceBonus(bonus)" title="Delete Bonus"><i class="fa-solid fa-trash-can"></i></div>
                                    </k-slide>
                                </div>
                            </div>
                            <h2 class="upsert-item__section--title" v-if="contract.nhlContractType === nhlOneWayContractType || contract.nhlContractType === ahlNhlTwoWayContractType">No-Trade Clause Information</h2>
                            <div class="input__row trio" v-if="contract.nhlContractType === nhlOneWayContractType || contract.nhlContractType === ahlNhlTwoWayContractType">
                                <k-checkbox class="input__label" v-model="contract.noTradeClause" :label="'No Trade Clause'"></k-checkbox>
                                <k-checkbox class="input__label" v-model="contract.noMoveClause" :label="'No Move Clause'"></k-checkbox>
                                <k-checkbox class="input__label" v-model="contract.limitedModifiedNoTradeClause" :label="'Limited/Modified No Trade Clause'"></k-checkbox>
                            </div>
                            <k-slide :appear="contract.limitedModifiedNoTradeClause">
                                <div v-if="contract.limitedModifiedNoTradeClause" class="input__row pair">
                                    <label class="input__label">
                                        <span v-if="contract.limitedModifiedOption === 'Specified Date'">*</span>Start Date
                                        <k-datepicker v-model="contract.limitedModifiedStartDate" class="trade-clause__date-picker" @focus="contract$.contract.limitedModifiedStartDate.$reset()" :class="[{ 'error': contract$.contract.limitedModifiedStartDate.$error }]"></k-datepicker>
                                        <span class="item-input--vuelidate-error" v-if="contract$.contract.limitedModifiedStartDate.$error"><i>Start date is required</i></span>
                                    </label>
                                    <label class="input__label">
                                        End Date
                                        <k-datepicker v-model="contract.limitedModifiedEndDate" class="trade-clause__date-picker" @focus="contract$.contract.limitedModifiedEndDate.$reset()" :class="[{ 'error': contract$.contract.limitedModifiedEndDate.$error }]"></k-datepicker>
                                        <span class="item-input--vuelidate-error" v-if="contract$.contract.limitedModifiedEndDate.$error"><i>End date is required</i></span>
                                    </label>
                                </div>
                                <div v-if="contract.limitedModifiedNoTradeClause" :class="['input__row', 'pair', { 'trio': contract.limitedModifiedOption === 'Specified Date' }]">
                                    <label class="input__label">
                                        Type
                                        <k-dropdown :data-items="limitedModifiedNoTradeClauseTypes"
                                                    :default-item="'Select Type'"
                                                    v-model="contract.limitedModifiedOption"></k-dropdown>
                                    </label>
                                    <label class="input__label">
                                        Number of Teams
                                        <k-numeric-input :placeholder="'Enter amount'" v-model="contract.limitedModifiedNumberTeams" :min="0"></k-numeric-input>
                                    </label>
                                    <label class="input__label" v-if="contract.limitedModifiedOption === 'Specified Date'">
                                        <span v-if="contract.limitedModifiedOption === 'Specified Date'">*</span>Due 
                                        <k-datepicker v-model="contract.limitedModifiedDate" :format="'MM / dd'" class="trade-clause__date-picker" @focus="contract$.contract.limitedModifiedDate.$reset()" :class="[{ 'error': contract$.contract.limitedModifiedDate.$error }]"></k-datepicker>
                                        <span class="item-input--vuelidate-error" v-if="contract$.contract.limitedModifiedDate.$error"><i>Due date is required</i></span>
                                    </label>
                                </div>
                            </k-slide>
                        </div>
                    </div>
                    <k-action-bar>
                        <k-button v-if="!showCreateContractType" class="item-save__btn" @click="onSaveClicked">Save</k-button>
                        <k-button class="item-cancel__btn" @click="onCloseDialog">Cancel</k-button>
                    </k-action-bar>
                </div>
                <div class="loader-container" v-if="isLoadingContracts">
                    <k-loader :size="'large'" :type="'infinite-spinner'"></k-loader>
                </div>
            </k-dialog>
        </transition>

        <div class="loader-container" v-if="isLoading">
            <k-loader :size="'large'" :type="'infinite-spinner'"></k-loader>
        </div>

        <transition name="dialogfade">
            <k-dialog v-if="showErrorDialog" :title="errorTitle" class="error__dialog" @close="toggleErrorDialog">
                <p class="error-dialog__msg">{{ errorMsg }}</p>
            </k-dialog>
        </transition>

        <k-notification-group class="default-notification-container">
                <k-fade :appear="successNotification">
                    <k-notification v-if="successNotification"
                                    :type="{ style: 'success', icon: true }"
                                    :closable="true"
                                    @close="onCloseNotification('successNotification')">
                        <span>{{ notificationMsg }}</span>
                    </k-notification>
                </k-fade>
        </k-notification-group>
    </div>
</template>

<script>
    import { Dialog, DialogActionsBar } from '@progress/kendo-vue-dialogs';
    import { DropDownList } from '@progress/kendo-vue-dropdowns';
    import { Input, NumericTextBox, TextArea, Checkbox } from "@progress/kendo-vue-inputs";
    import { DatePicker } from '@progress/kendo-vue-dateinputs';
    import { Button } from "@progress/kendo-vue-buttons";
    import { Slide, Fade } from '@progress/kendo-vue-animation';
    import { Loader } from "@progress/kendo-vue-indicators";
    import { Notification, NotificationGroup } from "@progress/kendo-vue-notification";

    import { filterBy } from '@progress/kendo-data-query';

    import { useVuelidate } from '@vuelidate/core';
    import { required, email, requiredIf } from '@vuelidate/validators';

    import cloneDeep from 'lodash.clonedeep';
    import date from 'date-and-time';

    import contractHelpers from '../helpers/contractHelpers.js';
    import auth from '../authConfig';
    import apiService from '../api/api.service.js';

    export default {
        setup() {
            return { contract$: useVuelidate() }
        },
        name: 'createContractDialog',
        props: ['showDialog'],
        emits: ['contractcreated', 'dialogclosed'],
        data() { 
            return {
                ahlNhlTwoWayContractDetails: [],
                ahlNhlTwoWayContractModel: { year: null, ahlTwoWayAmount: null, nhlTwoWayAmount: null, ahlGaurantee: null, nhlGuarantee: null, ahlTotalPayableDays: null, nhlTotalPayableDays: null, 
                                            ahlDailySalary: null, nhlDailySalary: null, playerId: null, contractId: null, startYear: null },
                ahlOneWayContractDetails: [],
                ahlTwoWayContractDetails: [],
                ahlOneWayContractModel: { year: null, ahlOneWayAmount: null, ahlTotalPayableDays: null, ahlOneWayDailySalary: null, ahlGuarantee: null, playerId: null, contractId: null, startYear: null },
                ahlTwoWayContractModel: { year: null, ahlTwoWayAmount: null, ahlTotalPayableDays: null, ahlGuarantee: null, echlTwoWayAmount: null, echlTotalPayableDays: null, ahlDailySalary: null, 
                                        echlDailySalary: null, playerId: null, contractId: null, startYear: null },
                allPlayers: [],
                contract: {},
                contractDateFields: {
                    'Agency Contract': ['spacSigningDate', 'expirationDate'],
                    'Endorsement Contract': ['endorsementExpirationDate', 'endorsementSigningDate'],
                    'Equipment Contract': ['equipmentExpirationDate', 'equipmentSigningDate'],
                    'Playing Contract': ['nhlContractStartYear', 'nhlContractEndYear']
                },
                contractModel: {
                    slidesLeft: null,
                    contractType: '',
                    contractTitle: '',
                    playingCommissionPercent: null,
                    endorsementCommissionPercent: null,
                    primaryAgent: null,
                    secondaryAgent: null,
                    thirdAgent: null,
                    uploadUrl: null,
                    spacSigningDate_formatted: null,
                    expirationDate_formatted: null,
                    teamName: null,
                    nhlSigningClub: null,
                    nhlContractStartYear_formatted: null,
                    nhlContractEndYear_formatted: null,
                    nhlContractType: null,
                    nhlContractExpirationStatus: null,
                    nhlOneWayAmount: null,
                    nhlTotalPayableDays: null,
                    nhlOneWayDailySalary: null,
                    ahlTwoWayDailySalary: null,
                    nhl_Escrow_Amount: null,
                    nhlTwoWayAmount: null,
                    ahlTotalPayableDays: null,
                    nhlTwoWayDailySalary: null,
                    ahlOneWayAmount: null,
                    ahlTwoWayAmount: null,
                    ahlGaurantee: null,
                    echlAmount: null,
                    nhlExpirationDate_formatted: null,
                    endorsementCompanyName: null,
                    endorsementContractType: null,
                    endorsementCommissionRate: null,
                    endorsementAmount: null,
                    endorsementNotes: null,
                    endorsementSigningDate_formatted: null,
                    endorsementExpirationDate_formatted: null,
                    billingContactName: null,
                    billingAddress: null,
                    billingContactEmail: null,
                    performanceBonus: null,
                    contractId: null,
                    equipmentContract: null,
                    equipmentSigningDate_formatted: null,
                    equipmentExpirationDate_formatted: null,
                    equipmentAmount: null,
                    equipmentCommissionRate: null,
                    equipmentNotes: null,
                    spcCommissionRate: null,
                    spcBillingAmount: null,
                    spcBillingType: null,
                    contractYears: 0,
                    noTradeClause: false,
                    noMoveClause: false,
                    limitedModifiedNoTradeClause: false,
                    limitedModifiedOption: null,
                    limitedModifiedDate: null,
                    associatedContractId: null,
                    limitedModifiedStartDate: null,
                    limitedModifiedEndDate: null,
                    limitedModifiedNumberTeams: null
                },
                contractTypeSelected: false,
                contractTypeSelects: ['Agency Contract', 'Endorsement Contract', 'Equipment Contract', 'Playing Contract'],
                echlContractDetails: [],
                echlContractDetailModel: { year: null, echlAmount: null, playerId: null, contractId: null, startYear: null },
                elcContractDetails: [],
                elcContractDetailModel: { year: null, ahlSalary: null, nhlSalary: null, playerId: null, contractId: null, startYear: null },
                elcPerformanceBonuses: [],
                elcPerformanceBonusModel: { bonusAmount: null, scheduleType: null, contractId: '', notes: null, bonusId: '', hasBonusAmountError: false, hasScheduleTypeError: false, hasBonusDateError: false },
                endorsementPayouts: [],
                endorsementPayoutModel: { payoutAmount: null, payoutDate: null, contractId: '', payoutId: '', hasPayoutAmountError: false, hasPayoutDateError: false },
                endorsementProductCredits: [],
                endorsementProductCreditModel: { creditAmount: null, startDate: null, endDate: null, contractId: '', productCreditId: '', hasCreditAmountError: false, hasStartDateError: false, hasEndDateError: false },
                errorMsg: '',
                errorTitle: '',
                filteredPlayers: [],
                isLoading: false,
                isLoadingContracts: false,
                nhlOneWayContractDetails: [],
                nhlOneWayContractModel: { year: null, nhlOneWayAmount: null, nhlOneWayDailySalary: null, nhlTotalPayableDays: null, playerId: null, contractId: null, startYear: null },
                nhlSigningBonuses: [],
                nhlSigningBonusModel: { bonusDate: null, bonusAmount: null, contractId: '', bonusId: '', hasBonusDateError: false, hasBonusAmountError: false, playerId: null },
                nhlTwoWayContractDetails: [],
                nhlTwoWayContractModel: { year: null, nhlTwoWayAmount: null, nhlTwoWayDailySalary: null, playerId: null, contractId: null, startYear: null },
                notificationMsg: '',
                noTradeClauses: [],
                noTradeClauseModel: { dueDate: null, playerId: null, contractId: null, notes: null, status: null, reportToTeam: null, numberTeams: null },
                performanceBonuses: [],
                performanceBonusModel: { bonusAmount: null, bonusTitle: null, notes: null, bonusId: '', contractId: '', hasBonusAmountError: false, hasBonusTitleError: false },
                selectedPlayer: { id: null, fullName: 'Select Player' },
                selectedPlayerContracts: [],
                showCreateContractType: false,
                showErrorDialog: false,
                successNotification: false
            }
        },
        validations() {
            return {
                contract: {
                    contractTitle: { required },
                    contractType: { required },
                    billingContactEmail: { email },
                    nhlContractStartYear_formatted: { 
                        required: requiredIf(() => {
                            return this.contract.contractType === this.playingContractType;
                        })
                    },
                    nhlContractEndYear_formatted: { 
                        required: requiredIf(() => {
                            return this.contract.contractType === this.playingContractType;
                        })
                    },
                    nhlContractType: {
                        required: requiredIf(() => {
                            return this.contract.contractType === this.playingContractType;
                        })
                    },
                    endorsementSigningDate_formatted: {
                        required: requiredIf(() => {
                            return this.contract.contractType === this.endorsementContractType;
                        })
                    },
                    spacSigningDate_formatted: {
                        required: requiredIf(() => {
                            return this.contract.contractType === this.agencyContractType;
                        })
                    },
                    equipmentSigningDate_formatted: {
                        required: requiredIf(() => {
                            return this.contract.contractType === this.equipmentContractType;
                        })
                    },
                    equipmentContract: {
                        required: requiredIf(() => {
                            return this.contract.contractType === this.equipmentContractType;
                        })
                    },
                    endorsementCompanyName: {
                        required: requiredIf(() => {
                            return this.contract.contractType === this.endorsementContractType;
                        })
                    },
                    limitedModifiedDate: {
                        required: requiredIf(() => {
                            return (this.contract.limitedModifiedNoTradeClause && this.contract.limitedModifiedOption === 'Specified Date');
                        })
                    },
                    limitedModifiedStartDate: {
                        required: requiredIf(() => {
                            return (this.contract.limitedModifiedNoTradeClause && this.contract.limitedModifiedOption === 'Specified Date');
                        })
                    },
                    limitedModifiedEndDate: {
                        required: requiredIf(() => {
                            return (this.contract.limitedModifiedNoTradeClause && this.contract.limitedModifiedOption === 'Specified Date');
                        })
                    }
                },
                selectedPlayer: {
                    id: { required}
                }
            }
        },
        computed: {
            agencyContractType() {
                return contractHelpers.agencyContractType;
            },
            ahlNhlTwoWayContractType() {
                return contractHelpers.ahlNhlTwoWayContractType;
            },
            ahlOneWayContractType() {
                return contractHelpers.ahlOneWayContractType;
            },
            ahlTwoWayContractType() {
                return contractHelpers.ahlTwoWayContractType;
            },
            contractAgentSelects() {
                return contractHelpers.contractAgentSelects;
            },
            contractNhlExpirationStatusSelects() {
                return contractHelpers.contractNhlExpirationStatusSelects;
            },
            contractNhlTypeSelects() {
                return contractHelpers.contractNhlTypeSelects;
            },
            contractSpcBillingTypeSelects() {
                return contractHelpers.contractSpcBillingTypeSelects;
            },
            echlContractType() {
                return contractHelpers.echlContractType;
            },
            elcBonusScheduleTypeSelects() {
                return contractHelpers.elcBonusScheduleTypeSelects;
            },
            elcContractType() {
                return contractHelpers.elcContractType;
            },
            endorsementContractType() {
                return contractHelpers.endorsementContractType;
            },
            endorsementContractTypeSelects() {
                return contractHelpers.endorsementContractTypeSelects;
            },
            equipmentContractSelects() {
                return contractHelpers.contractEquipmentContractSelects;
            },
            equipmentContractType() {
                return contractHelpers.equipmentContractType;
            },
            limitedModifiedNoTradeClauseTypes() {
                return contractHelpers.limitedModifiedNoTradeClauseTypes;
            },
            nhlOneWayContractType() {
                return contractHelpers.nhlOneWayContractType;
            },
            nhlTwoWayContractType() {
                return contractHelpers.nhlTwoWayContractType;
            },
            playingContractType() {
                return contractHelpers.playingContractType;
            }
        },
        watch: {
            showDialog(val) {
                if (val) {
                    this.showCreateContractType = true;
                    this.getPlayers();
                    this.getSeasonalSettings();
                } else {
                    this.resetDefaults();
                }
            },
            ahlOneWayContractDetails: {
                handler(val) {
                    if (val.length === 0) return;

                    val.forEach(item => {
                        if (!item.ahlTotalPayableDays) {
                            item.ahlTotalPayableDays = this.$store.state.seasonalSettings.ahlPlayableDays;
                        }

                        if (item.ahlOneWayAmount) {
                            item.ahlOneWayDailySalary = item.ahlOneWayAmount / item.ahlTotalPayableDays;
                        }
                    });
                },
                deep: true
            },
            ahlTwoWayContractDetails: {
                handler(val) {
                    if (val.length === 0) return;

                    val.forEach(item => {
                        if (!item.ahlTotalPayableDays) {
                            item.ahlTotalPayableDays = this.$store.state.seasonalSettings.ahlPlayableDays;
                        }

                        if (item.ahlTwoWayAmount) {
                            item.ahlDailySalary = item.ahlTwoWayAmount / item.ahlTotalPayableDays;
                        }

                        if (!item.echlTotalPayableDays) {
                            item.echlTotalPayableDays = this.$store.state.seasonalSettings.echlPlayableDays;
                        }

                        if (item.echlTwoWayAmount) {
                            item.echlDailySalary = item.echlTwoWayAmount / item.echlTotalPayableDays;
                        }
                    })
                }, 
                deep: true
            },
            ahlNhlTwoWayContractDetails: {
                handler(val) {
                    if (val.length === 0) return;

                    val.forEach(item => {
                        if (!item.ahlTotalPayableDays) {
                            item.ahlTotalPayableDays = this.$store.state.seasonalSettings.ahlPlayableDays;
                        }

                        if (!item.nhlTotalPayableDays) {
                            item.nhlTotalPayableDays = this.$store.state.seasonalSettings.nhlPlayableDays;
                        }

                        if (item.ahlTwoWayAmount) {
                            item.ahlDailySalary = item.ahlTwoWayAmount / item.ahlTotalPayableDays;
                        }

                        if (item.nhlTwoWayAmount) {
                            item.nhlDailySalary = item.nhlTwoWayAmount / item.nhlTotalPayableDays;
                        }
                    });
                },
                deep: true
            },
            nhlOneWayContractDetails: {
                handler(val) {
                    if (val.length === 0) return;

                    val.forEach(item => {
                        if (!item.nhlTotalPayableDays) {
                            item.nhlTotalPayableDays = this.$store.state.seasonalSettings.nhlPlayableDays;
                        }

                        if (item.nhlOneWayAmount) {
                            item.nhlOneWayDailySalary = item.nhlOneWayAmount / item.nhlTotalPayableDays;
                        }
                    });
                },
                deep: true
            }
        },
        mounted() {
        },
        methods: {
            buildAgencyContractName() {
                if (this.selectedPlayer.id && this.contract.spacSigningDate_formatted) {
                    this.contract.contractTitle = `${this.selectedPlayer.fullName} - Agency - ${this.contract.spacSigningDate_formatted.getFullYear()}`;
                }
            },
            buildEndorsementContractName() {
                if (this.selectedPlayer.id && this.contract.endorsementSigningDate_formatted && this.contract.endorsementCompanyName) {
                    this.contract.contractTitle = `${this.selectedPlayer.fullName} -  ${this.contract.endorsementCompanyName} - ${this.contract.endorsementSigningDate_formatted.toLocaleDateString()}`;
                }
            },
            buildEquipmentContractName() {
                if (this.selectedPlayer.id && this.contract.equipmentSigningDate_formatted && this.contract.equipmentContract) {
                    this.contract.contractTitle = `${this.selectedPlayer.fullName} - ${this.contract.equipmentContract} - ${this.contract.equipmentSigningDate_formatted.toLocaleDateString()}`;
                }
            },
            buildPlayingContractName() {
                let contractAbbr = this.getNhlContractTypeAbbreviation();

                if (this.selectedPlayer.id && this.contract.nhlContractType && this.contract.nhlContractStartYear_formatted && this.contract.nhlContractEndYear_formatted) {
                    this.contract.contractTitle = `${this.selectedPlayer.fullName} - ${contractAbbr} - ${this.contract.nhlContractStartYear_formatted.getFullYear()}/${this.contract.nhlContractEndYear_formatted.getFullYear()}`;
                }
            },
            async createContract(request) {
                let self = this;

                this.isLoading = true;
                this.$emit('dialogclosed');

                let tokenResponse = await auth.acquireTokenResponse();

                return apiService.CreateContract(tokenResponse.accessToken, request) 
                    .then(async () => {
                        await this.$store.dispatch('getContracts', true);

                        self.isLoading = false;
                        self.showNotification('Contract created successfully');

                        self.$emit('contractcreated');
                    }).catch(error => {
                        console.log(error);
                        self.onRequestError('An Error Occurred', error?.response?.data?.message);
                    });
            },
            defaultDropdownSelected(evt, field, defaultValue) {
                if (evt.target.value === defaultValue) {
                    return this.contract[field] = null;
                }

                this.contract[field] = evt.target.value;
            },
            elcBonusScheduleSelected(evt, defaultValue, itemIndex) {
                if (evt.target.value === defaultValue) {
                    return this.elcPerformanceBonuses[itemIndex].scheduleType = null;
                }

                this.elcPerformanceBonuses[itemIndex].scheduleType = evt.target.value;
                this.elcPerformanceBonuses[itemIndex].hasScheduleTypeError = false;
            },
            generateSalaryDetails(contractType) {
                let contractDetailModel;
                let contractDetailArrayName;

                switch(contractType) {
                    case this.elcContractType:
                        contractDetailModel = this.elcContractDetailModel;
                        contractDetailArrayName = 'elcContractDetails';
                        break;
                    case this.nhlOneWayContractType:
                        contractDetailModel = this.nhlOneWayContractModel;
                        contractDetailArrayName = 'nhlOneWayContractDetails';
                        break;
                    case this.nhlTwoWayContractType:
                        contractDetailModel = this.nhlTwoWayContractModel;
                        contractDetailArrayName = 'nhlTwoWayContractDetails';
                        break;
                    case this.ahlOneWayContractType:
                        contractDetailModel = this.ahlOneWayContractModel;
                        contractDetailArrayName = 'ahlOneWayContractDetails';
                        break;
                    case this.ahlTwoWayContractType:
                        contractDetailModel = this.ahlTwoWayContractModel;
                        contractDetailArrayName = 'ahlTwoWayContractDetails';
                        break;
                    case this.echlContractType:
                        contractDetailModel = this.echlContractDetailModel;
                        contractDetailArrayName = 'echlContractDetails';
                        break;
                    case this.ahlNhlTwoWayContractType:
                        contractDetailModel = this.ahlNhlTwoWayContractModel;
                        contractDetailArrayName = 'ahlNhlTwoWayContractDetails'
                }

                this[contractDetailArrayName] = [];

                for(let x = 0; x < this.contract.contractYears; x++) {
                    this[contractDetailArrayName].push(cloneDeep(contractDetailModel));
                    this[contractDetailArrayName][x].year = x + 1;
                    this[contractDetailArrayName][x].startYear = this.contract.nhlContractStartYear_formatted.getFullYear() + x;
                }
            },
            getContractDates(contract) {
                this.contractDateFields[contract.contractType].forEach(field => {
                    contract[field] = contract[`${field}_formatted`] ?? null;
                });

                return contract;
            },
            getEndorsementCommission() {
                if (this.contract.contractType === this.endorsementContractType || this.contract.contractType === this.equipmentContractType) {
                    let agencyContracts = this.selectedPlayerContracts.filter(contract => contract.contractType === this.agencyContractType);

                    if (agencyContracts.length > 0) {
                        let currentAgencyContract = agencyContracts.find(contract => contract.contractId === this.contract.associatedContractId);

                        if (currentAgencyContract) {
                            if (this.contract.contractType === this.endorsementContractType) {       
                                return this.contract.endorsementCommissionPercent = currentAgencyContract.endorsementCommissionPercent;
                            } 

                            return this.contract.equipmentCommissionRate = currentAgencyContract.endorsementCommissionPercent;
                        }
                    } 
                }
            },
            getNhlContractTypeAbbreviation() {
                switch(this.contract.nhlContractType) {
                    case this.nhlOneWayContractType:
                        return 'NHLOW';
                    case this.nhlTwoWayContractType:
                        return 'NHLTW';
                    case this.ahlOneWayContractType:
                        return 'AHLOW';
                    case this.ahlTwoWayContractType:
                        return 'AHLTW';
                    case this.ahlNhlTwoWayContractType:
                        return 'AHLNHLTW';
                    default:
                        return this.contract.nhlContractType;
                }
            },
            async getPlayerContracts(playerId) {
                this.isLoadingContracts = true;
                await this.$store.dispatch('getContracts', false);

                this.selectedPlayerContracts = cloneDeep(this.$store.state.contracts.filter(contract => contract.playerId === playerId));
                this.isLoadingContracts = false;

                if (!this.selectedPlayerContracts.some(contract => contract.contractType === this.agencyContractType) && this.contract.contractType !== this.agencyContractType) {
                        this.onRequestError('No Agency Contract Found', `Please create an agency contract for ${this.selectedPlayer.fullName} before creating other contracts`);
                    }
            },
            async getPlayers(update = true) {     
                await this.$store.dispatch('getPlayers', update);

                this.allPlayers = this.$store.state.players.map(player => {
                    return {
                        id: player.id,
                        fullName: player.fullName,
                        nhlRightsTeam: player.nhlRightsTeam
                    }
                });

                this.allPlayers.sort((a, b) => a.fullName.localeCompare(b.fullName));

                this.allPlayers.unshift({ id: null, fullName: 'Select Player' });

                this.filteredPlayers = cloneDeep(this.allPlayers);
            },
            async getSeasonalSettings(update = false) {
                await this.$store.dispatch('getSeasonalSettings', update);
            },
            onAddElcPerformanceBonus() {
                let newBonus = cloneDeep(this.elcPerformanceBonusModel);
                newBonus.bonusId = Math.random().toString();

                this.elcPerformanceBonuses.unshift(newBonus);
            },
            onAddEndorsementPayout() {
                let newPayout = cloneDeep(this.endorsementPayoutModel);
                newPayout.payoutId = Math.random().toString();

                this.endorsementPayouts.push(newPayout);
            },
            onAddEndorsementProductCredit() {
                let newCredit = cloneDeep(this.endorsementProductCreditModel);
                newCredit.productCreditId = Math.random().toString();

                this.endorsementProductCredits.push(newCredit);
            },
            onAddNhlSigningBonus() {
                let newBonus = cloneDeep(this.nhlSigningBonusModel);
                newBonus.bonusId = Math.random().toString();

                this.nhlSigningBonuses.push(newBonus);
            },
            onAddPerformanceBonus() {
                let newBonus = cloneDeep(this.performanceBonusModel);
                newBonus.bonusId = Math.random().toString();

                this.performanceBonuses.unshift(newBonus);
            },
            onAssociatedContractSelected(evt) {
                this.contract.associatedContractId = evt.target.value.contractId;

                if (this.contract.associatedContractId) {
                    this.getEndorsementCommission();
                }
            },
            onBackToContractType() {
                this.showCreateContractType = true;

                this.resetDefaults();
            },
            onCloseDialog() {
                this.$emit('dialogclosed');

                this.showCreateContractType = true;
            },
            onCloseNotification(flag) {
                this[flag] = false;
            },
            async onContractPlayerSelected() {
                this.contract$.selectedPlayer.id.$reset();

                if (this.selectedPlayer.id) {
                    await this.getPlayerContracts(this.selectedPlayer.id);

                    if (this.contract.contractType === this.playingContractType) {
                        this.buildPlayingContractName();
                    }

                    if (this.contract.contractType === this.endorsementContractType) {
                        this.buildEndorsementContractName();
                    }

                    if (this.contract.contractType === this.equipmentContractType) {
                        this.buildEquipmentContractName();
                    }

                    if (this.contract.contractType === this.agencyContractType) {
                        this.buildAgencyContractName();
                    }
                }
            },
            onContractTypeSelected(evt) {
                if (evt.value === 'Select Contract Type') {
                    return this.contract.contractType = '';
                }

                this.contract = cloneDeep(this.contractModel);

                this.contract.contractType = evt.value;
                this.showCreateContractType = false;
            },
            onDeleteElcPerformanceBonus(bonus) {
                this.elcPerformanceBonuses.splice(this.elcPerformanceBonuses.findIndex(p => p.bonusId === bonus.bonusId), 1);
            },
            onDeleteEndorsementPayout(payout) {
                this.endorsementPayouts.splice(this.endorsementPayouts.findIndex(p => p.payoutId === payout.payoutId), 1);
            },
            onDeleteEndorsementProductCredit(credit) {
                this.endorsementProductCredits.splice(this.endorsementProductCredits.findIndex(c => c.productCreditId === credit.productCreditId), 1);
            },
            onDeleteNhlSigningBonus(bonus) {
                this.nhlSigningBonuses.splice(this.nhlSigningBonuses.findIndex(p => p.bonusId === bonus.bonusId), 1);
            },
            onDeletePerformanceBonus(bonus) {
                this.performanceBonuses.splice(this.performanceBonuses.findIndex(p => p.bonusId === bonus.bonusId), 1);
            },
            onEquipmentContractSelected(evt, field, defaultValue) {
                this.contract$.contract.equipmentContract.$reset();

                this.defaultDropdownSelected(evt, field, defaultValue);

                if (this.contract.equipmentContract) {
                    this.buildEquipmentContractName();
                }
            },
            onNhlContractTypeSelected(evt, field, defaultValue) {
                this.contract$.contract.nhlContractType.$reset();

                this.contract.contractYears = this.contract.nhlContractEndYear_formatted.getFullYear() - this.contract.nhlContractStartYear_formatted.getFullYear();

                this.generateSalaryDetails(evt.target.value);
                
                this.defaultDropdownSelected(evt, field, defaultValue);

                if (this.contract.nhlContractType) {
                    this.buildPlayingContractName();
                }
            },
            onPlayerFilterChange(evt) {
                this.filteredPlayers = filterBy(this.allPlayers, evt.filter);
            },
            onRequestError(errorTitle, errorMsg) {
                this.isLoading = false;

                this.errorTitle = errorTitle;

                if (errorMsg) {
                    this.errorMsg = errorMsg;
                } else {
                    this.errorMsg = 'Please try again';
                }

                this.showErrorDialog = true;
            },
            async onSaveClicked() {
                await this.contract$.$validate();

                if (this.contract$.contract.$error || this.contract$.selectedPlayer.$error) return;

                if (!this.contract.limitedModifiedNoTradeClause) {
                        this.contract.limitedModifiedOption = null;
                        this.contract.limitedModifiedDate = null;
                        this.contract.limitedModifiedStartDate = null;
                        this.contract.limitedModifiedEndDate = null;
                        this.contract.limitedModifiedNumberTeams = null;
                }

                this.endorsementPayouts.forEach(payout => {
                    if (payout.payoutAmount && !payout.payoutDate) {
                        payout.hasPayoutDateError = true;
                    }

                    if (payout.payoutDate && !payout.payoutAmount) {
                        payout.hasPayoutAmountError = true;
                    }
                });

                this.endorsementProductCredits.forEach(credit => {
                    if ([credit.creditAmount, credit.startDate, credit.endDate].some(c => c) && ![credit.creditAmount, credit.startDate, credit.endDate].every(c => c)) {
                        if (!credit.creditAmount) {
                            credit.hasCreditAmountError = true;
                        }

                        if (!credit.startDate) {
                            credit.hasStartDateError = true;
                        }

                        if (!credit.endDate) {
                            credit.hasEndDateError = true;
                        }
                    }
                })

                this.elcPerformanceBonuses.forEach(bonus => {
                    if (!bonus.bonusAmount && bonus.scheduleType) {
                        bonus.hasBonusAmountError = true;
                    }

                    if (!bonus.scheduleType && bonus.bonusAmount) {
                        bonus.hasScheduleTypeError = true;
                    }
                });

                this.nhlSigningBonuses.forEach(bonus => {
                    if (bonus.bonusAmount && !bonus.bonusDate) {
                        bonus.hasBonusDateError = true;
                    }

                    if (bonus.bonusDate && !bonus.bonusAmount) {
                        bonus.hasBonusAmountError = true;
                    }
                });

                this.performanceBonuses.forEach(bonus => {
                    if (!bonus.bonusAmount && bonus.bonusTitle) {
                        bonus.hasBonusAmountError = true;
                    }

                    if (!bonus.bonusTitle && bonus.bonusAmount) {
                        bonus.hasBonusTitleError = true;
                    }
                });

                if ((this.performanceBonuses.some(bonus => bonus.hasBonusAmountError || bonus.hasBonusTitleError))
                    || (this.endorsementPayouts.some(payout => payout.hasPayoutAmountError || payout.hasPayoutDateError))
                    || (this.endorsementProductCredits.some(credit => credit.hasCreditAmountError || credit.hasStartDateError || credit.hasEndDateError))
                    || (this.elcPerformanceBonuses.some(bonus => bonus.hasBonusAmountError || bonus.hasScheduleTypeError))
                    || (this.nhlSigningBonuses.some(bonus => bonus.hasBonusAmountError || bonus.hasBonusDateError))) return;

                let contractDetailsField;

                if (this.contract.contractType === this.playingContractType) {
                    if (this.contract.nhlContractType === this.elcContractType) {
                        contractDetailsField = 'elc';
                    }

                    if (this.contract.nhlContractType === this.nhlOneWayContractType) {
                        contractDetailsField = 'nhlOneWay';
                    }

                    if (this.contract.nhlContractType === this.nhlTwoWayContractType) {
                        contractDetailsField = 'nhlTwoWay';
                    }

                    if (this.contract.nhlContractType === this.ahlOneWayContractType) {
                        contractDetailsField = 'ahlOneWay';
                    }

                    if (this.contract.nhlContractType === this.ahlTwoWayContractType) {
                        contractDetailsField = 'ahlTwoWay';
                    }

                    if (this.contract.nhlContractType === this.echlContractType) {
                        contractDetailsField = 'echl';
                    }

                    if (this.contract.nhlContractType === this.ahlNhlTwoWayContractType) {
                        contractDetailsField = 'ahlNhlTwoWay';
                    }
                }

                let request = {}; 

                if (this.contract.limitedModifiedNoTradeClause && this.contract.limitedModifiedOption === 'Specified Date' && this.contract.limitedModifiedDate && this.contract.limitedModifiedStartDate && this.contract.limitedModifiedEndDate) {
                    let tradeClauseStartDate = cloneDeep(this.contract.limitedModifiedStartDate);

                    this.contract.limitedModifiedDate.setFullYear(tradeClauseStartDate.getFullYear());

                    if (this.contract.limitedModifiedDate < tradeClauseStartDate) {
                        this.contract.limitedModifiedDate = date.addYears(this.contract.limitedModifiedDate, 1);
                    } 

                    this.noTradeClauses = [];
                    let tradeClauseYears = this.contract.limitedModifiedEndDate.getFullYear() - this.contract.limitedModifiedStartDate.getFullYear();

                    for(var i = 0; i <= tradeClauseYears; i++) {
                        this.noTradeClauses.push({ 
                            dueDate: new Date(this.contract.limitedModifiedDate.getFullYear() + i, this.contract.limitedModifiedDate.getMonth(), this.contract.limitedModifiedDate.getDate()),
                            playerId: this.selectedPlayer.id,
                            reportToTeam: this.selectedPlayer.nhlRightsTeam,
                            numberTeams: this.contract.limitedModifiedNumberTeams
                        });
                    }

                    request.noTradeClauses = this.noTradeClauses.filter(clause => clause.dueDate >= this.contract.limitedModifiedStartDate && clause.dueDate <= this.contract.limitedModifiedEndDate);
                }

                request[`${contractDetailsField}ContractType`] = true;
                request.ahlOneWayContractDetails = this.ahlOneWayContractDetails;
                request.ahlTwoWayContractDetails = this.ahlTwoWayContractDetails;
                request.echlContractDetails = this.echlContractDetails;
                request.elcContractDetails = this.elcContractDetails;
                request.nhlOneWayContractDetails = this.nhlOneWayContractDetails;
                request.nhlTwoWayContractDetails = this.nhlTwoWayContractDetails;
                request.ahlNhlTwoWayContractDetails = this.ahlNhlTwoWayContractDetails;
                request.contract = this.getContractDates(cloneDeep(this.contract));
                request.playerId = this.selectedPlayer.id;
                request.endorsementPayouts = cloneDeep(this.endorsementPayouts.filter(payout => payout.payoutAmount && payout.payoutDate));
                request.endorsementProductCredits = cloneDeep(this.endorsementProductCredits.filter(credit => credit.creditAmount && credit.startDate && credit.endDate));
                request.elcPerformanceBonuses = cloneDeep(this.elcPerformanceBonuses.filter(bonus => bonus.bonusAmount && bonus.scheduleType));
                request.nhlSigningBonuses = cloneDeep(this.nhlSigningBonuses.filter(bonus => bonus.bonusDate && bonus.bonusAmount));
                request.performanceBonuses = cloneDeep(this.performanceBonuses.filter(bonus => bonus.bonusTitle && bonus.bonusAmount));

                this.createContract(request);
            },
            resetDefaults() {
                this.contract$.$reset();
                this.selectedPlayer = { id: null, fullName: 'Select Player', nhlRightsTeam: null };
                this.elcPerformanceBonuses = [];
                this.endorsementPayouts = [];
                this.endorsementProductCredits = [];
                this.nhlSigningBonuses = [];
                this.performanceBonuses = [];
                this.contract = {};
                this.selectedPlayerContracts = [];
            },
            showNotification(notificationMsg) {
                this.notificationMsg = notificationMsg;

                this.successNotification = true;

                setTimeout(() => {
                    this.successNotification = false;
                }, 4000);
            },
            toggleErrorDialog() {
                this.showErrorDialog = !this.showErrorDialog;

                if(!this.showErrorDialog) {
                    this.errorTitle = '';
                    this.errorMsg = '';
                }
            }
        },
        filters: {
            toOrdinal(val) {
                switch (val) {
                    case 0:
                        return "1st";
                    case 1:
                        return "2nd";
                    case 2:
                        return "3rd";
                    case 3:
                        return "4th";
                    case 4:
                        return "5th";
                    case 5:
                        return "6th";
                    case 6:
                        return "7th";
                    case 7:
                        return "8th"
                }
            }
        },
        components: {
            'k-dropdown': DropDownList,
            'k-input': Input,
            'k-datepicker': DatePicker,
            'k-numeric-input': NumericTextBox,
            'k-textarea': TextArea,
            'k-button': Button,
            'k-slide': Slide,
            'k-dialog': Dialog,
            'k-action-bar': DialogActionsBar,
            'k-loader': Loader,
            'k-notification': Notification,
            'k-notification-group': NotificationGroup,
            'k-fade': Fade,
            'k-checkbox': Checkbox
        }
    }
</script>

<style>
    @import '/src/assets/css/defaultUpsertStyles.css';
    @import '/src/assets/css/createContractDialog.css';
</style>