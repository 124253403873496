<template>
    <home-component></home-component>
</template>

<script>
import homeComponent from '../components/homeComponent.vue';

import auth from '../authConfig';

export default {
  name: 'HomeView',
  components: {
    'home-component': homeComponent
  }
}
</script>
