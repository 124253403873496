<template>
    <div class="pipeline-view__container invoices-view">
        <view-header :title="'Invoices'">
            <template v-slot:viewicon>
                <i class="fa-duotone fa-file-invoice-dollar"></i>
            </template>
        </view-header>
        <k-grid
            :data-items="filteredItems"
            :data-item-key="'id'"
            :columns="columns"
            :sortable="sortable"
            :sort="sort"
            :filterable="true"
            :filter="filter"
            :group="group"
            :column-menu="true"
            :pageable="gridPageable"
            :take="take"
            :total="total"
            :skip="skip"
            @datastatechange="dataStateChange"
            @rowclick="onRowClick"
            @filterchange="onFilterChange"
            @sortchange="onSortChange">
            <template v-slot:pipelineActions="{ props }">
                <div class="pipeline-actions__container">
                    <k-button class="partial-payment__btn" @click.stop="onAddPayment(props.dataItem)" title="Add Partial Payment" :disabled="props.dataItem.status !== 'Paid in Part' && props.dataItem.status !== 'Invoice Sent'"><i class="fa-solid fa-dollar-sign"></i></k-button>
                    <k-button class="delete-item__btn" @click.stop="onDeleteItem(props.dataItem)" title="Delete Invoice" :disabled="props.dataItem.status !== 'Pending'"><i class="fa-duotone fa-trash"></i></k-button>
                </div>
            </template>
            <template v-slot:invoiceStatus="{ props }">
                <div class="pipeline-actions__container" @click.stop="">
                    <k-dropdown class="status__dropdown"
                                v-model="props.dataItem.statusUpdated"
                                :data-items="invoiceStatusSelects"
                                @change="onInvoiceStatusUpdated($event, props.dataItem)"></k-dropdown>
                </div>
            </template>
            <k-grid-toolbar>
                    <span class="k-textbox k-grid-search k-display-flex">
                        <k-input :style="{ width: '300px' }"
                                  placeholder="Search in all columns..."
                                  :value="searchWord"
                                  :icon-name="'search'"
                                  @input="onSearchChange"></k-input>
                    </span>
                    <div class="grid-button__group">
                        <k-button title="Create Invoice"
                                    class="create-invoice__btn"
                                    @click="onCreateItem">
                            <i class="fa-sharp fa-solid fa-file-invoice-dollar"></i> Create Invoice
                        </k-button>
                        <k-button title="Export to Excel"
                                    class="export-excel__btn"
                                    @click="exportExcel">
                            <i class="fa-solid fa-file-export"></i> Export to Excel
                        </k-button>
                        <div class="settings__icon" title="Seasonal Settings" @click="toggleSettingsDialog">
                            <i class="fa-duotone fa-gear"></i>
                        </div>
                    </div>
            </k-grid-toolbar>
        </k-grid>

        <seasonal-settings-dialog :show-dialog="showSettingsDialog"
                                    @dialogclosed="toggleSettingsDialog"></seasonal-settings-dialog>

        <create-invoice-dialog :show-dialog="showCreateDialog"
                                @dialogclosed="toggleCreateDialog"
                                @invoicecreated="getItems"></create-invoice-dialog>

        <invoice-payment-dialog :show-dialog="showInvoicePaymentDialog"
                                :selected-invoice="paidInPartInvoice"
                                @dialogclosed="onPaymentCanceled"
                                @paymentEntered="onPaymentEntered"></invoice-payment-dialog>

        <invoice-payment-date-dialog :show-dialog="showInvoicePaymentDateDialog"
                                     :selected-invoice="paidInvoice"
                                     @dialogclosed="onPaymentDateCanceled"
                                     @dateentered="onPaymentDateEntered"></invoice-payment-date-dialog>
                                    

        <confirm-dialog :show-dialog="showConfirmDialog"
                        :dialog-Msg="confirmMsg"
                        :icon-class="confirmIconClass"
                        @dialogclosed="toggleConfirmDialog"
                        @cancelclicked="toggleConfirmDialog"
                        @confirmclicked="deleteItem"></confirm-dialog>

        <div class="loader-container" v-if="isLoading">
            <k-loader :size="'large'" :type="'infinite-spinner'"></k-loader>
        </div>

        <transition name="dialogfade">
            <k-dialog v-if="showErrorDialog" :title="errorTitle" class="error__dialog" @close="toggleErrorDialog">
                <p class="error-dialog__msg">{{ errorMsg }}</p>
            </k-dialog>
        </transition>

        <k-notification-group class="default-notification-container">
                <k-fade :appear="successNotification">
                    <k-notification v-if="successNotification"
                                    :type="{ style: 'success', icon: true }"
                                    :closable="true"
                                    @close="onCloseNotification('successNotification')">
                        <span>{{ notificationMsg }}</span>
                    </k-notification>
                </k-fade>
        </k-notification-group>
    </div>
</template>

<script>
    import { Dialog } from '@progress/kendo-vue-dialogs';
    import { Grid, GridToolbar } from '@progress/kendo-vue-grid';
    import { Loader } from "@progress/kendo-vue-indicators";
    import { Input } from "@progress/kendo-vue-inputs";
    import { Button } from "@progress/kendo-vue-buttons";
    import { Notification, NotificationGroup } from "@progress/kendo-vue-notification";
    import { Fade } from "@progress/kendo-vue-animation";
    import { DropDownList } from '@progress/kendo-vue-dropdowns';
    
    import { saveExcel } from '@progress/kendo-vue-excel-export';
    import { orderBy, filterBy, process } from '@progress/kendo-data-query';

    import viewHeader from '../components/viewHeader.vue';
    import confirmDialog from '../components/confirmDialog.vue';
    import seasonalSettingsDialog from '../components/seasonalSettingsDialog.vue';
    import createInvoiceDialog from '../components/createInvoiceDialog.vue';
    import invoicePaymentDialog from '../components/invoicePaymentDialog.vue';
    import invoicePaymentDateDialog from '../components/invoicePaymentDateDialog.vue';

    import cloneDeep from 'lodash.clonedeep';

    import auth from '../authConfig';
    import apiService from '../api/api.service.js';
    import invoiceHelpers from '../helpers/invoiceHelpers.js';

    export default {
        name: 'invoicesView',
        data() {
            return {
                allItems: [],
                columns: [
                    { field: 'fullName', title: 'Player' },
                    { field: 'invoiceNo', title: 'Invoice No' },
                    { field: 'totalDue', title: 'Total Due', type: 'number', filter: 'numeric', format: '{0:c2}' },
                    { field: 'balanceRemaining', title: 'Balance Remaining', type: 'number', filter: 'numeric', format: '{0:c2}' },
                    { cell: 'invoiceStatus', field: 'status', title: 'Status' },
                    { field: 'invoiceDate', title: 'Invoice Date', filter: 'date', type: 'date', format: '{0:M/d/y}' },
                    { field: 'paymentDue', title: 'Due Date', filter: 'date', type: 'date', format: '{0:M/d/y}' },
                    { field: 'datePaid', title: 'Date Paid', filter: 'date', type: 'date', format: '{0:M/d/y}' },
                    { cell: 'pipelineActions', filterable: false, columnMenu: false, width: '100' }
                ],
                confirmIconClass: '',
                confirmMsg: '',
                createTitle: 'Create Invoice',
                deleteItemId: '',
                errorMsg: '',
                errorTitle: '',
                filter: null,
                filteredItems: [],
                gridPageable: {
                    buttonCount: 5,
                    info: true,
                    type: "numeric",
                    pageSizes: [5, 10, 20, 50],
                    previousNext: true,
                },
                group: [],
                isLoading: false,
                notificationMsg: '',
                onLoadFilters: [
                    { text: 'InvoiceSent', value: 'Invoice Sent' },
                    { text: 'Pending', value: 'Pending' },
                    { text: 'Paid', value: 'Paid' },
                    { text: 'PaidinPart', value: 'Paid in Part' },
                    { text: 'Approval', value: 'Approval' },
                    { text: 'Holding', value: 'Holding' },
                    { text: 'WaitingApproval', value: 'Waiting Approval' },
                    { text: 'Waived', value: 'Waived' }
                ],
                paidInvoice: null,
                paidInPartInvoice: null,
                searchWord: '',
                showConfirmDialog: false,
                showCreateDialog: false,
                showErrorDialog: false,
                showInvoicePaymentDateDialog: false,
                showInvoicePaymentDialog: false,
                showSettingsDialog: false,
                skip: 0,
                sort: [{ field: 'paymentDue', dir: 'asc' }],
                sortable: { allowUnsort: false, mode: 'single' },
                successNotification: false,
                take: 50,
                total: 0
            }
        },
        computed: {
            invoiceStatusSelects() {
                return invoiceHelpers.invoiceStatusSelects;
            }
        },
        watch: {
            '$store.state.errorAlert'(val) {
                if (val) {
                    this.onRequestError(this.$store.state.errorTitle, this.$store.state.errorMsg);

                    let errorData = {
                        isError: false,
                        title: '',
                        msg: ''
                    }
                    
                    this.$store.commit('updateErrorAlert', errorData);
                }
            }
        },
        mounted() {
            let filter = this.$route.query.filter;
            let year = this.$route.query.year;

            if (filter) {
                this.getOnLoadFilter(filter, year);
            }

            this.getItems();
        },
        methods: {
            createAppState(dataState) {
                this.group = dataState.group;
                this.take = dataState.take;
                this.skip = dataState.skip;
                this.sort = dataState.sort;
                this.filter = dataState.filter;

                this.getData();
            },
            dataStateChange(event) {
                this.createAppState(event.data);
            },
            async deleteItem() {
                let self = this;

                this.isLoading = true;
                this.toggleConfirmDialog();

                let tokenResponse = await auth.acquireTokenResponse();

                return apiService.DeleteInvoice(tokenResponse.accessToken, this.deleteItemId) 
                    .then(() => {
                        self.getItems(true);
                        self.showNotification('Invoice deleted successfully');
                    }).catch(error => {
                        console.log(error);
                        self.onRequestError('An Error Occurred', error?.response?.data?.message);
                    });
            },
            exportExcel() {
                let data = this.getFilteredItems();

                saveExcel({
                    data: data,
                    fileName: "Invoices",
                    columns: this.columns
                });
            },
            getData() {
                let filteredData = this.getFilteredItems();
              
                this.filteredItems = process(filteredData, {
                    take: this.take,
                    skip: this.skip,
                    group: this.group,
                    sort: this.sort,
                    filter: this.filter,
                }).data;

                this.total = process(filteredData, {
                    filter: this.filter
                }).total;

                this.isLoading = false;
            },
            getFilteredItems() {
                if (this.searchWord) {
                    this.filteredItems = this.allItems.filter(item => {
                        return Object.values(item).some(val => val && val.toString().toLowerCase().includes(this.searchWord.toLowerCase()));
                    });
                } else {
                    this.filteredItems = cloneDeep(this.allItems);
                }

                if (this.sort.length > 0) {
                    this.filteredItems = orderBy(this.filteredItems, this.sort);
                }

                if (this.filter) {
                    this.filteredItems = filterBy(this.filteredItems, this.filter);
                }

                return this.filteredItems;
            },
            async getItems(update = false) {     
                this.isLoading = true;
                await this.$store.dispatch('getInvoices', update);

                this.allItems = cloneDeep(this.$store.state.invoices);
                console.log(this.allItems)
        
                this.getData();
            },
            getOnLoadFilter(filterParam, yearParam) {
                let onLoadFilter = this.onLoadFilters.find(filter => filter.text === filterParam);

                let filter = {
                    logic: 'and',
                    filters: [
                        { field: 'status', operator: 'equals', value: onLoadFilter.value }
                    ]
                }

                if (yearParam && yearParam != 'All') {
                    let startDate = new Date(+yearParam, 0, 1);
                    let endDate = new Date(+yearParam, 11, 31);

                    filter.filters.push({ field: 'invoiceDate', operator: 'gte', value: startDate });
                    filter.filters.push({ field: 'invoiceDate', operator: 'lte', value: endDate });
                }

                this.onFilterChange({ filter: filter });
            },
            onAddPayment(invoice) {
                invoice.statusUpdated = 'Paid in Part';
                this.paidInPartInvoice = invoice;
                this.toggleInvoicePaymentDialog();
            },
            onCloseNotification(flag) {
                this[flag] = false;
            },
            onCreateItem() {
                this.toggleCreateDialog();
            },
            onDeleteItem(item) {
                this.confirmIconClass='fa-solid fa-trash-can';
                this.deleteItemId = item.id;
                this.confirmMsg = `Are you sure you would like to delete <span>${item.invoiceNo}</span>?`;
                this.toggleConfirmDialog();
            },
            onFilterChange(evt) {
                this.filter = evt.filter;
                this.getData();
            },
            onInvoiceStatusUpdated(evt, invoice) {
                if (invoice.statusUpdated === 'Paid in Part') {
                    this.paidInPartInvoice = invoice;
                    return this.toggleInvoicePaymentDialog();
                }

                if (invoice.statusUpdated === 'Paid') {
                    invoice.balanceRemaining = 0;
                    this.paidInvoice = invoice;
                    return this.toggleInvoicePaymentDateDialog();
                }

                invoice.status = invoice.statusUpdated;

                let request = {
                    playerId: invoice.playerId,
                    invoiceId: invoice.id,
                    invoiceUpdated: true,
                    invoice: invoice
                }

                this.updateInvoice(request);
            },
            onPaymentCanceled() {
                this.paidInPartInvoice.statusUpdated = this.paidInPartInvoice.status;

                this.toggleInvoicePaymentDialog();
            },
            onPaymentDateCanceled() {
                this.paidInvoice.statusUpdated = this.paidInvoice.status;

                this.toggleInvoicePaymentDateDialog();
            },
            onPaymentDateEntered(datePaid) {
                this.toggleInvoicePaymentDateDialog();

                this.paidInvoice.balanceRemaining = 0;
                this.paidInvoice.status = this.paidInvoice.statusUpdated;
                this.paidInvoice.datePaid = datePaid;

                let request = {
                    playerId: this.paidInvoice.playerId,
                    invoiceId: this.paidInvoice.id,
                    invoiceUpdated: true,
                    invoice: this.paidInvoice
                }

                this.updateInvoice(request);
            },
            onPaymentEntered(payment) {
                this.toggleInvoicePaymentDialog();

                this.paidInPartInvoice.balanceRemaining -= payment.paymentAmount;
                this.paidInPartInvoice.status = this.paidInPartInvoice.statusUpdated;

                payment.invoiceId = this.paidInPartInvoice.id;

                let request = {
                    playerId: this.paidInPartInvoice.playerId,
                    invoiceId: this.paidInPartInvoice.id,
                    invoiceUpdated: true,
                    invoice: this.paidInPartInvoice,
                    paymentsUpdated: true,
                    invoicePayments: [payment]
                }

                this.updateInvoice(request);
            },
            onRequestError(errorTitle, errorMsg) {
                this.isLoading = false;

                this.errorTitle = errorTitle;

                if (errorMsg) {
                    this.errorMsg = errorMsg;
                } else {
                    this.errorMsg = 'Please try again';
                }

                this.showErrorDialog = true;
            },
            onRowClick(evt) {
                this.$router.push(`/InvoiceDetail?id=${evt.dataItem.id}&playerId=${evt.dataItem.playerId}`);
            },
            onSearchChange(evt) {
                this.searchWord = evt.value;
                this.getData();
            },
            onSortChange(evt) {
                this.sort = evt.sort;
                this.getData();
            },
            showNotification(notificationMsg) {
                this.notificationMsg = notificationMsg;

                this.successNotification = true;

                setTimeout(() => {
                    this.successNotification = false;
                }, 4000);
            },
            toggleConfirmDialog() {
                this.showConfirmDialog = !this.showConfirmDialog;
            },
            toggleCreateDialog() {
                this.showCreateDialog = !this.showCreateDialog;
            },
            toggleErrorDialog() {
                this.showErrorDialog = !this.showErrorDialog;

                if(!this.showErrorDialog) {
                    this.errorTitle = '';
                    this.errorMsg = '';
                }
            },
            toggleInvoicePaymentDateDialog() {
                this.showInvoicePaymentDateDialog = !this.showInvoicePaymentDateDialog
            },
            toggleInvoicePaymentDialog() {
                this.showInvoicePaymentDialog = !this.showInvoicePaymentDialog;
            },
            toggleSettingsDialog() {
                this.showSettingsDialog = !this.showSettingsDialog;
            },
            async updateInvoice(request) {
                request.invoice.lastUpdateBy = auth.user()?.name;

                let self = this;
                this.isLoading = true;

                let tokenResponse = await auth.acquireTokenResponse();

                return apiService.UpdateInvoice(tokenResponse.accessToken, request) 
                    .then(async () => {
                        await this.$store.dispatch('getInvoices', true);

                        self.isLoading = false;
                        self.showNotification('Invoice updated successfully');

                        self.getItems();
                    }).catch(error => {
                        console.log(error);
                        self.onRequestError('An Error Occurred', error?.response?.data?.message);
                    });
            }
        },
        components: {
            'k-dialog': Dialog,
            'k-loader': Loader,
            'view-header': viewHeader,
            'k-input': Input,
            'k-button': Button,
            'confirm-dialog': confirmDialog,
            'k-notification': Notification,
            'k-notification-group': NotificationGroup,
            'k-fade': Fade,
            'seasonal-settings-dialog': seasonalSettingsDialog,
            'create-invoice-dialog': createInvoiceDialog,
            'k-dropdown': DropDownList,
            'k-grid': Grid,
            'k-grid-toolbar': GridToolbar,
            'invoice-payment-dialog': invoicePaymentDialog,
            'invoice-payment-date-dialog': invoicePaymentDateDialog
        }
    }
</script>

<style>
    @import '/src/assets/css/pipelineViewContainer.css';
    @import '/src/assets/css/defaultUpsertStyles.css';
    @import '/src/assets/css/invoicesView.css';
</style>