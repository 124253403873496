<template>
    <div class="pipeline-view__container home-component">
        <div class="dashboard-toggle">
            <p :class="['toggle-label', { 'active': showAgentView }]">Agent</p>
            <k-switch :on-label="''" :off-label="''" v-model="showAgentView" :size="'small'"></k-switch>
            <p :class="['toggle-label', { 'active': !showAgentView }]">Management</p>
        </div>
        <div class="dashboard__container" v-if="!showAgentView && !loadingUser">
            <div class="home__tabstrip-container">
                <dashboard-tab-component></dashboard-tab-component>
            </div>
            <div class="home__side-card-container">
                <!-- <open-invoices-component></open-invoices-component> -->
                <dashboard-puckpedia-component />
            </div>
        </div>
        <div class="dashboard__container dashboard--agent" v-if="showAgentView && !loadingUser">
            <!-- <agent-dashboard-component></agent-dashboard-component> -->
            <nhl-dashboard-component />
            <dashboard-puckpedia-component />
        </div>

        <div class="loader-container" v-if="loadingUser">
            <k-loader :size="'large'" :type="'infinite-spinner'"></k-loader>
        </div>
    </div>
</template>

<script>
    import { Switch } from "@progress/kendo-vue-inputs";
    import { Loader } from "@progress/kendo-vue-indicators";
 
    import dashboardTabComponent from '../components/dashboardTabComponent.vue';
    // openInvoicesComponent from './openInvoicesComponent.vue';
    import agentDashboardComponent from "./agentDashboardComponent.vue";
    import dashboardPuckPediaComponent from "./dashboardPuckPediaComponent.vue";
    import nhlDashboardComponent from "./nhlDashboardComponent.vue";

    export default {
        name: 'homeComponent',
        data () {
            return {
                agentDefaultEmail: 'dmilstein@goldstarfinancial.com',
                //agentDefaultEmail: 'cfernando@goldstarfinancial.com',
                loadingUser: false,
                showAgentView: false,
                user: null
            }
        },
        watch: {
            showAgentView(val) {
            }
        },
        async created () {
            this.loadingUser = true;

            await this.$store.dispatch('getUser');
            this.user = this.$store.state.user;

            if (this.user) {
                if (this.user.userprincipalname === this.agentDefaultEmail) {
                    this.showAgentView = true;
                }
            }

            this.loadingUser = false;
        },
        components: {
            'dashboard-tab-component': dashboardTabComponent,
            //'open-invoices-component': openInvoicesComponent,
            'k-switch': Switch,
            //'agent-dashboard-component': agentDashboardComponent,
            'k-loader': Loader,
            'dashboard-puckpedia-component': dashboardPuckPediaComponent,
            'nhl-dashboard-component': nhlDashboardComponent
        }
    }
</script>

<style>
    @import '/src/assets/css/pipelineViewContainer.css';
    @import '/src/assets/css/homeComponent.css';
</style>