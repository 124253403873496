<template>
    <k-card class="agent-dashboard-card">
        <k-card-header>
            <p class="card--title"><i :class="cardIconClass"></i> {{ cardTitle }}</p>
            <div class="header-actions__container">
                <k-button class="draft-reorder__button" @click="toggleDraftReorderDialog" v-if="cardType === 'draft'"><i class="fa-regular fa-arrow-up-arrow-down"></i> Reorder</k-button>
                <span class="k-textbox k-grid-search k-display-flex" v-if="agentDashboard">
                    <k-input :style="{ width: '300px' }"
                                placeholder="Search in all columns..."
                                :value="searchWord"
                                :icon-name="'search'"
                                v-model="searchWord"></k-input>
                </span>
            </div>
        </k-card-header>
        <k-card-body>
            <detail-view-grid  :columns="gridColumns"
                                :initial-sort="cardInitialSort"
                                :item-id-field="'playerId'"
                                :items="filteredGridItems"
                                :row-clickable="true"
                                :row-click-function="onGoToItem"
                                :agent-dashboard="agentDashboard"
                                :agent-search-word="searchWord"
                                :delete-action="false"
                                @filtereditems="assignItemTotal">
                </detail-view-grid>
        </k-card-body>
        <k-card-footer>
            <k-dropdown class="season-select-dropdown"
                        v-model="selectedSeason"
                        :data-items="seasonDropdownSelects"
                        @change="onSeasonSelected">
            </k-dropdown>
            <p class="item-total">{{ itemTotal }} Records</p>
        </k-card-footer>
    </k-card> 
</template>

<script>
    import {
        Card,
        CardHeader,
        CardBody,
        CardFooter
    } from "@progress/kendo-vue-layout";
    import { Input } from "@progress/kendo-vue-inputs";
    import { DropDownList } from '@progress/kendo-vue-dropdowns';
    import { Button } from "@progress/kendo-vue-buttons";

    import detailViewGrid from '../components/detailViewGrid.vue';

    export default {
        name: 'agentDashboardCard',
        props: ['agentDashboard', 'cardIconClass', 'cardInitialSort', 'cardTitle', 'cardType', 'currentSeason', 'currentSeasonStart', 'gridColumns', 'gridItems', 'nextSeason'],
        emits: ['drafteligibleyearselected', 'itemclicked', 'toggledraftreorderdialog'],
        data () {
            return {
                itemTotal: 0,
                searchWord: '',
                selectedSeason: '',
                showThisSeason: true
            }
        },
        mounted() {
            if (this.cardType === 'draft') {
                this.selectedSeason = this.currentSeasonStart;
            } else {
                this.selectedSeason = this.currentSeason;
            }
        },
        computed: {
            filteredGridItems() {
                if (this.cardType === 'contract') {
                    let selectedEndYear = +this.selectedSeason.split(' / ')[1];

                    return this.gridItems.filter(item => item.endDate?.getFullYear() === selectedEndYear);
                }

                if (this.cardType === 'draft') {
                    let selectedStartYear = +this.selectedSeason;

                    return this.gridItems.filter(item => item.draftEligibleDate?.getFullYear() === selectedStartYear);
                }

                return this.gridItems;
            },
            seasonDropdownSelects() {
                let seasonSelects = [];
                                
                if (this.cardType === 'contract') {
                    let maxExpirationYear = this.gridItems.reduce((prev, cur) => {
                        if (cur.endDate) {
                            return (prev = prev > cur.endDate.getFullYear() ? prev : cur.endDate.getFullYear());
                        } else {
                            return prev;
                        }
                    }, this.currentSeasonStart);

                    for (let i = this.currentSeasonStart; i < maxExpirationYear; i++ ) {
                        seasonSelects.push(`${i} / ${i + 1}`);
                    }
                }

                if (this.cardType === 'draft') {
                    let maxEligibleYear = this.gridItems.reduce((prev, cur) => {
                        if (cur.draftEligibleDate) {
                            return (prev = prev > cur.draftEligibleDate.getFullYear() ? prev : cur.draftEligibleDate.getFullYear());
                        } else {
                            return prev;
                        }
                    }, this.currentSeasonStart);

                    for (let i = this.currentSeasonStart; i <= maxEligibleYear; i++) {
                        seasonSelects.push(i);
                    }
                }

                return seasonSelects; 
            }
        },
        watch: {
        },
        methods: {
            assignItemTotal(total) {
                this.itemTotal = total;
            },
            onGoToItem(evt) {
                this.$emit('itemclicked', evt);
            },
            onSeasonSelected() {
                this.$emit('drafteligibleyearselected', +this.selectedSeason);
            },
            toggleDraftReorderDialog() {
                this.$emit('toggledraftreorderdialog');
            }
        },
        components: {
            'k-card': Card,
            'k-card-header': CardHeader,
            'k-card-body': CardBody,
            'detail-view-grid': detailViewGrid,
            'k-input': Input,
            'k-card-footer': CardFooter,
            'k-dropdown': DropDownList,
            'k-button': Button,
        }
    }
</script>

<style scoped>
    @import '/src/assets/css/agentDashboardCard.css';
</style>
