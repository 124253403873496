import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";
import PlayerManagementView from "../views/PlayerManagementView.vue";
import PlayerDetailView from "../views/PlayerDetailView.vue";
import ContractManagementView from "../views/ContractManagementView.vue";
import ContractDetailView from "../views/ContractDetailView.vue";
import CalendarView from "../views/CalendarView.vue";
import CitizenshipDocumentationView from "../views/CitizenshipDocumentationView.vue";
import InvoicesView from "../views/InvoicesView.vue";
import InvoiceDetailView from "../views/InvoiceDetailView.vue";
import NoTradeReportingView from "../views/NoTradeReportingView.vue";
import DocumentView from "../views/DocumentView.vue";
import ScoutingReportsView from "../views/ScoutingReportsView.vue";

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/PlayerManagement",
    name: "playermanagement",
    component: PlayerManagementView
  },
  {
    path: "/ContractManagement",
    name: "contractmanagement",
    component: ContractManagementView
  },
  {
    path: "/Invoices",
    name: "invoices",
    query: { filter: '', year: '' },
    component: InvoicesView
  },
  {
    path: '/InvoiceDetail',
    name: 'invoicedetail',
    query: { id: '', playerId: '' },
    component: InvoiceDetailView
  },
  {
    path: "/Calendar",
    name: "calendar",
    component: CalendarView,
  },
  {
    path: '/PlayerDetail',
    name: 'playerdetail',
    query: { id: '' },
    component: PlayerDetailView
  },
  {
    path: '/Document',
    name: 'documentview',
    query: { id: '' },
    component: DocumentView
  },
  {
    path: '/ContractDetail',
    name: 'contractdetail',
    query: { id: '' },
    component: ContractDetailView
  },
  {
    path: '/CitizenshipDocumentation',
    name: 'citizenshipdocumentation',
    component: CitizenshipDocumentationView
  },
  {
    path: '/NoTradeReporting',
    name: 'notradereporting',
    component: NoTradeReportingView
  },
  {
    path: '/ScoutingReports',
    name: 'scoutingreports',
    component: ScoutingReportsView
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
