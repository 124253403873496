<template>
    <div class="pipeline-view__container scouting-reports-view">
        <view-header :title="'Scouting Reports'">
            <template v-slot:viewicon>
                <i class="fa-duotone fa-solid fa-clipboard-list"></i>
            </template>
        </view-header>
        <k-grid
            :data-items="filteredItems"
            :data-item-key="'id'"
            :columns="columns"
            :sortable="sortable"
            :sort="sort"
            :filterable="false"
            :filter="filter"
            :group="group"
            :column-menu="true"
            :pageable="gridPageable"
            :take="take"
            :total="total"
            :skip="skip"
            @datastatechange="dataStateChange"
            @rowclick="onRowClick"
            @filterchange="onFilterChange"
            @sortchange="onSortChange">
            <template v-slot:pipelineActions="{ props }">
                <div class="pipeline-actions__container">
                    <k-button class="upload-doc__btn" v-if="!props.dataItem.associatedDocumentId" @click.stop="onUploadDocument(props.dataItem)" title="Add Associated Document"><i class="fa-sharp fa-solid fa-plus"></i></k-button>
                    <k-button class="open-doc__btn" v-if="props.dataItem.associatedDocumentId" @click.stop="onOpenDocument(props.dataItem)" title="View Associated Document"><i class="fa-duotone fa-folder-open"></i></k-button>
                    <k-button class="edit-doc__btn" v-if="props.dataItem.associatedDocumentId" @click.stop="onEditDocument(props.dataItem)" title="Edit Associated Document"><i class="fa-duotone fa-pen-to-square"></i></k-button>

                    <k-button class="delete-item__btn" @click.stop="onDeleteItem(props.dataItem)" title="Delete Document"><i class="fa-duotone fa-trash"></i></k-button>
                </div>
            </template>
            <template v-slot:poiCell="{ props }">
                <td class="poi-cell">
                    <span :class="['poi', { 'yes': props.dataItem.poi === 'Y' }, { 'no': props.dataItem.poi === 'N' }]">{{ props.dataItem.poi }}</span>
                </td>
            </template>
            <k-grid-toolbar>
                    <span class="k-textbox k-grid-search k-display-flex">
                        <k-input :style="{ width: '300px' }"
                                  placeholder="Search in all columns..."
                                  :value="searchWord"
                                  :icon-name="'search'"
                                  @input="onSearchChange"></k-input>
                    </span>
                    <k-dropdown class="grid-filter__select" 
                                :data-items="filterSelects" 
                                v-model="selectedFilter"></k-dropdown>
                    <div class="grid-button__group">
                        <k-button title="Create Report"
                                    class="create-report__btn"
                                    @click="onCreateItem">
                            <i class="fa-solid fa-passport"></i> Create Report
                        </k-button>
                        <k-button title="Export to Excel"
                                    class="export-excel__btn"
                                    @click="exportExcel">
                            <i class="fa-solid fa-file-export"></i> Export to Excel
                        </k-button>
                    </div>
            </k-grid-toolbar>
        </k-grid>

        <upsert-player-document-dialog :show-dialog="showUploadDocumentDialog"
                                    :player-id="selectedPlayerId"
                                    :report-id="selectedReport?.reportId"
                                    :default-folder-name="documentFolderNames.scoutingReportsFolderName"
                                    :current-document="scoutingDocuments?.length > 0 ? scoutingDocuments[0] : null"
                                    @dialogclosed="toggleUploadDocumentDialog"
                                    @documentsuploaded="getItems(true)"></upsert-player-document-dialog>

        <confirm-dialog :showDialog="showConfirmDialog"
                        :dialog-Msg="confirmMsg"
                        :icon-class="confirmIconClass"
                        @dialogclosed="toggleConfirmDialog"
                        @cancelclicked="toggleConfirmDialog"
                        @confirmclicked="deleteItem"></confirm-dialog>

        <div class="loader-container" v-if="isLoading">
            <k-loader :size="'large'" :type="'infinite-spinner'"></k-loader> 
        </div>

        <transition name="dialogfade">
            <k-dialog v-if="showErrorDialog" :title="errorTitle" class="error__dialog" @close="toggleErrorDialog">
                <p class="error-dialog__msg">{{ errorMsg }}</p>
            </k-dialog>
        </transition>

        <create-scouting-report-dialog :show-dialog="showCreateDialog"
                                       @dialogclosed="toggleCreateDialog"
                                       @reportcreated="getItems"></create-scouting-report-dialog>

        <update-scouting-report-dialog :show-dialog="showUpdateDialog"
                                       :selected-report="selectedReport"
                                       @dialogclosed="toggleUpdateDialog"
                                       @reportupdated="getItems"></update-scouting-report-dialog>

        <k-notification-group class="default-notification-container">
                <k-fade :appear="successNotification">
                    <k-notification v-if="successNotification"
                                    :type="{ style: 'success', icon: true }"
                                    :closable="true"
                                    @close="onCloseNotification('successNotification')">
                        <span>{{ notificationMsg }}</span>
                    </k-notification>
                </k-fade>
        </k-notification-group>
    </div>
</template>

<script>
    import { DropDownList } from '@progress/kendo-vue-dropdowns';
    import { Dialog } from '@progress/kendo-vue-dialogs';
    import { Grid, GridToolbar } from '@progress/kendo-vue-grid';
    import { Loader } from "@progress/kendo-vue-indicators";
    import { Input } from "@progress/kendo-vue-inputs";
    import { Button } from "@progress/kendo-vue-buttons";
    import { Notification, NotificationGroup } from "@progress/kendo-vue-notification";
    import { Fade } from "@progress/kendo-vue-animation";

    import { saveExcel } from '@progress/kendo-vue-excel-export';
    import { orderBy, filterBy, process } from '@progress/kendo-data-query';

    import viewHeader from '../components/viewHeader.vue';
    import upsertDocumentDialog from '../components/upsertDocumentDialog.vue';
    import confirmDialog from '../components/confirmDialog.vue';
    import createScoutingReportDialog from '../components/createScoutingReportDialog.vue';
    import updateScoutingReportDialog from '../components/updateScoutingReportDialog.vue';

    import cloneDeep from 'lodash.clonedeep';

    import auth from '../authConfig';
    import apiService from '../api/api.service.js';
    import documentHelpers from '../helpers/documentHelpers.js';

    export default {
        name: 'scoutingReportsView',
        data() {
            return {
                allItems: [],
                columns: [
                    { field: 'poi', title: 'POI' },
                    { field: 'fullName', title: 'Player' },
                    { field: 'status', title: 'Status' },
                    { field: 'positionString', title: 'Position(s)' },
                    { field: 'gameDate', title: 'Game Date', filter: 'date', type: 'date', format: '{0:M/d/y}' },
                    { field: 'speed', title: 'Speed', filter: 'numeric', type: 'number' },
                    { field: 'agility', title: 'Agility', filter: 'numeric', type: 'number' },
                    { field: 'shot', title: 'Shot', filter: 'numeric', type: 'number' },
                    { field: 'stickHandling', title: 'Handling', filter: 'numeric', type: 'number' },
                    { field: 'playmakingWithoutPuck', title: 'PM', filter: 'numeric', type: 'number' },
                    { field: 'playmakingWithPuck', title: 'PM / P', filter: 'numeric', type: 'number' },
                    { field: 'consistencyEffort', title: 'C / E', filter: 'numeric', type: 'number' },
                    { field: 'scout', title: 'Scout' },
                    { cell: 'pipelineActions', filterable: false, columnMenu: false }
                ],
                confirmIconClass: '',
                confirmMsg: '',
                createTitle: 'Create Report',
                deleteItemId: '',
                deleteItemType: '',
                filter: null,
                filteredItems: [],
                filterSelects: ['All Players', 'Clients', 'Targets'],
                gridPageable: {
                    buttonCount: 5,
                    info: true,
                    type: "numeric",
                    pageSizes: [5, 10, 20, 50],
                    previousNext: true,
                },
                group: [],
                isLoading: false,
                notificationMsg: '',
                scoutingDocuments: [],
                searchWord: '',
                selectedFilter: 'All Players',
                selectedReport: null,
                selectedPlayerId: null,
                showConfirmDialog: false,
                showCreateDialog: false,
                showErrorDialog: false,
                showUpdateDialog: false,
                showUploadDocumentDialog: false,
                skip: 0,
                sort: [{ field: 'gameDate', dir: 'des' }],
                sortable: { allowUnsort: false, mode: 'single' },
                successNotification: false,
                take: 50,
                total: 0
            }
        },
        mounted() {
            this.getItems();
        },
        computed: {
            documentFolderNames() {
                return documentHelpers.documentFolderNames;
            },
        },
        watch: {
            '$store.state.errorAlert'(val) {
                if (val) {
                    this.onRequestError(this.$store.state.errorTitle, this.$store.state.errorMsg);

                    let errorData = {
                        isError: false,
                        title: '',
                        msg: ''
                    }
                    
                    this.$store.commit('updateErrorAlert', errorData);
                }
            },
            selectedFilter(val) {
                let filter;

                switch(val) {
                case 'All Players':
                    filter = { field: 'status', operator: 'contains', value: '' };
                    break;
                case 'Clients':
                    filter = { field: 'status', operator: 'contains', value: 'Client' };
                    break;
                case 'Targets':
                    filter = { field: 'status', operator: 'contains', value: 'Target' }
                }

                if (!this.filter) {
                    this.onFilterChange({ filter: { filters: [ filter ], logic: 'and' } });
                } else {
                    let statusFilterIndex = this.getStatusFilterIndex();

                    if (statusFilterIndex >= 0) {
                        this.filter.filters.splice(statusFilterIndex, 1, filter );
                    } else {
                        this.filter.filters.push(filter);
                    }

                    this.onFilterChange({ filter: this.filter });
                }
            }
        },
        methods: {
            createAppState(dataState) {
                this.group = dataState.group;
                this.take = dataState.take;
                this.skip = dataState.skip;
                this.sort = dataState.sort;
                this.filter = dataState.filter;

                this.getData();
            },
            dataStateChange(event) {
                this.createAppState(event.data);
            },
            async deleteItem() {
                let self = this;

                this.isLoading = true;
                this.toggleConfirmDialog();

                let tokenResponse = await auth.acquireTokenResponse();

                return apiService.DeleteScoutingReport(tokenResponse.accessToken, this.deleteItemId) 
                    .then(() => {
                        self.getItems(true);
                        self.showNotification('Report deleted successfully');
                    }).catch(error => {
                        console.log(error);
                        self.onRequestError('An Error Occurred', error?.response?.data?.message);
                    });
            },
            exportExcel() {
                let data = this.getFilteredItems();

                saveExcel({
                    data: data,
                    fileName: "Scouting Reports",
                    columns: this.columns
                });
            },
            async getAssociatedScoutingReport() {
                let self = this;

                let tokenResponse = await auth.acquireTokenResponse();

                return apiService.GetPlayerDocument(tokenResponse.accessToken, this.selectedReport.associatedDocumentId)
                    .then((res) => {
                        self.scoutingDocuments = res.data.data;
                    }).catch(error => {
                        console.log(error);
                        self.onRequestError('An Error Occurred', error?.response?.data?.message);
                    });
            },
            getData() {
                let filteredData = this.getFilteredItems();
              
                this.filteredItems = process(filteredData, {
                    take: this.take,
                    skip: this.skip,
                    group: this.group,
                    sort: this.sort,
                    filter: this.filter,
                }).data;

                this.total = process(filteredData, {
                    filter: this.filter
                }).total;

                this.isLoading = false;
            },
            getFilteredItems() {
                if (this.searchWord) {
                    this.filteredItems = this.allItems.filter(item => {
                        return Object.values(item).some(val => val && val.toString().toLowerCase().includes(this.searchWord.toLowerCase()));
                    });
                } else {
                    this.filteredItems = cloneDeep(this.allItems);
                }

                if (this.sort.length > 0) {
                    this.filteredItems = orderBy(this.filteredItems, this.sort);
                }

                if (this.filter) {
                    this.filteredItems = filterBy(this.filteredItems, this.filter);

                    let statusFilterIndex = this.getStatusFilterIndex();

                    if (statusFilterIndex === -1) {
                        this.selectedFilter = 'All Players';
                    } 
                } else {
                    this.selectedFilter = 'All Players';
                }

                return this.filteredItems;
            },
            async getItems(update = false) {
                this.isLoading = true;
                await this.$store.dispatch('getScoutingReports', update);

                this.allItems = cloneDeep(this.$store.state.scoutingReports);
                console.log(this.allItems);

                this.getData();
            },
            getStatusFilterIndex() {
                return this.filter.filters.findIndex(filter => filter.field === 'status' || (filter.filters && filter.filters.some(f => f.field === 'status')));
            },
            onCloseNotification(flag) {
                this[flag] = false;
            },
            onCreateItem() {
                this.toggleCreateDialog();
            },
            onDeleteItem(item) {
                this.confirmIconClass='fa-solid fa-trash-can';
                this.deleteItemId = item.reportId;
                this.deleteItemType = item.type;
                this.confirmMsg = `Are you sure you would like to delete this Report?`;
                this.toggleConfirmDialog();
            },
            async onEditDocument(scoutingReport) {
                this.selectedPlayerId = scoutingReport.playerId;
                this.selectedReport = scoutingReport;

                await this.getAssociatedScoutingReport();

                this.toggleUploadDocumentDialog();
            },
            onFilterChange(evt) {
                this.filter = evt.filter;
                this.getData();
            },
            async onOpenDocument(scoutingReport) {
                this.selectedReport = scoutingReport;

                await this.getAssociatedScoutingReport();

                if (this.scoutingDocuments.length > 0) {
                    let selectedPlayerDocument = this.scoutingDocuments[0];
                    let routeData = this.$router.resolve({name: 'documentview', query: { id: selectedPlayerDocument.documentId }});
                    window.open(routeData.href, '_blank');
                }
            },
            onRequestError(errorTitle, errorMsg) {
                this.isLoading = false;

                this.errorTitle = errorTitle;

                if (errorMsg) {
                    this.errorMsg = errorMsg;
                } else {
                    this.errorMsg = 'Please try again';
                }

                this.showErrorDialog = true;
            },
            onRowClick(evt) {
                this.selectedReport = evt.dataItem;

                this.toggleUpdateDialog();
            },
            onSearchChange(evt) {
                this.searchWord = evt.value;
                this.getData();
            },
            onSortChange(evt) {
                this.sort = evt.sort;
                this.getData();
            },
            onUploadDocument(scoutingReport) {
                this.selectedPlayerId = scoutingReport.playerId;
                this.selectedReport = scoutingReport;

                this.toggleUploadDocumentDialog();
            },
            showNotification(notificationMsg) {
                this.notificationMsg = notificationMsg;

                this.successNotification = true;

                setTimeout(() => {
                    this.successNotification = false;
                }, 4000);
            },
            toggleConfirmDialog() {
                this.showConfirmDialog = !this.showConfirmDialog;
            },
            toggleCreateDialog() {
                this.showCreateDialog = !this.showCreateDialog;
            },
            toggleErrorDialog() {
                this.showErrorDialog = !this.showErrorDialog;

                if(!this.showErrorDialog) {
                    this.errorTitle = '';
                    this.errorMsg = '';
                }
            },
            toggleUpdateDialog() {
                this.showUpdateDialog = !this.showUpdateDialog;

                if (!this.showUpdateDialog) {
                    this.selectedReport = null;
                }
            },
            toggleUploadDocumentDialog() {
                this.showUploadDocumentDialog = !this.showUploadDocumentDialog;
            }
        },
        components: {
            'k-dialog': Dialog,
            'k-grid': Grid,
            'k-grid-toolbar': GridToolbar,
            'k-loader': Loader,
            'view-header': viewHeader,
            'k-input': Input,
            'k-button': Button,
            'confirm-dialog': confirmDialog,
            'k-notification': Notification,
            'k-notification-group': NotificationGroup,
            'k-fade': Fade,
            'upsert-player-document-dialog': upsertDocumentDialog,
            'create-scouting-report-dialog': createScoutingReportDialog,
            'update-scouting-report-dialog': updateScoutingReportDialog,
            'k-dropdown': DropDownList
        }
    }
</script>

<style>
    @import '/src/assets/css/pipelineViewContainer.css';
    @import '/src/assets/css/defaultUpsertStyles.css';
    @import '/src/assets/css/scoutingReportsView.css';
</style>