<template>
    <div class="detail-view-grid-season-select">
            <view-header :title="cardTitle">
                <template v-slot:viewicon>
                    <i class="fa-duotone fa-layer-group"></i>
                </template>
                <template v-slot:viewfilters>
                    <k-dropdown class="season-select-dropdown"
                                v-model="selectedSeason"
                                :data-items="seasonDropdownSelects"
                                @change="onSeasonSelected">
                    </k-dropdown>
                </template>
                <template v-slot:viewactions>
                </template>
            </view-header>
            <detail-view-grid  :columns="gridColumns"
                                :initial-sort="cardInitialSort"
                                :item-id-field="'playerId'"
                                :items="filteredGridItems"
                                :row-clickable="true"
                                :row-click-function="onGoToItem"
                                :agent-dashboard="agentDashboard"
                                :agent-search-word="searchWord"
                                :delete-action="false" 
                                :allow-export="true"
                                :export-title="cardTitle"
                                @filtereditems="assignItemTotal"
                                @playerupdated="onPlayerUpdated"> 
                </detail-view-grid>
    </div>
</template>

<script>
    import { DropDownList } from '@progress/kendo-vue-dropdowns';

    import detailViewGrid from '../components/detailViewGrid.vue';
    import viewHeader from '../components/viewHeader.vue';

    export default {
        name: 'agentDashboardCard',
        props: ['agentDashboard', 'cardIconClass', 'cardInitialSort', 'cardTitle', 'cardType', 'currentSeason', 'currentSeasonStart', 'gridColumns', 'gridItems', 'nextSeason'],
        emits: ['drafteligibleyearselected', 'itemclicked', 'playerupdated', 'toggledraftreorderdialog'],
        data () {
            return {
                itemTotal: 0,
                searchWord: '',
                selectedSeason: '',
                showThisSeason: true
            }
        },
        mounted() {
            if (this.cardType === 'draft') {
                this.selectedSeason = this.currentSeasonStart;
            } else {
                this.selectedSeason = this.currentSeason;
            }
        },
        computed: {
            filteredGridItems() {
                if (this.cardType === 'contract') {
                    let selectedEndYear = +this.selectedSeason.split(' / ')[1];

                    return this.gridItems.filter(item => item.endDate?.getFullYear() === selectedEndYear);
                }

                if (this.cardType === 'draft') {
                    let selectedStartYear = +this.selectedSeason;

                    return this.gridItems.filter(item => item.draftEligibleDate?.getFullYear() === selectedStartYear);
                }

                return this.gridItems;
            },
            seasonDropdownSelects() {
                let seasonSelects = [];
                                
                if (this.cardType === 'contract') {
                    let maxExpirationYear = this.gridItems.reduce((prev, cur) => {
                        if (cur.endDate) {
                            return (prev = prev > cur.endDate.getFullYear() ? prev : cur.endDate.getFullYear());
                        } else {
                            return prev;
                        }
                    }, this.currentSeasonStart);

                    for (let i = this.currentSeasonStart; i < maxExpirationYear; i++ ) {
                        seasonSelects.push(`${i} / ${i + 1}`);
                    }
                }

                if (this.cardType === 'draft') {
                    let maxEligibleYear = this.gridItems.reduce((prev, cur) => {
                        if (cur.draftEligibleDate) {
                            return (prev = prev > cur.draftEligibleDate.getFullYear() ? prev : cur.draftEligibleDate.getFullYear());
                        } else {
                            return prev;
                        }
                    }, this.currentSeasonStart);

                    for (let i = this.currentSeasonStart; i <= maxEligibleYear; i++) {
                        seasonSelects.push(i);
                    }
                }

                return seasonSelects; 
            }
        },
        watch: {
        },
        methods: {
            assignItemTotal(total) {
                this.itemTotal = total;
            },
            onGoToItem(evt) {
                this.$emit('itemclicked', evt);
            },
            onPlayerUpdated(request) {
                this.$emit('playerupdated', request);
            },
            onSeasonSelected() {
                this.$emit('drafteligibleyearselected', +this.selectedSeason);
            },
            toggleDraftReorderDialog() {
                this.$emit('toggledraftreorderdialog');
            }
        },
        components: {
            'detail-view-grid': detailViewGrid,
            'k-dropdown': DropDownList,
            'view-header': viewHeader
        }
    }
</script>

<style scoped>
    @import '/src/assets/css/detailViewGridSeasonSelect.css';
</style>
