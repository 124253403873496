export default {
    invoiceDateFields: ['createdOn', 'invoiceDate', 'paymentDue', 'updatedOn', 'datePaid'],
    invoiceDateTimeFields: [],
    invoiceItemCategoryManagementFee: 'Management Fee',
    invoiceItemCategoryEndorsementFee: 'Endorsement Fee',
    invoiceItemCategoryEscrow: 'Escrow',
    invoiceItemCategoryEscrowBonus: 'Escrow - Bonus',
    invoiceItemCategoryBalanceRemaining: 'Balance Remaining',
    invoiceItemCategoryExpense: 'Expense',
    invoiceItemCategoryCredit: 'Credit',
    invoiceItemCategoryMiscellaneous: 'Miscellaneous',
    invoiceItemCategorySelects: ['Management Fee', 'Endorsement Fee', 'Escrow', 'Escrow - Bonus', 'Balance Remaining', 'Expense', 'Credit', 'Miscellaneous'],
    invoiceItemDateFields: ['dateEarned'],
    invoiceItemManagementFeeNameSelects: ['Signing Bonus', 'NHL Salary', 'AHL Salary', 'NHL Salary (AHL Days)', 'ECHL Salary', 'Performance Bonus'],
    invoiceItemNameSigningBonus: 'Signing Bonus',
    invoiceItemNamePerformanceBonus: 'Performance Bonus',
    invoiceItemNameNHLAHLSalaryDays: 'NHL Salary (AHL Days)',
    invoiceItemNameNHLSalaryDays: 'NHL Salary',
    invoiceItemNameAHLSalaryDays: 'AHL Salary',
    invoiceItemNameECHLSalaryDays: 'ECHL Salary',
    invoiceStatusSelects: ['Pending', 'Holding', 'Waiting Approval', 'Approval', 'Invoice Sent', 'Paid', 'Paid in Part', 'Waived'],
    salaryDetailDateFields: ['firstPayPeriod', 'lastPayPeriod'],
    stateSelectsAbbr: [
        'AK', 'AL', 'AR', 'AZ', 'CA', 'CO', 'CT', 'DC', 'DE', 'FL', 'GA', 'HI', 'IA', 'ID', 'IL', 'IN', 'KS', 'KY', 'LA', 'MA', 'MD', 'ME', 'MI',
        'MN', 'MO', 'MS', 'MT', 'NC', 'ND', 'NE', 'NH', 'NJ', 'NM', 'NV', 'NY', 'OH', 'OK', 'OR', 'PA', 'RI', 'SC', 'SD', 'TN', 'TX', 'UT', 'VA', 'VT', 'WA', 'WI', 'WV', 'WY',
    ]
}